import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const UploadOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.47 4.776l.53-.53.53.53 3 3a.75.75 0 01-1.06 1.061l-1.72-1.72v8.19a.75.75 0 01-1.5 0v-8.19l-1.72 1.72a.75.75 0 11-1.06-1.06l3-3zM6.5 19.056a.75.75 0 010-1.5h11a.75.75 0 010 1.5h-11z"
      fill="#000"
    />
  </SvgIcon>
);
export default UploadOutline;
