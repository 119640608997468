import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ContentSquareOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2 20.5H8.8c-.865 0-1.423-.001-1.848-.036-.408-.033-.559-.09-.633-.127a1.5 1.5 0 01-.656-.656c-.037-.074-.094-.225-.127-.633-.035-.425-.036-.983-.036-1.848V6.8c0-.865.001-1.423.036-1.848.033-.408.09-.559.127-.633a1.5 1.5 0 01.656-.656c.074-.037.225-.094.633-.127C7.377 3.5 7.935 3.5 8.8 3.5h6.4c.865 0 1.423.001 1.848.036.408.033.559.09.633.127a1.5 1.5 0 01.655.656c.038.074.095.225.128.633.035.425.036.983.036 1.848v10.4c0 .865-.001 1.423-.036 1.848-.033.408-.09.559-.127.633a1.5 1.5 0 01-.656.655c-.074.038-.225.095-.633.128-.425.035-.983.036-1.848.036zm-10.873-.138C4 19.72 4 18.88 4 17.2V6.8c0-1.68 0-2.52.327-3.162a3 3 0 011.311-1.311C6.28 2 7.12 2 8.8 2h6.4c1.68 0 2.52 0 3.162.327a3 3 0 011.311 1.311C20 4.28 20 5.12 20 6.8v10.4c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C17.72 22 16.88 22 15.2 22H8.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311zm4.77-6.404L6.733 17.18a.2.2 0 00.162.318h10.233a.2.2 0 00.167-.31l-2.941-4.457a.2.2 0 00-.325-.012l-2.582 3.338a.2.2 0 01-.313.004l-1.72-2.113a.2.2 0 00-.317.008zM7.747 6.49a.75.75 0 100 1.5h8.5a.75.75 0 000-1.5h-8.5zm0 2.512a.75.75 0 100 1.5h6.995a.75.75 0 000-1.5H7.747z"
    />
  </SvgIcon>
);
export default ContentSquareOutline;
