import request from 'common/utils/axios-request';

import { ContentGroup } from './types/ContentTypeGroups';

export function contentGroupsGet(): Promise<readonly ContentGroup[]> {
  return request('/api/v1/content-type-groups', { method: 'GET' }).then(({ data }) => data);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function contentGroupsCreate(description: string): Promise<any> {
  return request('api/v1/content-type-groups', { method: 'POST', data: { description } }).then(
    (data) => data
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function contentGroupsEdit(contentGroup: ContentGroup): Promise<any> {
  return request('api/v1/content-type-groups', { method: 'PUT', data: contentGroup }).then(
    (data) => data
  );
}
