import { makeStyles, Theme } from '@material-ui/core/styles';
import { SIDEBAR_WIDTH, TOP_NAV_HEIGHT } from 'common/constants/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: '17px',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    paddingRight: '8px',
    background: theme.palette.surface[900],
    height: TOP_NAV_HEIGHT,
  },
  toggledRoot: {
    justifyContent: 'start',
  },
  selectedProject: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: SIDEBAR_WIDTH - 70,
    margin: '0 0 0 8px',
    fontSize: 14,
    color: '#fff',
  },
  selected: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    width: '100%',
    border: 'none',
    outline: 'none',
    padding: 0,
    '& > svg': {
      color: '#505F79',
    },
  },
  bottomLine: {
    background: '#D0D0D0',
    height: 3,
    borderRadius: 3,
    border: 0,
    marginTop: 11,
    marginBottom: 0,
  },
  menu: {
    maxWidth: 279,
    maxHeight: 350,
    listStyle: 'none',
    '& .MuiPopover-paper': {
      maxWidth: '100% !important',
      left: '0px !important',
      top: '52px !important',
    },
  },
  menuItem: {
    cursor: 'pointer',
    padding: '4px 0 4px 16px',
    fontSize: 14,
    textAlign: 'left',
    lineHeight: '20px',
    color: theme.palette.surface[500],
    border: 'none',
    width: '100%',
    background: 'transparent',
    display: 'flex',
    alignItems: 'center',
    minHeight: 32,
    '&:hover': {
      background: '#EBECF0',
    },
  },
  projectIcon: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    display: 'flex',
    fontSize: 16,
    color: '#505F79',
    fontWeight: 600,
    textTransform: 'uppercase',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #EBECF0',
  },
  selectedIcon: {
    background: '#ffffff',
    '& p': {
      fontSize: '20px !important',
    },
  },
  menuIcon: {
    background: '#F3F3F3',
    marginRight: 16,
  },
  nowrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: 130,
    marginLeft: 'auto',
  },
  hint: {
    position: 'absolute',
    background: '#253858',
    borderRadius: 3,
    top: 15,
    left: 65,
    color: 'white',
    whiteSpace: 'nowrap',
    padding: 8,
    fontSize: '13px',
  },
  selectAll: {
    padding: '4px 12px 4px 56px',
  },
  workspaceName: {
    width: 'calc(100% - 68px)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  empty: {
    width: 40,
  },
  checkbox: {},
  emptyWorkspace: {
    color: 'white',
    fontSize: 14,
  },
}));
