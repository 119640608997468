import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((_theme: Theme) => ({
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 36,
    alignItems: 'center',
  },
}));
