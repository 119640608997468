import { EmptyDataIcon } from 'components/IconPelican';
import { useStyles } from 'components/TableDynamic/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const NoData: React.FC = () => {
  const classes = useStyles({});
  const { t } = useTranslation();
  return (
    <div className={classes.empty}>
      <EmptyDataIcon />
      <p>{t('Empty data')}</p>
    </div>
  );
};

export default NoData;
