import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const CheckCircleFill: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21a9 9 0 100-18 9 9 0 000 18zm5.207-11.293a1 1 0 00-1.414-1.414L10.5 13.586l-2.293-2.293a1 1 0 00-1.414 1.414l3.707 3.707 6.707-6.707z"
      fill="#14B23B"
    />
  </SvgIcon>
);
export default CheckCircleFill;
