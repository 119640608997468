import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ModelConceptOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 2.744a.75.75 0 00-1.5 0v2.048a2.501 2.501 0 00-1.206 3.943L7.036 14.75H5a.75.75 0 000 1.5h1.286L4.25 20.323V21.5L5 22l.75-.5v-.823l2.214-4.427h3.286v1.25a.75.75 0 001.5 0v-1.25h3.287l2.213 4.427v.823l.75.5.75-.5v-1.177l-2.037-4.073H19a.75.75 0 000-1.5h-2.037l-3.007-6.015a2.501 2.501 0 00-1.206-3.943V2.744zM8.714 14.75h2.536V13.5a.75.75 0 011.5 0v1.25h2.537L12.7 9.578a2.5 2.5 0 01-1.4 0L8.714 14.75z"
    />
  </SvgIcon>
);
export default ModelConceptOutline;
