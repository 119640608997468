import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const NetworkTreeOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.75 4.465v2.5h4.5v-2.5h-4.5zm-1.5 3a1 1 0 001 1h2v2.285H9.003a2.337 2.337 0 00-1.82.973c-.383.52-.56 1.158-.59 1.74v2.028H4.75a1 1 0 00-1 1v3.5a1 1 0 001 1h5.5a1 1 0 001-1v-3.5a1 1 0 00-1-1H8.093v-1.97c.02-.35.128-.68.297-.91a.837.837 0 01.665-.361H14.947c.304.017.51.152.665.362.17.23.278.56.298.908v1.971h-2.16a1 1 0 00-1 1v3.5a1 1 0 001 1h5.5a1 1 0 001-1v-3.5a1 1 0 00-1-1h-1.84v-2.028c-.03-.582-.207-1.22-.59-1.74a2.338 2.338 0 00-1.822-.972l-.016-.001H12.75V8.465h2a1 1 0 001-1v-3.5a1 1 0 00-1-1h-5.5a1 1 0 00-1 1v3.5zm-3 12.026v-2.5h4.5v2.5h-4.5zm9-2.5v2.5h4.5v-2.5h-4.5z"
      fill="#000"
    />
  </SvgIcon>
);
export default NetworkTreeOutline;
