import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    borderRadius: 4,
    border: 'none',
    borderImage: 'none',
    height: '100%',

    '& .MuiInputBase-root': {
      background: '#F7F8FA',
      borderRadius: 4,
      fontSize: 14,
      minHeight: 32,
      paddingRight: 0,
    },
    '& .MuiInputBase-input': {
      background: '#F7F8FA',
      padding: '6px 8px',
      fontSize: 14,
      borderImage: 'none',
      border: 'none',
      '&:placeholder': {
        color: '#8F8F91',
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #D3D4D6',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: 0,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 6,
    },
    '& .MuiInputAdornment-positionStart': {
      marginRight: 6,
    },
    width: '100%',
  },
  variantSizeSmall: {
    '& .MuiInputBase-root': {
      fontSize: 14,
      minHeight: 28,
    },
  },
  paddingWithIcon: {
    '& .MuiInputBase-input': {
      padding: '6px 8px 6px 0',
    },
  },
  displayNone: {
    display: 'none',
  },
});
