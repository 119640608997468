import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { Props } from './props';
import { useStyles } from './styles';

export const VerticalTab: React.FC<Props> = ({ index, value, handleTabClick, children }) => {
  const classes = useStyles();
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <li
      className={clsx(classes.tabItem, value === index && classes.active)}
      onClick={() => handleTabClick(index)}
    >
      <Typography variant="body2">{children}</Typography>
    </li>
  );
};
