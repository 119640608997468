import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
    padding: '4px 20px',
    background: theme.palette.surface[50],
    display: 'flex',
    alignItems: 'center',
    width: 'max-content',
    position: 'fixed',
    left: '50%',
    transform: 'translate(-50%)',
    bottom: 54,
    zIndex: 100,
  },
  count: {},
  buttons: {
    display: 'flex',
  },
}));
