import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  formItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(1.5),
  },
  label: {
    color: '#545456',
    flex: 0,
    paddingTop: 5,
    minWidth: '20%',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    textAlign: 'right',
  },
  verticalLabel: {
    color: '#545456',
    textAlign: 'left',
  },
  input: {
    display: 'flex',
    flexDirection: 'column',
    flex: 4,
  },
  error: {
    color: theme.palette.error.main,
  },
  displayNone: {
    display: 'none',
  },
  vertical: {
    flexDirection: 'column',
  },
  required: {
    color: '#F44336',
  },
}));
