import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    padding: '8px 12px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  tag: {
    fontWeight: 'bold',
    fontSize: 11,
    color: '#fff',
    backgroundColor: '#36B37E',
    borderRadius: 3,
    textTransform: 'uppercase',
    padding: '1px 7px 0 7px',
    marginBottom: 6,
    display: 'inline-block',
  },
  title: {
    fonWeight: 400,
    fontSize: '14px',
    color: '#161618',
    marginTop: 8,
    marginBottom: 8,
  },
  comment: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#545456',
  },
  action: {
    marginLeft: 'auto',
  },
});
