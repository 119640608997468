import { Divider } from '@material-ui/core';
import { Space } from 'components/Space';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxPelican } from '../../CheckboxPelican';
import { useDataGrid } from '../DataGridContext';
import { Props } from './props';
import { useStyles } from './styles';

export const DataGridActions: React.FC<Props> = ({ onToggle, children }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { selectedRows } = useDataGrid();

  return (
    <>
      {selectedRows?.length > 0 && (
        <div className={styles.root}>
          <div className={styles.count}>
            <CheckboxPelican
              checked={true}
              onClick={() => onToggle(false)}
              style={{ marginRight: 6 }}
            />
            {selectedRows?.length}{' '}
            {t(selectedRows?.length > 1 ? 'items selected' : 'item selected')}
          </div>
          <Divider
            orientation="vertical"
            color="#AFB0B2"
            style={{ height: 16, margin: '0 16px' }}
          />
          <Space size={'small'}>{children}</Space>
        </div>
      )}
    </>
  );
};

export default DataGridActions;
