import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    padding: '10px 16px',
  },
  title: {
    textTransform: 'uppercase',
    color: '#545456',
    fontWeight: 600,
    marginBottom: 0,
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));
