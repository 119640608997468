import { ToggleButtonGroup } from '@material-ui/lab';
import React from 'react';

import { Props } from './props';

export const SwitchButtonGroup: React.FC<Props> = ({ handleChangeValue, children, ...rest }) => {
  return (
    <ToggleButtonGroup {...rest} onChange={(_e, val) => val && handleChangeValue(val)}>
      {children}
    </ToggleButtonGroup>
  );
};
