import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  BREADCRUMBS_HEIGHT,
  SIDEBAR_WIDTH,
  TOGGLE_SIDEBAR_WIDTH,
  TOP_NAV_HEIGHT,
} from 'common/constants/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    sidebar: {
      height: `calc(100% - ${TOP_NAV_HEIGHT}px)`,
      position: 'fixed',
      transition: '0.3s',
      width: SIDEBAR_WIDTH,
      zIndex: 1100,
    },
    main: {
      height: `calc(100% - ${TOP_NAV_HEIGHT}px)`,
      marginTop: TOP_NAV_HEIGHT,
    },
    container: ({ sidebarOn }: { readonly sidebarOn: boolean }) => ({
      marginLeft: !sidebarOn ? SIDEBAR_WIDTH : TOGGLE_SIDEBAR_WIDTH,
      width: `calc(100% - ${!sidebarOn ? SIDEBAR_WIDTH : TOGGLE_SIDEBAR_WIDTH}px)`,
      transition: '0.3s',
      background: '#fff',
      height: '100%',
    }),
    content: {
      marginTop: 0,
      height: '100%',
      width: '100%',
    },
    heightWithBreadcrumbs: {
      height: `calc(100% - ${BREADCRUMBS_HEIGHT}px)`,
    },
    hide: {
      width: TOGGLE_SIDEBAR_WIDTH,
      transition: '0.3s',
    },
    menu: {
      position: 'absolute',
      right: '-40px',
      top: '5px',
      cursor: 'pointer',
    },
    stretch: {
      width: '100%',
      transition: '0.3s',
      height: '100%',
      background: '#fff',
    },
    loginModal: {
      // filter: 'blur(10px)',
    },
    paddingWithTabs: {
      // paddingTop: 56 + CONTENT_TABS_HEIGHT,
    },
  })
);
