import { contentItemModerate, contentItemsSendMessage } from 'api/content';

import { RequestResponse } from './types/Response';

export const sendMessage = async (
  contentTypeSlug: string,
  sourceId: number,
  message: string
): Promise<RequestResponse> => {
  return await contentItemsSendMessage(contentTypeSlug, sourceId, message);
};

export const moderate = async (
  contentTypeSlug: string,
  sourceIds: readonly number[],
  action: string
): Promise<RequestResponse> => {
  return await contentItemModerate(contentTypeSlug, sourceIds, action);
};
