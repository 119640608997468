import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { assetsCreate } from 'api/assets';
import axios, { AxiosProgressEvent } from 'axios';
import { bytesToSize, getCorrectTranslate } from 'common/utils/helpers';
import { ButtonPelican } from 'components/ButtonPelican';
import { PenFill, TrashOutline } from 'components/IconPelican/icons';
import { ModalPelican } from 'components/ModalPelican';
import { SelectItem, SelectPelican } from 'components/SelectPelican';
import { Space } from 'components/Space';
import { useStyles as useFileUploadStyles } from 'components/VideoUpload/styles';
import ImageEditor from 'containers/ImageEditor/component';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getProjects } from 'store/workspaceSlice';

import { Props } from './props';
import { useStyles } from './styles';

const AssetsCreate: React.FC<Props> = ({ onCloseModal, open, ...rest }) => {
  const fileUploadStyles = useFileUploadStyles();
  const styles = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [files, setFiles] = useState<ReadonlyArray<File>>([]);
  const [imageForEdit, setImageForEdit] = useState<File | undefined>();
  const [imageIndexForEdit, setImageIndexForEdit] = useState<number | undefined>();
  const [destroyRestricted, setDestroyRestricted] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [selectedProjects, setSelectedProjects] = useState<string>('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [assetActionsIndex, setAssetActionsIndex] = useState<number>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [currentAbortController, setCurrentAbortController] = useState<AbortController>();

  const projects = useSelector(getProjects).map(
    (p): SelectItem => ({ label: p.title, value: p.slug })
  );

  useEffect(() => {
    setDestroyRestricted(false);
  }, [files]);

  const assignNewFiles = useCallback((files: FileList) => {
    setFiles((prev) => [
      ...prev,
      ...Array.from(files).filter((f) => !prev.some((file) => file.name === f.name)),
    ]);
  }, []);

  const onSelectFiles = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) {
        return;
      } else {
        assignNewFiles(e.target.files as FileList);
      }
    },
    [assignNewFiles]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      setDragOver(false);
      assignNewFiles(acceptedFiles as FileList);
    },
    [assignNewFiles]
  );

  const { getRootProps } = useDropzone({ onDrop });

  const getImageSrc = useCallback((file: File): string => {
    return URL.createObjectURL(file);
  }, []);

  const onDestroy = useCallback(
    (index: number) => {
      setFiles(files.filter((_f, i) => i !== index));
    },
    [files]
  );

  const handleConfirm = useCallback(async () => {
    setConfirmLoading(true);
    try {
      const formData = new FormData();
      files.forEach((f) => {
        formData.append('assets', f);
      });
      const uploadProgress = (progressEvent: AxiosProgressEvent): void => {
        const { loaded, total } = progressEvent;
        setUploadProgress(Math.floor((loaded * 100) / (total || 1)));
      };
      const controller = new AbortController();
      setCurrentAbortController(controller);
      const res = await assetsCreate(
        formData,
        selectedProjects || '',
        uploadProgress,
        controller.signal
      );
      if (res.status === 201) {
        onCloseModal(true);
        setFiles([]);
      }
      enqueueSnackbar(t('Saved successfully'), { variant: 'success' });
    } catch (e) {
      if (!axios.isCancel(e)) {
        enqueueSnackbar(`${t('Error saving')} ${t(e.response?.data?.message || e)}`, {
          variant: 'error',
        });
      }
    } finally {
      setCurrentAbortController(undefined);
      setConfirmLoading(false);
      setUploadProgress(0);
    }
  }, [enqueueSnackbar, files, onCloseModal, selectedProjects, t]);

  const handleSaveImage = useCallback(
    (canvas: AvatarEditor, index: number): void => {
      const newCanvas = canvas.getImageScaledToCanvas().toDataURL();
      imageForEdit &&
        fetch(newCanvas || '')
          .then((res) => res.blob())
          .then(async (blob) => {
            const newFile = new File([blob], imageForEdit.name, {
              type: imageForEdit.type,
            });
            setFiles((prev) => prev.map((f, i) => (i !== index ? f : newFile)));
            setImageForEdit(undefined);
          });
    },
    [imageForEdit]
  );

  const handleOnDragOver = useCallback(() => {
    setDragOver(true);
  }, []);

  const handleOnDragLeave = useCallback(() => {
    setDragOver(false);
  }, []);

  const onCancelUploading = useCallback(() => {
    if (currentAbortController) {
      currentAbortController.abort();
      setCurrentAbortController(undefined);
      setUploadProgress(0);
    }
  }, [currentAbortController]);

  return (
    <ModalPelican
      open={open}
      title={t('Creating Asset')}
      onClose={() => onCloseModal()}
      footer={
        <>
          <ButtonPelican
            variant="text"
            onClick={() => (confirmLoading ? onCancelUploading() : onCloseModal())}
          >
            {t('Cancel')}
          </ButtonPelican>
          {!confirmLoading && (
            <ButtonPelican
              disabled={files.length === 0}
              variant="contained"
              color="primary"
              onClick={handleConfirm}
            >
              {t('Confirm')}
            </ButtonPelican>
          )}
        </>
      }
      {...rest}
    >
      {confirmLoading && (
        <div className={styles.uploadContainer}>
          <div className={styles.progressBar} style={{ width: `${uploadProgress || 0}%` }} />
          {t('Uploading')}... {uploadProgress || 0}%
        </div>
      )}
      {!confirmLoading && (
        <>
          <h4 className={styles.label}>{t('Use in projects')}</h4>
          <SelectPelican
            id="projects"
            name="projects"
            placeholder="Select projects"
            isMulti
            multiButtons={false}
            options={projects}
            value={projects.filter((p) => selectedProjects.split(',').includes(p.value as string))}
            selected={(val) =>
              setSelectedProjects(
                val ? (val as ReadonlyArray<SelectItem>).map((s) => s.value).join(',') : ''
              )
            }
          />
          <div
            className={fileUploadStyles.emptyContainer}
            {...getRootProps({
              onDragOver: handleOnDragOver,
              onDragLeave: handleOnDragLeave,
            })}
          >
            {!dragOver && (
              <p className={fileUploadStyles.dragAndDrop}>{t('Drag and Drop file to upload')}</p>
            )}
            {dragOver && (
              <p className={fileUploadStyles.dragAndDrop}>
                {t('Now just drop the file to upload')}
              </p>
            )}
            <label htmlFor="upload-files" className={fileUploadStyles.label}>
              {t('Upload new file')}
            </label>
          </div>
          <input
            type="file"
            id="upload-files"
            accept="video/*, audio/*, image/*, .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, .geojson"
            className={fileUploadStyles.input}
            value=""
            onChange={onSelectFiles}
            multiple
          />
          {files.length > 0 && (
            <TableContainer className={styles.table}>
              <Typography className={styles.label} variant="subtitle2">{`${files.length} ${t(
                getCorrectTranslate('item', files.length)
              )}`}</Typography>
              <Table onMouseLeave={() => setAssetActionsIndex(undefined)}>
                <TableBody>
                  {files.map((f, index) => (
                    <TableRow onMouseEnter={() => setAssetActionsIndex(index)} key={index}>
                      <TableCell>
                        <div className={styles.previewContainer}>
                          <img
                            id={`preview_${index}`}
                            className={styles.preview}
                            src={
                              f.type.includes('image')
                                ? getImageSrc(f)
                                : `/assets/${f.type.split('/')[0]}-asset-icon.svg`
                            }
                            alt=""
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className={styles.fileName}>{f.name}</div>
                      </TableCell>
                      <TableCell className={styles.tableCell}>{bytesToSize(f.size || 0)}</TableCell>
                      <TableCell className={styles.actionButtonsCell}>
                        {assetActionsIndex === index && (
                          <Space align="end" size="middle">
                            {f.type.includes('image') && (
                              <PenFill
                                cursor="pointer"
                                onClick={() => {
                                  setImageForEdit(f);
                                  setImageIndexForEdit(index);
                                }}
                                htmlColor="#42526E"
                                className={styles.editIcon}
                                fontSize="small"
                              />
                            )}
                            <TrashOutline
                              cursor="pointer"
                              onClick={() => !destroyRestricted && onDestroy(index)}
                              className={(destroyRestricted && styles.disabled) || ''}
                              htmlColor="#BF2E0D"
                              fontSize="small"
                            />
                          </Space>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {imageForEdit && imageIndexForEdit !== undefined && (
            <ModalPelican
              open={!!imageForEdit}
              onClose={() => setImageForEdit(undefined)}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <ImageEditor
                preview={getImageSrc(imageForEdit)}
                imageId={`preview_${imageIndexForEdit}`}
                handleSave={(canvas) => handleSaveImage(canvas, imageIndexForEdit)}
              />
            </ModalPelican>
          )}
        </>
      )}
    </ModalPelican>
  );
};

export default AssetsCreate;
