import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  moreButton: {
    display: 'flex',
    alignSelf: 'center',
    padding: 0,
  },
  container: {
    background: '#EFF0F2',
    width: '35%',
    padding: '20px',
  },
  checkbox: {
    minWidth: '22px',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '60px',
    alignItems: 'center',
    borderBottom: '1px solid #D3D4D6',
  },
  version: {
    border: '1px solid #00801B',
    borderRadius: 4,
    padding: '2px 8px',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  closeButton: {
    position: 'absolute',
    right: '12px',
    top: '14px',
    fill: '#858585',
    cursor: 'pointer',
  },
}));
