import { OptionsType } from 'react-select';
import { OptionTypeBase as OptionType } from 'react-select/src/types';

export function changeRequestAttribute<T>(
  list: readonly T[],
  action: 'ADD' | 'EDIT' | 'DELETE',
  item: T,
  index?: number
): readonly T[] {
  if (action === 'ADD') {
    return [...list, item];
  }

  if (action === 'EDIT' && index !== undefined && index > -1) {
    const newList = list.slice(0);
    newList[index] = item;
    return newList;
  }

  if (action === 'DELETE' && index !== undefined && index > -1) {
    const newList = list.slice(0);
    newList.splice(index, 1);
    return newList;
  }

  return list;
}

export const getAuthTypes = (): OptionsType<OptionType> => [
  {
    label: 'No Auth',
    value: 'None',
  },
  {
    label: 'Bearer Token',
    value: 'Bearer',
  },
  {
    label: 'Basic Auth',
    value: 'Basic',
  },
];

export const requestMethods = [
  'GET',
  'POST',
  'PUT',
  'PATCH',
  'DELETE',
  'COPY',
  'HEAD',
  'OPTIONS',
  'LINK',
  'UNLINK',
  'PURGE',
  'LOCK',
  'UNLOCK',
  'PROPFIND',
  'VIEW',
];
