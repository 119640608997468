import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '12px 0',
    height: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    paddingTop: '15px',
    paddingBottom: '15px',
    border: '1px solid #ccc',
  },
  environments: {
    display: 'flex',
    flexDirection: 'column',
  },
  label: {
    color: '#545456',
  },
}));
