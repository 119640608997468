import { TablePagination as MuiTablePagination } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { ArrowLeftOutline, ArrowRightOutline } from 'components/IconPelican/icons';
import React, { useEffect } from 'react';

import { TablePaginationActions } from './DataGridPaginationActions/DataGridPaginationActions';
import { Props } from './props';
import { tablePaginationStyles } from './styles';

export const DataGridPagination: React.FC<Props> = ({
  handleRowsPerPageChange,
  handlePageChange,
  initialPage,
  rowsPerPage = 1,
  rowsCount = 1,
  rootClassName,
}) => {
  const styles = tablePaginationStyles();
  const [page, setPage] = React.useState(initialPage || 1);

  const [rowsNumberPerPage, setRowsNumberPerPage] = React.useState(rowsPerPage);
  const pageCount = Math.ceil(rowsCount / rowsPerPage);

  const handleChangePage = (_event: React.ChangeEvent<unknown> | null, newPage: number): void => {
    setPage(newPage);
    handlePageChange && handlePageChange(newPage);
  };

  useEffect(() => {
    setPage(initialPage || 1);
  }, [initialPage]);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setRowsNumberPerPage(parseInt(event.target.value, 10));
    setPage(1);
    handleRowsPerPageChange && handleRowsPerPageChange(parseInt(event.target.value, 10));
  };

  return (
    <MuiTablePagination
      component={'div'}
      rowsPerPageOptions={[10, 25, 50, 100]}
      count={rowsCount}
      rowsPerPage={rowsNumberPerPage}
      labelDisplayedRows={() => null}
      page={page}
      classes={{
        root: clsx(styles.tablePaginationRoot, rootClassName),
        caption: styles.tablePaginationCaption,
        selectIcon: styles.tablePaginationSelectIcon,
        select: styles.tablePaginationSelect,
        actions: styles.tablePaginationActions,
        spacer: styles.tablePaginationSpacer,
        toolbar: styles.tablePaginationToolbar,
      }}
      SelectProps={{
        inputProps: { 'aria-label': 'rows per page' },
        native: true,
        className: styles.select,
        IconComponent: ExpandMoreIcon,
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      nextIconButtonProps={<ArrowRightOutline />}
      backIconButtonProps={<ArrowLeftOutline />}
      ActionsComponent={() => (
        <TablePaginationActions
          pageCount={pageCount}
          handleChangePageTablePagination={handleChangePage}
          page={page}
          rowsCount={rowsCount}
        />
      )}
    />
  );
};
