import { makeStyles, Theme } from '@material-ui/core/styles';

import { Props } from './props';

export const useStyles = makeStyles((theme: Theme) => ({
  root: ({ variant, size, onClick }: Props) => {
    const baseStyles = {
      border: 0,
      borderRadius: 4,
      padding: size === 'large' ? '2px 8px' : '2px 8px',
      background: theme.palette.surface[100],
      marginRight: 2,
      marginBottom: 2,
      cursor: onClick ? 'pointer' : undefined,
    };
    switch (variant) {
      case 'default':
        return {
          ...baseStyles,
          background: theme.palette.surface[100],
          color: theme.palette.surface[900],
          border: `1px solid ${theme.palette.surface[300]}`,
        };
      case 'primary':
        return {
          ...baseStyles,
          background: theme.palette.blue[50],
          color: theme.palette.blue[700],
          border: `1px solid ${theme.palette.blue[700]}`,
        };
      case 'success':
        return {
          ...baseStyles,
          background: theme.palette.green[50],
          color: theme.palette.green[800],
          border: `1px solid ${theme.palette.green[800]}`,
        };
      case 'disabled':
        return {
          ...baseStyles,
          background: theme.palette.surface[300],
          color: theme.palette.surface[800],
          border: `1px solid ${theme.palette.surface[400]}`,
        };
      case 'error':
        return {
          ...baseStyles,
          background: theme.palette.red[50],
          color: theme.palette.red[900],
          border: `1px solid ${theme.palette.red[900]}`,
        };

      default:
        return baseStyles;
    }
  },
}));
