import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

type Props = {
  readonly column: number;
};

const useStyles = makeStyles({
  root: {
    padding: '10px 30px',
  },
  header: {
    background: '#f7f9fa',
    height: 46,
    borderRadius: 4,
  },
  body: ({ column: _column }: Props) => ({
    display: 'grid',
    padding: '8px',
    width: '100%',
    gridTemplateColumns: `26px 2.5fr 1fr`,
    gridGap: 28,
    justifyContent: 'stretch',
  }),
  item: {
    margin: '8px 0',
  },
});

export const DataGridSkeleton: React.FC<Props> = ({ column = 3 }) => {
  const styles = useStyles({ column });
  const arr = Array(column).fill(0);
  const rows = Array(7).fill(0);

  return (
    <div className={styles.root}>
      <SkeletonTheme>
        <>
          <div className={clsx(styles.header, styles.body)}>
            {arr.map((_a, index) => (
              <Skeleton
                key={index}
                height={20}
                baseColor="#EFF0F2"
                className={styles.item}
                borderRadius={4}
              />
            ))}
          </div>
          {rows.map((_i, rowIndex) => (
            <div key={rowIndex} className={styles.body}>
              {arr.map((_a, index) => (
                <Box key={index} display="flex" flexDirection="column">
                  <Skeleton key={index} height={20} baseColor="#EFF0F2" borderRadius={4} />
                  {index === 1 && (
                    <>
                      <Skeleton height={20} baseColor="#EFF0F2" width="70%" borderRadius={4} />
                      <Skeleton height={20} baseColor="#EFF0F2" width="40%" borderRadius={4} />
                    </>
                  )}
                </Box>
              ))}
            </div>
          ))}
        </>
      </SkeletonTheme>
    </div>
  );
};
