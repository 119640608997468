import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const FolderLineOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.997 6.703a2 2 0 012 2v9.742a2 2 0 01-2 2H4a2 2 0 01-2-2V6.703a2.843 2.843 0 010-.301c.024-.45.158-.867.4-1.199.332-.453.866-.748 1.6-.754h5.998c.733.006 1.267.301 1.598.754.242.332.376.749.4 1.199.005.099.005.2 0 .301h8.001zm0 1.5H3.5v10.242a.5.5 0 00.5.5h15.997a.5.5 0 00.5-.5V8.703a.5.5 0 00-.5-.5z"
    />
  </SvgIcon>
);
export default FolderLineOutline;
