import { BooleanField } from 'components/BooleanField';
import { SwitchField } from 'components/SwitchField';
import { fieldToRules } from 'pages/ContentEditorPage/ContentEditor/helpers';
import React, { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { useContentEditorProvider } from '../../useContentEditorProvider';
import { Props } from './props';

export const ContentBooleanInput: React.FC<Props> = ({ field }) => {
  const {
    form,
    embedLevel,
    isReviewing,
    contentItemId,
    handleFieldChange,
    contentItem,
  } = useContentEditorProvider();

  const { control, setValue, getValues } = form;

  const inputValue = getValues(field.slug);

  const defaultValue = useMemo(() => field.defaultValue?.toString(), [field.defaultValue]);

  const hasDefaultValue = useMemo(() => defaultValue === 'true' || defaultValue === 'false', [
    defaultValue,
  ]);

  const isNewContent = useMemo(() => contentItemId === '-1', [contentItemId]);

  const hasNotDefaultValue = useMemo(() => !hasDefaultValue, [hasDefaultValue]);

  const handleSwitchChange = useCallback(
    (checked: boolean) => {
      setValue(field.slug, checked.toString());
    },
    [setValue, field.slug]
  );

  const getBooleanFieldValue = (item?: string): string => {
    if (item === undefined) {
      return '';
    } else if (item !== undefined) {
      return item.toString();
    } else {
      return '';
    }
  };

  return (
    <>
      {hasDefaultValue && (isNewContent || contentItem[field.slug] !== undefined) && (
        <Controller
          name={field.slug}
          as={SwitchField}
          id={`${field.slug}-${embedLevel}`}
          label={field.label}
          control={control}
          initChecked={
            inputValue !== undefined || !isNewContent ? inputValue?.toString() : defaultValue
          }
          disabled={isReviewing}
          handleChange={(checked: boolean) => {
            handleSwitchChange(checked);
            handleFieldChange(field.slug);
          }}
          rules={fieldToRules(field)}
        />
      )}
      {((hasDefaultValue && !isNewContent && contentItem[field.slug] === undefined) ||
        hasNotDefaultValue) && (
        <>
          <Controller
            name={field.slug}
            as={BooleanField}
            id={`${field.slug}-${embedLevel}`}
            disabled={isReviewing}
            control={control}
            initValue={getBooleanFieldValue(inputValue)}
            handleRadioChange={(val: string) => {
              handleFieldChange(field.slug);
              setValue(field.slug, val);
            }}
            rules={fieldToRules(field)}
          />
        </>
      )}
    </>
  );
};
