import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: 20,
  },
  contentStateContainer: {
    display: 'flex',
  },
  contentState: {
    fontWeight: 'bold',
    fontSize: 11,
    color: '#fff',
    borderRadius: 3,
    marginRight: 8,
    textTransform: 'uppercase',
    padding: '1px 7px 0 7px',
    display: 'inline-block',
  },
  versionButton: {
    padding: '3px 9px 2px 9px',
    border: 0,
    outline: 'none',
    cursor: 'pointer',
  },
  flexAlignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  projects: {
    display: 'flex',
    flexDirection: 'column',
  },
  ownerProject: {},
}));
