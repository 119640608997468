import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const CubeOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 6.6l8.155-4.263a.75.75 0 01.702 0L20.5 6.6a.75.75 0 01.4.663v9a.75.75 0 01-.37.647l-8.148 4.736a.748.748 0 01-.378.104H12a.748.748 0 01-.378-.104L3.47 16.91a.75.75 0 01-.37-.647v-9A.75.75 0 013.5 6.6zm1.1 1.89v7.341l6.65 3.865v-7.269L4.6 8.492zm8.15 3.94v7.268l6.65-3.866V8.547l-6.65 3.883zm5.83-5.142l-6.574-3.442L5.47 7.263l6.532 3.867 6.577-3.842z"
    />
    <path d="M4.6 15.832V8.49l6.65 3.937v7.268L4.6 15.83z" fill="#000" />
  </SvgIcon>
);
export default CubeOutline;
