/* eslint-disable @typescript-eslint/no-explicit-any */

import { IconButton, Typography } from '@material-ui/core';
import { ContentItem } from 'api/types/ContentItem';
import clsx from 'clsx';
import ButtonPelican from 'components/ButtonPelican';
import { ArrowsOpen, PenFill } from 'components/IconPelican/icons';
import { Space } from 'components/Space';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Hooks,
  TableState,
  useColumnOrder,
  useFlexLayout,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useTable,
} from 'react-table';

import { DifferenceIcon } from '../IconPelican/icons/DifferenceIcon';
import { DataGridActions } from './DataGridActions';
import { useDataGrid } from './DataGridContext';
import { DataGridNoData } from './DataGridNoData/DataGridNoData';
import { DataGridPagination } from './DataGridPagination';
import { DataGridSkeleton } from './DataGridSkeleton';
import { IndeterminateCheckbox } from './IndeterminateCheckbox';
import { Props } from './props';
import { useStyles } from './styles';
import { useDataGridState } from './useDataGridState';
import { cellProps, headerProps } from './utils';

export const DataGrid: React.FC<Props> = ({
  columns,
  data,
  pagination,
  footer,
  loading,
  onOpen,
  disableSelection,
  noData,
  onClickCell,
  contentItems,
  tableContainerClassName,
  ...tableProps
}) => {
  const styles = useStyles();
  const gridContext = useDataGrid();
  const { initialState, setToLocalStorage } = useDataGridState(gridContext?.gridName || '');
  const [filledRowIds, setFilledRowIdss] = useState<ReadonlyArray<number>>([]);
  const disabledCells = useMemo(
    () => ['selection', ...columns.filter((c) => c.disabled).map((c) => c.id)],
    [columns]
  );
  const defaultColumn = useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
    }),
    []
  );
  const { t } = useTranslation();

  const tableInstance = useTable(
    {
      initialState: initialState || {},
      columns,
      data,
      defaultColumn,
      manualPagination: true,
      autoResetSelectedRows: false,
      getRowId: (row: any, index) => {
        return row.id || index;
      },
      ...tableProps,
    },
    useResizeColumns,
    useFlexLayout,
    usePagination,
    useRowSelect,
    useColumnOrder,
    (hooks: Hooks) => {
      if (!disableSelection) {
        hooks.allColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            disableResizing: true,
            minWidth: 35,
            width: 35,
            maxWidth: 35,
            // eslint-disable-next-line react/display-name
            Header: ({ getToggleAllRowsSelectedProps }) =>
              disableSelection ? null : (
                <div style={{ display: 'flex', justifyContent: 'center', width: '26px' }}>
                  <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                </div>
              ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            // eslint-disable-next-line react/display-name
            Cell: ({ row }) => (
              <div>
                {disableSelection ? null : !(row.original as ContentItem)._isDiff ? (
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                ) : (
                  <DifferenceIcon fontSize="small" />
                )}

                {onOpen && (
                  <ButtonPelican
                    onClick={() => onOpen(row.original)}
                    className={clsx(styles.openButton, 'open-table-item')}
                  >
                    <Space>
                      <ArrowsOpen height={18} />
                      {t('Open')}
                    </Space>
                  </ButtonPelican>
                )}
              </div>
            ),
          },
          ...columns,
        ]);
      }

      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        // fix the parent group of the selection button to not be resizable
        // eslint-disable-next-line prefer-destructuring
        const selectionGroupHeader = headerGroups[0].headers[0];
        selectionGroupHeader.canResize = false;
      });
    }
  );

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    allColumns,
    getTableBodyProps,
    state: { selectedRowIds, hiddenColumns, columnOrder, columnResizing },
  } = tableInstance;

  const contentHasChanges = useCallback(
    (compareItem: ContentItem, fieldSlug: string): boolean => {
      const contentItem = contentItems?.find((c) => compareItem.source_id === c.source_id);
      if (contentItem) {
        const changed = !!(
          contentItem.changedFields?.includes(fieldSlug) ||
          contentItem.childChangedFields?.includes(fieldSlug)
        );
        changed &&
          compareItem.id &&
          !filledRowIds.includes(compareItem.id) &&
          setFilledRowIdss((prev) => prev.concat(compareItem.id || 0));
        return changed;
      }
      return false;
    },
    [contentItems, filledRowIds]
  );

  useEffect(() => {
    if (gridContext.selectedRowIds.length === 0 && Object.keys(selectedRowIds).length > 0) {
      toggleAllRowsSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridContext.selectedRowIds, toggleAllRowsSelected]);

  useEffect(() => {
    if (gridContext) {
      console.log('on value', selectedRowIds);
      gridContext.setSelectedRowIds &&
        gridContext.setSelectedRowIds(Object.keys(selectedRowIds) as readonly any[]);
      setToLocalStorage({ hiddenColumns, columnOrder, columnResizing } as TableState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds, hiddenColumns, columnOrder, columnResizing]);

  useEffect(() => {
    if (gridContext?.setInitialColumns) {
      gridContext.setInitialColumns(columns);
    }

    if (gridContext?.setAllColumns) {
      gridContext.setAllColumns(allColumns);
    }

    if (gridContext?.setTableInstance) {
      gridContext.setTableInstance(tableInstance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, allColumns, tableInstance]);

  return (
    <div className={styles.container}>
      {loading && <DataGridSkeleton column={3} />}
      {!loading && (
        <div className={clsx(styles.tableContainer, tableContainerClassName)}>
          <table {...getTableProps()} className={styles.table}>
            <thead className={styles.thead}>
              {headerGroups.map((headerGroup, groupIndex) => (
                // eslint-disable-next-line react/jsx-key
                <tr
                  {...headerGroup.getHeaderGroupProps({
                    // style: { paddingRight: '15px' },
                  })}
                  key={groupIndex}
                  className={clsx(styles.tr, styles.trHead)}
                >
                  {headerGroup.headers.map((column, colIndex) => (
                    <th
                      {...column.getHeaderProps(headerProps)}
                      key={column.getHeaderProps(headerProps).key}
                      className={styles.th}
                    >
                      <Typography variant="caption" className={styles.caption}>
                        {column.render('Header')}
                      </Typography>
                      {colIndex < headerGroup.headers.length - 1 && column.canResize && (
                        <div
                          {...column.getResizerProps()}
                          className={`${styles.resizer} ${
                            column.isResizing ? styles.isResizing : ''
                          }`}
                        />
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className={styles.tbody}>
              {rows.map((row, rowIndex) => {
                prepareRow(row);
                return (
                  // eslint-disable-next-line react/jsx-key
                  <tr
                    {...row.getRowProps()}
                    className={clsx(
                      styles.tr,
                      filledRowIds.includes(row.original.id) && styles.hasChanges
                    )}
                    key={rowIndex}
                  >
                    {disableSelection && onOpen && (
                      <ButtonPelican
                        onClick={() => onOpen(row.original)}
                        className={clsx(
                          styles.openButton,
                          styles.noSelectionPosition,
                          'open-table-item'
                        )}
                      >
                        <Space>
                          <ArrowsOpen height={18} />
                          {t('Open')}
                        </Space>
                      </ButtonPelican>
                    )}
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps(cellProps)}
                        key={cell.getCellProps(cellProps).key}
                        className={clsx(
                          styles.td,
                          onOpen && cell.column.id === 'selection' && styles.openTd,
                          cell.row.original._isDiff &&
                            contentHasChanges(cell.row.original, cell.column.id) &&
                            styles.fieldChanged,
                          onClickCell && !disabledCells.includes(cell.column.id) && styles.cellHover
                        )}
                      >
                        {cell.render('Cell')}
                        {onClickCell && !disabledCells.includes(cell.column.id) && (
                          <IconButton
                            onClick={(e) => {
                              onClickCell(`${cell.row.original.id}#${cell.column.id}`);
                              e.stopPropagation();
                            }}
                            size="small"
                            className={clsx(styles.edit, 'edit-table-item')}
                          >
                            <PenFill />
                          </IconButton>
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {rows.length === 0 && !loading && <DataGridNoData {...noData} />}
      {!loading && (
        <footer className={styles.footer}>
          {footer && <DataGridActions onToggle={toggleAllRowsSelected}>{footer}</DataGridActions>}
          {pagination && rows.length > 0 && <DataGridPagination {...pagination} />}
        </footer>
      )}
    </div>
  );
};

export default DataGrid;
