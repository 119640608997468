import { Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import clsx from 'clsx';
import { AnchorElType } from 'common/types/AnchorElType';
import { CheckboxPelican } from 'components/CheckboxPelican';
import { ArrowSmallOutline } from 'components/IconPelican/icons';
import { SelectItem } from 'components/SelectPelican';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getProjects, getWorkspaces, setWorkspaces } from 'store/workspaceSlice';

import { Props } from './props';
import { useStyles } from './styles';

export const Workspace: React.FC<Props> = ({ isToggle }) => {
  const classes = useStyles({ sidebarOn: !isToggle });
  const projects = useSelector(getProjects);
  const workspaces = useSelector(getWorkspaces);
  const dispatch = useDispatch();
  const options = projects?.map((p) => ({ label: p.title, value: p.id })) || [];
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<AnchorElType>(null);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<ReadonlyArray<SelectItem>>([]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    dispatch(
      setWorkspaces(
        selectedWorkspaces.map((sw) => ({
          workspaceId: sw.value as number,
          workspaceName: sw.label,
          workspaceSlug: projects.find((p) => p.id === (sw.value as number))?.slug,
        }))
      )
    );
  }, [dispatch, projects, selectedWorkspaces]);

  const handleChange = (selected: SelectItem): void => {
    if (!selectedWorkspaces.some((sw) => sw.value === selected.value)) {
      setSelectedWorkspaces((prev) => [...prev, selected]);
    } else {
      if (selectedWorkspaces.length !== 1) {
        setSelectedWorkspaces((prev) => prev.filter((sw) => sw.value !== selected.value));
      }
    }
  };

  const handleSelectAll = useCallback(() => {
    if (selectedWorkspaces.length === projects.length) {
      setSelectedWorkspaces([
        { label: projects[0].title || projects[0].description || '', value: projects[0].id },
      ]);
    } else {
      setSelectedWorkspaces(
        projects.map((p) => ({ label: p.title || p.description || '', value: p.id }))
      );
    }
  }, [projects, selectedWorkspaces.length]);

  useEffect(() => {
    setSelectedWorkspaces(
      workspaces.map((w) => ({ label: w.workspaceName || '', value: w.workspaceId || '' }))
    );
  }, [workspaces]);

  return (
    <div>
      <div className={clsx(classes.root, isToggle && classes.toggledRoot)}>
        {workspaces.length === 0 && (
          <div className={classes.emptyWorkspace}>{t('No Workspace')}</div>
        )}
        {workspaces.length > 0 && (
          <button className={classes.selected} onClick={(e) => setAnchorEl(e.currentTarget)}>
            <div
              className={classes.selectedProject}
              style={{
                marginLeft:
                  selectedWorkspaces.length === 1 ? 0 : selectedWorkspaces.length === 2 ? -3 : -6,
              }}
            >
              {selectedWorkspaces.slice(0, 1).map((sw, i) => (
                <div
                  key={i}
                  className={clsx(classes.projectIcon, classes.selectedIcon)}
                  style={{ marginLeft: `-${i === 0 ? 0 : 34}px`, zIndex: 10 - i }}
                  title={selectedWorkspaces.map((sw) => sw.label).join(', ')}
                >
                  <Typography>{sw?.label?.charAt(0)}</Typography>
                </div>
              ))}
            </div>
            {!isToggle && (
              <Typography className={classes.title}>
                {selectedWorkspaces.map((sw) => sw.label).join(', ')}
              </Typography>
            )}
            <span className={classes.nowrap}>
              <ArrowSmallOutline htmlColor="#fff" />
            </span>
          </button>
        )}
        <Popover
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleClose}
          className={classes.menu}
        >
          <li>
            <button className={clsx(classes.menuItem)} onClick={handleSelectAll}>
              <div className={classes.empty} />
              <span className={classes.workspaceName}>{t('Select all')}</span>
              <CheckboxPelican
                size="small"
                className={classes.checkbox}
                checked={selectedWorkspaces.length === projects.length}
              />
            </button>
          </li>
          {options.map((o) => (
            <li key={o.value}>
              <button className={classes.menuItem} onClick={() => handleChange(o)}>
                <div className={clsx(classes.projectIcon, classes.menuIcon)}>
                  {o.label.charAt(0)}
                </div>
                <span className={classes.workspaceName}>{o.label}</span>
                <CheckboxPelican
                  className={classes.checkbox}
                  size="small"
                  checked={selectedWorkspaces.some((w) => w.value === o.value)}
                />
              </button>
            </li>
          ))}
        </Popover>
      </div>
    </div>
  );
};

export default Workspace;
