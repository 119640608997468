import { Menu, MenuItem, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { userLogout } from 'api/login';
import clsx from 'clsx';
import { AnchorElType } from 'common/types/AnchorElType';
import { Permission } from 'common/types/permission';
import { removeCookie } from 'common/utils/cookies';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setSearch } from 'store/contentSlice';
import { getCheckPermissions, logout } from 'store/userInfoSlice';

import { CustomTooltip } from '../../Sidebar/NavMenu/NavMenuItem/NavMenuItemText';
import { UserInfoProps } from './index';
import { useStyles } from './styles';

export const UserInfo: React.FC<UserInfoProps> = ({ name }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const checkPermissions = useSelector(getCheckPermissions);

  const [anchorEl, setAnchorEl] = React.useState<AnchorElType>(null);

  const handleUserClick = useCallback((event: React.MouseEvent<React.ReactNode>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleRedirect = useCallback(
    (url: string) => {
      history.push(url);
      setAnchorEl(undefined);
    },
    [history]
  );

  const handleLogout = useCallback(async () => {
    try {
      await userLogout();
      removeCookie('SESSION');
      dispatch(setSearch(''));
      dispatch(logout());
      history.push('/login');
      handleClose();
    } catch (e) {
      enqueueSnackbar(`${t('Logout error')} ${e.response?.data?.message || e}`, {
        variant: 'error',
      });
    }
  }, [history, dispatch, enqueueSnackbar, t]);

  return (
    <div className={clsx(classes.user)}>
      <Avatar className={classes.avatar} onClick={handleUserClick} />
      <Menu
        id="user-profile-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        style={{ top: 28 }}
        disableScrollLock={true}
      >
        <CustomTooltip
          disableHoverListener={checkPermissions(Permission.ACCESS_TOKEN)}
          title={t('Insufficient permissions') || ''}
        >
          <div>
            <MenuItem
              disabled={!checkPermissions(Permission.ACCESS_TOKEN)}
              onClick={() => handleRedirect('/access-tokens')}
            >
              {t('Access token')}
            </MenuItem>
          </div>
        </CustomTooltip>
        <CustomTooltip
          disableHoverListener={checkPermissions(Permission.WEBHOOK)}
          title={t('Insufficient permissions') || ''}
        >
          <div>
            <MenuItem
              disabled={!checkPermissions(Permission.WEBHOOK)}
              onClick={() => handleRedirect('/webhooks')}
            >
              {t('Webhooks')}
            </MenuItem>
          </div>
        </CustomTooltip>
        <MenuItem onClick={() => handleRedirect('/profile')}>{t('Profile')}</MenuItem>
        <MenuItem onClick={() => handleRedirect('/credentials/change-password')}>
          {t('Change password')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>{t('Logout')}</MenuItem>
      </Menu>
      <div className={classes.info}>
        <Typography variant={'body2'} className={classes.name}>
          {name}
        </Typography>
      </div>
    </div>
  );
};

export default UserInfo;
