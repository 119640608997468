import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const SaveOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 18A1.5 1.5 0 006 19.5h12a1.5 1.5 0 001.5-1.5V7.828a.5.5 0 00-.146-.353l-2.829-2.829a.5.5 0 00-.353-.146H6A1.5 1.5 0 004.5 6v12zM18 21H6a3 3 0 01-3-3V6a3 3 0 013-3h10.172a2 2 0 011.414.586l2.828 2.828A2 2 0 0121 7.828V18a3 3 0 01-3 3zm-3-11H6V7h9v3zm0 5a3 3 0 11-6 0 3 3 0 016 0z"
      fill="#000"
    />
  </SvgIcon>
);
export default SaveOutline;
