import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    paddingRight: 30,
    paddingLeft: 30,
  },
  flex: {
    display: 'flex',
  },
  alignCenter: {
    alignItems: 'center',
  },
  versionsHeader: {
    background: '#EBECF0',
    paddingTop: 17,
    paddingBottom: 16,
    minHeight: 73,
    paddingLeft: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  field: {
    paddingTop: 14,
  },
  checkedField: {
    backgroundColor: '#DEEBFF',
  },
  different: {
    backgroundColor: '#FBE9E7',
  },
  version: {
    width: '50%',
    height: '100%',
  },
  current: {
    borderRight: '1px solid #DFE1E6',
  },
  selectAll: {
    paddingTop: 18,
    paddingBottom: 17,
    fontSize: 14,
    borderBottom: '1px solid #DFE1E6',
    '&>span': {
      marginRight: 18,
    },
  },
  emptyValue: {
    paddingLeft: 10,
  },
  currentVersion: {
    fontWeight: 500,
    fontSize: 20,
    lineHeight: '24px',
  },
  bigHr: {
    height: 16,
    width: '100%',
    background: '#DFE1E6',
  },
  contentId: {
    paddingLeft: 72,
  },
  radioContainer: {
    paddingTop: 4,
  },
});
