import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: '#ffffff',
    padding: 30,
    overflowY: 'auto',
  },
  dFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  editor: {
    marginTop: 12,
    display: 'flex',
    justifyContent: 'center',
  },
  option: {
    marginBottom: 12,
    background: '#F4F5F7',
    borderRadius: 3,
    padding: '6px 8px',
    marginRight: 8,
    whiteSpace: 'nowrap',
    '&>label': {
      marginRight: 8,
    },
    '&>input': {
      backgroundColor: 'red',
    },
  },
  saveButton: {
    marginLeft: 'auto',
  },
  hiddenImage: {
    position: 'absolute',
    opacity: 0,
    zIndex: -1,
  },
  rotateButton: {
    marginBottom: 10,
    height: 44,
  },
  rotateLabel: {
    whiteSpace: 'nowrap',
  },
});
