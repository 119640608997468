import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    background: '#F7F8FA',
    marginBottom: 8,
    borderRadius: 3,
    userSelect: 'auto',
  },
  firstRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  values: {
    padding: '0 15px',
  },
  comparable: {
    padding: '0 16px',
  },
  sortButton: {
    cursor: 'pointer',
    marginLeft: -6,
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 'normal',
    color: '#253858',
    flexGrow: 1,
  },
  editButton: {
    fontSize: 14,
    color: '#0052CC',
    background: 'none',
    fontWeight: 'normal',
    paddingLeft: 4,
    paddingRight: 4,
  },
  secondRow: {
    paddingLeft: 16,
    textAlign: 'center',
    color: 'red',
  },
  displayField: {
    display: 'flex',
  },
  displayFieldLabel: {
    alignSelf: 'center',
    width: '39%',
  },
  displayFieldDivider: {
    width: '2%',
  },
  displayFieldContent: {
    maxWidth: 50,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  displayFieldItem: {
    flex: '0 50%',
    '& .image-IMAGE': {
      width: 150,
    },
    '& .image-ICON': {
      width: 60,
    },
  },
  embeddedModal: {
    paddingTop: 10,
    paddingLeft: 20,
  },
  table: {
    width: '100%',
  },
  absolute: {
    zIndex: 111,
    borderRadius: 3,
    padding: 5,
    position: 'absolute',
    background: '#ffffff',
  },
  refFieldLabel: {
    position: 'relative',
  },
  maxWidth: {
    maxWidth: '100%',
  },
  draggable: {},
  hasChanges: {
    background: '#FBE9E7',
  },
  itemId: {
    fontSize: 14,
    color: '#161618',
    fontWeight: 'bold',
  },
  diff: {
    paddingBottom: 12,
    borderBottom: '1px solid #D3D4D6',
    marginBottom: 12,
    padding: '0 16px',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 16px',
    justifyContent: 'space-between',
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  actionButtons: {
    justifySelf: 'flex-end',
  },
});
