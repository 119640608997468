import { makeStyles } from '@material-ui/core/styles';
import { RIGHT_BAR_WIDTH } from 'common/constants/styles';

export const useStyles = makeStyles(() => ({
  root: {},
  aside: {
    display: 'flex',
    flexDirection: 'column',
    // position: 'fixed',
    width: RIGHT_BAR_WIDTH,
    maxWidth: RIGHT_BAR_WIDTH,
    height: '100%',
    transition: '0.3s',
  },
  toggledAside: {
    width: 0,
  },
}));
