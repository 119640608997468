import { ButtonPelican } from 'components/ButtonPelican';
import Spin from 'components/Spin';
import { RichTextEditor } from 'containers/RichTextContent/RichTextEditor';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import tinymce from 'tinymce';
import Typograf from 'typograf';

import { Props } from './props';
import { useStyles } from './styles';

export const RichTextContent: React.FC<Props> = ({
  initialValue,
  selector,
  onCancel,
  onSave,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const typography = useMemo(() => new Typograf({ locale: ['ru', 'en-US'] }), []);
  typography.enableRule('*');

  const [text, setText] = useState<string>(initialValue || '');

  const handleClearText = useCallback(() => {
    setText('');
  }, []);

  const handleToStamp = useCallback(() => {
    setText(typography.execute(tinymce.editors?.[0].getContent({ format: 'text' }) || ''));
  }, [typography]);

  useEffect(() => {
    if (initialValue) {
      setText(initialValue);
    } else {
      setText('');
    }
  }, [initialValue]);

  return (
    <div className={styles.flex}>
      <div className={styles.modal}>
        <div className={styles.modalHeader}>
          <p>Rich text</p>
        </div>
        <div className={styles.modalBody}>
          <div className={selector}>
            {loading ? (
              <div className={styles.spinContainer}>
                <Spin size={48} />
              </div>
            ) : (
              <RichTextEditor
                onEditorChange={(content: string) => {
                  setText(content);
                }}
                value={text}
                {...rest}
              />
            )}
          </div>
        </div>
        <div className={styles.modalFooter}>
          <div className={styles.flexBetween}>
            <div>
              <ButtonPelican variant="contained" color="primary" onClick={handleClearText}>
                {t('ClearAll')}
              </ButtonPelican>
              <ButtonPelican
                variant="contained"
                color="primary"
                onClick={handleToStamp}
                className={styles.mx8}
              >
                {t('Typography')}
              </ButtonPelican>
            </div>
            <div>
              <ButtonPelican
                variant="contained"
                color="primary"
                disabled={!onSave}
                onClick={() => onSave && onSave(text)}
                className={styles.mx8}
                data-cy="save-richtext"
              >
                {t('Save')}
              </ButtonPelican>
              <ButtonPelican variant="text" onClick={onCancel} className={styles.cancelButton}>
                {t('Cancel')}
              </ButtonPelican>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RichTextContent;
