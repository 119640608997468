import request from 'common/utils/axios-request';

import { ContentReferences, ModelReference } from './types/BackLinks';

export function backlinksOfModels(
  contentTypeSlug: string,
  dataId?: number | string
): Promise<ReadonlyArray<ModelReference>> {
  return request(
    `/api/v1/${
      dataId
        ? `content-manager/${contentTypeSlug}/${dataId}/referencing-content-types`
        : `content-types/${contentTypeSlug}/references`
    }`,
    { method: 'GET' }
  ).then(({ data }) => data);
}

export function backlinksOfContent(
  currentSlug: string,
  contentTypeSlug: string,
  dataId: number | string,
  contentFieldSlug: string,
  contentState?: string
): Promise<{ readonly [key: string]: ReadonlyArray<ContentReferences> }> {
  return request(`/api/v1/content-manager/${currentSlug}/${dataId}/back-links`, {
    method: 'GET',
    params: {
      'referencing-content-type-slug': contentTypeSlug,
      'content-field-slug': contentFieldSlug,
      'content-state': contentState,
    },
  }).then(({ data }) => data);
}
