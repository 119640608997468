import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const AddOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4.25a.75.75 0 01.75.75v6.25H19a.75.75 0 010 1.5h-6.25V19a.75.75 0 01-1.5 0v-6.25H5a.75.75 0 010-1.5h6.25V5a.75.75 0 01.75-.75z"
      fill="currentColor"
    />
  </SvgIcon>
);
export default AddOutline;
