import { TableState } from 'react-table';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useDataGridState = (gridName: string) => {
  const keyName = `datagrid__${gridName}`;
  const initialValue = localStorage.getItem(keyName);
  const initialState: TableState = initialValue
    ? (JSON.parse(initialValue) as TableState)
    : ({} as TableState);

  const setToLocalStorage = (state: TableState): void => {
    localStorage.setItem(keyName, JSON.stringify(state));
  };

  const resetGridState = (): void => {
    localStorage.removeItem(keyName);
  };

  return {
    initialState,
    setToLocalStorage,
    resetGridState,
  };
};
