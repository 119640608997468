import { makeStyles } from '@material-ui/core/styles';

export const usePageStyles = makeStyles({
  container: {
    paddingTop: 15,
    marginLeft: 20,
    marginRight: 20,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#354662',
    marginBottom: 16,
  },
  heading: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
    color: '#172b4d',
    flexGrow: 1,
    margin: 0,
    fontFamily: 'Inter',
  },
  subheading: {
    fontSize: 14,
    color: '#505F79',
    marginBottom: 24,
  },
  createButton: {
    textAlign: 'right',
    flexGrow: 1,
  },
  marginLeftAuto: {
    marginLeft: 'auto',
  },
  marginBottom: {
    marginBottom: 24,
  },
  searchInput: {
    width: 175,
    marginRight: 12,
    '& .MuiInputBase-input': {
      padding: '9.5px 14px',
    },
  },
  embeddedCell: {
    marginLeft: 4,
  },
  link: {
    border: 0,
    background: 'transparent',
    color: '#0052CC',
    outline: 'none',
    '&:hover': {
      cursor: 'pointer',
      color: '#172B4D',
      textDecoration: 'underline',
    },
  },
  maxContent: {
    width: 'max-content',
    margin: 0,
  },
  reference: {
    padding: '0 4px',
    borderRadius: 3,
    whiteSpace: 'nowrap',
    color: '#172B4D',
    fontSize: 12,
    '&:not(:first-child)': {
      marginTop: 2,
    },
  },
  referenceMultiple: {
    backgroundColor: '#DAE6F7',
  },
  referenceSingle: {
    backgroundColor: '#DBEDE4',
  },
  referenceContainer: {
    marginTop: -3,
    marginBottom: -3,
  },
  referenceFullList: {
    position: 'absolute',
    borderRadius: 3,
    backgroundColor: '#fff',
    boxShadow: '0px 0px 1px rgba(9, 30, 66, 0.31), 0px 8px 16px rgba(9, 30, 66, 0.25)',
    padding: 2,
    left: 28,
    top: 37,
    zIndex: 100000,
  },
  flexReference: {
    flexDirection: 'column',
    alignItems: 'baseline',
  },
  locale: {
    padding: '2px 3px',
    background: '#EFF0F2',
    borderRadius: 4,
    fontSize: 11,
    lineHeight: '14px',
    color: '#545456',
    marginRight: 2,
    fontWeight: 500,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  ml8: {
    marginLeft: 8,
  },
  textButton: {
    cursor: 'pointer',
    padding: 0,
    outline: 'none',
    border: 0,
    background: 'transparent',
  },
  relative: {
    minHeight: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  colorChoose: {
    width: 20,
    height: 20,
    cursor: 'pointer',
    marginRight: 8,
    border: 'none',
  },
});
