import { ContentEditor } from 'pages/ContentEditorPage/ContentEditor';
import React from 'react';
import { useRouteMatch } from 'react-router';

// const ContentEditor = React.lazy(() => import('components/organisms/ContentEditor'));

export const ContentEditorPage: React.FC = () => {
  const { params } = useRouteMatch<{ readonly type: string; readonly sourceId: string }>();

  return (
    <ContentEditor
      contentTypeSlug={params.type}
      sourceId={params.sourceId}
      isReviewing={false}
      embedLevel={-1}
    />
  );
};

export default ContentEditorPage;
