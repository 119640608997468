import { makeStyles, Theme } from '@material-ui/core/styles';

export const useModalStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    minWidth: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: 'none',
  },
  container: {
    padding: 24,
  },
  modalTitle: {
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 500,
    fontSize: 20,
  },
  hr: {
    height: 2,
    background: '#EBECF0',
  },
  overFlowYScroll: {
    height: 'calc(100vh - 300px)',
    overflowY: 'auto',
  },
  formFooter: {
    color: 'black',
    padding: 16,
    textAlign: 'right',
  },
}));
