import request from 'common/utils/axios-request';

import { IntegrationMappingItemModel } from './types/IntegrationMappingItemModel';

export function integrationMappingsGetAll(
  integrationId: number
): Promise<readonly IntegrationMappingItemModel[]> {
  return request('/api/v1/integration-fields', {
    params: { integrationId },
  }).then(({ data }) => data as readonly IntegrationMappingItemModel[]);
}

export function integrationMappingPost(
  data: IntegrationMappingItemModel
): Promise<IntegrationMappingItemModel> {
  return request('/api/v1/integration-fields', {
    method: 'POST',
    data,
  }).then(({ data }) => data as IntegrationMappingItemModel);
}

export function integrationMappingPut(
  data: IntegrationMappingItemModel
): Promise<IntegrationMappingItemModel> {
  return request('/api/v1/integration-fields', {
    method: 'PUT',
    data,
  }).then(({ data }) => data as IntegrationMappingItemModel);
}

export function integrationMappingDelete(id: number): Promise<unknown> {
  return request(`/api/v1/integration-fields/${id}`, {
    method: 'DELETE',
  });
}
