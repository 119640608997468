import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: '#172B4D',
    padding: theme.spacing(2),
  },
  cell: {
    color: '#172B4D',
  },
  searchInput: {
    flexBasis: '200px',
  },
  centerAlign: {
    textAlign: 'center',
  },
  mark: {
    width: 30,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dot: {
    fontSize: '0.8rem',
    color: '#505F79',
  },
  addIcon: {
    width: 30,
    height: 30,
  },
  spinContainer: {
    position: 'relative',
    height: '200px',
  },
}));
