import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: ({ overflowUnset }: { readonly overflowUnset?: boolean }) => ({
    position: 'absolute',
    minWidth: 560,
    maxWidth: '80vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: 'none',
    overflow: overflowUnset ? 'unset' : 'auto',
  }),
  container: {
    padding: 24,
  },
  unset: {
    overflow: 'unset',
  },
  modalTitle: {
    marginTop: 0,
    marginBottom: 0,
    fontWeight: 700,
    fontSize: 16,
  },
  hr: {
    height: 2,
    background: '#EBECF0',
  },
  overflowYScroll: {
    maxHeight: 'calc(100vh - 300px)',
    overflowY: 'auto',
  },
  formFooter: {
    color: 'black',
    padding: '8px 16px',
    textAlign: 'right',
  },
  formFooterButton: {
    margin: 8,
  },
  overflowUnset: {
    overflow: 'unset',
  },
  closeButton: {
    position: 'absolute',
    right: '12px',
    top: '14px',
    fill: '#858585',
    cursor: 'pointer',
  },
}));
