import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const MentionOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66 12c.104 4.596 3.707 7.25 7.169 7.25h4.668a.75.75 0 010 1.5h-4.668c-4.192 0-8.554-3.226-8.669-8.734v-.032C3.275 6.593 7.528 3.25 12 3.25c4.45 0 8.855 3.27 8.855 8.75v.034c-.048 1.047-.205 2.058-.625 2.849-.449.844-1.19 1.415-2.263 1.519l-.066.006-.067-.005c-1.252-.103-2.045-.818-2.498-1.688a.641.641 0 01-.008-.014c-.775.99-1.997 1.628-3.501 1.656H11.798c-2.464-.05-4.43-1.976-4.43-4.414 0-2.335 1.821-4.405 4.441-4.418 2.699-.014 4.441 2.1 4.441 4.418 0 .051 0 .102-.002.152.02.68.14 1.392.418 1.928.253.486.62.809 1.223.878.493-.065.794-.304 1.016-.722.26-.488.405-1.221.45-2.196-.009-4.51-3.6-7.233-7.355-7.233-3.734 0-7.236 2.764-7.34 7.25zm10.088.06l.002-.117c0-1.568-1.146-2.927-2.933-2.918-1.755.009-2.95 1.367-2.95 2.918 0 1.578 1.267 2.873 2.947 2.914 1.765-.04 2.875-1.237 2.934-2.785a.45.45 0 010-.012z"
      fill="#000"
    />
  </SvgIcon>
);
export default MentionOutline;
