import Switch from '@material-ui/core/Switch';
import React, { useEffect, useState } from 'react';

import { Props } from './props';
import { useStyles } from './styles';

export const SwitchMaterial: React.FC<Props> = ({
  id,
  handleChange,
  checked,
  inputProps,
  name,
  color,
  disabled,
}: Props) => {
  const [switchChecked, setSwitchChecked] = useState<boolean>(
    checked === undefined ? false : checked
  );
  const classes = useStyles();

  useEffect(() => {
    checked !== undefined && setSwitchChecked(checked);
  }, [checked]);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSwitchChecked(event.target.checked);
    handleChange && handleChange(event.target.checked);
  };

  return (
    <div>
      {switchChecked}
      <Switch
        id={id}
        checked={switchChecked}
        onChange={handleValueChange}
        name={name}
        color={color}
        inputProps={inputProps}
        disabled={disabled}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
          disabled: classes.disabled,
        }}
      />
    </div>
  );
};
