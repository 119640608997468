import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
  primary: {
    backgroundColor: '#0052CC',
    color: '#fff',
  },
  danger: {
    backgroundColor: '#DE350B',
    color: '#fff',
  },
  mainButton: {
    padding: '0 15px',
    borderRadius: '4px 0 0 4px',
    borderRight: 0,
  },
  arrowButton: {
    borderRadius: '0 4px 4px 0',
    borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
    marginLeft: 0,
    padding: 0,
    minWidth: 31,
  },
  arrowDotsButton: {
    padding: 0,
    minWidth: 26,
  },
});
