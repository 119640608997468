import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '20px',
    marginTop: 10,
  },
  contentEditor: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
  },
  main: {
    display: 'flex',
    justifyContent: 'start',
    backgroundColor: 'white',
    height: '100%',
  },
  stretch: {
    maxWidth: '100%',
  },
  tabs: {
    marginRight: 30,
  },
  stickyDiv: {
    backgroundColor: 'white',
    zIndex: 2,
  },
}));
