import { AxiosProgressEvent, GenericAbortSignal } from 'axios';
import request from 'common/utils/axios-request';

import { FileItem } from './types/FileItem';

export function filePost(
  data: FormData,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  signal?: GenericAbortSignal
): Promise<FileItem> {
  return request(`/api/v1/assets`, {
    data,
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress,
    signal,
  }).then(({ data }) => data as FileItem);
}

export function fileGet(url: string): Promise<Blob> {
  return fetch(url).then((response) => response.blob());
}
