import TabsPelican from 'components/TabsPelican';
import TabPelican from 'components/TabsPelican/TabPelican';
import React from 'react';

import { useContentEditorProvider } from '../useContentEditorProvider';
import { useStyles } from './styles';

export const ContentEditorTabs: React.FC = () => {
  const { setActiveTab, activeTab, groups } = useContentEditorProvider();
  const styles = useStyles();

  return (
    <div className={styles.stickyDiv} data-testid="content-tab-list">
      <TabsPelican
        className={styles.tabs}
        value={activeTab}
        onChange={(_, newValue: number) => setActiveTab(newValue)}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {groups.map((group) => (
          <TabPelican key={group.id} label={group.label} />
        ))}
      </TabsPelican>
    </div>
  );
};
