import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const tablePaginationStyles = makeStyles((theme: Theme) =>
  createStyles({
    tablePagination: {},
    tablePaginationRoot: {
      borderBottom: 'none',
      display: 'flex',
      height: 56,
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
      // position: 'fixed',
      bottom: 10,
      marginTop: 46,
      // boxShadow: 'inset 0px 1px 0px #EBECF0',
    },
    tablePaginationCaption: {
      color: '#172B4D',
      fontSize: 14,
      order: 1,
    },
    tablePaginationSpacer: {
      display: 'none',
    },
    tablePaginationSelectIcon: {
      marginRight: 6,
    },
    tablePaginationSelect: {
      color: '#172B4D',
      fontSize: 14,
      '&.MuiSelect-select': {
        minWidth: 24,
      },
      '&.MuiTablePagination-select': {
        textAlignLast: 'center',
        paddingRight: 30,
      },
    },
    tablePaginationActions: {
      color: 'white',
    },
    tablePaginationToolbar: {
      paddingLeft: 0,
      width: '100%',
      '&.MuiTablePagination-caption': {
        order: '5 !important',
        background: '#000 !important',
      },
    },
    select: {
      background: theme.palette.surface[100],
      borderRadius: 4,
      marginLeft: 8,
      border: `1px solid ${theme.palette.surface[400]}`,
      order: 2,
    },
  })
);
