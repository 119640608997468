import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  moreButton: {
    display: 'flex',
    alignSelf: 'center',
    padding: 0,
  },
  container: {
    width: '35%',
    padding: '20px 0 20px 0',
    overflow: 'hidden',
  },
  checkbox: {
    minWidth: '22px',
  },
  item: {
    cursor: 'pointer',
    display: 'flex',
    paddingLeft: '4px',
    paddingRight: '20px',
    justifyContent: 'space-between',
    height: '60px',
    alignItems: 'center',
    borderBottom: '1px solid #D3D4D6',
  },
  active: {
    background: '#EFF0F2',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
}));
