import { FormControl, RadioGroup } from '@material-ui/core';
import { usePostApi, usePutApi } from 'common/hooks/useApiQuery';
import { addUserHeaders } from 'common/utils/axios-utils';
import { ButtonPelican } from 'components/ButtonPelican';
import { ImportOutline } from 'components/IconPelican/icons';
import { ModalPelican } from 'components/ModalPelican';
import { RadioItem } from 'components/RadioGroupPelican/types/RadioItem';
import RadioPelican from 'components/RadioPelican/component';
import { TextField } from 'containers/TextField/component';
import update from 'immutability-helper';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserInfo } from 'store/userInfoSlice';

import { EnvironmentItemDto } from '../../types/EnvironmentItemDto';
import { Props } from './props';
import SourceItem from './SourceItem/SourceItem';
import { useStyles } from './styles';
import { convertModelToForm, EnvironmentCreateFormDto } from './types/EnvironmentCreateFormDto';

function maxValue(arr: readonly number[]): number {
  return arr.reduce((max, val) => (max > val ? max : val));
}

export const EnvironmentCreate: React.FC<Props> = ({
  open,
  environments,
  item,
  handleAfterClose,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { post, loading: postLoading } = usePostApi('/environment');
  const { put, loading: putLoading } = usePutApi();
  const [environmentList, setEnvironmentList] = useState<readonly EnvironmentItemDto[]>([]);
  const [sourceEnvId, setSourceEnvId] = useState<string | number>(item?.sourceEnvId || '-1');
  const [sourcePriority, setSourcePriority] = useState<number>();
  const styles = useStyles();
  const user = useSelector(getUserInfo);
  const headers = useMemo(() => addUserHeaders(user.userName, user.userId), [user]);

  const [sourceEnvironments, setSourceEnvironments] = useState(environmentList);
  const isSourceEmpty = useMemo(
    () =>
      sourceEnvId === '-1' &&
      sourceEnvironments?.find((e) => e.id?.toString() === sourceEnvId) === undefined,
    [sourceEnvId, sourceEnvironments]
  );

  const { control, errors, handleSubmit } = useForm<EnvironmentCreateFormDto>({
    defaultValues: {
      ...{
        source_env_id: '',
        name: '',
      },
      ...(item ? convertModelToForm(item) : undefined),
    },
    reValidateMode: 'onBlur',
    criteriaMode: 'firstError',
  });

  const handleCancel = (): void => {
    handleAfterClose(false);
  };
  const handleSourceEnvChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const val = event.target.value;
    setSourceEnvId(val);
    const sourceEnvIndex = environmentList?.findIndex((e) => e.id === val);
    const newEnvIndex = environmentList?.findIndex((e) => e.id?.toString() === '-1');
    if (sourceEnvIndex > newEnvIndex) {
      sourceEnvIndex !== -1 && setSourcePriority(environmentList[sourceEnvIndex].priority);
    }

    if (sourceEnvIndex < newEnvIndex) {
      sourceEnvIndex !== -1 && setSourcePriority(environmentList[sourceEnvIndex].priority + 1);
    }
  };

  const handleSave = handleSubmit(async (data) => {
    try {
      if (isSourceEmpty) {
        return;
      }
      if (item?.id) {
        await put(
          {
            ...convertModelToForm({
              ...item,
            }),
            ...data,
            id: undefined,
            checked: undefined,
          },
          { baseURL: '/publication', headers },
          `/environment/${item.id}`
        );
      } else {
        const maxEnvPriority = environments?.length
          ? maxValue(environments.map((e) => e.priority))
          : 0;
        await post(
          {
            ...data,
            source_env_id: sourceEnvId?.toString() === '-1' ? undefined : sourceEnvId?.toString(),
            priority:
              sourcePriority !== undefined && !isNaN(sourcePriority)
                ? sourcePriority
                : maxEnvPriority + 1,
          },
          { baseURL: '/publication', headers }
        );
      }
      enqueueSnackbar(t('Data saved successfully'), { variant: 'success' });
      handleAfterClose(true);
    } catch (e) {
      enqueueSnackbar(
        `${t('Error: ')} ${e?.response?.data?.message || e?.response?.data?.error || e}`,
        {
          variant: 'error',
        }
      );
    }
  });

  const moveCard = (dragIndex: number, hoverIndex: number): void => {
    if (item?.id) {
      return;
    }

    if (hoverIndex === 0) {
      return;
    }

    const dragCard = environmentList[dragIndex];
    if (dragCard?.id?.toString() !== '-1') {
      return;
    }

    const newEnvironmentList = update(environmentList, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragCard],
      ],
    });

    setEnvironmentList(newEnvironmentList);

    if (hoverIndex === newEnvironmentList?.length - 1) {
      const curr = hoverIndex;
      const prev = curr > 0 ? curr - 1 : undefined;
      const newSourceEnvs = prev !== undefined &&
        environments &&
        environments[prev] && [
          environments[prev],
          ({ id: -1, name: t('Leave DB empty') } as unknown) as EnvironmentItemDto,
        ];

      setSourceEnvironments(newSourceEnvs || []);
    } else {
      const len = environmentList?.length;
      const curr = hoverIndex;
      const prev = curr > 0 ? curr - 1 : undefined;
      const next = curr < len - 1 ? curr + 1 : undefined;

      const newSourceEnvs =
        prev !== undefined &&
        next !== undefined &&
        newEnvironmentList?.length &&
        newEnvironmentList[prev] &&
        newEnvironmentList[next]
          ? [newEnvironmentList[prev], newEnvironmentList[next]]
          : environments;
      setSourceEnvironments(newSourceEnvs || []);
    }
    setSourceEnvId('-1');
    setSourcePriority(undefined);
  };

  useEffect(() => {
    setEnvironmentList([
      ...(environments || []),
      ({ id: -1, name: t('New Environment') } as unknown) as EnvironmentItemDto,
    ]);
    const curr = environments?.length ? environments?.length - 1 : 0;
    const prev = curr > 0 ? curr : undefined;
    const newSourceEnvs = prev !== undefined &&
      prev >= 0 &&
      environments &&
      environments[prev] && [
        environments[prev],
        ({ id: -1, name: t('Leave DB empty') } as unknown) as EnvironmentItemDto,
      ];

    setSourceEnvironments(
      newSourceEnvs || [({ id: -1, name: t('Leave DB empty') } as unknown) as EnvironmentItemDto]
    );
  }, [environments, t, item]);

  return (
    <ModalPelican
      open={open}
      onClose={() => handleAfterClose(false)}
      title={t(`${item ? t('Edit Environment') : t('New Environment')}`)}
      footer={
        <>
          <ButtonPelican variant={'text'} onClick={handleCancel}>
            {t('Cancel')}
          </ButtonPelican>
          <ButtonPelican
            onClick={handleSave}
            loading={postLoading || putLoading}
            color={'primary'}
            variant={'contained'}
          >
            {t('Save')}
          </ButtonPelican>
        </>
      }
    >
      <form onSubmit={handleSave}>
        {!item?.id &&
          environmentList?.map((e, index) => (
            <SourceItem
              key={e?.id || e?.name || index}
              index={index}
              name={e.name}
              item={e}
              moveCard={moveCard}
              isLast={index === environmentList?.length - 1}
            />
          ))}
        <div style={{ marginBottom: '20px' }} />
        <TextField
          rules={{ required: true }}
          errorMessage={t('ContentEnvironment name is required')}
          name="name"
          control={control}
          label={t('Environment name')}
          errors={errors}
          labelType={'vertical'}
        />
        <div className={styles.sourceRadio}>
          <div className={styles.importIcon}>
            <ImportOutline fontSize="large" />
          </div>
          <FormControl component="fieldset" className={styles.radioFieldSet}>
            <RadioGroup
              name={'source_env_id'}
              value={sourceEnvId}
              onChange={handleSourceEnvChange}
              className={styles.radioGroup}
            >
              {sourceEnvironments
                ?.map((e) => ({ label: e.name, value: e.id } as RadioItem))
                ?.map((ri, index) => (
                  <RadioPelican
                    background={'#F7F8FA'}
                    disabled={!!item?.id}
                    key={index}
                    value={ri.value?.toString()}
                    label={
                      ri?.value?.toString() === '-1' ? ri.label : `${t('Deploy from')} ${ri.label}`
                    }
                  />
                ))}
            </RadioGroup>
            {isSourceEmpty && <div className={styles.error}>{t('Choose source environment')}</div>}
          </FormControl>
        </div>
      </form>
    </ModalPelican>
  );
};

export default EnvironmentCreate;
