import { makeStyles } from '@material-ui/core/styles';
import { RIGHT_BAR_WIDTH } from 'common/constants/styles';

export const useStyles = makeStyles(() => ({
  container: ({ sidebarOn }: { readonly sidebarOn: boolean }) => ({
    width: `calc(100% - ${sidebarOn ? 30 : RIGHT_BAR_WIDTH}px)`,
    height: '100%',
    paddingLeft: 20,
    transition: 'width .3s',
  }),
  spinContainer: {
    position: 'relative',
    height: '200px',
    width: '200px',
  },
  fieldsScroll: {
    // overflowY: 'auto',
    height: 'calc(100% - 50px)',
    width: '88%',
    margin: '0 auto',
    paddingRight: '3%',
    // scrollbarWidth: 'none',
  },
}));
