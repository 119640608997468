import { ContentTypeField } from 'api/types/ContentTypeField';
import { formatOnlyDate, formatTime, parseDateTimeNotUndef } from 'common/utils/date';
import { parse } from 'date-fns';
import React, { useCallback } from 'react';

import { exclusion_keys } from '../../../helpers';
import { Props } from './props';
import { useStyles } from './styles';

export const ContentReferenceLabel: React.FC<Props> = ({ option, fields }) => {
  const classes = useStyles();

  const parseOptionValue = useCallback(
    (f: ContentTypeField): JSX.Element | null => {
      if (f.type === 'IMAGE' || f.type === 'ICON') {
        return (
          <div className={classes.alignCenter}>
            <img src={option[f.slug] as string} alt={f.slug} className={classes.image} />
          </div>
        );
      } else if (f.type === 'DATETIME') {
        return (
          <div className={classes.nowrap}>
            {formatOnlyDate(parseDateTimeNotUndef(option[f.slug] as string))}
            {formatTime(parseDateTimeNotUndef(option[f.slug] as string))}
          </div>
        );
      } else if (f.type === 'TIME') {
        return (
          <div className={classes.nowrap}>
            {formatTime(parse(option[f.slug] as string, 'HH:mm:ss', new Date(2000, 0, 1)))}
          </div>
        );
      } else if (f.type === 'COLOUR') {
        return (
          <div className={classes.nowrap}>
            <div className={classes.color} style={{ backgroundColor: option[f.slug] as string }} />
          </div>
        );
        // do not display boolean
      } else if (f.type !== 'BOOLEAN') {
        return <div className={classes.nowrap}>{option[f.slug] as string}</div>;
      }
      return null;
    },
    [classes, option]
  );

  return (
    <div className={classes.displayFlex}>
      {!Object.keys(option).some((k) => !exclusion_keys.includes(k)) && (
        <div className={classes.nowrap}>id: {option.id}</div>
      )}
      {fields
        .filter((f: ContentTypeField) => f.displayedValue && option[f.slug] !== undefined)
        .map((f: ContentTypeField, i) => (
          <>
            {i !== 0 && f.type !== 'BOOLEAN' && <div className={classes.divider}>|</div>}
            {parseOptionValue(f)}
          </>
        ))}
    </div>
  );
};
