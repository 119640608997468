import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((_theme: Theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  tableContainer: {
    position: 'relative',
    height: 'calc(100% - 100px)',
    width: '100%',
    overflow: 'auto', // overflow: 'scroll' shows on windows empty scroll bar
  },
  table: {
    borderSpacing: 0,
    marginBottom: 20,
    height: 'calc(100% - 20px)',
    width: '100%',
  },
  thead: {},
  tbody: {},
  tr: {
    position: 'relative',
    minHeight: 48,
    maxHeight: 86,
    '&:hover': {
      backgroundColor: '#f7f7f7 !important',
      cursor: 'pointer',
      '& .open-table-item': {
        display: 'block !important',
      },
    },
    '& .tableAnchor': {
      color: '#0039D4 !important',
      fontWeight: 500,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '&:first-child': {
      '& th': { borderTop: '1px solid #EBECF0' },
      '& th:first-child': { borderRight: '1px solid #EBECF0' },
      '& th:last-child': { borderRight: 'none' },
    },
    '& td:last-child': { borderRight: 'none' },
  },
  trHead: {
    minHeight: 36,
  },
  th: {
    padding: '4px 4px',
    background: '#FFFFFF',
    borderBottom: '1px solid #EBECF0',
    display: 'flex',
    alignItems: 'center !important',
    '& .MuiTableSortLabel-root ': {
      color: '#6B778C',
    },
    '& .MuiTableSortLabel-icon': {
      display: 'none',
    },
    '& .MuiTypography-caption': {
      fontSize: 12,
      fontWeight: 600,
      color: '#8F8F91',
      fontFamily: 'Inter',
    },
    position: 'relative',
    '&:last-child': { borderRight: 'none' },
  },
  caption: {
    color: '#8F8F91',
    fontSize: 13,
    lineHeight: '14px',
  },
  td: {
    position: 'relative',
    minWidth: 40,
    display: 'flex',
    alignItems: 'center !important',
    borderBottom: '1px solid #EBECF0',
    borderRight: '1px solid #EBECF0',
    '&:hover .edit-table-item': {
      display: 'inline-block',
    },
    padding: 8,
    textOverflow: 'elipsis',
    overflow: 'clip',
    // '&:last-child': { borderRight: '0' },
  },
  cellHover: {
    '&:hover': {
      boxShadow: '0px 0px 0px 2px rgba(8, 53, 198, 1) inset',
      borderRadius: 4,
      overflow: 'hidden !important',
      paddingRight: '2px !important',
    },
  },
  hasChanges: {
    background: '#FBE9E7 !important',
    '&:hover': {
      background: '#FBE9E7 !important',
    },
  },
  fieldChanged: {
    boxShadow: '0px 1px 0px #EBECF0, inset 0px -2px 0px #FF0000',
  },
  resizer: {
    display: 'inline-block',
    background: '#EBECF0',
    width: '1px',
    height: '100%',
    position: 'absolute',
    right: '0',
    top: '0',
    transform: 'translateX(50%)',
    zIndex: 1,
    touchAction: 'none',
  },
  isResizing: { background: '#676869', width: '2px' },
  footer: {
    width: '100%',
    position: 'relative',
    height: 56,
  },
  rowSelection: {
    '&:hover .open-table-item': {
      display: 'inline block !important',
    },
  },
  openButton: {
    position: 'absolute',
    left: 38,
    display: 'none',
    zIndex: 10,
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '4px 10px',
  },
  noSelectionPosition: {
    left: 8,
  },
  openTd: {
    textOverflow: 'elipsis',
    overflow: 'visible',
  },
  edit: {
    background: '#FFFFFF',
    position: 'absolute',
    top: 'calc(50% - 15px)',
    right: 2,
    display: 'none',
  },
}));
