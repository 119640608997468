import { createTheme } from '@material-ui/core';
import { ruRU } from '@material-ui/core/locale';

const defaultFontFamily = [
  'Inter',
  'Roboto',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

export const theme = createTheme(
  {
    typography: {
      fontFamily: defaultFontFamily,
      fontSize: 14,
      button: {
        textTransform: 'none',
        textDecoration: 'none',
      },
      h1: {
        fontSize: '40px',
      },
      h2: {
        fontSize: '34px',
      },
      h3: {
        fontSize: '26px',
      },
      h4: {
        fontSize: '20px',
      },
      h5: {
        fontSize: '16px',
      },
      body1: {
        fontSize: '14px',
      },
      body2: {
        fontSize: '14px',
      },
      subtitle1: {
        fontWeight: 400,
        color: '#545456',
        fontSize: 11,
        letterSpacing: '0.4px',
      },
      subtitle2: {
        color: '#545456',
        fontSize: '11px',
        letterSpacing: '0.4px',
        fontWeight: 600,
        textTransform: 'uppercase',
      },
    },
    overrides: {
      MuiButton: {
        root: {
          height: '32px',
          fontSize: 14,
          padding: '0 30px',
          borderRadius: 4,
          fontWeight: 500,
        },
        containedPrimary: {
          color: '#fff',
          backgroundColor: '#0E4DEC',
        },
      },
      MuiMenu: {
        paper: {
          filter:
            'filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.25))',
          borderRadius: 4,
        },
        list: {
          padding: '2px 0',
        },
      },
      MuiMenuItem: {
        root: {
          padding: '5px 12px',
          height: 32,
          color: '#161618',
          fontSize: 13,
          fontWeight: 400,
        },
      },
    },
    palette: {
      background: {
        default: '#ffffff',
      },
      text: {
        primary: '#161618',
        secondary: '#545456',
        hint: '#8F8F91',
      },
      primary: {
        light: '#4C9AFF',
        main: '#0E4DEC',
        dark: '#172B4D',
      },
      blue: {
        '50': '#E8EAFD',
        '100': '#C5CAFA',
        '200': '#9EAAF5',
        '300': '#7389F1',
        '400': '#4D6EEE',
        '500': '#0E4DEC',
        '600': '#0E4BDD',
        '700': '#0039D4',
        '800': '#0835C6',
        '900': '#0013B8',
      },
      purple: {
        '50': '#F7E4FD',
        '100': '#E7BBFA',
        '200': '#D88DF9',
        '300': '#C759F7',
        '400': '#BA25F6',
        '500': '#AB24F3',
        '600': '#9723ED',
        '700': '#7A21E8',
        '800': '#5C20E5',
        '900': '#081EDA',
      },
      red: {
        '50': '#FBE9E7',
        '100': '#FFCBBC',
        '200': '#FDA994',
        '300': '#FD876A',
        '400': '#FD6C4B',
        '500': '#FF4F24',
        '600': '#F24C2D',
        '700': '#E6431B',
        '800': '#D63E23',
        '900': '#BF2E0D',
      },
      surface: {
        '50': '#f7f8fa',
        '100': '#EFF0F2',
        '200': '#E4E5E7',
        '300': '#D3D4D6',
        '400': '#AFB0B2',
        '500': '#8F8F91',
        '600': '#676869',
        '700': '#545456',
        '800': '#545456',
        '900': '#161618',
      },
      green: {
        '50': '#E5F6E7',
        '100': '#C2E7C5',
        '200': '#9CD7A2',
        '300': '#71C97B',
        '400': '#4EBD60',
        '500': '#14B23B',
        '600': '#17A23A',
        '700': '#009127',
        '800': '#00801B',
        '900': '#006100',
      },
      yellow: {
        '50': '#FEF7E0',
        '100': '#FDEAB2',
        '200': '#FBDB86',
        '300': '#FACF58',
        '400': '#F9C33B',
        '500': '#F9BA00',
        '600': '#F7AC29',
        '700': '#F89A00',
        '800': '#F88900',
        '900': '#F86900',
      },
    },
  },
  ruRU
);
