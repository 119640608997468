import { Typography } from '@material-ui/core';
import { Version } from 'api/types/Version';
import { statusChipMapping } from 'common/constants/statuses';
import Chip from 'components/Chip/Chip';
import BackButton from 'containers/Breadcrumbs/BackButton/BackButton';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getContentTabs, setNewContentTabs } from 'store/contentTabsSlice';
import { getAllVersions } from 'store/versionSlice';

import { useStyles } from '../ContentEditorRightBar/ContentItemDetails/ContentProjectSelect/styles';
import { useContentEditorProvider } from '../useContentEditorProvider';

export const ContentStatus: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  const history = useHistory();
  const currentVersions = useSelector(getAllVersions);
  const tabs = useSelector(getContentTabs);

  const dispatch = useDispatch();
  const {
    contentItemId,
    contentTypeSlug,
    embedLevel,
    contentItem: { content_state, source_id },
  } = useContentEditorProvider();

  const statusCode = content_state?.code;
  const [publishedVersion, setPublishedVersion] = useState<Version | undefined>(undefined);
  const [draftVersion, setDraftVersion] = useState<Version | undefined>(undefined);
  const [reviewingVersion, setReviewingVersion] = useState<Version | undefined>(undefined);

  const redirectToVersion = useCallback(
    (id?: number) => {
      if (
        !tabs.some((tab) => tab.contentItemId === id && tab.contentTypeSlug === contentTypeSlug) &&
        id
      ) {
        dispatch(
          setNewContentTabs(
            tabs.map((tab) =>
              tab.contentItemId === +contentItemId && tab.contentTypeSlug === contentTypeSlug
                ? {
                    ...tab,
                    contentItemId: id,
                    status: statusCode === 'published' && draftVersion ? 'draft' : 'published',
                    hasDraftVersion: !tab.hasDraftVersion,
                  }
                : tab
            )
          )
        );
      }
      id && history.push(id + '');
    },
    [contentItemId, contentTypeSlug, dispatch, draftVersion, history, statusCode, tabs]
  );

  useEffect(() => {
    if (
      (statusCode === 'draft' || statusCode === 'reviewing') &&
      source_id &&
      currentVersions?.sourceId === source_id
    ) {
      const published = currentVersions.versions.find((val: Version) => val.status === 'published');
      setPublishedVersion(published);
    }

    if (statusCode === 'published' && source_id && currentVersions?.sourceId === source_id) {
      const version = currentVersions.versions.find(
        (val: Version) => val.status === 'draft' || val.status === 'reviewing'
      );
      setDraftVersion(version?.status === 'draft' ? version : undefined);
      setReviewingVersion(version?.status === 'reviewing' ? version : undefined);
    }
  }, [statusCode, currentVersions, source_id]);

  return (
    <div className={styles.flexAlignCenter}>
      {embedLevel === -1 && (
        <BackButton
          handleClick={() => {
            history.push(`/content/${contentTypeSlug}`);
          }}
        />
      )}
      <Typography variant="h4" style={{ marginRight: 10, fontWeight: 'bold' }}>
        {contentTypeSlug} {contentItemId === '-1' ? null : contentItemId}
      </Typography>
      <div className={styles.contentStateContainer}>
        {contentItemId !== '-1' && (
          <Chip
            variant={
              statusChipMapping[statusCode || 'draft'] as
                | 'success'
                | 'error'
                | 'primary'
                | 'disabled'
                | 'default'
            }
          >
            {statusCode}
          </Chip>
        )}
        {(statusCode === 'draft' || statusCode === 'reviewing') && publishedVersion && (
          <Chip
            variant={'success'}
            onClick={() => publishedVersion && redirectToVersion(publishedVersion.data_id)}
          >
            {t('Have published Version')} {publishedVersion.version}
          </Chip>
        )}
        {statusCode === 'published' && (draftVersion || reviewingVersion) && (
          <Chip
            onClick={() => redirectToVersion(draftVersion?.data_id || reviewingVersion?.data_id)}
            variant={draftVersion ? 'primary' : 'default'}
          >
            {t(draftVersion ? 'Have draft Version' : 'Have reviewing Version')}{' '}
            {draftVersion?.version || reviewingVersion?.version}
          </Chip>
        )}
      </div>
    </div>
  );
};
