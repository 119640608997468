import { MenuItem, SvgIcon, Typography } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { ButtonPelican } from 'components/ButtonPelican';
import React, { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCheckPermissions } from 'store/userInfoSlice';
import { CustomTooltip } from 'templates/MainTemplate/Sidebar/NavMenu/NavMenuItem/NavMenuItemText';

import { ArrowSmallOutline } from '../IconPelican/icons';
import { Props } from './props';
import { useStyles } from './styles';

export const DropdownButton: React.FC<Props> = ({
  label,
  variant,
  color,
  items,
  position = 'down',
  disabled,
  mainButtonType,
  onClick,
  loading,
  dataCy,
  permissions,
  popoverText,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const mainButtonRef = useRef<HTMLButtonElement>(null);
  const checkPermissions = useSelector(getCheckPermissions);
  const { t } = useTranslation();

  const classes = useStyles();

  const handleClick = (): void => {
    setAnchorEl(mainButtonRef.current);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const onItemClick = useCallback((event, index, click) => {
    click && click(event, index);
    setAnchorEl(null);
  }, []);

  const onMainClick = useCallback(
    (event) => {
      onClick && onClick(event);
    },
    [onClick]
  );

  return (
    <div className={classes.root}>
      <div>
        <ButtonPelican
          variant={variant}
          elementRef={mainButtonRef}
          disabled={disabled}
          color={color}
          type={mainButtonType}
          className={classes.mainButton}
          onClick={onMainClick}
          loading={loading}
          data-cy={dataCy}
          key="main-button"
          permissions={permissions}
          popoverText={popoverText}
        >
          {label}
        </ButtonPelican>
        <ButtonPelican
          variant={variant}
          className={classes.arrowButton}
          disabled={disabled || loading || !checkPermissions(permissions)}
          color={color}
          type="button"
          onClick={handleClick}
          key="additional-button"
        >
          <SvgIcon style={{ marginTop: 5 }} component={ArrowSmallOutline} />
        </ButtonPelican>
      </div>
      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          elevation={6}
          anchorOrigin={{
            vertical: (position === 'down' && 'bottom') || 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: (position === 'down' && 'top') || 'bottom',
            horizontal: 'left',
          }}
          disableScrollLock={true}
        >
          <>
            {items.map((item, index) => (
              <CustomTooltip
                key={(item.id || index) + '_menu_item'}
                disableHoverListener={!disabled && !item.popoverText}
                title={t(item.popoverText || 'Insufficient permissions') || ''}
              >
                <div>
                  <MenuItem
                    onClick={(event) => onItemClick(event, index, item.onClick)}
                    disabled={item.disabled}
                  >
                    <Typography variant={item.variant} noWrap={item.noWrap}>
                      <span dangerouslySetInnerHTML={{ __html: item.label }} /> {item.disabled}
                    </Typography>
                  </MenuItem>
                </div>
              </CustomTooltip>
            ))}
          </>
        </Popover>
      </div>
    </div>
  );
};
