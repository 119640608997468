import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  verticalTabs: {
    listStyle: 'none',
    display: 'flex',
    transformOrigin: 'top left',
    borderBottom: '1px solid #CECECE',
    background: '#fff',
    padding: 0,
    borderRadius: '0 0 10px 10px',
    margin: 0,
    '&>li:first-child': {
      borderRadius: '0 10px 0 0',
      borderRight: '1px solid #CECECE',
    },
    '&>li:last-child': {
      borderRadius: '10px 0 0 0',
      borderLeft: '1px solid #CECECE',
    },
  },
}));
