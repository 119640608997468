import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Props } from './props';

export const PrivateRoute: React.FC<Props> = ({
  permissions,
  userPermissions,
  redirectTo,
  component,
  users,
  userName,
  ...rest
}: Props) => {
  function canActivate(): boolean {
    if (userName && users) {
      return users.includes(userName.toLowerCase());
    } else {
      if (!permissions || permissions.length === 0) {
        return true;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return permissions.some((permission: any) =>
        Array.from(userPermissions).includes(permission.toString())
      );
    }
  }

  const access = canActivate();
  if (!access) {
    localStorage.removeItem('permissions');
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: redirectTo || '/home',
              state: { from: location },
            }}
          />
        )}
      />
    );
  }

  return <Route {...rest} component={component} />;
};
