import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '&>div': {
      flex: '0 33.33333%',
    },
  },
  alignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    maxHeight: 20,
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  divider: {
    paddingLeft: 5,
    paddingRight: 5,
  },
  color: {
    width: 20,
    height: 20,
  },
});
