import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 64,
    height: 40,
    padding: '8px 10px',
    marginLeft: -9,
  },
  switchBase: {
    top: 1,
    left: 3,
    '&.MuiIconButton-root:hover': {
      background: 'none',
    },
    '&$checked': {
      '& + $track': {
        backgroundColor: theme.palette.blue[500],
        opacity: 1,
      },
      '&:hover': {
        '& + $track': {
          backgroundColor: `${theme.palette.blue[700]} !important`,
          opacity: 1,
        },
      },
    },
    '&:hover': {
      '& + $track': {
        backgroundColor: `${theme.palette.surface[500]} !important`,
        opacity: 1,
      },
    },
  },
  thumb: {
    width: 20,
    height: 20,
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25), 0px 0px 1px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#ffffff',
  },
  track: {
    borderRadius: 20,
    backgroundColor: theme.palette.surface[400],
    opacity: 1,
  },
  checked: {},
  disabled: {
    '& + $track': {
      backgroundColor: `${theme.palette.surface[300]} !important`,
      opacity: 1,
    },
    '&$checked': {
      '& + $track': {
        backgroundColor: `${theme.palette.blue[100]} !important`,
        opacity: 1,
      },
    },
  },
}));
