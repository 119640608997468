import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { QuestionCircleOutline } from 'components/IconPelican/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const useStyles = makeStyles(() => ({
  shortcutContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 4,
    width: '32px',
    height: '28px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  shortcutTitle: {
    paddingLeft: 12,
    paddingRight: 12,
  },
  shortcut: {
    position: 'absolute',
    width: 380,
    top: 38,
    right: -100,
    background: 'white',
    padding: 20,
    borderRadius: 3,
    border: '1px solid #DFE1E6',
    color: '#172B4D',
    zIndex: 10000,
  },
  shortcutButton: {
    fontWeight: 600,
    fontSize: 12,
    background: '#091E42',
    color: 'white',
    padding: 8,
    cursor: 'pointer',
    borderRadius: 3,
  },
  flexBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: 6,
    },
  },
}));

export const Shortcuts: React.FC = () => {
  const isMacos = !!window.navigator.platform.match(/^Mac/);
  const [showShortcuts, setShowShortcuts] = React.useState(false);
  const { t } = useTranslation();

  const classes = useStyles();

  const shortcuts = [
    {
      name: 'Navigation List',
      symbol: `${isMacos ? '&#8984' : 'Ctrl'} + L`,
    },
    {
      name: 'Models',
      symbol: `${isMacos ? '&#8984' : 'Ctrl'} + M`,
    },
    {
      name: 'Select all contents',
      symbol: `${isMacos ? '&#8984' : 'Ctrl'} + A`,
    },
    {
      name: 'New model',
      symbol: `${isMacos ? '&#8984' : 'Ctrl'} + Shift + M`,
    },
    {
      name: 'New asset',
      symbol: `${isMacos ? '&#8984' : 'Ctrl'} + Shift + A`,
    },
    {
      name: 'New content',
      symbol: `${isMacos ? 'Option' : 'Alt'} + Shift + N`,
    },
    {
      name: 'Exit',
      symbol: `${isMacos ? 'Option' : 'Alt'} + Q`,
    },
    {
      name: 'Global search',
      symbol: `${isMacos ? '&#8984' : 'Ctrl'} + K`,
    },
    {
      name: 'Save content',
      symbol: `${isMacos ? '&#8984' : 'Ctrl'} + Shift + S`,
    },
    {
      name: 'Save content and go to list',
      symbol: `${isMacos ? '&#8984' : 'Ctrl'} + ${isMacos ? 'Option' : 'Alt'} + L`,
    },
    {
      name: 'Save content and publish',
      symbol: `${isMacos ? '&#8984' : 'Ctrl'} + Shift + P`,
    },
    {
      name: 'Save content and create new',
      symbol: `${isMacos ? '&#8984' : 'Ctrl'} + ${isMacos ? 'Option' : 'Alt'} + S`,
    },
    {
      name: 'Apply selected filter',
      symbol: `${isMacos ? '&#8984' : 'Ctrl'} + Shift + F`,
    },
  ];

  return (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
      className={classes.shortcutContainer}
      onMouseOver={() => setShowShortcuts(true)}
      onMouseOut={() => setShowShortcuts(false)}
    >
      <QuestionCircleOutline style={{ fill: '#fff' }} />
      {showShortcuts && (
        <div className={classes.shortcut}>
          {shortcuts.map((cut, index) => {
            return (
              <div key={index} className={classes.flexBetween}>
                <div
                  className={classes.shortcutButton}
                  dangerouslySetInnerHTML={{ __html: cut.symbol }}
                />
                <Typography variant="body1">{t(cut.name)}</Typography>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
