import { gql } from '@apollo/client';

export const CONTENT_DIFF = gql`
  query contentDiff(
    $contentTypeId: Int!
    $leftEnvId: String!
    $rightEnvId: String!
    $onlyChanged: Boolean!
    $_page: Int!
    $_size: Int!
    $sourceIds: [Int]!
  ) {
    contentDiff(
      leftEnvId: $leftEnvId
      rightEnvId: $rightEnvId
      contentTypeId: $contentTypeId
      onlyChanged: $onlyChanged
      _page: $_page
      _size: $_size
      sourceIds: $sourceIds
    ) {
      data {
        ids {
          left
          right
        }
        sourceId
        state
        versions {
          left
          right
        }
      }
      hasNext
      page
      size
      totalCount
      totalPages
    }
  }
`;
