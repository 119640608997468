import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import Spin from 'components/Spin/component';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCheckPermissions } from 'store/userInfoSlice';
import { CustomTooltip } from 'templates/MainTemplate/Sidebar/NavMenu/NavMenuItem/NavMenuItemText';

import { Props } from './props';
import { useStyles } from './styles';

export const ButtonPelican: React.FC<Props> = ({
  variant = 'contained',
  color = 'default',
  children,
  elementRef,
  className,
  loading,
  disabled,
  size,
  permissions,
  popoverText,
  onClick,
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const checkPermissions = useSelector(getCheckPermissions);
  const permissionDenied = !checkPermissions(permissions);
  const buttonDisabled = loading || disabled || permissionDenied;

  const adjustedButtonProps = {
    disabled: buttonDisabled,
    component: buttonDisabled ? 'div' : undefined,
    onClick: buttonDisabled ? undefined : onClick,
  };

  return (
    <CustomTooltip
      disableHoverListener={!permissionDenied && !popoverText}
      disableFocusListener={!permissionDenied && !popoverText}
      title={buttonDisabled ? t(popoverText || 'Insufficient permissions') || '' : ''}
    >
      <Button
        variant={variant || 'contained'}
        disableElevation
        ref={elementRef}
        classes={{
          root: classes.root,
        }}
        className={clsx(
          className,
          classes.defaultStyles,
          (color === 'primary' && variant === 'contained' && classes.colorPrimary) ||
            (color === 'danger' && variant === 'contained' && classes.colorDanger) ||
            (color === 'default' && variant === 'contained' && classes.colorDefault) ||
            (color === 'success' && variant === 'contained' && classes.colorSuccess) ||
            (color === 'dark' && variant === 'contained' && classes.colorDark) ||
            (color === 'primary' && variant === 'text' && classes.variantTextColorPrimary) ||
            (color === 'danger' && variant === 'text' && classes.variantTextColorDanger) ||
            (color === 'default' && variant === 'text' && classes.variantTextColorDefault) ||
            classes.colorDefault,
          size === 'small' && classes.variantSizeSmall
        )}
        autoFocus={buttonDisabled}
        {...rest}
        {...adjustedButtonProps}
      >
        <Typography
          variant="button"
          style={{
            visibility: loading ? 'hidden' : 'visible',
            lineHeight: 1,
          }}
        >
          {children}
        </Typography>
        {loading && <Spin size={20} bg="transparent" variant="absolute" />}
      </Button>
    </CustomTooltip>
  );
};
