import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 4,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  tableWrapper: {},
}));
