import { gql } from '@apollo/client';

export type EnvironmentVersionInfo = {
  readonly envId: string;
  readonly version: number;
  readonly contentId?: number;
  readonly envName?: string;
};

export type PublicationStateType = {
  readonly contentPublicationState: ReadonlyArray<{
    readonly sourceId: number;
    readonly envVersionInfo: ReadonlyArray<EnvironmentVersionInfo>;
  }>;
};

export const PUBLICATION_STATE = gql`
  query publicationState($contentTypeId: Int!, $sourceIds: [Int]!) {
    contentPublicationState(contentTypeId: $contentTypeId, sourceIds: $sourceIds) {
      sourceId
      envVersionInfo {
        envId
        version
        contentId
      }
    }
  }
`;
