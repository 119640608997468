import { ContentItem, SelectContentItem } from 'api/types/ContentItem';
import { ContentTypeField } from 'api/types/ContentTypeField';
import { RulesType } from 'common/types/RulesType';
import { formatDate, formatDateTime, formatTime, parseDate, parseTime } from 'common/utils/date';
import { SelectItem, SelectItemElementLabel } from 'components/SelectPelican';
import { isDate } from 'date-fns';
import { TFunction } from 'i18next';
import { DeepMap, FieldError } from 'react-hook-form';

export const exclusion_keys = [
  'id',
  'source_id',
  'count',
  'source_id',
  'content_state_id',
  'parent',
];

export const sortFieldsByPosition = (
  items: ReadonlyArray<ContentTypeField>
): ReadonlyArray<ContentTypeField> => {
  const noPosition = items.filter((field) => field.position === undefined);
  const negativePosition = items
    .filter((field) => field.position !== undefined && field.position < 0)
    .sort((a, b) => parseInt(`${a.position}`) - parseInt(`${b.position}`));
  const positivePosition = items
    .filter((field) => field.position !== undefined && field.position >= 0)
    .sort((a, b) => parseInt(`${a.position}`) - parseInt(`${b.position}`));
  return [...positivePosition, ...noPosition, ...negativePosition];
};

export const parseItemsToOptions = (
  items: ReadonlyArray<SelectContentItem>
): ReadonlyArray<SelectItem | SelectItemElementLabel> => {
  return items.map((i) => {
    const label = Object.entries(i)
      .filter((item) => !exclusion_keys.includes(item[0]) && typeof i[item[0]] !== 'object')
      .reduce((prev, next) => (prev.length > 0 && `${prev} ${next[1]}`) || `${next[1]}`, '');
    return {
      value: i.id,
      label: label,
    };
  });
};

export const fieldToRules = (field: ContentTypeField): RulesType => {
  return {
    required: field?.required,
    minLength: field?.minLength,
    maxLength: field?.maxLength,
    min: field?.minValue,
    max: field?.maxValue,
  };
};

export const getFieldValidationMessage = (
  field: ContentTypeField,
  errors: DeepMap<Record<string, unknown>, FieldError | undefined>,
  t: TFunction
): string => {
  const message = `${
    errors[field.slug]?.type === 'required' ? `${field.label} ${t('is required')}` : ''
  }
  ${
    errors[field.slug]?.type === 'minLength'
      ? `${t('The minimum length should be')} ${field.minLength}`
      : ''
  }
  ${
    errors[field.slug]?.type === 'maxLength'
      ? `${t('The maximum length should be')} ${field.maxLength}`
      : ''
  }
  ${
    errors[field.slug]?.type === 'min'
      ? `${t('The minimum value should be')} ${field.minValue}`
      : ''
  }
  ${
    errors[field.slug]?.type === 'max'
      ? `${t('The maximum value should be')} ${field.maxValue}`
      : ''
  }
  `;

  return message;
};

export const getEmptyContentItem = (fields: ReadonlyArray<ContentTypeField>): ContentItem => ({
  ...fields.reduce((prev, field) => {
    if (field.type === 'BOOLEAN') {
      if (field.defaultValue === 'true' || field.defaultValue === 'false') {
        return {
          ...prev,
          [field.slug]: field.defaultValue,
        };
      } else {
        return prev;
      }
    } else {
      return prev;
    }
  }, {}),
});

export const initEmbeddedContentItem = (
  item: ReadonlyArray<ContentItem>
): ReadonlyArray<ContentItem> => {
  return (
    (item &&
      item.map((embeddedItem: ContentItem) => {
        const itemKeys = Object.keys(embeddedItem);
        const result = itemKeys.reduce((prev, key) => {
          if (embeddedItem[key] && embeddedItem[key].items && embeddedItem[key].items.length >= 0) {
            return {
              ...prev,
              [key]:
                embeddedItem[key].items.length > 0
                  ? embeddedItem[key].items.reduce(
                      (prev: string, next: ContentItem) =>
                        (prev.length > 0 && `${prev},${next.id}`) || `${next.id}`,
                      ''
                    )
                  : '',
            };
          }
          if (Array.isArray(embeddedItem[key])) {
            return {
              ...prev,
              [key]: initEmbeddedContentItem(embeddedItem[key]),
            };
          }

          return {
            ...prev,
            [key]: embeddedItem[key],
          };
        }, {});
        return result;
      })) ||
    []
  );
};

export const parseContentItemToFormData = (
  items: ReadonlyArray<ContentItem>,
  fields: ReadonlyArray<ContentTypeField>
): ReadonlyArray<ContentItem> => {
  return items.map((item) => {
    const fieldsValues: Record<string, unknown> = fields.reduce((prev, next) => {
      if (next.type === 'DATE') {
        return {
          ...prev,
          [next.slug]: isDate(item[next.slug] as Date)
            ? formatDate(item[next.slug])
            : formatDate(parseDate(item[next.slug])),
        };
      } else if (next.type === 'DATETIME') {
        return {
          ...prev,
          [next.slug]: formatDateTime(item[next.slug]),
        };
      } else if (next.type === 'TIME') {
        return {
          ...prev,
          [next.slug]: isDate(item[next.slug] as Date)
            ? formatTime(item[next.slug])
            : formatTime(parseTime(item[next.slug])),
        };
      } else if (next.type === 'BOOLEAN') {
        return {
          ...prev,
          [next.slug]: item[next.slug],
        };
      } else if (next.type === 'REFERENCE' && !next.embedded) {
        return {
          ...prev,
          [next.slug]:
            (item[next.slug] &&
              item[next.slug].items
                .map((refItem: { readonly id: number }) => refItem.id)
                .join(',')) ||
            '',
        };
      } else if (next.type === 'REFERENCE' && next.embedded) {
        return {
          ...prev,
          [next.slug]: initEmbeddedContentItem(item[next.slug]),
        };
      } else {
        return {
          ...prev,
          [next.slug]: item[next.slug],
        };
      }
    }, {});
    return Object.entries(item).reduce((prev, next) => {
      if (next[0] === '$.multilingual' && next[1]) {
        return {
          ...prev,
          '$.multilingual': item['$.multilingual'],
        };
      }
      if (next[0] === 'id' && next[1]) {
        return {
          ...prev,
          id: item.id,
        };
      }
      return prev;
    }, fieldsValues);
  });
};

export const sortEmbeddedValues = (content: ContentItem): ContentItem => {
  const res: ContentItem = {};
  Object.keys(content).forEach((k) => {
    if (Array.isArray(content[k])) {
      res[k] = content[k]
        .slice()
        .sort((a: ContentItem, b: ContentItem) => (a.priority as number) - (b.priority as number));
    } else {
      res[k] = content[k];
    }
  });
  return res;
};
