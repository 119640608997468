import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const EyeCrossedOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.103 5.042c-.444-.443-1.04-.566-1.334-.273-.293.293-.17.89.273 1.334l1.683 1.682 1.098 1.1 6.886 6.884 1.165 1.166 2.023 2.023c.444.443 1.04.566 1.334.273.292-.293.17-.89-.274-1.334l-1.67-1.671-1.1-1.1-6.89-6.889-1.163-1.164-2.031-2.03zM3 12c0-.666.903-2.087 2.473-3.345l1.068 1.068-.118.093c-.688.55-1.211 1.121-1.548 1.596a3.433 3.433 0 00-.337.567l-.009.02.008.02c.06.135.168.328.337.566.337.475.86 1.047 1.548 1.597 1.378 1.102 3.307 2.028 5.574 2.028.334 0 .66-.02.976-.056l1.296 1.296c-.713.165-1.472.26-2.272.26C6.628 17.71 3 13.33 3 12zm14.6 2.184c-.042.035-.086.07-.13.104l1.068 1.068C20.11 14.094 21 12.666 21 12c0-1.337-3.565-5.71-9.004-5.71-.793 0-1.549.096-2.26.264l1.293 1.293c.315-.037.638-.057.967-.057 2.307 0 4.236.927 5.603 2.024.683.548 1.199 1.117 1.53 1.59.167.238.273.431.333.567l.012.028-.012.028c-.06.135-.165.328-.332.566-.331.473-.846 1.043-1.53 1.591z"
      fill="#000"
    />
  </SvgIcon>
);
export default EyeCrossedOutline;
