import { ToggleButton, ToggleButtonProps } from '@material-ui/lab';
import React from 'react';

import { useStyles } from './styles';

export const SwitchButton: React.FC<ToggleButtonProps> = ({ children, ...rest }) => {
  const styles = useStyles();

  return (
    <ToggleButton {...rest} classes={{ root: styles.root, selected: styles.selected }}>
      {children}
    </ToggleButton>
  );
};
