import { createSlice } from '@reduxjs/toolkit';
import { fetchAccessRights, fetchMenuRights } from 'api/access-right';
import { projectsGetAll } from 'api/projects';
import { Action } from 'redux';
import { AppThunk } from 'store/store';
import { setProjects, setWorkspaces } from 'store/workspaceSlice';

import { UserInfoState } from './types/user-info-state';

const initialState: UserInfoState = {
  email: '',
  permissions: [],
  userName: '',
  role: '',
  roleCode: '',
  loading: true,
  userId: -1,
};

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    setUserInfo(state, action: Action & { readonly payload: UserInfoState | undefined }) {
      const data = { ...state, ...action.payload };
      localStorage.setItem('userInfo', JSON.stringify(data));
      return data;
    },
    logout(state) {
      localStorage.removeItem('userInfo');
      sessionStorage.clear();
      return { ...state, ...initialState, loading: false };
    },
    setLoading(state, action: Action & { readonly payload: boolean }) {
      return { ...state, loading: action.payload };
    },
    setError(state, action: Action & { readonly payload: unknown }) {
      return { ...state, error: action.payload };
    },
  },
});

export const { setUserInfo, logout, setLoading, setError } = userInfoSlice.actions;

export const { getUserInfo, getPermissions, getCheckPermissions } = {
  getUserInfo(state: { readonly userInfo: UserInfoState }) {
    return state.userInfo;
  },

  getPermissions(state: { readonly userInfo: UserInfoState }) {
    return state.userInfo.permissions;
  },

  getCheckPermissions(state: { readonly userInfo: UserInfoState }) {
    return (permissions?: ReadonlyArray<string> | string) => {
      if (permissions === undefined || permissions.length === 0) {
        return true;
      }
      if (Array.isArray(permissions)) {
        return permissions.every((p) => state.userInfo.permissions.includes(p));
      } else {
        return state.userInfo.permissions.includes(permissions as string);
      }
    };
  },
};

export const loadInitialData = (): AppThunk => async (dispatch) => {
  try {
    const accessRights = await fetchAccessRights();
    const menuRights = await fetchMenuRights();
    const permissions = accessRights.map((r) => r.code).concat(menuRights.map((r) => r.code));
    const projects = await projectsGetAll();
    dispatch(setProjects({ projects: projects }));
    const defaultProject = projects.find((p) => p.default);
    if (projects.length) {
      if (!defaultProject) {
        const workspaceJson = localStorage.getItem('workspaces');
        if (workspaceJson) {
          dispatch(setWorkspaces(JSON.parse(workspaceJson)));
        } else {
          const [project] = projects;
          dispatch(
            setWorkspaces([
              {
                workspaceId: project.id,
                workspaceName: project.title,
                workspaceSlug: project.slug,
              },
            ])
          );
        }
      } else {
        dispatch(
          setWorkspaces([
            {
              workspaceId: defaultProject.id,
              workspaceName: defaultProject.title,
              workspaceSlug: defaultProject.slug,
            },
          ])
        );
      }
    }

    const userInfoJson = localStorage.getItem('userInfo');
    if (userInfoJson) {
      dispatch(setUserInfo({ ...(JSON.parse(userInfoJson) as UserInfoState), permissions }));
    }
  } catch (e) {
    dispatch(setError(e));
  } finally {
    dispatch(setLoading(false));
  }
};

export default userInfoSlice.reducer;
