import get from 'common/utils/axios-request';

import { Project, ProjectCreateEditType, ProjectsInput, ProjectsResult } from './types/Project';

export function projectsGet(data: ProjectsInput): Promise<ProjectsResult> {
  return get('/api/v1/projects', {
    params: data,
  }).then((res) => (res as unknown) as ProjectsResult);
}
export function projectGetById(id: string): Promise<ProjectCreateEditType> {
  return get('/api/v1/projects/' + id).then(({ data }) => data as ProjectCreateEditType);
}

export function standardProjectsGet(): Promise<ReadonlyArray<Project>> {
  return get('/api/v1/projects/standard-for-new-user').then(({ data }) => data);
}

export function projectEdit(data: ProjectCreateEditType): Promise<unknown> {
  return get('api/v1/projects', { method: 'PUT', data: data }).then(({ data }) => data);
}

export function projectCreate(data: ProjectCreateEditType): Promise<Project> {
  return get('api/v1/projects', { method: 'POST', data: data }).then(({ data }) => data);
}

export function projectDelete(slug: string | undefined): Promise<ProjectCreateEditType> {
  return get('api/v1/projects/' + slug, { method: 'DELETE' }).then(({ data }) => data);
}

export function userWorkspacesGet(userId: number): Promise<readonly Project[]> {
  return get(`/api/v1/users/${userId}/projects`).then(({ data }) => data);
}

export function userWorkspacesPost(
  userId: number,
  workspaces: ReadonlyArray<Project>
): Promise<readonly Project[]> {
  return get(`/api/v1/users/${userId}/projects`, {
    data: workspaces,
    method: 'POST',
  }).then(({ data }) => data);
}

export function projectsGetAll(): Promise<readonly Project[]> {
  return get(`/api/v1/me/projects?page=1&size=99999`).then(
    ({ data }) => data as readonly Project[]
  );
}
