import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const TimeCircleOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 19.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zm0 1.5a9 9 0 100-18 9 9 0 000 18z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6.002a.75.75 0 01.75.75v5.998H7.687a.75.75 0 010-1.5h3.563V6.752a.75.75 0 01.75-.75z"
    />
  </SvgIcon>
);
export default TimeCircleOutline;
