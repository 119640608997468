import { Tooltip, withStyles } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { NavItemProps } from './index';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    borderRadius: 3,
    padding: 0,
    '&:hover': {
      background: theme.palette.surface[200],
    },
    '&>a': {
      padding: 6,
      color: theme.palette.surface[700],
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      textDecoration: 'none',
      '&.active': {
        background: theme.palette.blue[50],
        color: theme.palette.blue[700],
        borderRadius: 3,
        '& svg': {
          color: theme.palette.blue[700],
        },
      },
    },
  },
  toggle: {
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  item: {
    display: 'block',
    width: '100%',
    height: '100%',
    padding: 4,
    textDecoration: 'none',
    color: theme.palette.surface[700],
  },
}));

export const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: theme.spacing(1),
    background: theme.palette.surface[900],
  },
  arrow: {
    color: theme.palette.surface[900],
  },
}))(Tooltip);

export const NavMenuItemText: React.FC<NavItemProps> = ({
  onClick,
  path,
  title,
  toggle,
  children,
}) => {
  const classes = useStyles();

  if (!path) {
    return (
      <ListItem
        className={classes.root}
        button
        onClick={onClick}
        style={{
          padding: 8,
        }}
      >
        {children}
      </ListItem>
    );
  }

  return (
    <>
      {toggle && (
        <CustomTooltip title={title} placement="right" arrow>
          <div className={classes.root}>
            <NavLink to={path} exact className={clsx(classes.item, toggle && classes.toggle)}>
              <div className={clsx(classes.link, toggle && classes.toggle)}>{children}</div>
            </NavLink>
          </div>
        </CustomTooltip>
      )}
      {!toggle && (
        <div className={classes.root}>
          <NavLink to={path} exact className={classes.item}>
            <div className={classes.link}>{children}</div>
          </NavLink>
        </div>
      )}
    </>
  );
};

export default NavMenuItemText;
