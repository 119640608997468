import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const UserDoubleOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.763 18.5h10.363a7.92 7.92 0 00-1.09-2.801c-.779-1.23-1.574-1.69-2.091-1.69h-4c-.518 0-1.313.46-2.092 1.69a7.918 7.918 0 00-1.09 2.801zm-1.568.75c0-2.623 2.126-6.74 4.75-6.74h4c2.623 0 4.75 4.117 4.75 6.74a.75.75 0 01-.75.75h-12a.75.75 0 01-.75-.75zM8.928 9.506a2 2 0 100-3.999 2 2 0 000 4zm3.5-2a3.5 3.5 0 11-7 0 3.5 3.5 0 017 0z"
    />
    <path d="M13.044 10.376c.568-.812.902-1.8.902-2.866a4.977 4.977 0 00-.902-2.865 3.5 3.5 0 110 5.73zM15.572 14.082a8.624 8.624 0 00-1.265-1.574h2.747c2.623 0 4.75 4.108 4.75 6.731v.75H17.068c.083-.235.128-.487.128-.75 0-1.648-.642-3.607-1.624-5.157z" />
  </SvgIcon>
);
export default UserDoubleOutline;
