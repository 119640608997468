import { Box } from '@material-ui/core';
import React from 'react';

import { PelicanTabPanelProps } from './index';

export const TabPanelPelican: React.FC<PelicanTabPanelProps> = ({
  value,
  children,
  index,
  padding,
  ...other
}) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={padding || 3} display={value === index ? 'block' : 'none'}>
        {children}
      </Box>
    </div>
  );
};

export default TabPanelPelican;
