import { contentItemGet, fieldsGet } from 'api/content';
import { ContentItem, PatchDataType } from 'api/types/ContentItem';
import { ContentTypeField } from 'api/types/ContentTypeField';
import { ButtonPelican } from 'components/ButtonPelican';
import update from 'immutability-helper';
import { sortFieldsByPosition } from 'pages/ContentEditorPage/ContentEditor/helpers';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { transformToApi } from '../../ContentEditorProvider/helpers';
import { useContentEditorProvider } from '../../useContentEditorProvider';
import { ContentEmbeddedItem } from './ContentEmbeddedItem';
import { Props } from './props';
import { useStyles } from './styles';

export const ContentEmbeddedInput: React.FC<Props> = ({ field }) => {
  const {
    embedLevel,
    isReviewing,
    contentItem,
    form,
    setContentItemValue,
    handleFieldChange,
    contentItemId,
    contentTypeSlug,
    selectedProjects,
    mainSourceId,
    updateInfo,
    setUpdateInfo,
    params,
    inTreeView,
    rootProjectId,
    selectedLocales,
    selectedProjectOwner,
    openEmbeddedInfo,
    setOpenEmbeddedInfo,
  } = useContentEditorProvider();
  const { setValue } = form;
  const { t } = useTranslation();
  const classes = useStyles();
  const currentProjectId =
    embedLevel === -1
      ? contentItem.project_id || (selectedProjectOwner.value as number)
      : rootProjectId;
  const embeddedItems = useMemo(
    () => (contentItem[field.slug] as ReadonlyArray<ContentItem>) || [],
    [contentItem, field.slug]
  );
  const handleSetEmbeddedItems = useCallback(
    (newEmbeddedItems: ReadonlyArray<ContentItem>) => {
      setContentItemValue({
        ...contentItem,
        [field.slug]: newEmbeddedItems,
      });
      setValue(field.slug, newEmbeddedItems);
    },
    [setContentItemValue, contentItem, field.slug, setValue]
  );

  const [unique, setUnique] = useState(1);
  const [fields, setFields] = useState<ReadonlyArray<ContentTypeField>>([]);
  const [newKeyCounter, setNewKeyCounter] = useState(0);

  const handleAddEmbeddedItem = useCallback(() => {
    handleSetEmbeddedItems([
      ...embeddedItems,
      { __isModalOpen: true, __uniqueKey: `key${unique}`, priority: embeddedItems.length },
    ]);
    setUnique(unique + 1);
  }, [embeddedItems, unique, handleSetEmbeddedItems]);

  const updateUpdateInfo = useCallback(
    (array: ReadonlyArray<PatchDataType>) => {
      setUpdateInfo({ ...updateInfo, [field.slug]: array });
    },
    [field.slug, setUpdateInfo, updateInfo]
  );

  const getDuplicatedItem = useCallback(
    (copyItem: ContentItem, embeddedItem: ContentItem, update: PatchDataType): ContentItem => {
      update['$.fields']?.forEach((f) => {
        if (update['$.updateInfo']?.newData[f.slug] !== undefined) {
          if (f.type === 'RICHTEXT') {
            copyItem[f.slug] = update['$.updateInfo']?.['newData'][f.slug];
          } else {
            copyItem[f.slug] = embeddedItem[f.slug];
          }
        }
      });
      update['$.updateInfo']?.multilingualFieldSlugs?.forEach((s) => {
        const patchMultilingual = update['$.updateInfo']?.newData['$.multilingual'];
        Object.keys(patchMultilingual || {}).forEach((k) => {
          if (patchMultilingual[k]?.[s] !== undefined) {
            if (copyItem['$.multilingual'][k]) {
              copyItem['$.multilingual'][k][s] = patchMultilingual[k][s];
            } else {
              copyItem['$.multilingual'][k] = {
                [s]: patchMultilingual[k][s],
              };
            }
          }
        });
      });
      Object.keys(update).forEach((slug) => {
        if (!slug.includes('$.')) {
          copyItem[slug] = [
            ...(copyItem[slug] as ReadonlyArray<ContentItem>).map((c) => {
              const ui = update[slug].find(
                (ui: PatchDataType) => ui['$.updateInfo']?.dataId === c.id
              );
              if (ui) {
                return getDuplicatedItem(c, embeddedItem[slug][ui['$.indexInField']], ui);
              } else {
                return c;
              }
            }),
            ...embeddedItem[slug].filter((c: ContentItem) => !c.id),
          ];
        }
      });
      return copyItem;
    },
    []
  );

  const handleDuplicateEmbedded = useCallback(
    async (i: number) => {
      if (contentItemId && contentItemId !== '-1') {
        const copy = embeddedItems[i].id
          ? await contentItemGet(
              field.referencedContentType || '',
              String(embeddedItems[i].id),
              -1,
              true
            )
          : embeddedItems[i];
        const patchInfo = (updateInfo[field.slug] as ReadonlyArray<PatchDataType>)?.find(
          (p) => p['$.indexInField'] === i
        );
        const newItem = patchInfo ? getDuplicatedItem(copy, embeddedItems[i], patchInfo) : copy;
        const newEmbItems = [
          ...embeddedItems,
          {
            ...newItem,
            id: undefined,
            projects: undefined,
            priority: embeddedItems.length,
            __uniqueKey: `key-${
              embeddedItems[i].id || embeddedItems[i].__uniqueKey
            }${newKeyCounter}`,
          },
        ];
        handleSetEmbeddedItems(newEmbItems);
        const putFields = transformToApi(fields, newItem, true);
        const putData = {
          ...putFields,
          source_id: undefined,
          id: undefined,
          projects: undefined,
          priority: newEmbItems.length - 1,
          content_state: {
            code: 'draft',
            description: 'Draft description',
            id: 1,
          },
          project_id: currentProjectId,
        };

        const multilingual = newItem['$.multilingual'];
        if (multilingual && Object.keys(multilingual).length > 0) {
          putData['$.multilingual'] = {};
          selectedLocales.forEach((l) => {
            putData['$.multilingual'][l.code] = multilingual[l.code];
          });
        }
        const newInfo = {
          contentTypeSlug: field.referencedContentType || '',
          fieldSlugInParent: field.slug,
          rootContentTypeSlug: params.type,
          rootDataId: +params.sourceId,
          newData: putData,
          parentDataId: +contentItemId,
          parentContentTypeSlug: contentTypeSlug,
          multilingualFieldSlugs: fields.filter((f) => f.multilingual).map((f) => f.slug),
        };
        updateUpdateInfo([
          ...((updateInfo[field.slug] as ReadonlyArray<PatchDataType>) || []),
          {
            '$.updateInfo': newInfo,
            '$.fields': fields,
            '$.indexInField': newEmbItems.length - 1,
          },
        ]);
      } else {
        const newEmbItem = [
          ...embeddedItems,
          {
            ...embeddedItems[i],
            id: undefined,
            priority: embeddedItems.length,
            __uniqueKey: `key-${
              embeddedItems[i].id || embeddedItems[i].__uniqueKey
            }${newKeyCounter}`,
          },
        ];
        handleSetEmbeddedItems(newEmbItem);
      }
      setNewKeyCounter((prev) => prev + 1);
    },
    [
      contentItemId,
      embeddedItems,
      field.referencedContentType,
      field.slug,
      newKeyCounter,
      handleSetEmbeddedItems,
      fields,
      currentProjectId,
      params.type,
      params.sourceId,
      contentTypeSlug,
      updateUpdateInfo,
      updateInfo,
      selectedLocales,
      getDuplicatedItem,
    ]
  );

  const setEmbeddedPriorities = useCallback(
    (newEmb: ReadonlyArray<ContentItem>, dragIndex?: number, hoverIndex?: number) => {
      if (contentItemId && contentItemId !== '-1') {
        const newUpdates = newEmb.map(
          (e, i): PatchDataType => {
            const current = (updateInfo[field.slug] as ReadonlyArray<PatchDataType>)?.find(
              (u) =>
                u['$.indexInField'] ===
                (i === dragIndex ? hoverIndex : i === hoverIndex ? dragIndex : i)
            );
            return {
              ...current,
              '$.updateInfo': {
                dataId: e.id,
                contentTypeSlug: field.referencedContentType || '',
                fieldSlugInParent: !e.id ? field.slug : undefined,
                rootContentTypeSlug: params.type,
                rootDataId: +params.sourceId,
                newData: {
                  ...current?.['$.updateInfo']?.newData,
                  priority: i,
                  projects: selectedProjects,
                  project_id: currentProjectId,
                },
                parentDataId: +contentItemId,
                parentContentTypeSlug: contentTypeSlug,
              },
              '$.indexInField': i,
            };
          }
        );
        updateUpdateInfo(newUpdates);
      }
    },
    [
      contentItemId,
      contentTypeSlug,
      currentProjectId,
      field.referencedContentType,
      field.slug,
      params.sourceId,
      params.type,
      selectedProjects,
      updateInfo,
      updateUpdateInfo,
    ]
  );

  const removeEmbeddedItem = useCallback(
    (index: number) => {
      const newEmbItems = embeddedItems.filter((_e, i) => i !== index);
      embeddedItems[index].id && handleFieldChange(field.slug);
      contentItemId &&
        updateUpdateInfo(
          ((updateInfo[field.slug] as ReadonlyArray<PatchDataType>) || [])
            .filter((u: PatchDataType) => u['$.indexInField'] !== index)
            .map((u) =>
              (u['$.indexInField'] || 0) > index
                ? { ...u, '$.indexInField': (u['$.indexInField'] || 0) - 1 }
                : u
            )
        );
      handleSetEmbeddedItems(newEmbItems);
    },
    [
      updateUpdateInfo,
      embeddedItems,
      handleFieldChange,
      field.slug,
      contentItemId,
      updateInfo,
      handleSetEmbeddedItems,
    ]
  );

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newEmb = update(embeddedItems, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, embeddedItems[dragIndex]],
        ],
      });
      setEmbeddedPriorities(newEmb, dragIndex, hoverIndex);
      handleSetEmbeddedItems(newEmb);
    },
    [embeddedItems, handleSetEmbeddedItems, setEmbeddedPriorities]
  );

  const embeddedFormSubmitHandler = useCallback(
    async (data: Record<string, unknown>, index: number, updateInfoData?: PatchDataType) => {
      const newEmbeddedItems = embeddedItems.slice();
      newEmbeddedItems[index] = { ...newEmbeddedItems[index], ...data, __isModalOpen: undefined };
      handleSetEmbeddedItems(newEmbeddedItems);
      if (contentItemId && contentItemId !== '-1' && updateInfoData) {
        updateUpdateInfo([
          ...((updateInfo?.[field.slug] as ReadonlyArray<PatchDataType>) || []).filter(
            (c) => c['$.indexInField'] !== updateInfoData['$.indexInField']
          ),
          updateInfoData,
        ]);
      }
      handleFieldChange('#patch_' + field.slug);
    },
    [
      embeddedItems,
      handleSetEmbeddedItems,
      contentItemId,
      handleFieldChange,
      field.slug,
      updateUpdateInfo,
      updateInfo,
    ]
  );

  const handleCloseItem = useCallback(
    (saveItem?: boolean) => {
      if (!saveItem) {
        handleSetEmbeddedItems(embeddedItems.slice(0, embeddedItems.length - 1));
      }
      setOpenEmbeddedInfo(undefined);
    },
    [embeddedItems, handleSetEmbeddedItems, setOpenEmbeddedInfo]
  );

  const getFieldsList = useCallback(async () => {
    const fieldsResult = await fieldsGet(field.referencedContentType || '', {
      'fetch-references': false,
      project_id: currentProjectId,
    });
    setFields(sortFieldsByPosition(fieldsResult));
  }, [field.referencedContentType, currentProjectId]);

  const isItemOpen = useCallback(
    (item: ContentItem): boolean => {
      return (
        !!openEmbeddedInfo &&
        openEmbeddedInfo.id === item.id &&
        openEmbeddedInfo.slug === field.slug
      );
    },
    [field.slug, openEmbeddedInfo]
  );

  useEffect(() => {
    getFieldsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root} id={`${field.slug}-${embedLevel}`}>
      <div className={classes.title}>
        {embeddedItems?.length} {t('Elements')}
      </div>
      <div className={classes.items}>
        {embeddedItems?.map((item, i) => (
          <ContentEmbeddedItem
            key={(item.id as number) || (item.__uniqueKey as string)}
            index={i}
            item={
              contentItemId === '-1'
                ? { ...item, id: undefined, source_id: undefined, content_state: undefined }
                : item
            }
            fields={fields}
            embedLevel={embedLevel + 1}
            contentTypeSlug={field.referencedContentType || ''}
            onRemoveItem={() => removeEmbeddedItem(i)}
            onSubmitForm={(data: Record<string, unknown>, updateInfo?: PatchDataType) =>
              embeddedFormSubmitHandler(data, i, updateInfo)
            }
            onDuplicate={() => handleDuplicateEmbedded(i)}
            isModalOpen={(item.__isModalOpen || isItemOpen(item)) as boolean}
            moveCard={moveCard}
            disabled={isReviewing || (inTreeView && contentItemId !== '-1')}
            parentId={contentItemId}
            parentSlug={contentTypeSlug}
            fieldSlugInParent={field.slug}
            handleCloseItem={handleCloseItem}
            mainSourceId={mainSourceId}
            rootUpdateInfo={(updateInfo[field.slug] as ReadonlyArray<PatchDataType>)?.find(
              (p) => p['$.indexInField'] === i
            )}
            canReview={isReviewing}
            isList={!!field.list}
            rootProjectId={
              embedLevel === -1
                ? contentItem.project_id || (selectedProjectOwner.value as number)
                : rootProjectId
            }
          />
        ))}
        {field.list && !isReviewing && (contentItemId === '-1' || !inTreeView) && (
          <ButtonPelican
            disableElevation
            variant="contained"
            className={classes.addItemButton}
            onClick={handleAddEmbeddedItem}
            data-cy="add-items"
          >
            {t('Add items')}
          </ButtonPelican>
        )}
        {!field.list &&
          (contentItemId === '-1' || !inTreeView) &&
          !isReviewing &&
          embeddedItems &&
          embeddedItems.length === 0 && (
            <ButtonPelican
              disableElevation
              variant="contained"
              className={classes.addItemButton}
              onClick={handleAddEmbeddedItem}
              data-cy="add-item"
            >
              {t('Add item')}
            </ButtonPelican>
          )}
      </div>
    </div>
  );
};
