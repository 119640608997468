import { Avatar, Typography } from '@material-ui/core';
import { ButtonPelican } from 'components/ButtonPelican';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from './props';
import { useStyles } from './styles';

export const Comment: React.FC<Props> = ({ author, time, text }: Props) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [fullShown, setFullShown] = useState<boolean>(false);

  return (
    <div className={styles.root}>
      <div className={styles.flex}>
        <Avatar style={{ width: 24, height: 24 }} />
        <Typography className={styles.author}>{author}</Typography>
        <Typography className={styles.time}>{time}</Typography>
      </div>
      <Typography variant="body1" className={styles.text}>
        {(text && text.length > 150 && !fullShown && `${text.slice(0, 150)}...`) || text}
      </Typography>
      {text &&
        text.length > 150 &&
        ((!fullShown && (
          <ButtonPelican
            disableRipple
            className={styles.showMoreButton}
            variant="text"
            color="primary"
            onClick={() => setFullShown(true)}
          >
            {t('Show more')}
          </ButtonPelican>
        )) || (
          <ButtonPelican
            disableRipple
            className={styles.showMoreButton}
            variant="text"
            color="primary"
            onClick={() => setFullShown(false)}
          >
            {t('Show less')}
          </ButtonPelican>
        ))}
    </div>
  );
};
