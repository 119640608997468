import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    color: '#6B778C',
    flex: 0,
    paddingTop: 5,
    minWidth: '20%',
    textAlign: 'right',
  },
  sourceRadio: {
    background: 'rgba(9, 30, 66, 0.04)',
    borderRadius: '10px',
    display: 'flex',
    padding: '15px',
  },
  importIcon: {
    width: '40px',
    height: '40px',
  },
  radioGroup: {},
  radioFieldSet: {
    marginLeft: '20px',
  },
  error: {
    color: theme.palette.error.main,
  },
}));
