import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const FilterOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.263 7.688a1.996 1.996 0 00-.024-1.438 2 2 0 00-3.71 0H3.75a.75.75 0 000 1.5h2.78a2 2 0 003.733-.062zM15.628 14a2 2 0 10-1.854-2.75H3.75a.75.75 0 000 1.5h10.024A2 2 0 0015.628 14zm-5.39 3.75a2 2 0 110-1.5H20.25a.75.75 0 010 1.5H10.239zm10.012-10h-8.446a3.51 3.51 0 000-1.5h8.446a.75.75 0 010 1.5zm-1.202 5h1.202a.75.75 0 000-1.5h-1.202a3.516 3.516 0 010 1.5zM4.884 17c0 .257.028.508.08.75H3.75a.75.75 0 010-1.5h1.215c-.053.242-.08.493-.08.75z"
      fill="#000"
    />
  </SvgIcon>
);
export default FilterOutline;
