import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {},
  title: {
    marginBottom: 10,
    fontWeight: 600,
  },
  fieldNavigation: {
    position: 'relative',
  },
  fixed: {
    overflowY: 'auto',
  },
  fieldsHeader: {
    marginBottom: 12,
    color: '#0039D4',
    fontWeight: 600,
  },
  fieldsTypography: {
    fontSize: 14,
    lineHeight: '20px',
    marginBottom: 6,
    cursor: 'pointer',
    '&:hover': {
      color: '#161618',
    },
    color: '#8F8F91',
  },
  required: {
    color: '#F44336',
  },
  marginTop: {
    marginTop: 16,
  },
}));
