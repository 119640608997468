import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { Props } from './props';

const useStyles = makeStyles(() => ({
  absolute: ({ bg, color }: Props) => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: bg === 'white' ? '#fff' : 'transparent',
    position: 'absolute',
    zIndex: 999,
    color: color || 'primary',
  }),
}));

export const Spin: React.FC<Props> = ({ variant, color, size = 40, bg = 'white' }: Props) => {
  const classes = useStyles({ bg, color });
  return !variant || variant === 'absolute' ? (
    <div className={classes.absolute}>
      <CircularProgress size={size} color={bg === 'white' ? 'primary' : 'inherit'} />
    </div>
  ) : (
    <CircularProgress size={size} />
  );
};

export default Spin;
