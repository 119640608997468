import { SelectContentItem } from 'api/types/ContentItem';
import { ReferenceField } from 'pages/ContentEditorPage/ContentEditor/ContentFormFields/ContentReferenceInput/ReferenceField/component';
import React, { useCallback, useMemo } from 'react';

import { useContentEditorProvider } from '../../useContentEditorProvider';
import { Props } from './props';

export const ContentReferenceInput: React.FC<Props> = ({ field }) => {
  const {
    form,
    embedLevel,
    contentItem,
    isReviewing,
    rootProjectId,
    selectedProjectOwner,
    contentTypeSlug,
    handleFieldChange,
    fields,
    setLoadedReferable,
    loadedReferable,
  } = useContentEditorProvider();

  const { setValue, watch, getValues } = form;

  const values = useMemo(() => watch(field.slug)?.items || [], [field.slug, watch]);

  const handleChangeValue = useCallback(
    (items: ReadonlyArray<SelectContentItem>, referable?: ReadonlyArray<SelectContentItem>) => {
      setValue(field.slug, { items });
      if (referable) {
        setLoadedReferable({ [field.referencedContentType || '']: referable });
        fields
          .filter(
            (f) => f.slug !== field.slug && f.referencedContentType === field.referencedContentType
          )
          .forEach((f) => {
            setValue(f.slug, {
              items:
                getValues(f.slug)?.items?.map((item: SelectContentItem) => {
                  const newItem = items.find((it) => it.source_id === item.source_id);
                  return newItem || item;
                }) || [],
            });
            handleFieldChange(f.slug);
          });
      }
      handleFieldChange(field.slug);
    },
    [field, fields, getValues, handleFieldChange, setLoadedReferable, setValue]
  );

  return (
    <ReferenceField
      isReviewing={isReviewing}
      onChange={handleChangeValue}
      field={field}
      contentItem={contentItem}
      value={values}
      embedLevel={embedLevel}
      rootProjectId={rootProjectId}
      selectedProjectOwner={selectedProjectOwner}
      contentTypeSlug={contentTypeSlug}
      loadedReferable={loadedReferable}
    />
  );
};

export default ContentReferenceInput;
