import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const EyeClose: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <svg width={25} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.915 4.77c.293-.294.89-.171 1.334.272l12.854 12.855c.444.444.566 1.04.273 1.334-.293.293-.89.17-1.334-.273L5.188 6.103c-.444-.444-.566-1.04-.273-1.334z"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.619 8.655C4.049 9.913 3.145 11.334 3.145 12c0 1.33 3.628 5.71 8.997 5.71.8 0 1.559-.095 2.272-.26l-1.974-1.973a3.505 3.505 0 01-.291.012c-1.943 0-3.51-1.595-3.517-3.489 0-.107.005-.213.014-.318L5.619 8.655zm6.802 4.681l-1.614-1.614a1.37 1.37 0 011.062-1.06l1.617 1.618a1.37 1.37 0 01-1.065 1.056zm1.604 1.604l1.995 1.995c.502-.208.974-.448 1.412-.71l-2.34-2.34c-.276.422-.64.783-1.067 1.055zm1.619-2.624l3.04 3.04c1.57-1.262 2.461-2.69 2.461-3.356 0-1.337-3.565-5.71-9.003-5.71-.794 0-1.55.096-2.261.264l1.97 1.97a3.49 3.49 0 013.793 3.793zm-5.386-3.264c-.43.269-.795.627-1.07 1.05L6.87 7.786c.438-.262.909-.503 1.41-.712l1.978 1.979z"
        fill="#000"
      />
      <path
        d="M13.514 12.007c0 .745-.613 1.358-1.372 1.358a1.361 1.361 0 01-1.365-1.358c0-.752.606-1.372 1.365-1.372s1.372.62 1.372 1.372z"
        fill="#000"
      />
    </svg>
  </SvgIcon>
);
export default EyeClose;
