import { Language } from 'api/types/Language';
import clsx from 'clsx';
import { ContentItemDetails } from 'pages/ContentEditorPage/ContentEditor/ContentEditorRightBar/ContentItemDetails';
import React from 'react';

import { useContentEditorProvider } from '../useContentEditorProvider';
import { useStyles } from './styles';

export const ContentEditorRightBar: React.FC = () => {
  const {
    contentTypeSlug,
    setSelectedLocales,
    setSelectedCheckedLocales,
    contentItemId,
    setShowOnlyMultilingual,
    embedLevel,
    toggledBar,
    setToggledBar,
    contentItem,
    contentChanged,
    handleValidate,
    selectedProjectOwner,
    rootProjectId,
    fields,
    setOpenEmbeddedInfo,
  } = useContentEditorProvider();

  const styles = useStyles(toggledBar);

  const handleSelectLocales = (locales: readonly Language[]): void => {
    setSelectedLocales(locales);
    setSelectedCheckedLocales(locales.filter((l) => l.checked));
  };

  const handleShowOnlyMultilingual = (checked: boolean): void => {
    setShowOnlyMultilingual(checked);
  };

  return (
    <aside className={clsx(styles.aside, toggledBar && styles.toggledAside)}>
      <ContentItemDetails
        handleSelectLocales={handleSelectLocales}
        handleShowOnlyMultilingual={handleShowOnlyMultilingual}
        contentItem={contentItem}
        type={contentTypeSlug}
        contentItemId={contentItemId}
        toggledPanel={toggledBar}
        openPanel={setToggledBar}
        contentChangedProps={contentChanged}
        embedLevel={embedLevel}
        handleValidate={handleValidate}
        projectId={
          (contentItemId === '-1' ? rootProjectId : contentItem.project_id) ||
          (selectedProjectOwner.value as number)
        }
        fields={fields}
        handleOpenEmbeddedItem={(id, slug) => setOpenEmbeddedInfo({ slug, id })}
      />
    </aside>
  );
};

export default ContentEditorRightBar;
