import { useQuery } from '@apollo/client';
import { Box } from '@material-ui/core';
import { useDeleteApi } from 'common/hooks/useApiQuery';
import { addUserHeaders } from 'common/utils/axios-utils';
import { ButtonPelican } from 'components/ButtonPelican';
import { DataGrid, DataGridSettings, useDataGrid, WithGridContext } from 'components/DataGrid';
import { AddOutline } from 'components/IconPelican/icons';
import { ConfirmContext } from 'containers/ConfirmContext';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Cell, Column } from 'react-table';
import { getUserInfo } from 'store/userInfoSlice';

import { EnvironmentItemDto } from '../../types/EnvironmentItemDto';
import { EnvironmentCreate } from '../EnvironmentCreate/EnvironmentCreate';
import { ENVIRONMENTS_GET } from './queries';
import { useStyles } from './styles';

export const EnvironmentList: React.FC = () => {
  const { t } = useTranslation();
  const { data, loading, refetch, error: envError } = useQuery<{
    readonly publicationEnvironments: readonly EnvironmentItemDto[];
  }>(ENVIRONMENTS_GET);

  const { publicationEnvironments } = data || {};
  const user = useSelector(getUserInfo);
  const headers = useMemo(() => addUserHeaders(user.userName, user.userId), [user]);

  const [list, setList] = useState<readonly EnvironmentItemDto[]>([]);
  const [item, setItem] = useState<EnvironmentItemDto>();
  const [open, setOpen] = useState(false);
  const styles = useStyles();
  const confirm = useContext(ConfirmContext);
  const { remove, loading: removeLoading } = useDeleteApi('/environment');
  const { selectedRow, isNotSingleSelected, tableInstance } = useDataGrid();
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateNew = (): void => {
    setItem(undefined);
    setOpen(true);
  };

  const handleEdit = (): void => {
    const { id } = selectedRow;
    const item = list.find((l) => l.id === id);
    setItem(item);
    setOpen(true);
  };

  const handleOpen = (env: EnvironmentItemDto): void => {
    setItem(env);
    setOpen(true);
  };

  const handleAfterClose = (reload: boolean): void => {
    if (reload) {
      tableInstance?.toggleAllRowsSelected(false);
      refetch();
    }
    setOpen(false);
  };

  const handleDelete = (): void => {
    confirm({
      description: t('Delete with value', { value: selectedRow?.name }),
    }).then(async () => {
      try {
        await remove(selectedRow?.id, {
          baseURL: '/publication',
          headers,
        });
        enqueueSnackbar(t('Successfully deleted'), {
          variant: 'success',
        });
        tableInstance?.toggleAllRowsSelected(false);
        refetch();
      } catch (e) {
        enqueueSnackbar(
          `${t('Error: ')} ${e?.response?.data?.message || e?.response?.data?.error || e}`,
          {
            variant: 'error',
          }
        );
      }
    });
  };

  const columns: ReadonlyArray<Column<EnvironmentItemDto>> = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: 15,
      },
      {
        Header: `${t('ContentEnvironment name')}`,
        accessor: 'name',
        width: 15,
      },
      {
        Header: `${t('Priority')}`,
        Cell: ({ row }: Cell<EnvironmentItemDto>) => <div>{row.original.priority ?? '-'}</div>,
        width: 15,
      },
    ],
    [t]
  );

  useEffect(() => {
    setList(publicationEnvironments || []);
  }, [publicationEnvironments]);

  useEffect(() => {
    envError &&
      enqueueSnackbar(`${t('Error loading environments')} ${envError.message}`, {
        variant: 'error',
      });
  }, [enqueueSnackbar, envError, t]);

  return (
    <div className={styles.container}>
      <Box display={'flex'} mt={1} mb={1} alignItems="center" justifyContent="flex-end">
        <DataGridSettings />
        <ButtonPelican
          startIcon={<AddOutline />}
          style={{ marginLeft: 8 }}
          color="primary"
          size="small"
          variant="contained"
          onClick={handleCreateNew}
          disabled={loading}
        >
          {t('Create new environment')}
        </ButtonPelican>
      </Box>
      <div className={styles.tableWrapper}>
        <DataGrid
          onOpen={handleOpen}
          columns={columns}
          data={list}
          loading={loading}
          footer={
            <>
              <ButtonPelican disabled={isNotSingleSelected} variant="text" onClick={handleEdit}>
                {t('Edit')}
              </ButtonPelican>
              <ButtonPelican
                variant="text"
                color="danger"
                onClick={handleDelete}
                disabled={isNotSingleSelected}
                loading={removeLoading}
              >
                {t('Delete')}
              </ButtonPelican>
            </>
          }
        />
      </div>
      {open && (
        <EnvironmentCreate
          environments={publicationEnvironments}
          key={item?.id}
          open={open}
          item={item}
          handleAfterClose={handleAfterClose}
        />
      )}
    </div>
  );
};
export default WithGridContext(EnvironmentList, 'EnvironmentList');
