/* eslint-disable @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-explicit-any,react-hooks/exhaustive-deps */
import { AxiosRequestConfig } from 'axios';
import request from 'common/utils/axios-request';
import { useCallback, useState } from 'react';

export type PagedView<T> = {
  readonly currentPage?: number;
  readonly lastPage?: number;
  readonly perPage?: number;
  readonly total?: number;
  readonly firstRowOnPage?: number;
  readonly lastRowOnPage?: number;
  readonly data: readonly T[];
};

export function useGetApi<TEntity>(baseUrl?: string) {
  const [loading, setLoading] = useState(false);
  const getAll = useCallback(
    (config?: AxiosRequestConfig, url?: string): Promise<readonly TEntity[]> => {
      setLoading(true);
      return request
        .get(url || baseUrl || '', config)
        .then(({ data }) => data as readonly TEntity[])
        .finally(() => setLoading(false));
    },
    [baseUrl]
  );

  return { getAll, loading };
}

export function usePagedApi<TEntity>(baseUrl: string) {
  const [loading, setLoading] = useState(false);
  const getPaged = useCallback(
    (
      page: number,
      limit: number,
      sort?: string,
      value?: string | null
    ): Promise<PagedView<TEntity>> => {
      setLoading(true);
      return request
        .get(baseUrl, {
          params: {
            page,
            limit,
            sort,
            value,
          },
        })
        .then(({ data }) => data as PagedView<TEntity>)
        .finally(() => {
          setLoading(false);
        });
    },
    [baseUrl]
  );

  return { getPaged, loading };
}

export function usePagedPostApi<TEntity>(baseUrl: string) {
  const [loading, setLoading] = useState(false);
  const postPaged = useCallback(
    (page: number, limit: number, body?: any): Promise<PagedView<TEntity>> => {
      setLoading(true);
      return request
        .post(baseUrl, {
          page,
          limit,
          ...body,
        })
        .then(({ data }) => data as PagedView<TEntity>)
        .finally(() => {
          setLoading(false);
        });
    },
    [baseUrl]
  );

  return { postPaged, loading };
}

export function useGetByIdApi<TEntity>(baseUrl: string) {
  const [loading, setLoading] = useState(false);
  const getById = useCallback(
    (id: string) => {
      setLoading(true);
      return request({
        url: `${baseUrl}/${id}`,
      })
        .then(({ data }) => data as TEntity)
        .finally(() => {
          setLoading(false);
        });
    },
    [baseUrl]
  );

  return { getById, loading };
}

export function useGetQueryApi<TEntity>(baseUrl: string) {
  const [loading, setLoading] = useState(false);
  const getByQuery = useCallback(
    (params?: any) => {
      setLoading(true);
      return request({
        url: `${baseUrl}`,
        params,
      })
        .then(({ data }) => data as TEntity)
        .finally(() => {
          setLoading(false);
        });
    },
    [baseUrl]
  );

  return { getByQuery, loading };
}

export function usePostApi<TEntity>(baseUrl: string) {
  const [loading, setLoading] = useState(false);
  const post = useCallback(
    (entity: TEntity, params?: AxiosRequestConfig) => {
      setLoading(true);
      return request({
        url: baseUrl,
        method: 'POST',
        data: entity,
        ...params,
      })
        .then(({ data }) => data as TEntity)
        .finally(() => {
          setLoading(false);
        });
    },
    [baseUrl]
  );

  return { post, loading };
}

export function usePutApi<TEntity>(baseUrl?: string) {
  const [loading, setLoading] = useState(false);
  const put = useCallback(
    (entity: TEntity, params?: AxiosRequestConfig, url?: string) => {
      setLoading(true);
      return request({
        url: url || baseUrl,
        method: 'PUT',
        data: entity,
        ...params,
      })
        .then(({ data }) => data as TEntity)
        .finally(() => {
          setLoading(false);
        });
    },
    [baseUrl]
  );

  return { put, loading };
}

export function useDeleteApi(baseUrl: string) {
  const [loading, setLoading] = useState(false);
  const remove = useCallback(
    (id: string, config?: AxiosRequestConfig): Promise<void> => {
      setLoading(true);
      return request
        .delete(`${baseUrl}/${id}`, config)
        .then(({ data }) => data)
        .finally(() => {
          setLoading(false);
        });
    },
    [baseUrl]
  );

  return { remove, loading };
}

export function useRestApi<TEntity>(baseUrl: string) {
  const { loading: pagedLoading, getPaged } = usePagedApi<TEntity>(baseUrl);
  const { loading: postLoading, post } = usePostApi<TEntity>(baseUrl);
  const { loading: putLoading, put } = usePutApi<TEntity>(baseUrl);
  const { loading: getByIdLoading, getById } = useGetByIdApi<TEntity>(baseUrl);
  const { loading: removeLoading, remove } = useDeleteApi(baseUrl);

  return {
    pagedLoading,
    getPaged,
    postLoading,
    post,
    putLoading,
    put,
    getByIdLoading,
    getById,
    remove,
    removeLoading,
  };
}
