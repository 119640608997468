import { gql } from '@apollo/client';

export enum AuditEventType {
  CREATED = 'CREATED',
  DELETED = 'DELETED',
  EDITED = 'EDITED',
  READ = 'READ',
  NEW_VERSION = 'NEW_VERSION',
}
export enum AuditEventSourceType {
  CONTENT = 'CONTENT',
  MODEL = 'MODEL',
}
export enum AuditEventStatus {
  NEW = 'NEW',
  FAILED = 'FAILED',
  DONE = 'DONE',
}
export enum EventType {
  CREATE_CONTENT_TYPE = 'CREATE_CONTENT_TYPE',
  UPDATE_CONTENT_TYPE = 'UPDATE_CONTENT_TYPE',
  DELETE_CONTENT_TYPE = 'DELETE_CONTENT_TYPE',

  CREATE_CONTENT_TYPE_FIELD = 'CREATE_CONTENT_TYPE_FIELD',
  UPDATE_CONTENT_TYPE_FIELD = 'UPDATE_CONTENT_TYPE_FIELD',
  DELETE_CONTENT_TYPE_FIELD = 'DELETE_CONTENT_TYPE_FIELD',

  CREATE_CONTENT_TYPE_ITEM = 'CREATE_CONTENT_TYPE_ITEM',
  UPDATE_CONTENT_TYPE_ITEM = 'UPDATE_CONTENT_TYPE_ITEM',
  DELETE_CONTENT_TYPE_ITEM = 'DELETE_CONTENT_TYPE_ITEM',
}

export const typeDefs = gql`
  scalar JSON
  scalar DateTime

  type Query {
    audit(
      page: Int
      size: Int
      sourceType: AuditEventSourceType
      auditEventType: AuditEventType
      eventType: EventType
      contentTypeId: ID
      slug: String
      dataId: ID
    ): [AuditEvent]
  }

  type AuditEvent {
    dataId: ID
    contentTypeId: ID
    slug: String
    dataType: Int
    userId: ID
    username: String
    dataDiffs: [DataDiff]
    auditEventType: AuditEventType
    sourceType: AuditEventSourceType
    status: AuditEventStatus
    eventType: EventType
    oldEventState: String
    newEventState: String
    failedCount: Int
    trailDate: String
    sourceName: String
    ipAddress: String
    endpoint: String
    methodType: String
    envTo: String
  }

  type DataDiff {
    dataId: ID
    contentTypeId: ID
    slug: String
    auditEventType: AuditEventType
    changes: JSON
    multilingual: JSON
    newData: JSON
  }

  enum AuditEventType {
    CREATED
    DELETED
    EDITED
    READ
    NEW_VERSION
  }
  enum AuditEventSourceType {
    CONTENT
    MODEL
  }
  enum AuditEventStatus {
    NEW
    FAILED
    DONE
  }
  enum EventType {
    NOT_AUTHORIZED_EXCEPTION
    ACCESS_DENIED_EXCEPTION
    AUTHENTICATION_EXCEPTION
    GET_LIST_ASSETS
    GET_ASSET_BY_HASH
    CREATE_ASSET
    UPDATE_ASSET
    DELETE_ASSET
    BULK_CREATE_ASSETS
    GET_LIST_ASSET_MAPPINGS
    CREATE_FORM_RIGHT_FOR_ROLE
    GET_LIST_FORM_GROUP
    GET_LIST_FORM_BY_GROUP
    GET_LIST_FORM_BY_ROLE_AND_GROUP
    GET_FORM_LIST_BY_ROLE
    SCHEMA_UPDATE
    GET_CONTENT_TYPE_LIST
    CREATE_CONTENT_TYPE
    UPDATE_CONTENT_TYPE
    DELETE_CONTENT_TYPE
    DUPLICATE_CONTENT_TYPE
    GET_CONTENT_TYPE_PAGE
    GET_REFERENCING_CONTENT_TYPES
    CREATE_CONTENT_STATE
    UPDATE_CONTENT_STATE
    DELETE_CONTENT_STATE
    GET_CONTENT_STATE_LIST
    CREATE_CONTENT_TYPE_ITEM
    INTEGRATION_BULK_CREATE
    UPDATE_CONTENT_TYPE_ITEM
    DELETE_CONTENT_TYPE_ITEM
    PERFORM_ACTION_FOR_CONTENT_TYPE_ITEM
    GET_CONTENT_TYPE_ITEM_BY_ID
    GET_CONTENT_TYPE_ITEM_LIST
    GET_REFERABLE_CONTENT_ITEMS
    GET_CONTENT_FOR_MONITORING
    GET_CONTENT_TYPE_ITEM_TREE_BY_ID
    GET_CONTENT_TYPE_ITEM_PARENT_PATH_BY_ID
    GET_REFERENCING_CONTENT_ITEMS
    GET_LIST_WORKFLOW
    GET_CONTENT_ACTION_LIST
    CREATE_CONTENT_ACTION
    UPDATE_CONTENT_ACTION
    GET_CONTENT_TYPE_ITEM_SCHEDULE
    UPDATE_SCHEDULED_ACTIONS_BY_CONTENT
    CREATE_CONTENT_TYPE_FIELD
    UPDATE_CONTENT_TYPE_FIELD
    DELETE_CONTENT_TYPE_FIELD
    GET_FIELD_TYPE_LIST
    GET_FIELD_CONTENT_TYPE_BY_ID
    GET_CONTENT_TYPE_FIELD_LIST_BY_CONTENT_TYPE
    CREATE_PROJECT
    UPDATE_PROJECT
    DELETE_PROJECT
    GET_PROJECT_BY_SLUG
    GET_PROJECT_LIST
    GET_PROJECT_LIST_BY_USER
    CREATE_CONTENT_TYPE_GROUP
    UPDATE_CONTENT_TYPE_GROUP
    GET_CONTENT_TYPE_GROUP_LIST
    GET_CONTENT_TYPE_GROUP_BY_ID
    CREATE_CONTENT_TYPE_FIELD_GROUP
    UPDATE_CONTENT_TYPE_FIELD_GROUP
    GET_CONTENT_TYPE_FIELD_GROUP_LIST
    DELETE_CONTENT_TYPE_FIELD_GROUP
    LOGIN_USER
    LOGOUT_USER
    CREATE_USER
    UPDATE_USER
    DELETE_USER
    GET_USER_BY_ID
    GET_USER_LIST
    GET_ME
    GET_CURRENT_USER_PROJECTS
    GET_CURRENT_USER_CONTENT_TYPES
    GET_USER_ROLES
    USER_PASSWORD_UPDATE
    GET_ROLE_BY_ID
    GET_ROLE_LIST
    CREATE_ROLE
    UPDATE_ROLE
    DELETE_ROLE
    BIND_AND_UNBIND_USER_PROJECT
    BIND_AND_UNBIND_USER_ROLES
    GET_LANGUAGE_LIST
    CREATE_LANGUAGE
    UPDATE_LANGUAGE
    DELETE_LANGUAGE
    CREATE_SURVEY
    UPDATE_SURVEY
    DELETE_SURVEY
    GET_SURVEY_BY_ID
    GET_SURVEY_LIST
    SURVEY_ACTION
    CREATE_QUESTION
    UPDATE_QUESTION
    DELETE_QUESTION
    GET_QUESTION_BY_ID
    GET_QUESTIONS_BY_SURVEY_ID
    CREATE_OPTION
    UPDATE_OPTION
    DELETE_OPTION
    GET_OPTION_BY_ID
    GET_OPTION_BY_QUESTION_ID
    GET_BY_USER_AND_SURVEY
    GET_ALL_VERSION_HISTORY_BY_CONTENT
    GET_ALL_VERSION_BY_SOURCE
    GET_ALL_CONTENT_CONVERSATIONS
    CREATE_CONTENT_CONVERSATION
    VOTE
    GET_SETTINGS_BY_TYPE
    GET_ALL_SETTINGS
    UPDATE_SETTINGS
    GET_REPORT_LIST
    GET_REPORT
    CREATE_REPORT
    UPDATE_REPORT
    DELETE_REPORT
    GENERATE_REPORT
    GENERATE_REPORT_XLSX
    GET_REPORT_HISTORY_LIST
    GET_REPORT_HISTORY
    GET_REPORT_PARAMETER_LIST
    CREATE_REPORT_PARAMETER
    UPDATE_REPORT_PARAMETER
    DELETE_REPORT_PARAMETER
    GET_REPORT_PARAMETER_TYPES_LIST
    GET_REPORT_HEADERS
    GET_REPORT_HEADER
    CREATE_REPORT_HEADER
    UPDATE_REPORT_HEADER
    DELETE_REPORT_HEADER
    CREATE_INTEGRATION
    UPDATE_INTEGRATION
    GET_PAGE_OF_INTEGRATIONS
    GET_PAGE_OF_ENABLED_INTEGRATIONS
    GET_INTEGRATION_BY_ID
    DELETE_INTEGRATION
    UPDATE_INTEGRATION_FLAG_ENABLE
    EXECUTE_TEST_INTEGRATION_REQUEST
    EXECUTE_INTEGRATION_REQUEST_AND_PARSE_DATA
    GET_CONTENT_TYPES_LINKED_TO_INTEGRATION
    CREATE_INTEGRATION_FIELD
    UPDATE_INTEGRATION_FIELD
    GET_ALL_FIELDS_OF_INTEGRATION
    GET_ENABLED_FIELDS_OF_INTEGRATION
    GET_INTEGRATION_FIELD_BY_ID
    DELETE_INTEGRATION_FIELD
    CREATE_INTEGRATION_REQUEST_ATTRIBUTE
    UPDATE_INTEGRATION_REQUEST_ATTRIBUTE
    FIND_ALL_REQUEST_ATTRIBUTES_OF_INTEGRATION_BY_FILTER
    GET_INTEGRATION_REQUEST_ATTRIBUTE_BY_ID
    DELETE_INTEGRATION_REQUEST_ATTRIBUTE
    CREATE_WEBHOOK
    UPDATE_WEBHOOK
    GET_PAGE_OF_WEBHOOKS
    GET_PAGE_OF_ENABLED_WEBHOOKS
    GET_WEBHOOK_BY_ID
    DELETE_WEBHOOK
    UPDATE_WEBHOOK_FLAG_ENABLE
    EXECUTE_TEST_WEBHOOK_REQUEST
    CREATE_ACCESS_TOKEN
    GET_LIST_OF_ACTIVE_ACCESS_TOKENS
    REVOKE_ACCESS_TOKEN
    SUBSCRIPTION_REQUEST
    UNSUBSCRIPTION_REQUEST
    SUBSCRIPTION_CONFIRMATION
    UNSUBSCRIPTION_CONFIRMATION
    PUBLISH_ALL
    PUBLISH_ALL_TO_MONGODB
    PUBLISH_ALL_TO_ELASTICSEARCH
    BROKEN_LINKS_START
    BROADCAST
    GET_ALL_MAILINGS
    GET_MAILING
    CONVERT_BASE64_TO_LINK
    COMMON_AUDIT_EVENT
  }
`;
