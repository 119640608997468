import request from 'common/utils/axios-request';
import i18next from 'i18next';
import { FilterType } from 'pages/Contents/ContentFilters/types';
import { RequestResponse } from 'pages/Contents/types/Response';

import { CommentType } from './types/Comment';
import { CommentModel } from './types/CommentModel';
import { ContentAction } from './types/ContentAction';
import {
  ContentFieldReference,
  ContentItem,
  RichTextContent,
  SelectContentItem,
  UpdateInfoForPatch,
} from './types/ContentItem';
import {
  ContentTypeField,
  ContentTypeFieldParams,
  ContentTypeFieldResponse,
  ReferableObjectsGetParams,
} from './types/ContentTypeField';
import { ContentTypeFieldGroup } from './types/ContentTypeFieldGroup';
import { History } from './types/History';
import { Language } from './types/Language';
import { RawVersion, Version } from './types/Version';

type GetResult = {
  readonly list: ReadonlyArray<ContentItem>;
  readonly totalCount: number;
};

type Params = {
  readonly page: number;
  readonly size: number;
  readonly sortBy?: string;
  readonly sortOrder?: 'asc' | 'desc';
  readonly parent?: number | 'null';
  readonly check_diff?: boolean;
};

export type ContentItemsParams = Params;

const parseField = (field: ContentTypeFieldResponse): ContentTypeField => ({
  addDefaultReference: field.add_default_reference,
  additionalSearchCriteria: field.additional_search_criteria,
  body: field.body,
  contentType: field.content_type,
  contentTypeId: field.content_type_id,
  displayedValue: field.displayed_value,
  defaultValue: field.default_value,
  embedded: field.embedded,
  externalKey: field.external_key,
  filteredByProjects: field.filtered_by_projects,
  hidden: field.hidden,
  id: field.id,
  internal: field.internal,
  label: field.label,
  list: field.list,
  multilingual: field.multilingual,
  parent: field.parent,
  position: field.position,
  required: field.required,
  searchable: field.searchable,
  slug: field.slug,
  sortable: field.sortable,
  title: field.title,
  type: field.type,
  unique: field.unique,
  wysiwyg: field.wysiwyg,
  description: field.description,
  referencedContentType: field.referenced_content_type,
  referableObjects: field.referable_objects,
  mask: field.mask,
  minLength: field.min_length,
  maxLength: field.max_length,
  minValue: field.min_value,
  maxValue: field.max_value,
  integrationFieldId: field.integration_field_id,
});

export const fieldsGet = (
  contentTypeSlug: string,
  params?: ContentTypeFieldParams
): Promise<ReadonlyArray<ContentTypeField>> => {
  return request(`/api/v1/content-types/${contentTypeSlug}/fields`, {
    method: 'GET',
    params,
    headers: { 'Accept-Language': i18next.language },
  }).then(({ data }) => {
    return data.map(parseField) as ReadonlyArray<ContentTypeField>;
  });
};

export const referableObjectsGet = (
  contentTypeSlug: string,
  fieldId: number,
  params: ReferableObjectsGetParams
): Promise<{ readonly list: ReadonlyArray<SelectContentItem>; readonly totalCount: number }> => {
  return request(`/api/v1/content-types/${contentTypeSlug}/fields/${fieldId}/referable`, {
    method: 'GET',
    params,
  }).then(({ data, headers }) => ({ list: data, totalCount: parseInt(headers['x-total-count']) }));
};

export const contentTypeFieldGroupsGet = (
  contentTypeSlug: string
): Promise<ReadonlyArray<ContentTypeFieldGroup>> => {
  return request(`/api/v1/content-types/${contentTypeSlug}/content-type-field-groups`, {
    method: 'GET',
  }).then(({ data }) => {
    return data as ReadonlyArray<ContentTypeFieldGroup>;
  });
};

export const contentItemsGet = (
  contentTypeSlug: string,
  params: Params,
  workspaceId?: string,
  query?: string
): Promise<GetResult> => {
  return request(`/api/v1/content-manager/${contentTypeSlug}?${query || ''}`, {
    method: 'GET',
    params: {
      page: params.page,
      size: params.size,
      project_id: `in:${workspaceId}`,
      'sort-by': params.sortBy && `${params.sortBy}:${params.sortOrder}`,
      parent: params.parent,
      check_diff: params.check_diff,
    },
  }).then(({ data, headers }) => {
    return {
      list: data as ReadonlyArray<ContentItem>,
      totalCount: parseInt(headers['x-total-count']),
    };
  });
};
export const contentItemGet = (
  contentTypeSlug: string,
  sourceId: string | number,
  depth = -1,
  fullText?: boolean,
  check_diff?: boolean
): Promise<ContentItem> => {
  return request(`/api/v1/content-manager/${contentTypeSlug}/${sourceId}`, {
    method: 'GET',
    headers: { 'Accept-Language': i18next.language },
    params: { depth, fullText, check_diff },
  }).then(({ data }) => {
    return data as ContentItem;
  });
};
export const contentItemModerate = (
  contentTypeSlug: string,
  sourceIds: readonly number[],
  action: string
): Promise<RequestResponse> => {
  return request(`/api/v1/content-manager/${contentTypeSlug}/bulk-action/${action}`, {
    data: {},
    method: 'PUT',
    params: { id: sourceIds.join(',') },
    withCredentials: true,
  })
    .then((res) => ({
      status: res.status,
      url: res.config.url,
      message: 'Content item status successfully changed',
      variant: 'success',
      statusCode: 0,
      data: res.data,
    }))
    .catch((e) => ({ ...e.response.data, variant: 'error' }));
};
export const contentItemDelete = (
  contentTypeSlug: string,
  sourceIds: readonly number[]
): Promise<RequestResponse> => {
  return request(`/api/v1/content-manager/${contentTypeSlug}`, {
    data: {},
    params: { id: sourceIds.join(',') },
    method: 'DELETE',
    withCredentials: true,
  })
    .then((res) => ({
      status: res.status,
      url: res.config.url,
      message: 'Content item deleted',
      variant: 'success',
      statusCode: 0,
    }))
    .catch((e) => ({ ...e.response.data, variant: 'error' }));
};
export const contentItemsSendMessage = (
  contentTypeSlug: string,
  sourceId: number,
  message: string
): Promise<RequestResponse> => {
  return request(`/api/v1/content-conversations/${contentTypeSlug}/${sourceId}`, {
    data: { message },
    method: 'POST',
    withCredentials: true,
  })
    .then((res) => ({
      status: res.status,
      url: res.config.url,
      message: 'Message sent',
      variant: 'success',
      statusCode: 0,
    }))
    .catch((e) => ({ ...e.response.data, variant: 'error' }));
};
export const contentActionsGet = (
  contentTypeSlug: string,
  contentItemId: number | string = '-1'
): Promise<ReadonlyArray<ContentAction>> => {
  return request(`/api/v1/workflow/content-actions/${contentTypeSlug}/${contentItemId}`, {
    method: 'GET',
  }).then(({ data }) => {
    return data as ReadonlyArray<ContentAction>;
  });
};

export const contentItemPost = (
  contentTypeSlug: string,
  data: ContentItem,
  isWhole: boolean
): Promise<RequestResponse & { readonly data: { readonly id: number } }> => {
  return request(`/api/v1/content-manager/${contentTypeSlug}`, {
    data,
    method: 'POST',
    params: { isWhole },
  })
    .then((res) => ({
      contentItemId: res.data.id,
      status: res.status,
      url: res.config.url,
      message: 'Saved successfully',
      variant: 'success',
      statusCode: 0,
      data: res.data,
    }))
    .catch((e) => ({ ...e.response.data, variant: 'error' }));
};

export const contentItemsPost = (
  contentTypeSlug: string,
  data: ReadonlyArray<ContentItem>
): Promise<RequestResponse> => {
  return request(`/api/v1/content-manager/${contentTypeSlug}/bulk-create`, { data, method: 'POST' })
    .then((res) => ({
      status: res.status,
      url: res.config.url,
      message: 'Saved successfully',
      variant: 'success',
      statusCode: 0,
      data: res.data,
    }))
    .catch((e) => ({ ...e.response.data, variant: 'error' }));
};

export const contentItemPut = (
  contentTypeSlug: string,
  sourceId: string,
  data: ContentItem,
  isWhole: boolean,
  childContentTypeSlug: string,
  fieldSlugInParent?: string,
  parentContentTypeSlug?: string,
  parentDataId?: string | number
): Promise<RequestResponse & { readonly data: { readonly id: number } }> => {
  data.multilangsave = true;
  return request(`/api/v1/content-manager/${contentTypeSlug}/${sourceId}`, {
    data,
    method: 'PUT',
    params: {
      isWhole,
      childContentTypeSlug,
      fieldSlugInParent,
      parentContentTypeSlug,
      parentDataId,
    },
  })
    .then((res) => ({
      status: res.status,
      url: res.config.url,
      message: 'Saved successfully',
      variant: 'success',
      statusCode: 0,
      data: res.data,
    }))
    .catch((e) => ({ ...e.response.data, variant: 'error' }));
};

export const contentItemPatch = (
  updateInfo: ReadonlyArray<UpdateInfoForPatch>,
  contentTypeSlug: string,
  sourceId: string
): Promise<RequestResponse & { readonly data: { readonly id: number } }> => {
  return request(`/api/v1/content-manager/${contentTypeSlug}/${sourceId}`, {
    data: updateInfo,
    method: 'PATCH',
    params: { isWhole: true },
  })
    .then((res) => ({
      status: res.status,
      url: res.config.url,
      message: 'Saved successfully',
      variant: 'success',
      statusCode: 0,
      data: res.data,
    }))
    .catch((e) => ({ ...e.response.data, variant: 'error' }));
};
export const contentItemsBulkPatch = (
  contentTypeSlug: string,
  contentIds: readonly number[],
  data: ContentItem,
  addToExisting?: boolean
): Promise<RequestResponse> => {
  return request(`/api/v1/content-manager/${contentTypeSlug}/bulk-patch-same-field`, {
    method: 'PATCH',
    params: { ids: contentIds.join(',') },
    data: { contentTypeSlug, rootContentTypeSlug: contentTypeSlug, newData: data, addToExisting },
  })
    .then((res) => ({
      status: res.status,
      url: res.config.url,
      message: 'Content item status successfully changed',
      variant: 'success',
      statusCode: 0,
    }))
    .catch((e) => ({ ...e.response.data, variant: 'error' }));
};

export const getProjectLocales = (projectSlug: string): Promise<ReadonlyArray<Language>> => {
  return request(`/api/v1/projects/${projectSlug}`, {
    method: 'GET',
  }).then(({ data }) => {
    return data.languages as ReadonlyArray<Language>;
  });
};
export const getContentItemVersions = (
  type: string,
  page: number,
  contentItemId: number | string,
  size?: number
): Promise<{ readonly list: ReadonlyArray<Version>; readonly totalCount: number }> => {
  return request(`/api/v1/content-version/${type}/source/${contentItemId}`, {
    method: 'GET',
    params: { page, size: size || 5, 'sort-by': 'id:desc' },
  }).then(({ data, headers }) => {
    return {
      list: (data as ReadonlyArray<RawVersion>).map((v) => ({
        ...v,
        user: { ...v.user, id: v.user.user_id },
      })) as ReadonlyArray<Version>,
      totalCount: parseInt(headers['x-total-count']),
    };
  });
};
export const getVersionHistory = (
  type: string,
  contentItemId: number
): Promise<ReadonlyArray<History>> => {
  return request(`/api/v1/content-version/${type}/history/${contentItemId}`, {
    method: 'GET',
  }).then(({ data }) => {
    return data as ReadonlyArray<History>;
  });
};
export const getContentItemComments = (
  type: string,
  contentItemId: number
): Promise<ReadonlyArray<CommentType>> => {
  return request(`/api/v1/content-conversations/${type}/${contentItemId}`, {
    method: 'GET',
  }).then(({ data }) => {
    return data as ReadonlyArray<CommentType>;
  });
};

export const createFilter = (
  contentTypeSlug: string,
  label: string,
  requestParams: string
): Promise<unknown> => {
  const data = {
    label: label,
    request_params: requestParams,
  };
  return request(`/api/v1/me/content-types/${contentTypeSlug}/content-filters`, {
    data,
    method: 'POST',
  }).then(({ data }) => {
    return data;
  });
};

export const getFavoriteFilters = (contentTypeSlug: string): Promise<readonly FilterType[]> => {
  return request(`/api/v1/me/content-types/${contentTypeSlug}/content-filters`).then(({ data }) => {
    return data;
  });
};

export const deleteContentFilter = (
  contentTypeSlug: string,
  filterId: number
): Promise<unknown> => {
  return request(`/api/v1/me/content-types/${contentTypeSlug}/content-filters/${filterId}`, {
    method: 'DELETE',
  }).then(({ data }) => {
    return data;
  });
};

export const editContentFilter = (
  contentTypeSlug: string,
  filterId: number,
  label: string,
  requestParams: string
): Promise<unknown> => {
  const data = {
    id: filterId,
    label: label,
    request_params: requestParams,
  };
  return request(`/api/v1/me/content-types/${contentTypeSlug}/content-filters`, {
    data,
    method: 'PUT',
  });
};

export const contentItemCommentPost = (
  data: CommentModel,
  contentTypeSlug: string,
  contentItemId: string
): Promise<CommentType> => {
  return request(`/api/v1/content-conversations/${contentTypeSlug}/${contentItemId}`, {
    data,
    method: 'POST',
  }).then(({ data }) => data);
};

export const approveSchedule = (
  scheduled_time: string,
  contentItemSlug: string,
  contentItemId: string
): Promise<unknown> => {
  const data = {
    scheduled_time: scheduled_time,
  };
  return request(`/api/v1/content-manager/${contentItemSlug}/${contentItemId}/approve/schedule`, {
    method: 'PUT',
    data,
  }).then(({ data }) => {
    return data;
  });
};

export const archiveSchedule = (
  scheduled_time: string,
  contentItemSlug: string,
  contentItemId: string
): Promise<unknown> => {
  const data = {
    scheduled_time: scheduled_time,
  };
  return request(`/api/v1/content-manager/${contentItemSlug}/${contentItemId}/archive/schedule`, {
    method: 'PUT',
    data,
  }).then(({ data }) => {
    return data;
  });
};

export const cancelApproveSchedule = (
  contentItemSlug: string,
  contentItemId: string
): Promise<unknown> => {
  const data = {
    scheduled_time: null,
  };
  return request(`/api/v1/content-manager/${contentItemSlug}/${contentItemId}/approve/schedule`, {
    method: 'PUT',
    data,
  }).then(({ data }) => {
    return data;
  });
};

export const cancelArchiveSchedule = (
  contentItemSlug: string,
  contentItemId: string
): Promise<unknown> => {
  const data = {
    scheduled_time: null,
  };
  return request(`/api/v1/content-manager/${contentItemSlug}/${contentItemId}/archive/schedule`, {
    method: 'PUT',
    data,
  }).then(({ data }) => {
    return data;
  });
};

export const getContentItemSchedules = (
  contentItemSlug: string,
  projectId: number
): Promise<{ readonly approve: string; readonly archive?: string }> => {
  return request(`/api/v1/content-manager/${contentItemSlug}/${projectId}/schedule`).then(
    ({ data }) => {
      return data;
    }
  );
};

export const publishAll = (publishType: string): Promise<RequestResponse> => {
  return request('/api/v1/utils/publish-all', { method: 'POST', params: { mode: publishType } })
    .then((res) => ({
      status: res.status,
      url: res.config.url,
      message: 'Content item status successfully changed',
      variant: 'success',
      statusCode: 0,
    }))
    .catch((e) => ({ ...e.response.data, variant: 'error' }));
};

export const contentTreeGet = (
  contentTypeSlug: string,
  id: string | number
): Promise<ReadonlyArray<ContentFieldReference>> => {
  return request(`/api/v1/content-manager/${contentTypeSlug}/${id}/tree`).then(({ data }) => data);
};
export const getRichtextContent = (
  contentTypeSlug: string,
  contentItemId: string,
  fieldSlug: string,
  locale?: string
): Promise<RichTextContent> => {
  return request(`/api/v1/content-manager/${contentTypeSlug}/${contentItemId}/${fieldSlug}`, {
    method: 'GET',
    params: { langCode: locale },
  }).then(({ data }) => {
    return data;
  });
};

export const contentItemDuplicate = (
  contentTypeSlug: string,
  dataId: number | string,
  projectId: number | string
): Promise<{ readonly id: number }> => {
  return request(`/api/v1/content-manager/${contentTypeSlug}/${dataId}/duplicate`, {
    method: 'POST',
    params: {
      projectId,
    },
  }).then(({ data }) => data);
};
