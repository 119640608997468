import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    background: '#EFF0F2',
    borderRadius: 4,
    padding: '5px 8px',
    fontSize: 13,
    lineHeight: '138%',
    color: '#545456',
    cursor: 'pointer',
    border: '1px solid #D3D4D6',
  },
  input: {
    display: 'none',
    position: 'absolute',
    top: 0,
    zIndex: -1,
    maxHeight: 450,
  },
  fileContainer: {
    minHeight: 128,
    maxWidth: 450,
    marginBottom: 12,
    display: 'flex',
  },
  fileInfo: {
    maxWidth: 338,
    marginBottom: 12,
  },
  preview: {
    display: 'flex',
    justifyContent: 'center',
    width: 100,
    height: 100,
    marginRight: 16,
  },
  fileName: {
    width: 338,
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '20px',
    margin: '0 0 2px 0',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fileDetails: {
    color: '#505F79',
    fontSize: 14,
    lineHeight: '20px',
    margin: '0 0 16px 0',
  },
  image: {
    cursor: 'pointer',
    maxWidth: 100,
    maxHeight: 100,
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#F7F8FA',
    border: '2px dashed #D3D4D6',
    borderRadius: 8,
    padding: '52px 25px',
    marginBottom: 12,
    marginTop: 24,
  },
  dragAndDrop: {
    fontSize: 14,
    lineHeight: '16px',
    margin: '8px 0',
    textAlign: 'center',
    color: '#161618',
    fontWeight: 600,
  },
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    outline: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    overflowY: 'auto',
  },
  video: {
    width: 500,
  },
  dFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  settings: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 24,
  },
  marginLeft: {
    marginLeft: 18,
  },
  marginRight: {
    marginRight: 12,
  },
  marginRightMd: {
    marginRight: 8,
  },
  marginBottom: {
    marginBottom: 12,
  },
  extension: {
    textTransform: 'uppercase',
  },
  divider: {
    borderLeft: '1px solid #B3BAC5',
    height: 20,
    marginLeft: 8,
    marginRight: 8,
  },
  previewButton: {
    padding: '6px 0px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  downloadButton: {
    color: '#0052CC',
    textDecoration: 'none',
  },
  or: {
    margin: '0 10px',
    fontSize: 14,
    lineHeight: '20px',
    color: '#666666',
  },
  assetsContainer: {
    width: 850,
    maxHeight: '100vh',
    overflowY: 'auto',
    outline: 'none',
  },
  fullWidth: {
    width: '100%',
  },
  uploadContainer: {
    padding: '63px 0',
    marginBottom: 12,
    marginTop: 24,
  },
  progressBar: {
    height: 10,
    background: theme.palette.blue[500],
    borderRadius: 10,
    marginBottom: 4,
  },
}));
