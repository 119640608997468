import { Action, createSlice } from '@reduxjs/toolkit';
import { contentGroupsGet } from 'api/content-type-groups';
import { contentTypesGet } from 'api/content-types';
import { ContentType } from 'api/types/ContentType';
import { ContentGroup } from 'api/types/ContentTypeGroups';
import { AppThunk } from 'store/store';

import { ContentState } from './types/content-state';

const initialState: ContentState = {
  groups: [],
  types: [],
  search: '',
  loading: false,
  selected: {
    name: 'Выберите тип контента',
    slug: '',
  },
  recentlyContentsOpen: false,
};

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    setGroups(
      state,
      action: Action & {
        readonly payload: readonly ContentGroup[];
      }
    ) {
      return {
        ...state,
        ...{ groups: action.payload },
      };
    },
    setTypes(
      state,
      action: Action & {
        readonly payload: readonly ContentType[];
      }
    ) {
      return {
        ...state,
        ...{ types: action.payload },
      };
    },
    setLoading(
      state,
      action: Action & {
        readonly payload: boolean;
      }
    ) {
      return {
        ...state,
        ...{ loading: action.payload },
      };
    },
    setRecentlyContents(
      state,
      action: Action & {
        readonly payload: boolean;
      }
    ) {
      return {
        ...state,
        ...{ recentlyContentsOpen: action.payload },
      };
    },
    setSelected(
      state,
      action: Action & {
        readonly payload: {
          readonly name: string;
          readonly slug?: string;
        };
      }
    ) {
      localStorage.setItem('selectedContent', JSON.stringify(action.payload));
      return {
        ...state,
        ...{ selected: action.payload },
      };
    },
    setSearch(
      state,
      action: Action & {
        readonly payload: string;
      }
    ) {
      return {
        ...state,
        search: action.payload,
      };
    },
  },
});

export const {
  setGroups,
  setTypes,
  setLoading,
  setSelected,
  setSearch,
  setRecentlyContents,
} = contentSlice.actions;

export const { getGroups, getTypes, getLoading, getSelected, getSearch, getRecentlyContents } = {
  getGroups(state: { readonly content: ContentState }) {
    return state.content.groups;
  },
  getTypes(state: { readonly content: ContentState }) {
    return state.content.types;
  },
  getLoading(state: { readonly content: ContentState }) {
    return state.content.loading;
  },
  getRecentlyContents(state: { readonly content: ContentState }) {
    return state.content.recentlyContentsOpen;
  },
  getSelected(state: { readonly content: ContentState }) {
    const { selected } = state.content;
    return selected.slug
      ? selected
      : (JSON.parse(
          localStorage.getItem('selectedContent') || '{"name": "Выберите тип контента"}'
        ) as {
          readonly name: string;
          readonly slug?: string;
        });
  },
  getSearch(state: { readonly content: ContentState }) {
    return state.content.search;
  },
};

export default contentSlice.reducer;

export const loadContentData = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const contentGroups = await contentGroupsGet();
    dispatch(setGroups(contentGroups));
    const contentTypes = await contentTypesGet(false);
    dispatch(setTypes(contentTypes));
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(setLoading(false));
  }
};
