import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  environments: {
    display: 'flex',
    alignItems: 'start',
  },
  environment: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 24,
  },
  environmentName: {
    fontSize: '12px',
    color: '#545456',
    lineHeight: '150%',
    textTransform: 'lowercase',
    overflow: 'unset',
  },
  menuItem: {
    borderTop: '1px solid #D3D4D6',
  },
  menuLabel: {
    color: '#545456 !important',
    opacity: '1 !important',
    textTransform: 'uppercase',
  },
  primary: {
    color: '#0039D4',
  },
  newest: {
    color: '#00801B',
  },
  loading: {
    '& img': {
      filter: 'grayscale(100%)',
    },
  },
  archived: {
    color: '#000000',
    marginLeft: 8,
  },
});
