import { createSlice } from '@reduxjs/toolkit';
import { Action } from 'redux';

import { SessionState } from './types/session-state';

const initialState: SessionState = {
  expired: false,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionExpired(
      state,
      action: Action & {
        readonly payload: boolean;
      }
    ) {
      return {
        ...state,
        ...{ expired: action.payload },
      };
    },
    setSessionReturnUrl(
      state,
      action: Action & {
        readonly payload: string;
      }
    ) {
      return {
        ...state,
        ...{ returnUrl: action.payload },
      };
    },
  },
});

export const { setSessionExpired, setSessionReturnUrl } = sessionSlice.actions;

export const { getSessionExpired, getSessionReturnUrl } = {
  getSessionExpired(state: { readonly session: SessionState }) {
    return state.session.expired;
  },
  getSessionReturnUrl(state: { readonly session: SessionState }) {
    return state.session.returnUrl;
  },
};

export default sessionSlice.reducer;
