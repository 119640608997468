import { makeStyles } from '@material-ui/core/styles';

export const useStatusStyle = makeStyles({
  status: {
    color: '#ffffff',
    fontWeight: 'bold',
    fontSize: 11,
    lineHeight: '16px',
    padding: '0 7px',
    borderRadius: 3,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  published: {
    background: '#36B37E',
  },
  reviewing: {
    background: '#FF5630',
  },
  draft: {
    background: '#6554C0',
  },
  archived: {
    background: '#000000',
  },
});
