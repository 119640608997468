import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  body: {
    position: 'relative',
  },
  jsonContainer: {
    height: 500,
    backgroundColor: '#FAFBFC',
    border: '2px solid #DFE1E5',
    borderRadius: 3,
    padding: 15,
    overflow: 'auto',
  },
  copyJsonFile: {
    position: 'absolute',
    top: 15,
    right: 10,
    cursor: 'pointer',
    color: '#172B4D',
  },
});
