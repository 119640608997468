import { Permission } from 'common/types/permission';
import {
  AssetsOutline,
  BagOutline,
  ContentSquareOutline,
  CubeOutline,
  Db,
  DoorOutline,
  GearOutline,
  HomeOutline,
  LanguageOutline,
  ModelConceptOutline,
  TreeOutline,
  UserDoubleOutline,
} from 'components/IconPelican/icons';
import { ContentEditorPage } from 'pages/ContentEditorPage/ContentEditorPage';
import Environments from 'pages/Environments/Environments';
import React from 'react';

import RouteItem from './types/route-item';

const ChangePassword = React.lazy(() => import('pages/ChangePassword/ChangePassword'));
const CompareVersion = React.lazy(() => import('pages/CompareVersion/CompareVersion'));

const AccessTokens = React.lazy(() => import('pages/AccessTokens/AccessTokens'));
const AssetsPage = React.lazy(() => import('pages/AssetsPage/AssetsPage'));

const ContentPage = React.lazy(() => import('pages/ContentPage/ContentPage'));
const Contents = React.lazy(() => import('pages/Contents/Contents'));
const ContentTypeEditPage = React.lazy(
  () => import('pages/ContentTypeEditPage/ContentTypeEditPage')
);
const ContentTypeGroups = React.lazy(() => import('pages/ContentTypeGroups/ContentTypeGroups'));
const ContentTypes = React.lazy(() => import('pages/ContentTypes/ContentTypes'));
const Home = React.lazy(() => import('pages/Home/Home'));
const IntegrationEditPage = React.lazy(
  () => import('pages/IntegrationEditPage/IntegrationEditPage')
);
const Integrations = React.lazy(() => import('pages/Integrations/Integrations'));
const Languages = React.lazy(() => import('pages/Languages/Languages'));
const ProfilePage = React.lazy(() => import('pages/ProfilePage/ProfilePage'));
const Projects = React.lazy(() => import('pages/Projects/Projects'));
const Rights = React.lazy(() => import('pages/Rights/Rights'));
const Roles = React.lazy(() => import('pages/Roles/Roles'));
const SystemSettings = React.lazy(() => import('pages/SystemSettings/SystemSettings'));
// const Translates = React.lazy(() => import('pages/Translates/Translates'));
const UsersPage = React.lazy(() => import('pages/UsersPage/UsersPage'));
const Webhooks = React.lazy(() => import('pages/Webhooks/Webhooks'));
const AssetEditPage = React.lazy(() => import('pages/AssetsPage/AssetEditPage/AssetEditPage'));
const ReleaseHistory = React.lazy(
  () => import('pages/Environments/ReleasesHistory/ReleasesHistory')
);
const ReleaseDetails = React.lazy(
  () => import('pages/Environments/ReleasesHistory/ReleaseDetails/ReleaseDetails')
);

export const routeList: readonly RouteItem[] = [
  {
    path: '/home',
    permissions: [],
    name: 'Home page',
    component: Home,
    icon: <HomeOutline />,
  },
  {
    path: '/projects',
    permissions: [Permission.PROJECT],
    name: 'Workspaces',
    component: Projects,
    icon: <BagOutline />,
  },
  {
    path: '/content-types',
    permissions: [Permission.CONTENT_TYPE],
    name: 'Models',
    component: ContentTypes,
    icon: <ModelConceptOutline />,
    items: [
      {
        path: '/content-types/:code',
        name: 'Edit model',
        component: ContentTypeEditPage,
        hidden: true,
        permissions: [Permission.CONTENT_TYPE],
      },
      {
        path: '/content-types/create',
        name: 'Create model',
        component: ContentTypeEditPage,
        hidden: true,
        permissions: [Permission.CONTENT_TYPE],
      },
    ],
  },

  {
    name: 'Contents',
    permissions: [Permission.CONTENT],
    path: '/content',
    icon: <ContentSquareOutline />,
    component: ContentPage,
    items: [
      {
        name: '',
        path: '/content/:type',
        // eslint-disable-next-line react/display-name
        component: () => <Contents url={'content'} />,
        permissions: [],
        hidden: true,
        items: [
          {
            name: '',
            path: '/content/:type/:sourceId',
            component: ContentEditorPage,
            permissions: [],
            hidden: true,
            items: [
              {
                path: '/content/:type/:contentId/:versionId',
                name: '',
                permissions: [],
                // eslint-disable-next-line react/display-name
                component: () => <CompareVersion url={'content'} />,
              },
            ],
          },
        ],
      },
    ],
    dynamic: true,
  },
  {
    path: '/system-settings',
    permissions: [Permission.SETTINGS],
    name: 'System settings',
    component: SystemSettings,
    icon: <GearOutline />,
  },
  {
    name: 'Users',
    path: '/users',
    component: UsersPage,
    permissions: [Permission.USER],
    icon: <UserDoubleOutline />,
  },
  {
    name: 'Roles and rights',
    path: '/roles',
    // eslint-disable-next-line react/display-name
    component: Roles,
    permissions: [Permission.PERMISSIONS_ROLES],
    icon: <DoorOutline />,
    items: [
      {
        name: 'Access rights settings',
        path: '/roles/:id',
        // eslint-disable-next-line react/display-name
        component: Rights,
        permissions: [Permission.PERMISSIONS_ROLES],
        hidden: true,
      },
    ],
  },
  {
    path: '/languages',
    name: 'Languages',
    permissions: [Permission.LANGUAGE],
    component: Languages,
    icon: <LanguageOutline />,
  },
  {
    path: '/content-type-groups',
    name: 'Model groups',
    permissions: [Permission.MODEL_GROUP],
    component: ContentTypeGroups,
    icon: <TreeOutline />,
  },
  {
    path: '/access-tokens/',
    name: 'Access tokens',
    permissions: [Permission.ACCESS_TOKEN],
    component: AccessTokens,
    hidden: true,
  },
  {
    path: '/webhooks',
    name: 'Webhooks',
    permissions: [Permission.WEBHOOK],
    component: Webhooks,
    hidden: true,
  },
  {
    path: '/credentials/change-password',
    name: 'Change password',
    permissions: [],
    component: ChangePassword,
    hidden: true,
  },
  {
    path: '/asset',
    name: 'Assets',
    permissions: [Permission.ASSET],
    icon: <AssetsOutline />,
    component: AssetsPage,
    items: [
      {
        path: '/asset/:id',
        name: 'Asset edit',
        permissions: [Permission.ASSET],
        hidden: true,
        component: AssetEditPage,
      },
    ],
  },
  {
    path: '/integrations',
    name: 'Integrations',
    permissions: [Permission.INTEGRATION],
    icon: <CubeOutline />,
    component: Integrations,
    items: [
      {
        path: '/integrations/:id',
        name: 'Integration page',
        permissions: [Permission.INTEGRATION],
        hidden: true,
        component: IntegrationEditPage,
      },
    ],
  },
  // {
  //   path: '/translates',
  //   permissions: [],
  //   icon: <PlanetOutline />,
  //   name: 'Translates',
  //   component: ContentPage,
  //   items: [
  //     {
  //       name: 'Translate',
  //       path: '/translates/:type',
  //       component: Translates,
  //       permissions: [],
  //       hidden: true,
  //     },
  //   ],
  // },
  {
    path: '/environment-releases',
    permissions: [Permission.ENVIRONMENT_AND_RELEASE],
    icon: <Db />,
    name: 'Environments and releases',
    // eslint-disable-next-line react/display-name
    component: () => <Environments type={'release'} />,
    items: [
      {
        path: '/environment-releases/history',
        name: 'Release history',
        hidden: true,
        permissions: [],
        component: ReleaseHistory,
        items: [
          {
            path: '/environment-releases/history/:id',
            name: 'Release details',
            hidden: true,
            permissions: [],
            component: ReleaseDetails,
          },
        ],
      },
    ],
  },
  {
    path: '/profile',
    permissions: [],
    hidden: true,
    name: 'Profile',
    component: ProfilePage,
  },
];

export default routeList;
