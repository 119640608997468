import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const Search: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.083 7.083a5 5 0 107.071 7.071 5 5 0 00-7.07-7.07zm-1.06 8.132a6.5 6.5 0 119.692-.56l3.947 3.946a.75.75 0 11-1.061 1.06l-3.947-3.946a6.501 6.501 0 01-8.631-.5z"
    />
  </SvgIcon>
);
export default Search;
