import { useCallback, useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function useToggle(
  initialValue = false,
  localStorageKey?: string
): readonly [boolean, () => void] {
  const localStorageValue = localStorageKey ? localStorage.getItem(localStorageKey) : undefined;
  const [value, setValue] = useState(
    localStorageValue === undefined || localStorageValue === null
      ? initialValue
      : JSON.parse(localStorageValue)
  );
  const toggle = useCallback(() => {
    setValue((v: boolean) => {
      if (localStorageKey) {
        localStorage.setItem(localStorageKey, (!v).toString());
      }
      return !v;
    });
  }, [localStorageKey]);
  return [value as boolean, toggle];
}
