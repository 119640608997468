import { makeStyles } from '@material-ui/core';
import { TOP_NAV_HEIGHT } from 'common/constants/styles';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { useStyles as useLoginStyles } from './LoginForm/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: `calc(100% - ${TOP_NAV_HEIGHT}px)`,
    width: '100%',
  },
  header: {
    height: '44px',
    width: `calc(100% - 60px)`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 30px',
    color: '#fff',
    position: 'absolute',
    top: 10,
    left: 30,
    right: 30,
  },
  flex: {
    display: 'flex',
    marginBottom: 20,
    width: '100%',
  },
  mb: {
    marginBottom: 20,
  },
});

export const LoginPageSkeleton: React.FC = () => {
  const styles = useStyles();
  const loginStyles = useLoginStyles();

  return (
    <div className={styles.root}>
      <Skeleton className={styles.header} height={44} />
      <div className={styles.container}>
        <div className={loginStyles.loginContainer}>
          <Skeleton className={styles.mb} count={2} height={32} />
          <Skeleton className={styles.mb} count={2} height={40} />
          <div className={styles.flex}>
            <div style={{ flex: 1, marginRight: 10 }}>
              <Skeleton height={30} />
            </div>
            <div style={{ flex: 1 }}>
              <Skeleton height={30} />
            </div>
          </div>
          <Skeleton className={styles.mb} height={40} />
        </div>
      </div>
    </div>
  );
};
