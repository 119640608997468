import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const BookOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.881 3.722c-.319-.185-.863-.248-1.85-.207H7.97c-1.073-.043-1.608.06-1.895.248-.212.138-.423.42-.484 1.263v11.927c.435-.2.928-.3 1.43-.273h11.391V5.024c-.06-.899-.309-1.173-.53-1.302zm-.175 14.458H6.976l-.023-.001a1.53 1.53 0 00-.97.272c-.26.186-.379.41-.394.605-.02.259.106.619.404.937.292.312.664.492 1.01.495H17.52l.02.001c.409.022.539-.13.563-.171.02-.034.056-.124-.066-.274L18 19.997l-.03-.052a2.428 2.428 0 01-.263-1.765zm1.69-.212l-.022.034a1.78 1.78 0 00-.159.339c-.09.263-.11.541.031.814.438.58.51 1.326.141 1.938-.374.62-1.095.933-1.906.895H6.994c-.843-.007-1.587-.426-2.096-.97-.498-.532-.854-1.273-.807-2.043v-14l.002-.025c.07-1.047.364-1.921 1.16-2.443.722-.471 1.696-.533 2.762-.491h7.97c.944-.039 1.908-.021 2.65.409.84.488 1.202 1.367 1.276 2.528l.001.023v11.67c.018.362-.037.683-.194.954a1.292 1.292 0 01-.323.368z"
      fill="#000"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.512 7.678a.75.75 0 01.75-.75h7.476a.75.75 0 010 1.5H8.262a.75.75 0 01-.75-.75zm0 2.88a.75.75 0 01.75-.75h4.672a.75.75 0 110 1.5H8.262a.75.75 0 01-.75-.75z"
      fill="#000"
    />
  </SvgIcon>
);
export default BookOutline;
