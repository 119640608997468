import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  item: ({ background }: { readonly background?: string }) => ({
    backgroundColor: background || 'rgba(9, 30, 66, 0.04)',
    marginRight: 4,
    padding: '0 8px',
    color: '#253858',
    fontSize: 14,
    '& .MuiFormControlLabel-root': {
      marginRight: 0,
    },
    borderRadius: 3,
  }),
});

export const useStyles1 = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    border: `1px solid ${theme.palette.surface[300]}`,
    'input:hover ~ &': {
      backgroundColor: theme.palette.surface[200],
    },
    'input:disabled ~ &, input:active ~ &': {
      background: theme.palette.surface[300],
    },
  },
  checkedIcon: {
    border: `1px solid ${theme.palette.blue[500]}`,
    backgroundColor: 'transparent',
    'input:disabled ~ &': {
      border: `1px solid ${theme.palette.blue[100]}`,
      background: 'transparent',
      '&:before': {
        background: theme.palette.blue[100],
      },
    },
    '&:before': {
      display: 'block',
      width: 10,
      height: 10,
      top: 2,
      left: 2,
      position: 'relative',
      borderRadius: '50%',
      background: theme.palette.blue[500],
      content: '""',
    },
  },
}));
