import { Action, createSlice } from '@reduxjs/toolkit';
import { ContentItem } from 'api/types/ContentItem';
import { Version } from 'api/types/Version';
import { VersionState } from 'store/versionSlice/types/version-state';

const initialState: VersionState = {
  version: {},
  allVersions: [],
  currentVersion: {},
};

const versionSlice = createSlice({
  name: 'versionSlice',
  initialState,
  reducers: {
    setNewVersion(state, action: Action & { readonly payload: ContentItem }) {
      return { ...state, version: action.payload };
    },
    setAllVersions(
      state,
      action: Action & {
        readonly payload: { readonly versions: ReadonlyArray<Version>; readonly sourceId: number };
      }
    ) {
      return { ...state, current: action.payload };
    },
    setCurrentVersion(
      state,
      action: Action & {
        readonly payload: {
          readonly id?: number;
          readonly sourceId?: number;
          readonly version?: number;
        };
      }
    ) {
      const { id, sourceId, version } = action.payload;
      return { ...state, currentVersion: { id, sourceId, version } };
    },
  },
});

export const { setNewVersion, setAllVersions, setCurrentVersion } = versionSlice.actions;

export const { getNewVersion, getAllVersions, getCurrentVersion } = {
  getNewVersion(state: VersionState) {
    return state.version.version;
  },
  getAllVersions(state: VersionState) {
    return state.version.current;
  },
  getCurrentVersion(state: VersionState) {
    return state.version.currentVersion;
  },
};

export default versionSlice.reducer;
