import { Tabs, Theme, withStyles } from '@material-ui/core';

export const TabsPelican = withStyles((theme: Theme) => ({
  root: {
    borderBottom: '1px solid #e8e8e8',
    minHeight: 36,
    overflow: 'visible',
  },
  indicator: {
    backgroundColor: theme.palette.blue[500],
  },
  scroller: {
    padding: 0,
  },
  scrollButtons: {
    padding: 0,
    width: 20,
  },
}))(Tabs);

export default TabsPelican;
