// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const headerProps = (props: unknown, { column }: any): any => getStyles(props, column.align);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const cellProps = (props: unknown, { cell }: any): any =>
  getStyles(props, cell.column.align);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getStyles = (props: unknown, align = 'left'): any => [
  props,
  {
    style: {
      justifyContent: align === 'right' ? 'flex-end' : 'flex-start',
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
];
