import { makeStyles } from '@material-ui/core/styles';

import { IconPelicanProps } from './index';

export const useStyles = makeStyles(() => ({
  root: (props: IconPelicanProps) => ({
    width: props?.width || 24,
    height: props?.height || 24,
    background: `url(/assets/${props.type}.svg) center no-repeat`,
    display: 'inline-block',
  }),
}));
