import clsx from 'clsx';
import { useContentEditorProvider } from 'pages/ContentEditorPage/ContentEditor/useContentEditorProvider';
import React from 'react';

import { ContentActionButtons } from './ContentActionButtons';
import { ContentEditorRightBar } from './ContentEditorRightBar';
import { ContentEditorTabs } from './ContentEditorTabs/ContentEditorTabs';
import { ContentFormFields } from './ContentFormFields';
import { ContentLeaveModal } from './ContentLeaveModal';
import { ContentStatus } from './ContentStatus/ContentStatus';
// import ContentTreeView from './ContentTreeView/component';
import { useStyles } from './styles';

export const ContentEditorForm: React.FC = () => {
  const { fields, isTreeView } = useContentEditorProvider();

  const styles = useStyles();

  return (
    <div className={clsx(styles.contentEditor, isTreeView && styles.stretch)}>
      <div className={styles.header}>
        <ContentStatus />
        <ContentActionButtons fields={fields} />
      </div>
      <ContentEditorTabs />
      <div className={styles.main}>
        {!isTreeView && <ContentFormFields />}
        {/* {isTreeView && <ContentTreeView />}*/}
        <ContentEditorRightBar />
        <ContentLeaveModal />
      </div>
    </div>
  );
};
