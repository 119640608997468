import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { backlinksOfContent, backlinksOfModels } from 'api/back-links';
import { ContentReferences, ModelReference } from 'api/types/BackLinks';
import clsx from 'clsx';
import { ButtonPelican } from 'components/ButtonPelican';
import { ModalPelican } from 'components/ModalPelican';
import Spin from 'components/Spin';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useStatusStyle } from 'theme/status-styles';

import { BackLinkProps, Props } from './props';
import { useStyles } from './styles';

export const BackLinksTable: React.FC<BackLinkProps> = ({
  models,
  isModel,
  contentType,
  dataId,
  onClose,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const statusStyles = useStatusStyle();
  const { enqueueSnackbar } = useSnackbar();

  const [openIndexes, setOpenIndexes] = useState<ReadonlyArray<number>>([]);
  const [contents, setContents] = useState<Record<string, ReadonlyArray<ContentReferences>>>({});

  const getContentsBackLinks = useCallback(
    async (modelSlug: string, fieldSlug: string) => {
      try {
        const res = await backlinksOfContent(contentType, modelSlug, dataId || '', fieldSlug);
        setContents({ ...contents, ...res });
      } catch (e) {
        enqueueSnackbar(`${t('Error on loading')} ${t('backlinks')} ${e.response?.data?.message}`, {
          variant: 'error',
        });
      }
    },
    [contentType, dataId, contents, enqueueSnackbar, t]
  );

  const handleOpenModel = useCallback(
    (reference: ModelReference, index: number) => {
      if (openIndexes.includes(index)) {
        setOpenIndexes(openIndexes.filter((i) => i !== index));
      } else {
        setOpenIndexes([...openIndexes, index]);
        if (contents[reference.content_type_slug] === undefined) {
          getContentsBackLinks(reference.content_type_slug, reference.content_field_slug);
        }
      }
    },
    [openIndexes, getContentsBackLinks, contents]
  );

  const handleRedirect = useCallback(
    (typeSlug: string, fieldSlug: string) => {
      const location = `/${isModel ? 'content-types' : 'content'}/${typeSlug}`;
      const query = isModel
        ? `?searchFor=${fieldSlug}`
        : `?searchId=${dataId}&searchField=${fieldSlug}`;
      history.push(location + query);
      onClose();
    },
    [dataId, history, isModel, onClose]
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={styles.headCell}>{t('Model')}</TableCell>
          <TableCell className={styles.headCell}>{t('Field name')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {models?.map((m, i) => (
          <>
            <TableRow
              key={i}
              className={clsx(
                styles.row,
                !(openIndexes.includes(i) && !!contents[m.content_type_slug]) && styles.borderBottom
              )}
            >
              <TableCell className={styles.cell}>
                <div className={styles.dFlex}>
                  {!isModel && !m.is_embedded && (
                    <ChevronLeftRoundedIcon
                      onClick={() => handleOpenModel(m, i)}
                      className={clsx(styles.chevron, openIndexes.includes(i) && styles.active)}
                      htmlColor="#FFFFFF"
                    />
                  )}
                  {!isModel && m.is_embedded && <LockOutlinedIcon />}
                  {(!m.is_embedded || isModel) && (
                    <ButtonPelican
                      variant="text"
                      color="primary"
                      onClick={() => handleRedirect(m.content_type_slug, m.content_field_slug)}
                    >
                      {m.content_type_description}
                    </ButtonPelican>
                  )}
                  {m.is_embedded && !isModel && (
                    <div className={styles.marginLeft}>
                      <h1 className={styles.modelDescription}>{m.content_type_description}</h1>
                      <p className={styles.errorText}>{t('Embedded')}</p>
                    </div>
                  )}
                </div>
              </TableCell>
              <TableCell className={styles.cell}>{m.content_field_label}</TableCell>
            </TableRow>
            {openIndexes.includes(i) && !!contents[m.content_type_slug] && (
              <TableRow>
                <TableCell colSpan={2} className={styles.contentsCell}>
                  <div className={styles.contents}>
                    {contents[m.content_type_slug].map((c, i) => (
                      <ButtonPelican
                        key={i}
                        className={clsx(styles.linkButton, statusStyles[c.content_state])}
                        onClick={() => history.push(`/content/${m.content_type_slug}/${c.id}`)}
                      >
                        ID:{c.id}{' '}
                        {t(c.content_state.charAt(0).toUpperCase() + c.content_state.slice(1))}
                      </ButtonPelican>
                    ))}
                  </div>
                </TableCell>
              </TableRow>
            )}
          </>
        ))}
      </TableBody>
    </Table>
  );
};

export const BackLinks: React.FC<Props> = ({
  isModel,
  dataId,
  contentType,
  onClose,
  contentsReferences,
  disableTitle,
  disableDescription,
  onConfirmDisable,
  open,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [models, setModels] = useState<ReadonlyArray<ModelReference>>([]);
  const [modelsLoading, setModelsLoading] = useState(true);
  const [openContentsIds, setOpenContentsIds] = useState<ReadonlyArray<number>>([]);

  const getModelsBackLinks = useCallback(async () => {
    try {
      const res = await backlinksOfModels(contentType, dataId);
      setModels(res);
    } catch (e) {
      enqueueSnackbar(
        `${t('Error on loading')} ${t('backlinks')} ${t(e.response?.data?.message || e)}`,
        {
          variant: 'error',
        }
      );
    } finally {
      setModelsLoading(false);
    }
  }, [contentType, dataId, enqueueSnackbar, t]);

  const handleOpenContent = useCallback(
    (newId: number) => {
      if (openContentsIds.includes(newId)) {
        setOpenContentsIds(openContentsIds.filter((id) => id !== newId));
      } else {
        setOpenContentsIds([...openContentsIds, newId]);
      }
    },
    [openContentsIds]
  );

  useEffect(() => {
    if (!contentsReferences) {
      getModelsBackLinks();
    }
  }, [getModelsBackLinks, contentsReferences]);

  return (
    <ModalPelican
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      title={disableTitle || t('Back links')}
      footer={
        <>
          <ButtonPelican onClick={onClose} variant="text">
            {t('Close')}
          </ButtonPelican>
          {onConfirmDisable && (
            <ButtonPelican variant="contained" color="primary" onClick={onConfirmDisable}>
              {t('Yes')}
            </ButtonPelican>
          )}
        </>
      }
    >
      <>
        {contentsReferences && (
          <div>
            <Typography variant="body1" className={styles.description}>
              {disableDescription}
            </Typography>
            {contentsReferences.map((ref, i) => (
              <div key={i} className={styles.contentReferences}>
                {!isModel && `ID: ${ref.id}`}
                <div
                  className={clsx(
                    styles.padding,
                    !openContentsIds.includes(ref.id) && styles.dNone
                  )}
                >
                  <BackLinksTable
                    isModel={isModel}
                    contentType={contentType}
                    dataId={ref.id}
                    models={ref.backLinks}
                    onClose={onClose}
                  />
                </div>
                <ButtonPelican
                  variant="text"
                  color="primary"
                  onClick={() => handleOpenContent(ref.id)}
                >
                  {!openContentsIds.includes(ref.id) ? t('More') : t('Hide')}
                </ButtonPelican>
              </div>
            ))}
          </div>
        )}
        {!disableDescription && (
          <div>
            {models.length > 0 && !modelsLoading && (
              <BackLinksTable
                isModel={isModel}
                contentType={contentType}
                models={models}
                dataId={dataId}
                onClose={onClose}
              />
            )}
            {models.length === 0 && !modelsLoading && (
              <div className={clsx(styles.empty, styles.autoHeight)}>
                <p>{t('No back links')}</p>
              </div>
            )}
            {modelsLoading && (
              <div className={styles.loading}>
                `<Spin variant="inline" />`
              </div>
            )}
          </div>
        )}
      </>
    </ModalPelican>
  );
};

export default BackLinks;
