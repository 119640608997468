import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  maxWidth: {
    maxWidth: 700,
  },
  cell: {
    padding: '14px 0',
    color: '#253858',
    fontSize: 14,
    borderBottom: 0,
  },
  headCell: {
    padding: '8px 0',
    fontSize: 12,
    fontWeight: 600,
    color: '#6B778C',
    textTransform: 'uppercase',
  },
  link: {
    color: '#0052CC',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  row: {
    minHeight: 48,
    alignItems: 'center',
  },
  modelDescription: {
    paddingTop: 12,
    color: '#172B4D',
    margin: 0,
    fontWeight: 500,
    fontSize: 14,
  },
  errorText: {
    color: '#FF5630',
    fontSize: 11,
    margin: 0,
  },
  chevron: {
    transform: 'rotate(180deg)',
    backgroundColor: '#B3BAC5',
    width: 20,
    height: 20,
    borderRadius: 15,
    marginRight: 16,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#505F79',
    },
  },
  active: {
    transform: 'rotate(270deg)',
    backgroundColor: '#505F79',
  },
  dFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  linkButton: {
    border: 0,
    outline: 'none',
    padding: '2px 4px',
    textTransform: 'uppercase',
    borderRadius: 3,
    marginRight: 12,
    marginBottom: 8,
    color: '#FFFFFF',
    cursor: 'pointer',
  },
  marginLeft: {
    marginLeft: 14,
  },
  contents: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  contentsCell: {
    padding: '0 0 0 40px',
  },
  borderBottom: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  overFlowYScroll: {
    maxHeight: 'calc(100vh - 300px)',
    overflowY: 'auto',
  },
  autoHeight: {
    minHeight: 'auto',
  },
  loading: {
    padding: 40,
    width: '100%',
    textAlign: 'center',
  },
  dNone: {
    display: 'none',
  },
  contentReferences: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    marginBottom: 12,
  },
  padding: {
    padding: '0 12px 12px',
  },
  description: {
    marginBottom: 8,
  },
  empty: {
    fontSize: 20,
    lineHeight: '24px',
    textAlign: 'center',
    color: '#6B778C',
    padding: '40px 25%',
    minHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
});
