/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import { Column, ColumnInstance, TableInstance } from 'react-table';

import { DataGridContextState } from './types/DataGridContextState';

export const DataGridContext = React.createContext<DataGridContextState>({
  allColumns: [],
  selectedRows: [],
  selectedRowIds: [],
  initialColumns: [],
});

export const WithGridContext = (
  Component: React.ComponentType<any>,
  gridName: string
): React.FC<any> => {
  // eslint-disable-next-line react/display-name
  return ({ ...props }) => {
    const [value, setValue] = useState<ReadonlyArray<string | number>>([]);
    const [allColumns, setAllColumns] = useState<ReadonlyArray<ColumnInstance>>([]);
    const [initialColumns, setInitialColumns] = useState<ReadonlyArray<Column<any>>>([]);

    console.log('value', value);
    DataGridContext.displayName = gridName;
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const isNotSingleSelected = useMemo(() => value?.length !== 1, [value]);
    const [tableInstance, setTableInstance] = useState<TableInstance<any>>();
    const selectedFlatRows = tableInstance?.selectedFlatRows;
    const selectedRows = useMemo(() => selectedFlatRows?.map((s) => s.original) || [], [
      selectedFlatRows,
    ]);

    const selectedRow = useMemo(() => (selectedRows?.length ? selectedRows[0] : undefined), [
      selectedRows,
    ]);

    return (
      <DataGridContext.Provider
        value={{
          selectedRowIds: value,
          setSelectedRowIds: setValue,
          gridName: gridName,
          isNotSingleSelected,
          allColumns,
          setAllColumns,
          tableInstance,
          setTableInstance,
          selectedRows,
          selectedRow,
          initialColumns,
          setInitialColumns,
        }}
      >
        <Component {...props} />
      </DataGridContext.Provider>
    );
  };
};
