import { Modal } from '@material-ui/core';
import clsx from 'clsx';
import useToggle from 'common/hooks/useToggle';
import { Breadcrumbs } from 'containers/Breadcrumbs';
import LoginForm from 'pages/Login/LoginForm';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionExpired, setSessionExpired } from 'store/sessionSlice';
import GlobalSearch from 'templates/MainTemplate/GlobalSearch/GlobalSearch';
import { Sidebar } from 'templates/MainTemplate/Sidebar';
import TopNav from 'templates/MainTemplate/TopNav';

import { Props } from './props';
import { useStyles } from './styles';

export const MainTemplate: React.FC<Props> = ({ children, hideNav = false }) => {
  const [sidebarOn, handleSidebarOn] = useToggle(false, 'sidebarOn');
  const classes = useStyles({ sidebarOn });

  const [contentSearchOn, handleContentSearchOn] = useToggle();
  const [breadcrumbsHidden, setBreadcrumbsHidden] = useState<boolean>();
  const [isSearch, setSearch] = useState(false);
  const dispatch = useDispatch();
  const sessionExpired = useSelector(getSessionExpired);

  const handleSidebarToggle = (): void => {
    localStorage.setItem('sidebarOn', (!sidebarOn).toString());
    handleSidebarOn();
  };

  const handleLoginModalClose = (): void => {
    dispatch(setSessionExpired(!sessionExpired));
  };

  const onHidden = (val: boolean): void => setBreadcrumbsHidden(val);

  return (
    <section className={classes.root}>
      <TopNav
        contentNavigationOn={contentSearchOn}
        sidebarOn={sidebarOn}
        toggleContentNavigation={handleContentSearchOn}
        toggleSidebar={handleSidebarToggle}
        showSearch={() => setSearch(true)}
      />
      <main className={classes.main}>
        {!hideNav && (
          <nav className={clsx(classes.sidebar, sidebarOn && classes.hide)}>
            <Sidebar
              showContentNavigation={handleContentSearchOn}
              showSearch={() => setSearch(true)}
              expandToggle={handleSidebarToggle}
              isToggle={sidebarOn}
            />
          </nav>
        )}
        <div
          className={clsx(!hideNav && classes.container, (sidebarOn || hideNav) && classes.stretch)}
        >
          <Breadcrumbs onHidden={onHidden} />
          <div
            className={clsx(classes.content, !breadcrumbsHidden && classes.heightWithBreadcrumbs)}
          >
            {children}
          </div>
        </div>
      </main>
      {isSearch && (
        <Modal
          open={isSearch}
          onClose={() => setSearch(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <GlobalSearch onClose={() => setSearch(false)} />
        </Modal>
      )}
      {sessionExpired && (
        <Modal open={sessionExpired} BackdropProps={{ classes: { root: classes.loginModal } }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              width: '100vw',
            }}
          >
            <div style={{ width: 420 }}>
              <LoginForm type="sessionExpired" onModalClose={handleLoginModalClose} />
            </div>
          </div>
        </Modal>
      )}
    </section>
  );
};
