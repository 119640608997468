import { TextInput } from 'components/TextInput';
import React, { FormEventHandler, useCallback, useEffect, useState } from 'react';

import { Props } from './props';

export const MultiLingualTextInput: React.FC<Props> = ({
  langTextValue,
  handleMultiLangChange,
  ...rest
}) => {
  const [text, setText] = useState(langTextValue);

  const handleChange = useCallback<FormEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
    (event) => {
      const val = event.currentTarget.value;
      setText(val);

      if (event.type === 'blur' && val !== langTextValue) {
        handleMultiLangChange(val);
      }
    },
    [handleMultiLangChange, langTextValue]
  );

  useEffect(() => {
    setText(langTextValue || '');
  }, [langTextValue]);

  return (
    <TextInput
      value={text}
      inputProps={{
        onChange: handleChange,
        onBlur: handleChange,
        readOnly: rest.readOnly,
      }}
      {...rest}
    />
  );
};

export default MultiLingualTextInput;
