import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const Eye: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <svg width={25} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.142 17.71c5.445 0 9.003-4.38 9.003-5.71 0-1.337-3.565-5.71-9.003-5.71-5.362 0-8.997 4.373-8.997 5.71 0 1.33 3.628 5.71 8.997 5.71zm.007-2.221c-1.943 0-3.51-1.595-3.517-3.489-.006-1.95 1.574-3.489 3.517-3.489A3.49 3.49 0 0115.659 12c0 1.894-1.574 3.489-3.51 3.489zm-.007-2.124c.759 0 1.372-.613 1.372-1.358 0-.752-.613-1.372-1.372-1.372-.759 0-1.365.62-1.365 1.372 0 .745.606 1.358 1.365 1.358z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
export default Eye;
