import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {},
  title: {
    padding: '6px',
    fontSize: 14,
    color: '#253858',
  },
  items: {},
  addItemButton: {
    background: 'rgba(9, 30, 66, 0.04)',
    borderRadius: 3,
    color: '#0747A6',
    fontSize: 14,
    padding: '6px 8px',
    fontWeight: 'normal',
  },
  loading: {
    padding: 40,
    width: '100%',
    textAlign: 'center',
  },
});
