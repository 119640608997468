import { FormControlLabel, Radio, RadioProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from './props';
import { useStyles, useStyles1 } from './styles';

const StyledRadio: React.FC<RadioProps> = (props) => {
  const classes = useStyles1();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

export const RadioPelican: React.FC<Props> = ({ value, label, background, ...rest }) => {
  const classes = useStyles({ background });
  const { t } = useTranslation();

  return (
    <div className={classes.item}>
      <FormControlLabel value={value} control={<StyledRadio {...rest} />} label={t(label)} />
    </div>
  );
};

export default RadioPelican;
