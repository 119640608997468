import { createSlice } from '@reduxjs/toolkit';
import { Project } from 'api/types/Project';
import { Action } from 'redux';

import { WorkspaceModel } from './types/workspace-model';
import { WorkspaceState } from './types/workspace-state';

const initialState: WorkspaceState = {
  selected: [],
  projects: [],
};

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setWorkspaces(
      state,
      action: Action & {
        readonly payload: ReadonlyArray<WorkspaceModel>;
      }
    ) {
      localStorage.setItem('workspaces', JSON.stringify(action.payload));
      return {
        ...state,
        ...{ selected: action.payload },
      };
    },
    setProjects(
      state,
      action: Action & { readonly payload: { readonly projects: readonly Project[] } }
    ) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setWorkspaces, setProjects } = workspaceSlice.actions;

export const { getWorkspaces, getProjects } = {
  getWorkspaces(state: { readonly workspace: WorkspaceState }) {
    return state.workspace.selected;
  },
  getProjects(state: { readonly workspace: WorkspaceState }) {
    return state.workspace.projects;
  },
};

export default workspaceSlice.reducer;
