import { Drawer } from '@material-ui/core';
import { fieldsGet } from 'api/content';
import { ContentItem, ContentItem as ContentItemType } from 'api/types/ContentItem';
import { ContentTypeField } from 'api/types/ContentTypeField';
import clsx from 'clsx';
import { ButtonPelican } from 'components/ButtonPelican';
import Spin from 'components/Spin';
import { useSnackbar } from 'notistack';
import VersionFields from 'pages/CompareVersion/CompareVersions/VersionFields/component';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router';
import { setNewVersion } from 'store/versionSlice';
import { usePageStyles as useUsersStyles } from 'theme/page-styles';

import { checkAllFields, getCurrentAndCompareItems, sortEmbeddedItems } from './helpers';
import { ContentCheckedType, EmbeddedCheckedType, Props } from './props';
import { useStyles } from './styles';

const CompareVersions: React.FC<Props> = ({
  fields,
  embedLevel = -1,
  compareItems,
  currentItems,
  locales,
  compareType,
  handleSaveChange,
  checkedContentsProps,
  url,
  onlyReview,
  handleClose,
  embedLabel,
  environmentCompare,
}) => {
  const pageClasses = useUsersStyles();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { params } = useRouteMatch<{
    readonly type: string;
    readonly contentId: string;
    readonly versionId: string;
  }>();
  const [onlyDiffs, setOnlyDiffs] = useState<boolean>(false);
  const [selectAllCurrent, setSelectAllCurrent] = useState<boolean | undefined>(
    compareType === 'restore' ? false : undefined
  );
  const [currentFields, setCurrentFields] = useState<ReadonlyArray<ContentTypeField>>(fields);
  const [isEmbedComparing, setEmbedComparing] = useState<boolean>();
  const [embedFields, setEmbedFields] = useState<Record<string, ReadonlyArray<ContentTypeField>>>(
    {}
  );
  const [checkedContents, setCheckedContent] = useState<ReadonlyArray<ContentCheckedType>>(
    checkedContentsProps ||
      new Array(
        currentItems.length >= compareItems.length ? currentItems.length : compareItems.length
      ).fill({})
  );
  const [fieldsInitialized, setFieldsInitialized] = useState(false);
  const [contentsInitialized, setContentsInitialized] = useState(false);
  const [compareEmbedField, setCompareEmbedField] = useState<ContentTypeField>();
  const [compareEmbedSourceId, setCompareEmbedSourceId] = useState<number>(0);
  const [compareEmbedIndex, setCompareIndex] = useState<number>(0);
  const [submitContents, setSubmitContents] = useState<ReadonlyArray<ContentItemType>>(
    new Array(
      currentItems.length >= compareItems.length ? currentItems.length : compareItems.length
    ).fill({})
  );

  const getFieldsList = useCallback(
    async (embedField?: ContentTypeField) => {
      try {
        if (embedField) {
          const res = await fieldsGet(embedField.referencedContentType || '', {
            project_id: currentItems[0]?.project_id || compareItems[0]?.project_id,
          });
          setEmbedFields((prev) => ({ ...prev, [embedField.slug]: res }));
        } else if (fields.length > 0) {
          const promises = Array.from(
            fields.filter((f) => f.embedded),
            async (f) => {
              const res = await fieldsGet(f.referencedContentType || '', {
                project_id: currentItems[0]?.project_id || compareItems[0]?.project_id,
              });
              return { fieldSlug: f.slug, list: res };
            }
          );
          const results = await Promise.all(promises);
          setEmbedFields(
            results.reduce((prev, res) => ({ ...prev, [res.fieldSlug]: res.list }), {})
          );
        }
      } catch (e) {
        enqueueSnackbar(`${t('Error loading')} ${e.response?.data?.message || e}`, {
          variant: 'error',
        });
      }
    },
    [compareItems, currentItems, enqueueSnackbar, fields, t]
  );

  const onDeselect = useCallback(
    (field: ContentTypeField, index: number): void => {
      const deletedObj: ContentItemType = submitContents[index] || {};
      delete deletedObj[field.slug];
      const deletedChecked: ContentCheckedType = checkedContents[index] || {};
      delete deletedChecked[field.slug];
      setCheckedContent(checkedContents.map((c, i) => (i === index ? deletedChecked : c)));
      setSubmitContents(submitContents.map((c, i) => (i === index ? deletedObj : c)));
    },
    [checkedContents, submitContents]
  );

  const onSelectField = useCallback(
    (field: ContentTypeField, checked: boolean, sourceId: number, index: number): void => {
      if (field.embedded) {
        setCheckedContent(
          checkedContents.map((c, i) =>
            i === index
              ? {
                  ...checkedContents[index],
                  [field.slug]: {
                    checkedCurrent: checked,
                    checkedCompare: !checked,
                    items: checkAllFields(
                      currentItems.find((c) => c.source_id === sourceId)?.[field.slug]?.items || [],
                      compareItems.find((c) => c.source_id === sourceId)?.[field.slug]?.items || [],
                      checked,
                      locales
                    ),
                  },
                }
              : c
          )
        );
        if (!submitContents[index][field.slug]) {
          setSubmitContents(
            submitContents.map((c, i) =>
              i === index
                ? {
                    ...submitContents[index],
                    [field.slug]: {
                      content_type_slug: field.referencedContentType,
                      items:
                        (checked ? currentItems : compareItems)[index]?.[field.slug]?.items || [],
                    },
                  }
                : c
            )
          );
        }
      } else {
        setCheckedContent(
          checkedContents.map((c, i) =>
            i === index ? { ...checkedContents[index], [field.slug]: checked } : c
          )
        );
        if (field.multilingual) {
          const multi: Record<string, Record<string, string>> = {};
          locales.forEach((code) => {
            multi[code] = {
              ...submitContents[index]['$.multilingual']?.[code],
              [field.slug]: (checked ? currentItems : compareItems)[index]?.['$.multilingual']?.[
                code
              ]?.[field.slug],
            };
          });
          setSubmitContents(
            submitContents.map((c, i) =>
              i === index
                ? {
                    ...submitContents[index],
                    '$.multilingual': multi,
                  }
                : c
            )
          );
        } else {
          setSubmitContents(
            submitContents.map((c, i) =>
              i === index
                ? {
                    ...submitContents[index],
                    [field.slug]: (checked ? currentItems : compareItems)[index]?.[field.slug],
                  }
                : c
            )
          );
        }
      }
      setSelectAllCurrent(undefined);
      setCurrentFields(Array.from(currentFields));
    },
    [checkedContents, currentItems, compareItems, submitContents, locales, currentFields]
  );

  const handleSave = useCallback(() => {
    if (embedLevel > -1) {
      handleSaveChange && handleSaveChange(checkedContents, submitContents);
    } else {
      dispatch(
        setNewVersion({
          ...submitContents[0],
          project_id: undefined,
          projects: undefined,
          id: undefined,
          content_state: undefined,
        })
      );
      url && history.push(`/${url}/${params.type}/-1`);
    }
  }, [
    checkedContents,
    submitContents,
    handleSaveChange,
    embedLevel,
    history,
    dispatch,
    params,
    url,
  ]);

  const selectAll = (isCurrent: boolean): void => {
    if (isCurrent !== selectAllCurrent || isCurrent === undefined) {
      setSelectAllCurrent(isCurrent);
      setCheckedContent(checkAllFields(currentItems, compareItems, isCurrent, locales, false));
      setSubmitContents(
        (isCurrent ? currentItems : compareItems).map((c, i) => {
          const res: ContentItemType = {};
          Object.keys(c).forEach((k) => {
            if (Array.isArray(c[k])) {
              res[k] = submitContents[i]?.[k] || c[k];
            } else {
              res[k] = c[k];
            }
          });
          return res;
        })
      );
    } else {
      const emptyArray = new Array(
        currentItems.length >= compareItems.length ? currentItems.length : compareItems.length
      ).fill({});
      setSelectAllCurrent(undefined);
      setCheckedContent(emptyArray);
      setSubmitContents(emptyArray);
    }
  };
  const allChecked = useCallback(
    (checks: ReadonlyArray<ContentCheckedType>, isCompare: boolean): boolean => {
      const arr = checks.map((ch) =>
        Object.values(ch).map((c) =>
          c === true || c === false
            ? c === !isCompare
            : isCompare
            ? (c as EmbeddedCheckedType).checkedCompare
            : (c as EmbeddedCheckedType).checkedCurrent
        )
      );
      return arr.map((a) => a.includes(true)).includes(true);
    },
    []
  );

  const handleEmbedCompare = useCallback(
    (field: ContentTypeField, index: number, sourceId: number, embedSourceId?: number) => {
      setCompareEmbedField(field);
      setCompareEmbedSourceId(embedSourceId || sourceId);
      setCompareIndex(index);
      setEmbedComparing(true);
      !onlyReview && getFieldsList(field);
    },
    [getFieldsList, onlyReview]
  );

  const handleChangeEmbedded = useCallback(
    (
      checks: ReadonlyArray<ContentCheckedType>,
      contents: ReadonlyArray<ContentItemType>,
      index: number
    ) => {
      if (compareEmbedField) {
        setCheckedContent(
          checkedContents.map((c, i) =>
            i === index
              ? {
                  ...checkedContents[index],
                  [compareEmbedField.slug]: {
                    checkedCurrent: allChecked(checks, false),
                    checkedCompare: allChecked(checks, true),
                    items: checks,
                  },
                }
              : c
          )
        );
        setSubmitContents(
          submitContents.map((c, i) =>
            i === index
              ? {
                  ...submitContents[index],
                  [compareEmbedField.slug]: contents.filter((con) => Object.values(con).length > 0),
                }
              : c
          )
        );
        setEmbedComparing(false);
      }
    },
    [checkedContents, submitContents, compareEmbedField, allChecked]
  );

  const getEmbeddedContents = (isCompare: boolean): ReadonlyArray<ContentItem> => {
    if (compareEmbedField) {
      if (onlyReview) {
        const current =
          currentItems[0]?.[compareEmbedField.slug].find(
            (em: ContentItem) => em.source_id === compareEmbedSourceId
          ) || {};
        const compare =
          compareItems[0]?.[compareEmbedField.slug].find(
            (em: ContentItem) => em.source_id === compareEmbedSourceId
          ) || {};
        return [
          sortEmbeddedItems(current, compare, embedFields[compareEmbedField.slug], isCompare),
        ];
      } else {
        const currents =
          currentItems.find((c) => c.source_id === compareEmbedSourceId)?.[
            compareEmbedField.slug
          ] || [];
        const compares =
          compareItems.find((c) => c.source_id === compareEmbedSourceId)?.[
            compareEmbedField.slug
          ] || [];
        return getCurrentAndCompareItems(currents, compares, isCompare);
      }
    }
    return [];
  };

  useEffect(() => {
    if (compareType === 'restore') {
      currentFields.length > 0 &&
        setCurrentFields(
          currentFields.map((f) =>
            currentItems[0][f.slug] !== undefined ? { ...f, checked: false } : f
          )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareItems]);

  useEffect(() => {
    fields && setCurrentFields(fields);
    fields.length > 0 && setFieldsInitialized(true);
  }, [embedLevel, fields]);

  useEffect(() => {
    if (!contentsInitialized) {
      if (compareItems.length > 0 && currentItems.length > 0) {
        setCheckedContent(new Array(currentItems.length).fill({}));
        setContentsInitialized(true);
      }
    }
  }, [contentsInitialized, embedLevel, compareItems, currentItems]);

  useEffect(() => {
    checkedContentsProps && setCheckedContent(checkedContentsProps);
  }, [checkedContentsProps]);

  useEffect(() => {
    if (onlyReview) {
      getFieldsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {fieldsInitialized && contentsInitialized && (
        <>
          <div className={pageClasses.container}>
            <div className={clsx(pageClasses.flex, pageClasses.marginBottom)}>
              <h1 className={pageClasses.heading}>{embedLabel || t('Compare versions')}</h1>
              {!onlyReview && !environmentCompare ? (
                <ButtonPelican
                  variant={compareType === 'restore' ? 'text' : 'contained'}
                  color="primary"
                  onClick={handleSave}
                >
                  {t(
                    compareType !== 'restore'
                      ? embedLevel === -1
                        ? 'Create new version'
                        : 'Save'
                      : 'Restore version'
                  )}
                </ButtonPelican>
              ) : (
                !environmentCompare && (
                  <ButtonPelican variant="contained" onClick={handleClose}>
                    {t('Close')}
                  </ButtonPelican>
                )
              )}
            </div>
          </div>
          <div className={classes.flex}>
            <div className={clsx(classes.current, classes.version)}>
              {(currentItems.length >= compareItems.length ? currentItems : compareItems).map(
                (_content, i) => (
                  <div key={i}>
                    <VersionFields
                      contentItem={currentItems[i] || {}}
                      compareItem={compareItems[i] || {}}
                      fields={currentFields}
                      onlyDiffs={onlyDiffs}
                      onSelectField={(field, checked, sourceId) =>
                        onSelectField(field, checked, sourceId, i)
                      }
                      onDeselectField={(field) => onDeselect(field, i)}
                      locales={locales}
                      handleEmbedCompare={handleEmbedCompare}
                      itemIndex={i}
                      checkedContent={checkedContents[i]}
                      embedLevel={embedLevel}
                      onlyReview={onlyReview}
                      embedFields={embedFields}
                      handleOnlyDiffs={setOnlyDiffs}
                      handleSelectAll={selectAll}
                      selectAllCurrent={selectAllCurrent}
                      environmentCompare={environmentCompare}
                    />
                  </div>
                )
              )}
            </div>
            <div className={classes.version}>
              {(currentItems.length >= compareItems.length ? currentItems : compareItems).map(
                (_content, i) => (
                  <div key={i}>
                    <VersionFields
                      compareItem={compareItems[i] || {}}
                      contentItem={currentItems[i] || {}}
                      fields={currentFields}
                      onlyDiffs={onlyDiffs}
                      isCompare
                      onSelectField={(field, checked, sourceId) =>
                        onSelectField(field, checked, sourceId, i)
                      }
                      onDeselectField={(field) => onDeselect(field, i)}
                      locales={locales}
                      handleEmbedCompare={handleEmbedCompare}
                      itemIndex={i}
                      checkedContent={checkedContents[i]}
                      embedLevel={embedLevel}
                      onlyReview={onlyReview}
                      embedFields={embedFields}
                      handleSelectAll={selectAll}
                      selectAllCurrent={selectAllCurrent}
                      environmentCompare={environmentCompare}
                    />
                  </div>
                )
              )}
            </div>
          </div>
          <Drawer anchor={'right'} open={isEmbedComparing} onClose={() => setEmbedComparing(false)}>
            {/* TODO: fix fields */}
            <div style={{ width: `${80 - embedLevel - 1}vw`, minHeight: '100vh' }}>
              {compareEmbedField && (
                <CompareVersions
                  handleClose={() => setEmbedComparing(false)}
                  onlyReview={onlyReview}
                  currentItems={getEmbeddedContents(false)}
                  embedLevel={embedLevel + 1}
                  compareItems={getEmbeddedContents(true)}
                  url={url}
                  embedLabel={compareEmbedField.label}
                  fields={embedFields[compareEmbedField.slug] || []}
                  locales={locales}
                  compareType={compareType}
                  handleSaveChange={(checks, contents) =>
                    handleChangeEmbedded(checks, contents, compareEmbedIndex)
                  }
                  checkedContentsProps={
                    (checkedContents[compareEmbedIndex][
                      compareEmbedField.slug
                    ] as EmbeddedCheckedType)?.items
                  }
                  environmentCompare={environmentCompare}
                />
              )}
            </div>
          </Drawer>
        </>
      )}
      {!fieldsInitialized ||
        (!contentsInitialized && (
          <div>
            <Spin />
          </div>
        ))}
    </>
  );
};

export default CompareVersions;
