import { useSnackbar } from 'notistack';
import { LoginPageSkeleton } from 'pages/Login/LoginPageSkeleton';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loadContentData } from 'store/contentSlice';
import { getUserInfo, loadInitialData, setError, setLoading } from 'store/userInfoSlice';

import { MainRouterSkeleton } from './MainRouterSkeleton';
import Routes from './Routes';

const MainRouter: React.FC = () => {
  const { loading, permissions, userName, error } = useSelector(getUserInfo);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isNotLoginPage = useMemo(() => window.location?.pathname?.toLowerCase() !== '/login', []);

  const load = useCallback(async () => {
    if (isNotLoginPage) {
      dispatch(loadInitialData());
      dispatch(loadContentData());
    } else {
      dispatch(setLoading(false));
    }
  }, [dispatch, isNotLoginPage]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (error) {
      enqueueSnackbar(
        `${t('Error on loading')} ${t('user rights')} ${t(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          `${(error as any).response?.data?.message || error}`
        )}`,
        {
          variant: 'error',
        }
      );
      dispatch(setError(undefined));
    }
  }, [dispatch, enqueueSnackbar, error, t]);

  return loading ? (
    <>
      {isNotLoginPage && <MainRouterSkeleton />}
      {!isNotLoginPage && <LoginPageSkeleton />}
    </>
  ) : (
    <Routes userPermissions={permissions} userName={userName} />
  );
};

export default MainRouter;
