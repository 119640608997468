import { Divider, Drawer, IconButton, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { ButtonPelican } from 'components/ButtonPelican';
import { Drag, DuplicateOutline, Eye, PenFill, TrashOutline } from 'components/IconPelican/icons';
import { ConfirmContext } from 'containers/ConfirmContext';
import { XYCoord } from 'dnd-core';
import { ContentEditor } from 'pages/ContentEditorPage/ContentEditor/index';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { DisplayedValues } from './DisplayedValues';
import { Props } from './props';
import { useStyles } from './styles';

export const ContentEmbeddedItem: React.FC<Props> = ({
  item,
  index,
  fields,
  onRemoveItem,
  moveCard,
  disabled,
  onDuplicate,
  contentTypeSlug,
  embedLevel,
  isModalOpen = false,
  onSubmitForm,
  parentId,
  parentSlug,
  fieldSlugInParent,
  handleCloseItem,
  mainSourceId,
  rootUpdateInfo,
  canReview,
  isList,
  rootProjectId,
  hasChanges,
  handleShowChanges,
  isCompare,
  onlyDiff,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const confirm = useContext(ConfirmContext);
  const [formModalOpen, setFormModalOpen] = useState<boolean>(isModalOpen);
  const isNewItem = item.id === undefined && !item.__isDirty;
  const isNotSaved = !handleShowChanges && item.id === undefined && parentId !== '-1';

  const modalOpenCloseHandler = useCallback(
    (state: boolean) => {
      handleCloseItem && handleCloseItem(!item.__isModalOpen);
      setFormModalOpen(state);
    },
    [handleCloseItem, item]
  );

  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'card',
    hover(
      dragItem: Record<string, unknown> & { readonly type: 'card' },
      monitor: DropTargetMonitor
    ) {
      if (disabled) {
        return;
      }
      if (!ref.current) {
        return;
      }
      const dragIndex = dragItem.index as number;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard && moveCard(dragIndex, hoverIndex);
      dragItem.index = hoverIndex;
    },
  });

  const [{ opacity }, drag] = useDrag({
    item: { ...item, type: 'card', index },
    collect: (monitor) => ({
      opacity: !disabled && monitor.isDragging() ? 0 : 1,
    }),
  });

  drag(drop(ref));

  const duplicateConfirm = useCallback(() => {
    confirm({ rejectText: t('Cancel'), title: t('Embedded duplicate confirm') }).then(() => {
      onDuplicate && onDuplicate();
    });
  }, [confirm, onDuplicate, t]);

  const handleCloseFormModal = useCallback(
    (saveItem?: boolean) => {
      setFormModalOpen(false);
      handleCloseItem && handleCloseItem(saveItem);
    },
    [handleCloseItem]
  );

  useEffect(() => {
    const compareItem = document.getElementById(
      `${!isCompare}_${fieldSlugInParent}_${index}_${embedLevel}`
    );
    const currentItem = document.getElementById(
      `${!!isCompare}_${fieldSlugInParent}_${index}_${embedLevel}`
    );
    if (compareItem && currentItem) {
      const diff = compareItem.offsetHeight - currentItem.offsetHeight;
      if (diff > 0) {
        currentItem.style.marginBottom = diff + 8 + 'px';
      }
    }
  }, [embedLevel, fieldSlugInParent, index, isCompare]);

  useEffect(() => {
    setFormModalOpen(isModalOpen);
  }, [isModalOpen]);

  return (
    <div
      id={`${!!isCompare}_${fieldSlugInParent}_${index}_${embedLevel}`}
      ref={!disabled ? ref : undefined}
      style={{
        opacity,
        display: !onlyDiff || hasChanges ? 'block' : 'none',
      }}
      className={clsx(classes.root, hasChanges && classes.hasChanges)}
    >
      <header className={classes.cardHeader}>
        <div className={classes.cardTitle}>
          <Drag className={classes.sortButton} />
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            ID: {item.id}
          </Typography>
        </div>
        {!disabled && !canReview && (
          <div className={classes.actionButtons}>
            <IconButton style={{ padding: 4 }} onClick={() => setFormModalOpen(true)}>
              <PenFill />
            </IconButton>
            {isList && (
              <IconButton style={{ padding: 4 }} onClick={duplicateConfirm}>
                <DuplicateOutline onClick={duplicateConfirm} />
              </IconButton>
            )}
            <IconButton style={{ padding: 4 }} onClick={onRemoveItem}>
              <TrashOutline />
            </IconButton>
          </div>
        )}
        {canReview && (
          <IconButton style={{ padding: 4 }} onClick={() => setFormModalOpen(true)}>
            <Eye />
          </IconButton>
        )}
      </header>
      <Divider color="#D3D4D6" />
      <div>
        {handleShowChanges && (
          <div className={clsx(classes.firstRow, classes.diff)}>
            <span className={classes.itemId}>ID: {item.id || '-'}</span>
            <ButtonPelican onClick={handleShowChanges}>{t('Show changes')}</ButtonPelican>
          </div>
        )}
        <div
          className={clsx(
            classes.firstRow,
            classes.values,
            handleShowChanges && classes.comparable
          )}
        >
          <DisplayedValues
            projectId={rootProjectId}
            contentItem={{ ...item, id: !handleShowChanges ? item.id : '' }}
            fields={fields}
          />
        </div>
        {isNotSaved && <div className={classes.secondRow}>{t('Item is not saved yet')}</div>}
      </div>
      <Drawer
        anchor={'right'}
        open={formModalOpen}
        disableBackdropClick
        onClose={() => modalOpenCloseHandler(!formModalOpen)}
      >
        <div
          className={classes.embeddedModal}
          style={{ width: `${90 - embedLevel}vw`, minHeight: '100vh', height: '100%' }}
        >
          {formModalOpen && (
            <ContentEditor
              isEmbeddedItem
              contentTypeSlug={contentTypeSlug}
              embedLevel={embedLevel}
              contentItemInitValue={
                parentId !== '-1' && item.id ? rootUpdateInfo?.['$.saved'] && item : item
              }
              isReviewing={!!disabled}
              onEmbeddedFieldSave={onSubmitForm}
              isNewItem={isNewItem}
              onCloseButtonClick={handleCloseFormModal}
              parentId={parentId}
              parentSlug={parentSlug}
              fieldSlugInParent={fieldSlugInParent}
              mainSourceId={mainSourceId}
              sourceId={item.id && parentId !== '-1' ? String(item.id) : '-1'}
              rootUpdateInfo={rootUpdateInfo || { '$.indexInField': index }}
              rootProjectId={rootProjectId}
            />
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default ContentEmbeddedItem;
