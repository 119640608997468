import request from 'common/utils/axios-request';

import { IntegrationFieldResult } from './types/IntegrationFieldResult';
import { IntegrationItem } from './types/IntegrationItem';
import { PagedXCount } from './types/PagedXCount';

export function integrationsGet(
  page = 1,
  size = 1000,
  sortBy = 'id:desc'
): Promise<PagedXCount<IntegrationItem>> {
  return request('/api/v1/integrations', {
    params: { page, size, sortBy },
  }).then(({ data, headers }) => ({
    list: data,
    total: parseInt(headers['x-total-count']),
  }));
}

export function integrationsPost(data: IntegrationItem): Promise<IntegrationItem> {
  return request('/api/v1/integrations', {
    method: 'POST',
    data,
  }).then(({ data }) => data as IntegrationItem);
}

export function integrationsPut(data: IntegrationItem): Promise<IntegrationItem> {
  return request('/api/v1/integrations', {
    method: 'PUT',
    data,
  }).then(({ data }) => data as IntegrationItem);
}

export function integrationsGetById(id: number): Promise<IntegrationItem> {
  return request(`/api/v1/integrations/${id}`).then(({ data }) => data as IntegrationItem);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function integrationTestQuery(id: number, data: any): Promise<unknown> {
  return request(`/api/v1/integrations/${id}/actions/test`, {
    method: 'POST',
    data,
  }).then(({ data }) => data);
}

export function integrationQueryAndParse(
  contentType: string
): Promise<readonly IntegrationFieldResult[]> {
  return request(`/api/v1/content-types/${contentType}/integration/actions/request-and-parse`, {
    method: 'POST',
  }).then(({ data }) => {
    return data as readonly IntegrationFieldResult[];
  });
}

export function integrationDelete(id: number): Promise<unknown> {
  return request('/api/v1/integrations/' + id, { method: 'DELETE' }).then((res) => res);
}

export function integrationEnable(id: number): Promise<unknown> {
  return request(`/api/v1/integrations/${id}/actions/enable`, { method: 'PUT' }).then((res) => res);
}

export function integrationDisable(id: number): Promise<unknown> {
  return request(`/api/v1/integrations/${id}/actions/disable`, { method: 'PUT' }).then(
    (res) => res
  );
}
