import { Box } from '@material-ui/core';
import { integrationQueryAndParse } from 'api/integrations';
import { IntegrationFieldResult } from 'api/types/IntegrationFieldResult';
import { ButtonPelican } from 'components/ButtonPelican';
import { DataGrid, DataGridSettings, useDataGrid, WithGridContext } from 'components/DataGrid';
import ModalPelican from 'components/ModalPelican';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Column } from 'react-table';

import { Props } from './props';

const IntegrationFieldModal: React.FC<Props> = ({
  open,
  contentTypeSlug,
  onBeforeClose,
  close,
}) => {
  const [list, setList] = useState<readonly IntegrationFieldResult[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ReadonlyArray<Column<any>> = useMemo(
    () => Object.keys(list?.length ? list[0] : {}).map((l) => ({ Header: l, accessor: l })),
    [list]
  );
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { selectedRows } = useDataGrid();

  // const onSelectAll = useCallback(() => {
  //   setList(
  //     list.map(
  //       (item) => ({ ...item, checked: list.some((it) => !it.checked) } as IntegrationFieldResult)
  //     )
  //   );
  // }, [list]);

  // const handleRowClick = useCallback(
  //   (rowIndex: number) => {
  //     const item = list[rowIndex];
  //     const newList = isMultiSelect
  //       ? list.slice(0)
  //       : list.slice(0).map((l) => ({ ...l, checked: false } as IntegrationFieldResult));

  //     newList[rowIndex] = { ...item, checked: !item.checked } as IntegrationFieldResult;
  //     setList(newList);
  //   },
  //   [list, isMultiSelect]
  // );

  const handleSaveClick = (): void => {
    onBeforeClose(selectedRows);
    close();
  };

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await integrationQueryAndParse(contentTypeSlug);
      setList(data);
    } catch (e) {
      enqueueSnackbar(`${t('Error loading')} ${e.response?.data?.message || e}`, {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [contentTypeSlug, enqueueSnackbar, t]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <ModalPelican
      open={open}
      onClose={close}
      fullWidth
      maxWidth="md"
      title="Load from integration"
      footer={
        <>
          <ButtonPelican onClick={close} variant="text">
            {t('Cancel')}
          </ButtonPelican>
          <ButtonPelican onClick={handleSaveClick} variant="contained" color="primary">
            {t('Ok')}
          </ButtonPelican>
        </>
      }
    >
      <Box display="flex" justifyContent="flex-end" mb={1}>
        <DataGridSettings />
      </Box>
      <div>
        <DataGrid data={list} columns={columns} loading={loading} />
      </div>
    </ModalPelican>
  );
};

export default WithGridContext(IntegrationFieldModal, 'IntegrationFieldModal');
