import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  modalHeader: {
    fontSize: '20px',
    fontWeight: 500,
    padding: '24px',
    borderBottom: '2px solid #EBECF0',
  },
  modalBody: {
    padding: '20px 65px',
    borderBottom: '2px solid #EBECF0',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'end',
  },
  cancelButton: {
    width: 80,
    height: 32,
    background: 'white',
    color: '#42526E',
    fontSize: 14,
    border: 'none',
    cursor: 'pointer',
  },
  changeModalBody: {
    padding: '32px 16px',
    borderBottom: '2px solid #EBECF0',
    textAlign: 'center',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '25px 16px',
  },
  paper: {
    position: 'absolute',
    width: 631,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    outline: 'none',
  },
}));
