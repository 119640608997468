import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    position: 'absolute',
    width: 631,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  modalHeader: {
    fontSize: '20px',
    fontWeight: 500,
    padding: '24px',
    borderBottom: '2px solid #EBECF0',
  },
  modalBody: {
    padding: '20px 65px',
    borderBottom: '2px solid #EBECF0',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '25px 16px',
  },
  dateContainer: {
    display: 'flex',
  },
  timePicker: {
    width: '65%',
  },
  timePickerContainer: {
    display: 'flex',
    alignItems: 'center',
    background: '#f4f5f7',
    color: '#42526E',
  },
  publish: {
    marginRight: 16,
  },
  publishTypography: {
    height: '50px',
    fontWeight: 600,
    fontSize: '14px',
  },
  loader: {
    position: 'relative',
    width: '100%',
    height: 120,
  },
}));
