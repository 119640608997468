import { ContentItem } from 'api/types/ContentItem';
import { TextTypeField } from 'pages/ContentEditorPage/ContentEditor/ContentFormFields/ContentTextInput/TextTypeField/component';
import React, { useCallback, useEffect, useState } from 'react';

import { fieldToRules } from '../../helpers';
import { useContentEditorProvider } from '../../useContentEditorProvider';
import { Props } from './props';

export const ContentTextInput: React.FC<Props> = ({ field }) => {
  const {
    form,
    selectedCheckedLocales,
    multilingual,
    setMultilingualValue,
    handleFieldChange,
    contentItem,
    contentTypeSlug,
    changedFields,
  } = useContentEditorProvider();

  const { setValue, getValues, register } = form;

  const formVal = getValues(field.slug);
  const [inputValue, setInputValue] = useState<string>();

  const handleSetValue = useCallback(
    (val?: string) => {
      setInputValue(val || '');
      setValue(field.slug, val);
    },
    [field.slug, setValue]
  );

  const onChange = useCallback(
    (newMultilingual?: ContentItem, newVal?: string, locale?: string) => {
      if (field.multilingual) {
        setMultilingualValue(newMultilingual || {});
        handleFieldChange(`${locale}$${field.slug}`);
      } else {
        handleSetValue(newVal);
        handleFieldChange(field.slug);
      }
    },
    [field.multilingual, field.slug, handleFieldChange, handleSetValue, setMultilingualValue]
  );

  useEffect(() => {
    !field.multilingual && register(field.slug, fieldToRules(field));
  }, [field, register]);

  return (
    <TextTypeField
      multilingual={multilingual}
      value={inputValue === undefined ? formVal : inputValue}
      onChange={onChange}
      field={field}
      locales={selectedCheckedLocales}
      contentItem={contentItem}
      contentTypeSlug={contentTypeSlug}
      changedFields={changedFields}
    />
  );
};
