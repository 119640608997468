import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    background: '#fff',
    borderRadius: '3px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '40px',
    padding: '0 8px',
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  code: {
    marginLeft: '4px',
    color: '#0052CC',
    fontSize: '14px',
    lineHeight: '20px',
  },
  label: {
    marginLeft: '16px',
    fontSize: '14px',
    lineHeight: '20px',
  },
}));
