import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modal: {
    marginTop: 50,
    width: '80%',
    background: 'white',
  },
  modalHeader: {
    fontSize: '20px',
    fontWeight: 500,
    padding: '0px 24px',
    borderBottom: '2px solid #EBECF0',
  },
  modalBody: {
    padding: '12px',
    borderBottom: '2px solid #EBECF0',
  },
  modalFooter: {
    padding: '25px 16px',
  },
  editorButton: {
    padding: '8px 16px',
    fontSize: '14px',
    cursor: 'pointer',
    border: 'none',
    background: '#EBECF0',
    borderRadius: '3px',
  },
  saveButton: {
    height: 32,
    background: '#0052CC',
    color: 'white',
    fontSize: 14,
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  },
  clearButton: {
    marginRight: '12px',
    height: 32,
    background: '#0052CC',
    color: 'white',
    fontSize: 14,
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  },
  cancelButton: {
    height: 32,
    background: 'white',
    color: '#42526E',
    fontSize: 14,
    border: 'none',
    cursor: 'pointer',
  },
  mx8: {
    margin: '0 8px',
  },
  spinContainer: {
    position: 'relative',
    height: '100px',
  },
});
