import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const RequiredOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.25 5.75a.75.75 0 011.5 0v4.951l4.288-2.476a.75.75 0 01.75 1.3L13.5 12l4.288 2.476a.75.75 0 01-.75 1.298L12.75 13.3v4.951a.75.75 0 01-1.5 0v-4.951l-4.288 2.476a.75.75 0 01-.75-1.3L10.5 12 6.212 9.524a.75.75 0 01.75-1.299l4.288 2.476V5.75z"
      fill="#000"
    />
  </SvgIcon>
);
export default RequiredOutline;
