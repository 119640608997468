import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const paginationStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      order: 3,
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%)',
    },
    justifyCenter: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
    info: {
      flexShrink: 0,
      order: 4,
      position: 'absolute',
      right: 0,
    },
    rootSpacer: {
      marginRight: '100%',
    },
    button: {
      border: 'none',
    },
    ul: {
      '& button': {
        color: theme.palette.surface[700],
        height: 26,
        margin: '0px 3px',
        padding: '0 8px',
        minWidth: 21,
        borderRadius: 4,
        '& .Mui-disabled': {
          opacity: 0.4,
        },
      },
      '& button.Mui-selected': {
        backgroundColor: theme.palette.surface[100],
        border: `1px solid ${theme.palette.surface[300]}`,
        color: theme.palette.surface[900],
      },
      '& button svg.MuiPaginationItem-icon': {
        fontSize: '1.525rem',
      },
    },
  })
);
