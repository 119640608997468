import { IconButton } from '@material-ui/core';
import { Eye, EyeOutline, PlanetOutline } from 'components/IconPelican/icons';
import React from 'react';

import { LocaleProps } from './index';
import { useStyles } from './styles';

export const Locale: React.FC<LocaleProps> = ({ id, label, code, checked, onToggle }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <PlanetOutline fontSize="small" htmlColor="#0052CC" />
        <span className={classes.code}>{code}</span>
        <span className={classes.label}>{label}</span>
      </div>
      <IconButton size="small" onClick={() => onToggle && onToggle(id, checked)}>
        {checked ? <Eye htmlColor="#0052CC" /> : <EyeOutline htmlColor="#0052CC" />}
      </IconButton>
    </div>
  );
};
