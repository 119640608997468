import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
  defaultStyles: {
    padding: '6px 8px',
  },
  colorDefault: {
    background: '#FFFFFF',
    border: `1px solid ${theme.palette.surface[300]}`,
    color: theme.palette.surface[700],
    '&:hover': {
      background: theme.palette.surface[100],
    },
    '&:disabled': {
      background: theme.palette.surface[300],
    },
  },
  colorPrimary: {
    background: theme.palette.blue[500],
    color: '#fff',
    '&:hover': {
      background: theme.palette.blue[700],
    },
    '&:disabled': {
      background: theme.palette.blue[100],
    },
  },
  colorDanger: {
    backgroundColor: theme.palette.red[500],
    color: '#fff',
    '&:hover': {
      background: theme.palette.red[700],
    },
    '&:disabled': {
      background: theme.palette.red[100],
    },
  },
  colorSuccess: {
    background: theme.palette.green[500],
    color: '#fff',
    '&:hover': {
      background: theme.palette.green[700],
    },
    '&:disabled': {
      background: theme.palette.green[100],
    },
  },
  colorDark: {
    background: theme.palette.surface[900],
    color: '#fff',
    '&:hover': {
      background: '#545456',
    },
    '&:disabled': {
      background: theme.palette.surface[900],
      color: '#545456',
    },
  },
  variantTextColorPrimary: {
    backgroundColor: 'transparent',
    color: theme.palette.blue[700],
  },
  variantTextColorDanger: {
    backgroundColor: 'transparent',
    color: '#DE350B',
  },
  variantTextColorDefault: {
    backgroundColor: 'transparent',
    color: '#161618',
    fontWeight: 500,
  },
  variantSizeSmall: {
    height: 28,
    fontSize: 13,
  },
}));
