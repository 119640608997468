export type Status = {
  readonly code: string;
  readonly name: string;
};

export const statuses: ReadonlyArray<Status> = [
  {
    code: 'archived',
    name: 'Archived',
  },
  {
    code: 'draft',
    name: 'Draft',
  },
  {
    code: 'published',
    name: 'Published',
  },
  {
    code: 'reviewing',
    name: 'Reviewing',
  },
];

export type StatusChipType = {
  readonly archived: string;
  readonly draft: string;
  readonly published: string;
  readonly reviewing: string;
};

export const statusChipMapping: StatusChipType = {
  archived: 'disabled',
  draft: 'primary',
  published: 'success',
  reviewing: 'error',
};
