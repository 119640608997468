import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const TrashOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.75 4a.25.25 0 01.25-.25h2a.25.25 0 01.25.25v1.25h-2.5V4zm4 0v1.25h3.75a.75.75 0 010 1.5h-13a.75.75 0 010-1.5h3.75V4c0-.966.784-1.75 1.75-1.75h2c.966 0 1.75.784 1.75 1.75zM7.083 9.25a.25.25 0 00-.249.27l.84 10.5c.01.13.119.23.25.23H16.076c.13 0 .239-.1.249-.23l.84-10.5a.25.25 0 00-.25-.27H7.084zm-1.744.39a1.75 1.75 0 011.744-1.89h9.834a1.75 1.75 0 011.744 1.89l-.84 10.5a1.75 1.75 0 01-1.744 1.61H7.923a1.75 1.75 0 01-1.744-1.61l-.84-10.5zm4.099.863a.75.75 0 01.81.685l.5 6a.75.75 0 11-1.495.124l-.5-6a.75.75 0 01.685-.81zm5.124 0a.75.75 0 01.685.81l-.5 6a.75.75 0 11-1.494-.125l.5-6a.75.75 0 01.81-.685z"
      fill="currentColor"
    />
  </SvgIcon>
);
export default TrashOutline;
