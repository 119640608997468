import React from 'react';

import { Props } from './props';
import { useStyles } from './styles';

export const TableActions: React.FC<Props> = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.buttons}>{children}</div>
    </div>
  );
};
