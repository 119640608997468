import 'react-loading-skeleton/dist/skeleton.css';

import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import SnackbarProvider from 'containers/SnackbarProvider';
import { isAfter } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider as ReduxProvider } from 'react-redux';

import packageJson from '../package.json';
import { AxiosInterceptor } from './common/utils/axios-request';
import { ConfirmProvider } from './containers/ConfirmContext';
import { typeDefs } from './pages/ContentEditorPage/ContentEditor/ContentEditorRightBar/ContentItemDetails/types/Schema';
import MainRouter from './router/MainRouter';
import store from './store/store';
import { theme } from './theme/theme';

const baseApiUrl = process.env.BASE_API_URL;

const client = new ApolloClient({
  cache: new InMemoryCache(),
  uri: `${baseApiUrl || ''}/graphql`,
  typeDefs,
});

AxiosInterceptor(store);

const buildDateGreaterThan = (
  latestDate: string | number,
  currentDate: string | number
): boolean => {
  return isAfter(Number(latestDate), Number(currentDate));
};

function App(): React.ReactElement {
  const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      setIsLatestBuildDate(true);
    } else {
      fetch('/meta.json')
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const currentVersionDate = packageJson.buildDate || 0;

          const shouldForceRefresh = buildDateGreaterThan(latestVersionDate, currentVersionDate);
          if (shouldForceRefresh) {
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        });
    }
  }, []);

  const refreshCacheAndReload = (): void => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    // delete browser cache and hard reload
    window.location.reload();
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <ApolloProvider client={client}>
        <ReduxProvider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider>
              <ConfirmProvider>{isLatestBuildDate ? <MainRouter /> : null}</ConfirmProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </ReduxProvider>
      </ApolloProvider>
    </DndProvider>
  );
}

export default App;
