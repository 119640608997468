import { Typography } from '@material-ui/core';
import { ArrowRight, NetworkTreeOutline } from 'components/IconPelican/icons';
import { XYCoord } from 'dnd-core';
import React, { useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import { Props } from './props';
import { useStyles } from './styles';

export const SourceItem: React.FC<Props> = ({ name, moveCard, index, item, disabled, isLast }) => {
  const styles = useStyles();
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: 'sourceItem',
    hover(
      dragItem: Record<string, unknown> & { readonly type: 'sourceItem' },
      monitor: DropTargetMonitor
    ) {
      if (disabled) {
        return;
      }
      if (!ref.current) {
        return;
      }
      const dragIndex = dragItem.index as number;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // console.log(ref.current?.parentElement?.getBoundingClientRect());

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();

      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveCard && moveCard(dragIndex, hoverIndex);
      dragItem.index = hoverIndex;
    },
  });

  const [{ opacity }, drag] = useDrag({
    item: { ...item, type: 'sourceItem', index },
    collect: (monitor) => ({
      opacity: !disabled && monitor.isDragging() ? 0 : 1,
    }),
  });

  drag(drop(ref));

  return (
    <>
      <div ref={ref} style={{ opacity }} className={styles.draggable}>
        <div className={styles.root}>
          <div className={styles.icon}>
            <NetworkTreeOutline />
          </div>
          <div className={styles.description}>
            <Typography style={{ color: '#161618' }}>{name}</Typography>
          </div>
        </div>
      </div>
      {!isLast && (
        <div className={styles.arrowDown}>
          <ArrowRight className={styles.rotate} />
        </div>
      )}
    </>
  );
};

export default SourceItem;
