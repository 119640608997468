import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  contentActionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  smallWidth: {},
  contentActionButtons: {
    '&>*': {
      marginRight: 12,
    },
  },
  moreButton: {
    display: 'flex',
    alignSelf: 'center',
    padding: 0,
  },
  tree: {
    padding: 6,
    backgroundColor: '#ECEDF0',
    borderRadius: 3,
    marginRight: 8,
  },
}));
