import Pagination from '@material-ui/lab/Pagination';
import clsx from 'clsx';
import { getCorrectTranslate } from 'common/utils/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from './props';
import { paginationStyles } from './styles';

export const TablePaginationActions: React.FC<Props> = ({
  pageCount,
  page,
  handleChangePageTablePagination,
  rowsCount,
  inModal,
}) => {
  const classes = paginationStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={clsx(classes.root, inModal && classes.justifyCenter)}>
        <Pagination
          count={pageCount}
          size="small"
          classes={{
            ul: classes.ul,
          }}
          onChange={handleChangePageTablePagination}
          page={page}
        />
      </div>
      {!inModal && (
        <div className={classes.info}>
          {rowsCount} {t(getCorrectTranslate('item', rowsCount))} / {pageCount}{' '}
          {t(getCorrectTranslate('page', pageCount))}
        </div>
      )}
    </>
  );
};
