import { Typography } from '@material-ui/core';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { Schedule } from 'api/types/ContentItem';
import { ButtonPelican } from 'components/ButtonPelican';
import { Datepicker } from 'components/Datepicker';
import { Timepicker } from 'components/Timepicker';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from './props';
import { useStyles } from './styles';

export const ScheduleModal: React.FC<Props> = ({
  onConfirm,
  onClose,
  approveDate,
  archiveDate,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const currentDate = new Date();
  const [publishSchedule, setPublishSchedule] = useState<Schedule | undefined>(undefined);
  const [unpublishSchedule, setUnpublishSchedule] = useState<Schedule | undefined>(undefined);
  // const [loadingResetSchedule, setLoadingResetSchedule] = useState(false);

  const onPublishDatePicked = useCallback((date) => {
    setPublishSchedule({ gmt: '+06:00', time: date, date: date });
    setUnpublishSchedule(undefined);
  }, []);

  const onPublishTimePicked = useCallback((time) => {
    setPublishSchedule({ gmt: '+06:00', time: time, date: time });
  }, []);

  const onUnpublishDatePicked = useCallback((date) => {
    setUnpublishSchedule({ gmt: '+06:00', time: date, date: date });
  }, []);

  const onUnpublishTimePicked = useCallback((time) => {
    setUnpublishSchedule({ gmt: '+06:00', time: time, date: time });
  }, []);

  const handleConfirmSchedule = useCallback(() => {
    onConfirm(publishSchedule, unpublishSchedule);
  }, [publishSchedule, unpublishSchedule, onConfirm]);

  useEffect(() => {
    if (approveDate) {
      const schedule = {
        time: approveDate,
        date: approveDate,
        gmt: '+06:00',
      };
      setPublishSchedule(schedule);
    }
    if (archiveDate) {
      const schedule = {
        time: archiveDate,
        date: archiveDate,
        gmt: '+06:00',
      };
      setUnpublishSchedule(schedule);
    }
  }, [archiveDate, approveDate]);

  return (
    <div className={styles.paper}>
      <div className={styles.modalHeader}>
        <Typography variant="h3">{t('Schedule publish')}</Typography>
      </div>
      <div className={styles.modalBody}>
        <div className={styles.dateContainer}>
          <div className={styles.publish}>
            <Typography className={styles.publishTypography}>{t('Publish Date')}</Typography>
            <div className={styles.dateContainer}>
              <Datepicker
                onChangeDate={onPublishDatePicked}
                date={publishSchedule ? publishSchedule.date : currentDate}
                dateFormat="dd/MM/yyyy"
                minDate={currentDate}
              />
              <div className={styles.timePickerContainer}>
                <Timepicker
                  className={styles.timePicker}
                  onChangeTime={onPublishTimePicked}
                  time={publishSchedule ? publishSchedule.time : currentDate}
                  minDate={currentDate}
                />
                <DateRangeIcon />
              </div>
            </div>
          </div>
          <div>
            <Typography className={styles.publishTypography}>
              {t('Unpublish date optional')}
            </Typography>
            <div className={styles.dateContainer}>
              <Datepicker
                placeholderText="DD/MM/YYYY"
                onChangeDate={onUnpublishDatePicked}
                dateFormat="dd/MM/yyyy"
                date={unpublishSchedule && unpublishSchedule.date}
                minDate={publishSchedule?.date}
              />
              <div className={styles.timePickerContainer}>
                <Timepicker
                  className={styles.timePicker}
                  placeholderText="HH:MM"
                  onChangeTime={onUnpublishTimePicked}
                  time={unpublishSchedule && unpublishSchedule.time}
                />
                <DateRangeIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.modalFooter}>
        <div>
          <ButtonPelican
            disabled={!publishSchedule && !unpublishSchedule}
            onClick={handleConfirmSchedule}
            variant="contained"
            color="primary"
          >
            {t('Confirm')}
          </ButtonPelican>
          <ButtonPelican variant="text" color="primary" onClick={() => onClose()}>
            {t('Cancel')}
          </ButtonPelican>
        </div>
      </div>
    </div>
  );
};
