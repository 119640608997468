import { useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import clsx from 'clsx';
import { Spin } from 'components/Spin/component';
import NoData from 'containers/NoData/NoData';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NoDiff } from '../../NoDiff/NoDiff';
import { useReleasesContext } from '../ReleasesProvider';
import { DiffField } from '../types/DiffModels';
import { DiffFieldsParam } from '../types/DiffParam';
import { DIFF_FIELDS } from './queries';
import { useStyles } from './styles';

export const ModelFieldList: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { envFrom, envTo, selectedSlugId } = useReleasesContext();
  const [noDiff, setNoDiff] = useState(false);
  const { data, loading } = useQuery<
    { readonly contentTypeFieldsDiff: readonly DiffField[] },
    DiffFieldsParam
  >(DIFF_FIELDS, {
    variables: {
      leftEnvId: envFrom || '',
      rightEnvId: envTo || '',
      contentTypeId: selectedSlugId || 0,
    },
    skip: !(envFrom && envTo && selectedSlugId),
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data?.contentTypeFieldsDiff?.length === 0) {
      setNoDiff(true);
    } else {
      setNoDiff(false);
    }
  }, [data]);

  return (
    <Box className={styles.container}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography style={{ fontWeight: 'bold' }} variant={'h3'}>
          {t('Поля модели')}
        </Typography>
        <MoreHorizIcon cursor="pointer" className={styles.moreButton} />
      </Box>
      <div className={styles.fields}>
        {loading && <Spin />}
        {!data?.contentTypeFieldsDiff?.length && !loading && !noDiff && <NoData />}
        {noDiff && <NoDiff />}
        {data?.contentTypeFieldsDiff?.map((l) => (
          <div
            key={l.id}
            className={clsx(
              styles.item,
              l.state === 'NEW' && styles.newField,
              l.state === 'DELETED' && styles.deletedField,
              l.state === 'EDITED' && styles.editedField
            )}
          >
            <Box display={'flex'} justifyContent={'space-between'} p={2} width={'100%'}>
              <div>{l.label}</div>
              <div
                className={clsx(
                  l.state === 'NEW' && styles.newFieldState,
                  l.state === 'DELETED' && styles.deletedFieldState,
                  l.state === 'EDITED' && styles.editedFieldState
                )}
              >
                {l.state === 'PUBLISHED' && '-'}
                {l.state === 'NEW' && t('New')}
                {l.state === 'DELETED' && t('Deleted')}
                {l.state === 'EDITED' && t('Edited')}
              </div>
            </Box>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default ModelFieldList;
