import { SvgIcon, SvgIconProps } from '@material-ui/core';
import * as React from 'react';

export const ArrowRightPrimaryIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path d="m23 12-8.25 6.929V5.072L23 12.001ZM1.5 10v4h13.25v-4H1.5Z" fill="#0039D4" />
  </SvgIcon>
);

export default ArrowRightPrimaryIcon;
