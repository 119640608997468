import { gql } from '@apollo/client';

export const ENVIRONMENTS_GET = gql`
  query environments {
    publicationEnvironments {
      id
      name
      priority
    }
  }
`;
