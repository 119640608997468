import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ArrowDoubleOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.816 19.198a1 1 0 01-1.414.018l-6.216-6.064-.03-.037a6.441 6.441 0 00-.107-.122l-.006-.007a4.68 4.68 0 01-.116-.133l-.003-.004a1.35 1.35 0 01-.312-.665c-.06-.418.117-.736.237-.905a3.04 3.04 0 01.373-.41l6.176-6.082a1 1 0 011.404 1.426l-5.905 5.814 5.901 5.757a1 1 0 01.018 1.414z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.416 19.198a1 1 0 01-1.414.018l-6.216-6.064-.03-.037a5.945 5.945 0 00-.107-.122l-.006-.007a4.577 4.577 0 01-.116-.133l-.003-.004a1.351 1.351 0 01-.312-.665c-.06-.418.117-.736.237-.905a3.04 3.04 0 01.373-.41l6.176-6.082a1 1 0 111.404 1.426l-5.905 5.814 5.901 5.757a1 1 0 01.018 1.414z"
    />
  </SvgIcon>
);
export default ArrowDoubleOutline;
