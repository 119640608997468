import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { ArrowDoubleOutline } from 'components/IconPelican/icons';

import { Props } from './props';

const useStyles = makeStyles(() => ({
  root: ({ variant }: { readonly variant: 'left' | 'right' }) => ({
    color: '#8F8F91',
    width: 20,
    height: 18,
    cursor: 'pointer',
    transform: variant === 'left' ? undefined : 'rotate(180deg)',
  }),
  rotate: ({ variant }: { readonly variant: 'left' | 'right' }) => ({
    transform: variant === 'left' ? 'rotate(180deg)' : 'rotate(360deg)',
  }),
}));

export const ToggleMenu: React.FC<Props> = ({ value, toggle, className, variant = 'left' }) => {
  const styles = useStyles({ variant });
  return (
    <ArrowDoubleOutline
      onClick={toggle}
      className={clsx(styles.root, value && styles.rotate, className && className)}
    />
  );
};
