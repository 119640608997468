import { SelectItem, SelectPelican } from 'components/SelectPelican';
import { SelectVariant } from 'components/SelectPelican/types/SelectVariant';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const LanguageSelect: React.FC = () => {
  const { i18n } = useTranslation();

  const options = useMemo(
    () => [
      { label: 'en', value: 'en' },
      { label: 'ru', value: 'ru' },
      { label: 'kk', value: 'kk' },
    ],
    []
  );
  const handleLanguageChange = (e: SelectItem): void => {
    i18n.changeLanguage(e.value as string);
    localStorage.setItem('i18nextLng', e.value as string);
  };

  return (
    <div style={{ width: '60px' }}>
      <SelectPelican
        id="languageSelect"
        theme="dark"
        variant={SelectVariant.Text}
        options={options}
        value={options.find((o) => o.value === i18n.language)}
        selected={(val) => handleLanguageChange(val as SelectItem)}
      />
    </div>
  );
};

export default LanguageSelect;
