import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const MinusCircleFill: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21a9 9 0 100-18 9 9 0 000 18zm-4-9.75a.75.75 0 000 1.5h8a.75.75 0 000-1.5H8z"
      fill="#8F8F91"
    />
  </SvgIcon>
);
export default MinusCircleFill;
