import { GlobalFilter, SearchGlobalType } from 'api/types/SearchGlobal';
import request from 'common/utils/axios-request';

export function searchGlobal(
  params: GlobalFilter
): Promise<{ readonly list: ReadonlyArray<SearchGlobalType>; readonly totalCount: number }> {
  return request(`/api/v1/global-search`, { params }).then(({ data, headers }) => ({
    list: data,
    totalCount: Number(headers['x-total-count']),
  }));
}
