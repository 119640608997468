import { Box, Popover, Typography } from '@material-ui/core';
import { BellOutline, FolderLineOutline, Search } from 'components/IconPelican/icons';
import Workspace from 'containers/Workspace';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getContentTabs } from 'store/contentTabsSlice';
import { getUserInfo } from 'store/userInfoSlice';
import ContentInfo from 'templates/MainTemplate/TopNav/ContentNavigation';
import UserInfo from 'templates/MainTemplate/TopNav/UserInfo';

import ContentTabs from './ContentTabs/component';
import LanguageSelect from './LanguageSelect';
import { Props } from './props';
import { Shortcuts } from './Shortcuts/Shortcuts';
import { useStyles } from './styles';

export const TopNav: React.FC<Props> = ({ contentNavigationOn, sidebarOn, showSearch }) => {
  const classes = useStyles({ sidebarOn });
  const { t } = useTranslation();
  const user = useSelector(getUserInfo);
  const tabs = useSelector(getContentTabs);

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [open, setOpen] = React.useState<boolean>(contentNavigationOn);
  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setOpen(false);
    setAnchorEl(null);
  };

  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setOpen(Boolean(anchorEl));
  }, [anchorEl]);

  useEffect(() => {
    setOpen(contentNavigationOn);
  }, [contentNavigationOn]);

  return (
    <div className={classes.toolbar}>
      <div className={classes.workspace}>
        <Workspace isToggle={sidebarOn} />
      </div>
      <div className={classes.innerContainer}>
        <div className={classes.notifications}>
          {tabs?.length > 0 && (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div
                className={classes.openButton}
                style={{ margin: '0 12px 0 22px' }}
                onClick={() => showSearch()}
              >
                <Search />
              </div>
            </>
          )}
          {tabs?.length === 0 && (
            <>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div className={classes.globalSearch} onClick={() => showSearch()}>
                <Search className={classes.searchIcon} />
                <Typography className={classes.globalSearchText}>{t('Global Search')}</Typography>
              </div>
            </>
          )}
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div className={classes.openButton} onClick={handleClick}>
            <FolderLineOutline style={{ fill: '#fff' }} />
          </div>
        </div>
        <div className={classes.contentTabs}>
          <ContentTabs />
        </div>
        <div className={classes.userInfo}>
          <div className={classes.notifications}>
            <div className={classes.iconContainer}>
              <BellOutline style={{ fill: '#fff', color: '#fff' }} />
            </div>
            <Shortcuts />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{ root: classes.popoverRoot, paper: classes.popover }}
            disableScrollLock={true}
          >
            <ContentInfo onClose={handleClose} />
          </Popover>
          <div className={classes.userInfoWrapper}>
            <Box width={56} mr={2}>
              <LanguageSelect />
            </Box>
            <UserInfo name={user.userName} role={user.role} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNav;
