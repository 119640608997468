import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const LockerOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.737 12.498c-.036.432-.037 1-.037 1.88v1.792c0 .88.001 1.447.037 1.88.034.413.093.566.132.641a1.5 1.5 0 00.64.64c.075.04.228.098.642.132.432.036 1 .037 1.879.037h3.94c.88 0 1.447-.001 1.88-.037.413-.035.566-.093.641-.132a1.5 1.5 0 00.64-.64c.04-.075.098-.228.132-.642.036-.432.037-1 .037-1.879v-1.793c0-.88-.001-1.447-.037-1.879-.034-.414-.093-.567-.132-.642a1.5 1.5 0 00-.64-.64c-.075-.039-.228-.097-.642-.132-.432-.035-1-.037-1.879-.037h-3.94c-.88 0-1.447.002-1.88.037-.413.035-.566.093-.641.132a1.5 1.5 0 00-.64.64c-.04.075-.098.228-.132.642zM5.2 14.378c0-1.71 0-2.563.338-3.214a3 3 0 011.28-1.279c.65-.338 1.504-.338 3.212-.338h3.94c1.708 0 2.563 0 3.213.338a3 3 0 011.28 1.28c.337.65.337 1.504.337 3.212v1.793c0 1.708 0 2.563-.338 3.213a3 3 0 01-1.28 1.28C16.533 21 15.68 21 13.97 21h-3.94c-1.708 0-2.563 0-3.213-.338a3 3 0 01-1.28-1.28c-.337-.65-.337-1.504-.337-3.212v-1.793z"
      fill="#000"
    />
    <path d="M13.5 15.274a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" fill="#000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3.015h.018c1.096.027 2.153.387 2.967 1.125.821.743 1.341 1.82 1.445 3.17l.002.028v2.827h-1.5V7.397c-.083-1.002-.456-1.695-.953-2.145-.504-.457-1.191-.714-1.979-.736-.787.022-1.473.28-1.977.735-.497.45-.87 1.144-.953 2.146v2.768h-1.5V7.338l.002-.028c.104-1.35.623-2.427 1.444-3.17.814-.738 1.87-1.098 2.966-1.124H12z"
      fill="#000"
    />
  </SvgIcon>
);
export default LockerOutline;
