import { formatDate, parseDate } from 'common/utils/date';
import { Datepicker } from 'components/Datepicker';
import { isDate } from 'date-fns';
import { fieldToRules } from 'pages/ContentEditorPage/ContentEditor/helpers';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { useContentEditorProvider } from '../../useContentEditorProvider';
import { Props } from './props';

export const ContentDateInput: React.FC<Props> = ({ field }) => {
  const { form, embedLevel, isReviewing, handleFieldChange } = useContentEditorProvider();

  const { control, setValue, getValues } = form;

  const val = getValues(field.slug);

  const inputValue = useMemo(() => {
    return isDate(val) ? val : parseDate(val);
  }, [val]);

  return (
    <Controller
      name={field.slug}
      as={Datepicker}
      id={`${field.slug}-${embedLevel}`}
      control={control}
      disabled={isReviewing}
      onChangeDate={(date?: Date) => {
        setValue(field.slug, formatDate(date));
        handleFieldChange(field.slug);
      }}
      dateFormat="dd/MM/yyyy"
      placeholderText="DD/MM/YYYY"
      withIcon
      defaultValue={inputValue}
      date={inputValue}
      rules={fieldToRules(field)}
    />
  );
};
