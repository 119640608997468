import { createSlice } from '@reduxjs/toolkit';
import { ContentTabType } from 'api/types/ContentTab';
import { Action } from 'redux';

import { ContentTabsState } from './types/content-tabs-state';

const initialState: ContentTabsState = {
  tabs: [],
};

const contentTabsSlice = createSlice({
  name: 'contentTabs',
  initialState,
  reducers: {
    setNewContentTabs(state, action: Action & { readonly payload: ReadonlyArray<ContentTabType> }) {
      localStorage.setItem('content_tabs', JSON.stringify(action.payload.slice(0, 20)));
      return {
        ...state,
        ...{ tabs: action.payload.slice(0, 20) },
      };
    },
  },
});

export const { setNewContentTabs } = contentTabsSlice.actions;

export const { getContentTabs } = {
  getContentTabs(state: { readonly contentTabs: ContentTabsState }) {
    return state.contentTabs.tabs;
  },
};

export default contentTabsSlice.reducer;
