import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '2px solid #DFE1E6',
    background: '#F4F5F7',
    color: '#42526E',
    borderRadius: 3,
  },
  flex: {
    display: 'flex',
  },
  calendarIcon: {
    marginRight: 10,
  },
});
