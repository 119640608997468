import React, { useContext, useState } from 'react';

import { PublishItemDto } from './types/PublishItemDto';

export type ReleaseContent = {
  readonly contentTypeSlug: number;
  readonly publishAll: boolean;
  readonly sourceIds: readonly number[];
};
export type ReleasesContextState = {
  readonly selectedSlugId?: number;
  readonly leftSlug?: string;
  readonly rightSlug?: string;
  readonly selectedContentName?: string;
  readonly publishItems: readonly PublishItemDto[];
  readonly envFrom?: string;
  readonly envTo?: string;
} & {
  readonly setSelectedSlug: (val?: number) => void;
  readonly setLeftSlug: (val?: string) => void;
  readonly setRightSlug: (val?: string) => void;
  readonly setEnvFrom: (val?: string) => void;
  readonly setEnvTo: (val?: string) => void;
  readonly setSelectedContentName: (val?: string) => void;
  readonly setPublishItems: React.Dispatch<React.SetStateAction<readonly PublishItemDto[]>>;
  readonly resetRelease: (onlyModels?: boolean) => void;
};

export const ReleasesContext = React.createContext<ReleasesContextState>(
  {} as ReleasesContextState
);

ReleasesContext.displayName = 'ReleasesContextProvider';

export const ReleasesProvider: React.FC = ({ children }) => {
  const [selectedSlug, setSelectedSlug] = useState<number>();
  const [leftSlug, setLeftSlug] = useState<string>();
  const [rightSlug, setRightSlug] = useState<string>();
  const [selectedContentName, setSelectedContentName] = useState<string>();
  const [publishItems, setPublishItems] = useState<readonly PublishItemDto[]>([]);
  const [envFrom, setEnvFrom] = useState<string>();
  const [envTo, setEnvTo] = useState<string>();

  const resetRelease = (isOnlyModel?: boolean): void => {
    console.log('onlyModels: ', isOnlyModel);
    setSelectedSlug(undefined);
    setSelectedContentName(undefined);
    setPublishItems([]);
  };

  return (
    <ReleasesContext.Provider
      value={{
        envFrom,
        envTo,
        selectedSlugId: selectedSlug,
        setSelectedSlug,
        selectedContentName,
        setSelectedContentName,
        publishItems,
        setPublishItems,
        setEnvFrom,
        setEnvTo,
        resetRelease,
        leftSlug,
        setLeftSlug,
        rightSlug,
        setRightSlug,
      }}
    >
      {children}
    </ReleasesContext.Provider>
  );
};

export const useReleasesContext = (): React.ContextType<typeof ReleasesContext> => {
  const context = useContext(ReleasesContext);

  return context;
};

export default ReleasesProvider;
