import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ArrowSmallOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path d="M16.118 9.64c0 .14-.087.253-.202.4l-3.195 4.356c-.219.296-.431.397-.721.397s-.502-.101-.72-.397l-3.196-4.355c-.11-.152-.202-.264-.202-.405 0-.26.3-.429.77-.429l6.696.004c.47 0 .77.17.77.43z" />
    <path d="M16.118 9.64c0 .14-.087.253-.202.4l-3.195 4.356c-.219.296-.431.397-.721.397s-.502-.101-.72-.397l-3.196-4.355c-.11-.152-.202-.264-.202-.405 0-.26.3-.429.77-.429l6.696.004c.47 0 .77.17.77.43z" />
  </SvgIcon>
);
export default ArrowSmallOutline;
