import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  breadcrumbs: {
    fontSize: 12,
    fontWeight: 600,
    display: 'flex',
    color: '#8F8F91',
    listStyle: 'none',
    margin: '8px 0 0 20px',
    paddingTop: 8,
    marginBlockStart: 0,
    marginBlockEnd: 0,
    paddingLeft: 0,
  },
  breadcrumbItem: {
    textDecoration: 'none',
    color: '#8F8F91',
  },
  lastItem: {
    color: '#545456',
  },
  delimiter: {
    margin: '0 8px',
  },
});
