import { useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { usePostApi } from 'common/hooks/useApiQuery';
import { addUserHeaders } from 'common/utils/axios-utils';
import { DropdownButton } from 'components/DropdownButton';
import { ArrowRightPrimaryIcon } from 'components/IconPelican/icons';
import { SelectItem, SelectPelican } from 'components/SelectPelican';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserInfo } from 'store/userInfoSlice';

import { EnvironmentItemDto } from '../../types/EnvironmentItemDto';
import { ENVIRONMENTS_GET } from '../EnvironmentList/queries';
import ModelDiff from './ModelDiff/ModelDiff';
import ModelFieldList from './ModelFieldList/ModelFieldList';
import ModelList from './ModelList/ModelList';
import { ENVIRONMENT_DIFF } from './queries';
import ReleasesProvider, { useReleasesContext } from './ReleasesProvider';
import { useStyles } from './styles';
import { DiffEnvResult } from './types/DiffModels';
import { DiffParam } from './types/DiffParam';
import { PublishRootSnackDto } from './types/publishRootDto';

const Realises: React.FC = () => {
  const styles = useStyles();
  const { data, error } = useQuery<{
    readonly publicationEnvironments: readonly EnvironmentItemDto[];
  }>(ENVIRONMENTS_GET);
  const { publicationEnvironments } = data || {};
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { setEnvFrom, setEnvTo, publishItems, resetRelease } = useReleasesContext();
  const [source, setSource] = useState<SelectItem | null>();
  const [target, setTarget] = useState<SelectItem | null>();
  const sourceId = source?.value?.toString() || '';
  const targetId = target?.value?.toString() || '';
  const sourcePriority = useMemo(
    () => publicationEnvironments?.find((e) => e.id?.toString() === sourceId?.toString())?.priority,
    [publicationEnvironments, sourceId]
  );
  const user = useSelector(getUserInfo);
  const headers = useMemo(() => addUserHeaders(user.userName, user.userId), [user]);

  const { post, loading: postLoading } = usePostApi<PublishRootSnackDto>('/content');

  const sourceEnvironments = useMemo(
    () => publicationEnvironments?.map((r) => ({ value: r.id, label: r.name })) || [],
    [publicationEnvironments]
  );

  const targetEnvironments = useMemo(
    () =>
      publicationEnvironments
        ?.filter(
          (e) =>
            sourcePriority !== undefined && !isNaN(sourcePriority) && e.priority > sourcePriority
        )
        .map((r) => ({ value: r.id, label: r.name })) || [],
    [publicationEnvironments, sourcePriority]
  );

  const { data: contentData, refetch, loading, error: diffError } = useQuery<
    { readonly contentTypeDiff: readonly DiffEnvResult[] },
    DiffParam
  >(ENVIRONMENT_DIFF, {
    variables: {
      leftEnvId: sourceId,
      rightEnvId: targetId,
      contentTypeIds: [],
    },
    skip: !(sourceId && targetId && sourceId !== targetId),
    fetchPolicy: 'network-only',
  });

  const loadDiff = useCallback(async () => {
    if (sourceId && targetId && sourceId !== targetId) {
      resetRelease(true);
      await refetch({ leftEnvId: sourceId, rightEnvId: targetId, contentTypeIds: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceId, targetId, refetch]);

  const handleDeploy = useCallback(
    async (withAllContents = false, onlyModels = false) => {
      try {
        const priorityFrom = publicationEnvironments?.find((p) => p.id === sourceId)?.priority;
        const priorityTo = publicationEnvironments?.find((p) => p.id === targetId)?.priority;
        await post(
          {
            env_from: sourceId,
            env_to: targetId,
            contents: publishItems.map((p) => ({
              content_type_id: p.contentTypeId,
              publish_all: withAllContents,
              source_ids: withAllContents || onlyModels ? [] : p.sourceIds,
            })),
            priority_from: priorityFrom || 0,
            priority_to: priorityTo || 0,
          },
          { baseURL: '/publication', headers }
        );
        enqueueSnackbar(t('Model and content successfully published'), { variant: 'success' });
        resetRelease(onlyModels);
        await refetch();
      } catch (e) {
        enqueueSnackbar(`${e.response?.data?.message || e}`, {
          variant: 'error',
        });
      }
    },
    [
      sourceId,
      targetId,
      publishItems,
      enqueueSnackbar,
      post,
      t,
      publicationEnvironments,
      resetRelease,
      refetch,
      headers,
    ]
  );

  useEffect(() => {
    setEnvFrom(sourceId);
    setEnvTo(targetId);
  }, [sourceId, targetId, setEnvFrom, setEnvTo]);

  useEffect(() => {
    error &&
      enqueueSnackbar(`${t('Error loading environments')} ${error.message}`, {
        variant: 'error',
      });
  }, [enqueueSnackbar, error, t]);

  useEffect(() => {
    diffError &&
      enqueueSnackbar(`${t('Error loading environments diff')} ${diffError.message}`, {
        variant: 'error',
      });
  }, [enqueueSnackbar, diffError, t]);

  useEffect(() => {
    loadDiff();
  }, [loadDiff]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.environments}>
          <label className={styles.label}>{t('Choose environments')}</label>
          <Box display={'flex'}>
            <Box width={'150px'}>
              <SelectPelican
                placeholder={t('Choose')}
                isLoading={loading}
                id={'env-source'}
                value={source}
                selected={(val) => {
                  setSource(val as SelectItem);
                  setTarget(null);
                }}
                options={sourceEnvironments}
                isMulti={false}
                isClearable
              />
            </Box>
            <Box width={'50px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
              <ArrowRightPrimaryIcon />
            </Box>
            <Box width={'150px'}>
              <SelectPelican
                placeholder={t('Choose')}
                isLoading={loading}
                key={targetId}
                id={'env-target'}
                options={targetEnvironments}
                selected={(val) => setTarget(val as SelectItem)}
                value={target}
                isClearable
                isMulti={false}
              />
            </Box>
          </Box>
        </div>
        <Box display={'flex'} alignItems={'center'}>
          {publishItems?.length > 0 && (
            <Typography
              variant={'body2'}
              className={styles.label}
              style={{ marginRight: '30px' }}
            >{`${t('Models')} ${publishItems?.length}, ${t('Contents')} ${
              publishItems?.flatMap((p) => p.sourceIds)?.length
            }`}</Typography>
          )}
          <DropdownButton
            variant={'contained'}
            onClick={() => handleDeploy(false)}
            label={target?.label ? `${t('Deploy to')} ${target?.label}` : t('Deploy')}
            loading={postLoading}
            disabled={postLoading || !(sourceId && targetId) || !publishItems?.length}
            items={[
              {
                id: 'deploy_with_all_contents',
                label: t('Deploy with all contents'),
                onClick: () => handleDeploy(true),
              },
              {
                id: 'deploy_only_models',
                label: t('Deploy only models'),
                onClick: () => handleDeploy(false, true),
              },
            ]}
            color={'primary'}
          />
        </Box>
      </div>
      <Box display={'flex'} height={'100%'} alignItems={'stretch'}>
        <ModelList list={contentData?.contentTypeDiff} loading={loading} />
        <ModelDiff />
        <ModelFieldList />
      </Box>
    </div>
  );
};
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,react/display-name
export const Releases: React.FC = () => (
  <ReleasesProvider>
    <Realises />
  </ReleasesProvider>
);
export default Releases;
