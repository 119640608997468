import request from 'common/utils/axios-request';

import { RawRole } from './types/Role';

type LoginInput = {
  readonly username: string;
  readonly password: string;
};

type LoginResult = {
  readonly authorities: readonly RawRole[];
  readonly enabled: boolean;
  readonly id: number;
  readonly need_to_change_password: boolean;
  readonly username: string;
};
export function loginPost(data: LoginInput): Promise<LoginResult> {
  const formData = new FormData();
  Object.keys(data).forEach((key) => formData.append(key, data[key as keyof LoginInput]));
  return request('/api/v1/login', {
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(({ data }) => data as LoginResult);
}

export function userLogout(): Promise<unknown> {
  return request('/api/v1/logout', { method: 'POST', data: {} }).then((res) => res);
}
