import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const DifferenceIcon: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      d="M11 4L11 13L20 13"
      stroke="#0039D4"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="transparent"
    />
  </SvgIcon>
);
export default DifferenceIcon;
