import { Box, Typography } from '@material-ui/core';
import { ButtonPelican } from 'components/ButtonPelican';
import { Space } from 'components/Space';
import { SwitchButtonGroup } from 'components/SwitchButtonGroup';
import { SwitchButton } from 'components/SwitchButtonGroup/SwitchButton';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { usePageStyles } from 'theme/page-styles';

import EnvironmentList from './components/EnvironmentList/EnvironmentList';
import Releases from './components/Realises/Realises';
import { EnvType, Props } from './props';

export const Environments: React.FC<Props> = ({ type }) => {
  const pageStyles = usePageStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [envType, setEnvType] = useState<EnvType>(type);

  const isEnvironment = useMemo(() => envType === 'environment', [envType]);
  const isRealise = useMemo(() => envType === 'release', [envType]);

  const handleEnvClick = (type: EnvType): void => {
    setEnvType(type);
  };

  return (
    <div className={pageStyles.container}>
      <Box display="flex" justifyContent={'space-between'} paddingBottom={0}>
        <Typography variant="h4" className={pageStyles.heading}>
          {isRealise && t('Environments and releases')}
          {isEnvironment && t('Environments')}
        </Typography>
        <Space>
          <SwitchButtonGroup
            exclusive
            value={envType}
            handleChangeValue={(val) => handleEnvClick(val as EnvType)}
          >
            <SwitchButton value="release">{t('Content management')}</SwitchButton>
            <SwitchButton value="environment">{t('Environments')}</SwitchButton>
          </SwitchButtonGroup>
          <ButtonPelican onClick={() => history.push('/environment-releases/history')}>
            {t('History')}
          </ButtonPelican>
          {/* <ButtonPelican*/}
          {/*  onClick={() => handleEnvClick('history')}*/}
          {/*  variant="contained"*/}
          {/*  color={isHistory ? 'primary' : 'default'}*/}
          {/* >*/}
          {/*  {t('History')}*/}
          {/* </ButtonPelican>*/}
        </Space>
      </Box>
      <Box>
        {isEnvironment && <EnvironmentList />}
        <div style={{ display: isRealise ? 'block' : 'none' }}>
          <Releases />
        </div>
      </Box>
    </div>
  );
};

export default Environments;
