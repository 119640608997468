import Spin from 'components/Spin/component';
import { createBrowserHistory } from 'history';
import Login from 'pages/Login/Login';
import { TezAppModeler } from 'pages/Modeler/Modeler';
import React, { Suspense } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import MaterialTemplate from 'templates/MainTemplate';

import { renderRouteList } from './helpers';
import { routeList } from './route-list';
import { RoutesProps } from './types/routes-props';

export const browserHistory = createBrowserHistory();

const Routes: React.FC<RoutesProps> = ({ userPermissions, userName }: RoutesProps) => {
  const privateRouteList = renderRouteList(routeList, userPermissions, userName);

  return (
    <Router history={browserHistory}>
      <Suspense fallback={<Spin size={60} />}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route path="/modeler" exact>
            <MaterialTemplate hideNav>
              <TezAppModeler />
            </MaterialTemplate>
          </Route>
          <Route path="/">
            <MaterialTemplate>
              <Suspense fallback={<Spin size={60} />}>
                <Switch>{privateRouteList}</Switch>
              </Suspense>
            </MaterialTemplate>
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
