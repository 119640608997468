import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 13,
    lineHeight: '138%',
    color: theme.palette.surface[700],
    padding: '5px 8px',
    marginLeft: '-3px !important',
  },
  selected: {
    backgroundColor: `${theme.palette.surface[900]} !important`,
    color: '#FFFFFF !important',
    borderRadius: '4px !important',
  },
}));
