import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  coordinates: {
    lineHeight: '26px',
  },
  settings: {
    display: 'flex',
    marginTop: '12px',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 500,
  },
  yandexMap: {
    width: '100%',
    height: '100%',
  },
  input: {
    width: 200,
  },
});
