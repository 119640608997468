import 'tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/plugins/code';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/contextmenu';
import 'tinymce/plugins/fullpage';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/emoticons/js/emojis.js';
import 'tinymce/plugins/emoticons/plugin.js';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/image';
import 'tinymce/plugins/imagetools';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/legacyoutput';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/noneditable';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/print';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/spellchecker';
import 'tinymce/plugins/tabfocus';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/textpattern';
import 'tinymce/plugins/toc';
import 'tinymce/plugins/wordcount';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/help';

import { Editor } from '@tinymce/tinymce-react';
import React from 'react';

import { Props } from './props';

export const RichTextEditor: React.FC<Props> = ({ onEditorChange, value }) => {
  return (
    <Editor
      value={value}
      init={{
        relative_urls: false,
        body_class: 'tinymce',
        height: 500,
        plugins: [
          'advlist',
          'anchor',
          'autolink',
          'charmap',
          'code',
          'colorpicker',
          'contextmenu',
          'directionality',
          'emoticons',
          'fullpage',
          'fullscreen',
          'hr',
          'image',
          'imagetools',
          'insertdatetime',
          'legacyoutput',
          'link',
          'lists',
          'media',
          'nonbreaking',
          'noneditable',
          'pagebreak',
          'paste',
          'preview',
          'print',
          'save',
          'searchreplace',
          'tabfocus',
          'table',
          'template',
          'textcolor',
          'textpattern',
          'wordcount',
          'visualblocks',
          'visualchars',
        ],
        skin: false,
        content_css: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
        language_url: '/langs/ru.js',
        language: 'ru',
        formats: {
          alignleft: {
            selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img',
            classes: 'left',
          },
          aligncenter: {
            selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img',
            classes: 'center',
          },
          alignright: {
            selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img',
            classes: 'right',
          },
          alignjustify: {
            selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table,img',
            classes: 'full',
          },
          bold: { inline: 'span', classes: 'bold' },
          italic: { inline: 'span', classes: 'italic' },
          underline: { inline: 'span', classes: 'underline', exact: true },
          strikethrough: { inline: 'del' },
          forecolor: { inline: 'span', classes: 'forecolor', styles: { color: '%value' } },
          hilitecolor: {
            inline: 'span',
            classes: 'hilitecolor',
            styles: { backgroundColor: '%value' },
          },
          custom_format: { block: 'h1', attributes: { title: 'Header' }, styles: { color: 'red' } },
        },
        toolbar: [
          'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | imgbutton | filebutton | myinfo | toc | adddocumentbutton | addspoilerbutton',
          'formatselect fontselect fontsizeselect | blockquote | hr | forecolor backcolor permanentpen formatpainter | link unlink | removeformat | addcomment',
          'charmap pastetext | spellchecker searchreplace | media | table tabledelete tablecellprops tablemergecells tablesplitcells tableinsertrowbefore tableinsertrowafter tabledeleterow tablerowprops tableinsertcolbefore tableinsertcolafter tabledeletecol',
        ],
      }}
      onEditorChange={(a, editor) => {
        const text = editor.getContent({ format: 'text' });
        onEditorChange && onEditorChange(text ? a : '', editor);
      }}
    />
  );
};
