import { makeStyles } from '@material-ui/core';
import { SIDEBAR_WIDTH } from 'common/constants/styles';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

const useStyles = makeStyles({
  root: {
    padding: '10px 20px',
    height: '100%',
  },
  flex: {
    display: 'flex',
  },
  block1: {
    flex: 1,
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  navigator: {
    marginTop: 20,
    minHeight: 600,
    height: '100%',
    width: SIDEBAR_WIDTH,
  },
  block2: {
    marginBottom: 20,
  },
});

export const MainRouterSkeleton: React.FC = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Skeleton height={44} />
      <div className={styles.flex}>
        <div className={styles.navigator}>
          <Skeleton height={700} />
        </div>
        <div className={styles.block1}>
          <div className={styles.block2}>
            <Skeleton height={40} />
          </div>
          <div className={styles.block2}>
            <Skeleton height={60} />
          </div>
          <div className={styles.block2}>
            <Skeleton height={400} />
          </div>
          <div className={styles.block2} style={{ marginTop: 40, marginBottom: 30 }}>
            <Skeleton height={60} />
          </div>
          <div className={styles.block2}>
            <Skeleton height={40} />
          </div>
        </div>
      </div>
    </div>
  );
};
