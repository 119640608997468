import { ErrorMessage } from '@hookform/error-message';
import { Typography } from '@material-ui/core';
import { loginPost } from 'api/login';
import { ButtonPelican } from 'components/ButtonPelican';
import { CheckboxPelican } from 'components/CheckboxPelican';
import { LinkButton } from 'components/LinkButton/LinkButton';
import { TextInput } from 'components/TextInput';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { loadContentData } from 'store/contentSlice';
import { setSessionExpired, setSessionReturnUrl } from 'store/sessionSlice';
import { loadInitialData, setUserInfo } from 'store/userInfoSlice';
import { UserInfoState } from 'store/userInfoSlice/types/user-info-state';
import { useFormStyles } from 'theme/form-styles';

import { Props } from './props';
import { useStyles } from './styles';
import { LoginFormInput } from './types/login-form-input';

export const LoginForm: React.FC<Props> = ({ type = 'signIn', onModalClose }) => {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, errors } = useForm<LoginFormInput>({
    defaultValues: {
      login: '',
      password: '',
    },
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();

  const handleLogin = useCallback(
    async (data: LoginFormInput) => {
      const errors = {
        login: !data.login ? t('Login is required') : undefined,
        password: !data.password ? t('Password is required') : undefined,
      };
      if (!errors.login && !errors.password) {
        try {
          const result = await loginPost({
            username: data.login,
            password: data.password,
          });
          if (result.enabled) {
            const [authority] = result.authorities;
            const allUserRoles = result.authorities.map((role) => role.description);
            dispatch(
              setUserInfo(({
                userName: result.username,
                roleCode: authority.code,
                role: authority?.description || authority?.code,
                need_to_change_password: result.need_to_change_password,
                userRoles: allUserRoles,
                userId: result.id,
              } as unknown) as UserInfoState)
            );
            await dispatch(loadInitialData());
            await dispatch(loadContentData());
            localStorage.setItem('sessionExpired', 'false');
            dispatch(setSessionExpired(false));
            dispatch(setSessionReturnUrl(''));

            if (result.need_to_change_password) {
              onModalClose && onModalClose();
              history.push('/credentials/change-password');
            } else {
              if (type === 'signIn') {
                const isRedirect = sessionStorage.getItem('is_redirect');
                if (isRedirect) {
                  sessionStorage.removeItem('is_redirect');
                  history.push(isRedirect);
                } else {
                  history.push('/');
                }
              } else if (type === 'sessionExpired') {
                onModalClose && onModalClose();
              }
            }
          }
        } catch (e) {
          enqueueSnackbar(`${t('Login failed')}: ${e.response?.data?.message || e}`, {
            variant: 'error',
          });
        }
      }

      return errors;
    },
    [history, t, dispatch, enqueueSnackbar, type, onModalClose]
  );

  const login = useAsyncCallback(async (data: LoginFormInput) => {
    await handleLogin(data);
  });

  return (
    <div className={classes.loginContainer}>
      <header className={classes.header}>
        <img src="/app-name-blue.svg" alt="tezapp" className={classes.appLogo} />
        <Typography variant="h3" className={classes.h1}>
          {type === 'signIn' && t('Login to Your Account')}
          {type === 'sessionExpired' && t('Session expired')}
        </Typography>
      </header>
      <form onSubmit={handleSubmit(login.execute)}>
        <div className={classes.formItem}>
          <label className={formClasses.label} htmlFor="login">
            {t('Login')}
          </label>
          <div className={formClasses.input}>
            <Controller
              id="login"
              name="login"
              control={control}
              as={TextInput}
              placeholder={t('Username')}
              rules={{
                required: true,
              }}
            />
            <div className={formClasses.error}>
              <ErrorMessage errors={errors} message={t('Login is required')} name="login" />
            </div>
          </div>
        </div>

        <div className={classes.formItem}>
          <label className={formClasses.label} htmlFor="password">
            {t('Password')}
          </label>
          <div className={formClasses.input}>
            <Controller
              id="password"
              name="password"
              control={control}
              as={TextInput}
              placeholder={t('Password')}
              type="password"
              rules={{
                required: true,
              }}
            />
            <div className={formClasses.error}>
              <ErrorMessage errors={errors} message={t('Password is required')} name="password" />
            </div>
          </div>
        </div>
        <div className={classes.forgotPwd}>
          <label htmlFor="remember-me" className={classes.rememberMe}>
            <CheckboxPelican id="remember-me" />
            <Typography style={{ marginLeft: 6, color: '#545456' }}>{t('Remember me')}</Typography>
          </label>
          <LinkButton>{t('Forgot password')}?</LinkButton>
        </div>
        <div className={classes.buttonsContainer}>
          <ButtonPelican
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            loading={login.loading}
          >
            {t('Log in')}
          </ButtonPelican>
          {type === 'sessionExpired' && (
            <ButtonPelican
              className={classes.button}
              variant="text"
              color="default"
              onClick={onModalClose}
            >
              {t('Cancel')}
            </ButtonPelican>
          )}
        </div>
        {type === 'signIn' && (
          <footer className={classes.footer}>
            <Typography>
              {t(
                'Signing in for a Tezapp account means you agree to the Privacy Policy and Terms of Service.'
              )}
            </Typography>
          </footer>
        )}
      </form>
    </div>
  );
};

export default LoginForm;
