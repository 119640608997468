import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    className: {
      height: 32,
      background: '#F4F5F7',
      border: 'none',
      fontSize: 14,
      fontFamily: 'Inter, sans-serif',
      color: '#242424',
      minWidth: 0,
      outline: 'none',
      padding: '8px 6px',
      width: '100%',
    },
    root: {
      display: 'flex',
      alignItems: 'center',
      border: '2px solid #DFE1E6',
      background: '#F4F5F7',
      color: '#42526E',
      borderRadius: 3,
    },
    calendarIcon: {
      marginRight: 10,
    },
    calendarClassName: {
      background: '#fff',
      borderRadius: 8,
      padding: '0 8px',
      border: 'none',
      boxShadow: '0px 0px 1px rgba(9, 30, 66, 0.31), 0px 4px 8px rgba(9, 30, 66, 0.25)',
      '& .react-datepicker__current-month': {
        color: '#172B4D',
        fontWeight: 500,
      },
      '& .react-datepicker__month': {
        background: 'transparent',
        marginTop: 9,
        marginBottom: 12,
      },
      '& .react-datepicker__triangle': {
        display: 'none',
      },
      '& .react-datepicker__header': {
        paddingTop: 18,
        backgroundColor: 'transparent',
        borderBottom: 'none',
      },
      '& .react-datepicker__navigation': {
        border: 'none',
        width: 24,
        height: 24,
        '&--next': {
          right: 22,
          top: 16,
        },
        '&--previous': {
          left: 22,
          top: 16,
        },
        '&>svg': {
          top: 0,
          right: 0,
          position: 'absolute',
        },
        '&:focus': {
          outline: 'none',
        },
      },
      '& .react-datepicker__day-names': {
        marginTop: 18,
      },
      '& .react-datepicker__day-name': {
        width: 41,
        margin: 0,
      },
      '&.react-datepicker--time-only': {
        width: 200,
        '& .react-datepicker__time-container': {
          width: '100%',
        },
        '& .react-datepicker-time__header': {
          display: 'none',
        },
        '& .react-datepicker__header--time': {
          padding: '0 33px',
        },
        '& .react-datepicker__time-box': {
          width: '100%',
        },
      },
    },
    dayClassName: {
      fontSize: 14,
      background: 'transparent',
      margin: '0 4.5px',
      width: 32,
      height: 32,
      lineHeight: '142%',
      color: '#242424',
      paddingTop: 6,
      '&:hover': {
        background: '#E9F2FF',
        borderRadius: 8,
      },
    },
    dayClassNameCurrent: {
      color: '#FFFFFF',
      backgroundColor: '#0E4DEC',
      borderRadius: 8,
      '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#0E4DEC',
        borderRadius: 8,
      },
    },
    weekDayClassName: {
      textTransform: 'uppercase',
      color: '#555555',
      fontSize: 11,
      fontWeight: 'bold',
      lineHeight: '127%',
      background: 'transparent',
    },
    monthClassName: {
      background: 'transparent',
    },
    timeClassName: {
      background: 'transparent',
      textAlign: 'left',
      height: 18,
      padding: '7px 10px',
    },
    popperClassName: {
      background: 'transparent',
    },
    wrapperClassName: {
      width: '100%',
    },
  })
);
