import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import { Props } from './props';
import { useStyles } from './styles';

export const FormField: React.FC<Props> = ({
  title,
  description,
  help,
  comparable,
  hidden,
  error,
  children,
  type,
  maxLength,
  minLength,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        hidden && classes.displayNone,
        comparable && classes.comparable
      )}
      {...rest}
    >
      <div className={!comparable ? clsx(classes.stick, error && classes.error) : ''} />
      <div className={comparable ? classes.fill : classes.controller}>
        {type && (
          <Typography variant="h6" className={classes.small}>
            {type}
          </Typography>
        )}
        <div className={classes.heading}>
          <Typography variant="h5" className={classes.title}>
            {title}
          </Typography>
          <Typography variant="h6" className={classes.small}>
            {description}
          </Typography>
          {(maxLength || minLength) && (
            <div className={classes.right}>
              <Typography variant="h6" className={classes.small}>
                {[minLength && `min: ${minLength}`, maxLength && `max: ${maxLength}`]
                  .filter((s) => !!s)
                  .join(', ')}
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.children}>{children}</div>
        <div className={classes.footer}>{help}</div>
      </div>
    </div>
  );
};
