import { geopointApiGet } from 'api/geopoint';
import { coordinatesRegEx } from 'common/constants/geopoint';
import { ButtonPelican } from 'components/ButtonPelican';
import { TextInput } from 'components/TextInput';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GeolocationControl,
  Map,
  Placemark,
  SearchControl,
  YMaps,
  ZoomControl,
} from 'react-yandex-maps';

import { Props } from './props';
import { useStyles } from './styles';

export const YandexMap: React.FC<Props> = ({
  width = 500,
  height = 350,
  defaultValue,
  onChangePoint,
  disabled,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [mapData, setMapData] = useState({
    center: [43.231801, 76.948003],
    zoom: 10,
  });
  const [state, setState] = useState(mapData);
  const [value, setValue] = useState<string | undefined>(defaultValue);
  const [showPlaceMark, setShowPlaceMark] = useState<boolean>();
  const [apikey, setApikey] = useState<string>();
  const [apikeyLoading, setApikeyLoading] = useState(false);

  const valid = useCallback((): boolean => {
    return !value || coordinatesRegEx.test(value.substring(1, value.length - 1));
  }, [value]);

  const clickMap = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (ymaps: any): void => {
      if (!disabled) {
        const arr = ymaps._sourceEvent.originalEvent.coords.map((val: number) => val.toFixed(6));
        const newVal = `(${arr.join(', ')})`;
        setMapData((prev) => ({ ...prev, center: arr.map(Number) }));
        setValue(newVal);
        setShowPlaceMark(true);
        onChangePoint && onChangePoint(newVal);
      }
    },
    [disabled, onChangePoint]
  );

  const handleClear = useCallback(() => {
    setValue('');
    setShowPlaceMark(false);
    onChangePoint && onChangePoint('');
  }, [onChangePoint]);

  // eslint-disable-next-line functional/prefer-readonly-type
  const parseStringToCoordinates = useCallback((val): number[] => {
    return val
      .substring(1, val.length - 1)
      .split(',')
      .map(Number);
  }, []);

  const getApiKey = useCallback(async () => {
    setApikeyLoading(true);
    try {
      const res = await geopointApiGet();
      setApikey(res.yandex?.apikey);
    } catch (e) {
      enqueueSnackbar(`${t('Error on loading')} ${t('key')} ${e.response?.data?.message || e}`, {
        variant: 'error',
      });
    } finally {
      setApikeyLoading(false);
    }
  }, [enqueueSnackbar, t]);

  const handleSaveValue = useCallback(() => {
    if (!disabled) {
      if (value && valid()) {
        setShowPlaceMark(true);
        setState({
          zoom: 10,
          center: parseStringToCoordinates(value),
        });
      } else {
        setShowPlaceMark(false);
      }
      onChangePoint && onChangePoint(value);
    }
  }, [onChangePoint, parseStringToCoordinates, valid, value, disabled]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (showPlaceMark === undefined && defaultValue) {
      setShowPlaceMark(true);
      setState((prev) => ({
        ...prev,
        center: parseStringToCoordinates(defaultValue),
      }));
    }
  }, [defaultValue, parseStringToCoordinates, showPlaceMark]);

  useEffect(() => {
    setMapData(state);
  }, [state]);

  useEffect(() => {
    getApiKey();
  }, [getApiKey]);

  return !apikeyLoading ? (
    <div>
      <YMaps query={{ apikey }}>
        <Map onClick={clickMap} width={width} height={height} state={state} defaultState={mapData}>
          <GeolocationControl options={{ float: 'left' }} />
          <ZoomControl options={{ float: 'right' }} />
          {showPlaceMark && <Placemark geometry={mapData.center} />}
          <SearchControl options={{ float: 'right', noPlacemark: true }} />
        </Map>
      </YMaps>
      <div className={styles.settings}>
        <TextInput
          readOnly={disabled}
          error={!valid()}
          inputClassName={styles.input}
          placeholder={t('Coordinates')}
          value={value && value.substring(1, value.length - 1)}
          onChange={(e) => {
            const val = e.target.value;
            setValue(val ? `(${val})` : '');
          }}
          helperText={!valid() && t('Incorrect format')}
          onBlur={handleSaveValue}
        />
        {value && !disabled && (
          <ButtonPelican onClick={handleClear} color="primary" variant="text">
            {t('Clear')}
          </ButtonPelican>
        )}
      </div>
    </div>
  ) : (
    <div>{t('API key loading')}...</div>
  );
};

export default YandexMap;
