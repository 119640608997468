import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from './props';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    minHeight: 560,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  img: {},
  title: {
    fontSize: 20,
    fontWeight: 600,
    marginTop: 20,
    marginBottom: 20,
  },
}));

export const DataGridNoData: React.FC<Props> = ({ img, text, action }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <img src={`/assets/icons/${img || 'data-grid-no-data'}.svg`} alt={'No Result'} />
      <Typography variant={'h4'} className={styles.title}>
        {t(text || 'No results')}
      </Typography>
      {action}
    </div>
  );
};
