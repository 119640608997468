import { fieldsGet } from 'api/content';
import { ContentTypeField } from 'api/types/ContentTypeField';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { usePageStyles as usersPageStyles } from 'theme/page-styles';

import { Props } from './props';

export const EmbeddedFieldDisplayedValues: React.FC<Props> = ({
  contentItems,
  contentTypeSlug,
  contentField,
  projectId,
}) => {
  const usersPageClasses = usersPageStyles();

  const [fields, setFields] = useState<ReadonlyArray<ContentTypeField>>();

  const onContentTypeSlugInit = useCallback(async () => {
    const fieldsList = await fieldsGet(contentTypeSlug, {
      'fetch-references': false,
      project_id: projectId,
    });
    setFields(fieldsList);
  }, [contentTypeSlug, projectId]);

  useEffect(() => {
    contentTypeSlug && onContentTypeSlugInit();
  }, [contentTypeSlug, onContentTypeSlugInit]);

  return (
    <div>
      {fields &&
        contentItems.map((contentItem, i: number) => (
          <div
            key={i}
            className={clsx(
              usersPageClasses.reference,
              (contentField.list && usersPageClasses.referenceMultiple) ||
                usersPageClasses.referenceSingle
            )}
          >
            {fields
              .filter((field) => field.displayedValue && field.type !== 'REFERENCE')
              .map((field) => contentItem[field.slug])
              .join(' ')}
          </div>
        ))}
    </div>
  );
};
