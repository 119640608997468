import { Box, Drawer, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { ButtonPelican } from 'components/ButtonPelican';
import { ArrowRight } from 'components/IconPelican/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CompareEnvVersion from '../../../../CompareEnvVersion/CompareEnvVersion';
import { useStyles } from './styles';

export const ModelDiffLink: React.FC<{
  readonly slug?: string;
  readonly left?: number;
  readonly right?: number;
  readonly leftId?: number;
  readonly rightId?: number;
  readonly state?: string;
}> = ({ slug, left, right, leftId, rightId, state }) => {
  const styles = useStyles();
  const [compareOpen, setCompareOpen] = useState(false);
  const { t } = useTranslation();

  console.log(slug, left, right);
  const handleOpen = (): void => {
    setCompareOpen(true);
  };

  const inner = (
    <Box display={'flex'}>
      <Typography
        style={{
          color: state === 'DELETED' ? '#545456' : '#0039D4',
          background: state === 'DELETED' ? '#D3D4D6' : '#E5F6E7',
          border: state === 'DELETED' ? '1px solid #AFB0B2' : '1px solid #00801B',
        }}
        className={styles.version}
        variant={'body2'}
      >
        {left ? left : t('Archived')} {!right && `${t('New')}`}
      </Typography>

      {(right || state === 'DELETED') && (
        <>
          <Box width={'50px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <ArrowRight htmlColor="#00801B" />
          </Box>
          <Typography
            className={styles.version}
            style={{
              color: '#0039D4',
              background: '#E5F6E7',
              border: '1px solid #00801B',
            }}
            variant={'body2'}
          >
            {right}
          </Typography>
        </>
      )}
    </Box>
  );

  return leftId && rightId ? (
    <>
      <ButtonPelican variant={'text'} onClick={handleOpen}>
        {inner}
      </ButtonPelican>
      <Drawer
        anchor={'right'}
        open={compareOpen}
        onClose={() => setCompareOpen(false)}
        disableBackdropClick
      >
        <div style={{ width: `90vw`, minHeight: '100vh', height: '100%', position: 'relative' }}>
          <CancelIcon onClick={() => setCompareOpen(false)} className={styles.closeButton} />
          <CompareEnvVersion
            type={slug || ''}
            contentId={leftId.toString()}
            versionId={rightId.toString()}
            url={'content'}
            controlType={'compare'}
          />
        </div>
      </Drawer>
    </>
  ) : (
    inner
  );
};

export default ModelDiffLink;
