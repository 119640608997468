import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const AssetsOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <g clipPath="url(#prefix__clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.244 2.984c1.617 1.617 1.3 4.004 0 5.303l-9.9 9.9c-1.001 1.002-2.419.763-3.18.001l-.354-.353c-.754-.755-1-2.182 0-3.183l6.718-6.717a.75.75 0 011.06 1.06l-6.717 6.718a.748.748 0 000 1.061l.353.354a.74.74 0 001.06-.002l9.9-9.9c.792-.792.954-2.226 0-3.18l-.354-.353c-.957-.957-2.393-.795-3.184-.004l-9.9 9.9c-1.413 1.413-1.504 3.791.007 5.302l.354.354c1.397 1.397 3.774 1.529 5.3.004l7.424-7.425a.75.75 0 011.06 1.06l-7.424 7.425c-2.148 2.149-5.475 1.942-7.42-.003l-.354-.354c-2.12-2.12-1.984-5.448-.007-7.424l9.9-9.9c1.299-1.299 3.687-1.614 5.305.004"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
);
export default AssetsOutline;
