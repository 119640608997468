import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ArrowRightOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.317 5.6a.75.75 0 011.06 0l6.543 6.54-6.542 6.539a.75.75 0 11-1.06-1.061l5.48-5.477-5.48-5.48a.75.75 0 010-1.06z"
      fill="currentColor"
    />
  </SvgIcon>
);
export default ArrowRightOutline;
