import { Popover, Typography } from '@material-ui/core';
import ButtonPelican from 'components/ButtonPelican';
import update from 'immutability-helper';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataGrid } from '../DataGridContext';
import { DataGridSettingsItem } from './DataGridSettingsItem/DataGridSettingsItem';
import { useStyles } from './styles';

export const DataGridSettings: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { allColumns, tableInstance } = useDataGrid();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [columns, setColumns] = useState<string[]>(allColumns?.map((c) => c.id) || []); // eslint-disable-line

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);

    if (typeof tableInstance?.setColumnOrder === 'function') {
      tableInstance?.setColumnOrder(columns);
      tableInstance?.resetResizing();
    }
  };

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = columns[dragIndex];
      setColumns(
        update(columns, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [columns]
  );

  const open = Boolean(anchorEl);

  useEffect(() => {
    setColumns(allColumns?.map((c) => c.id) || []);
  }, [allColumns]);

  return (
    <>
      <ButtonPelican size="small" variant="contained" onClick={handleClick}>
        {t('Column settings')}
      </ButtonPelican>
      <Popover
        id={'table-settings-button'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        disableScrollLock={true}
      >
        <div className={styles.root}>
          <header className={styles.header}>
            <Typography variant="body1" className={styles.title}>
              {t('Show in table')}
            </Typography>
          </header>

          <ul className={styles.list}>
            {columns.map((column, index) => {
              const col = allColumns.find((c) => c.id === column);
              return (
                col &&
                col.id !== 'selection' && (
                  <DataGridSettingsItem
                    key={column}
                    column={col}
                    index={index}
                    id={column}
                    moveCard={moveCard}
                  />
                )
              );
            })}
          </ul>
        </div>
      </Popover>
    </>
  );
};
