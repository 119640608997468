import { makeStyles, Theme } from '@material-ui/core/styles';

export const useAssetStyles = makeStyles((theme: Theme) => ({
  previewContainer: {
    display: 'flex',
    margin: '0 auto',
  },
  preview: {
    maxWidth: 44,
    maxHeight: 44,
  },
  extension: {
    fontSize: 11,
    lineHeight: '16px',
    padding: '0 7px',
    textTransform: 'uppercase',
    color: '#505F79',
    background: '#DFE1E6',
    borderRadius: 3,
    fontWeight: 'bold',
  },
  fileSize: {
    fontSize: 11,
    whiteSpace: 'nowrap',
    lineHeight: '16px',
    borderRadius: 3,
    padding: '0 7px',
    textTransform: 'uppercase',
    color: '#ffffff',
    fontWeight: 'bold',
  },
  smallFile: {
    background: '#36B37E',
  },
  mediumFile: {
    background: '#FFAB00',
  },
  bigFile: {
    background: '#DE350B',
  },
  largeFile: {
    background: '#091E42',
  },
  fileName: {
    width: 180,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: 0,
  },
  fileType: {
    width: 120,
    margin: 0,
  },
  fileNameWidth: {
    width: 180,
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  filterLabel: {
    color: '#97A0AF',
    fontSize: 12,
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  filterInput: {
    width: 100,
  },
  marginBottom: {
    marginBottom: 28,
  },
  selectType: {
    width: 120,
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    marginTop: 30,
  },
  openButton: {
    height: 32,
    background: 'rgba(9, 30, 66, 0.04)',
    minWidth: 150,
  },
  endIcon: {
    marginLeft: 'auto',
    marginRight: -6,
    '&>svg': {
      fontSize: '24px !important',
      fill: 'black',
    },
  },
  btn: {
    width: 100,
  },
  sizeBlock: {
    display: 'flex',
    alignItems: 'center',
    padding: 8,
    borderRadius: 3,
    fontSize: 14,
    lineHeight: '20px',
    color: '#253858',
    '&>*': {
      marginRight: 10,
    },
  },
  margin0: {
    minHeight: '75vh',
    marginBottom: 0,
  },
  mr8: {
    marginRight: 8,
  },
  projects: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'absolute',
    borderRadius: 3,
    backgroundColor: '#fff',
    boxShadow: '0px 0px 1px rgba(9, 30, 66, 0.31), 0px 8px 16px rgba(9, 30, 66, 0.25)',
    top: 37,
    zIndex: 100000,
    padding: 2,
  },
}));
