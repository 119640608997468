import { ErrorMessage } from '@hookform/error-message';
import { contentItemCommentPost } from 'api/content';
import { CommentModel } from 'api/types/CommentModel';
import { ButtonPelican } from 'components/ButtonPelican';
import { TextInput } from 'components/TextInput';
import React, { useCallback } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFormStyles } from 'theme/form-styles';

import { Props } from './props';
import { useStyles } from './styles';

export const CommentCreateForm: React.FC<Props> = ({
  contentItemId,
  type,
  onCommentSubmitted,
  onClose,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formClasses = useFormStyles();

  const { control, handleSubmit, errors } = useForm<CommentModel>();

  const sendComment = useAsyncCallback(async (data: CommentModel) => {
    await contentItemCommentPost(data, type, contentItemId);
    onCommentSubmitted();
  });

  const cancel = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <form onSubmit={handleSubmit(sendComment.execute)} className={classes.form}>
      <div className={formClasses.input}>
        <Controller
          name="message"
          control={control}
          as={TextInput}
          multiline={true}
          rows={6}
          placeholder={t('Message')}
          rules={{
            required: true,
          }}
        />
      </div>

      <div className={formClasses.error}>
        <ErrorMessage errors={errors} message={t('Message is required')} name="message" />
      </div>
      <div className={classes.formFooter}>
        <ButtonPelican
          variant="contained"
          color="primary"
          type="submit"
          loading={sendComment.loading}
        >
          {t('Send')}
        </ButtonPelican>
        <ButtonPelican variant="contained" color="default" type="button" onClick={cancel}>
          {t('Cancel')}
        </ButtonPelican>
      </div>
    </form>
  );
};
