import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const EyeOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.462 12.027l.012-.028-.012-.028a3.412 3.412 0 00-.332-.566c-.332-.473-.848-1.043-1.531-1.591-1.367-1.097-3.296-2.024-5.603-2.024-2.264 0-4.193.925-5.573 2.026-.688.55-1.211 1.121-1.548 1.596a3.433 3.433 0 00-.337.567l-.009.02.008.02c.06.135.168.328.337.566.337.475.86 1.047 1.548 1.597 1.378 1.102 3.307 2.028 5.574 2.028 2.31 0 4.24-.929 5.605-2.026.683-.548 1.198-1.118 1.53-1.591.166-.238.272-.43.33-.566zm-7.466 5.683C17.442 17.71 21 13.33 21 12c0-1.337-3.565-5.71-9.004-5.71C6.635 6.29 3 10.663 3 12c0 1.33 3.628 5.71 8.996 5.71zm0-3.96c.973 0 1.758-.786 1.758-1.741 0-.964-.785-1.759-1.758-1.759-.974 0-1.75.795-1.75 1.759 0 .955.776 1.741 1.75 1.741z"
      fill="currentColor"
    />
  </SvgIcon>
);
export default EyeOutline;
