export const SIDEBAR_WIDTH = 280;
export const RIGHT_BAR_WIDTH = 350;
export const TOGGLE_SIDEBAR_WIDTH = 72;
export const TOP_NAV_HEIGHT = 44;
export const BREADCRUMBS_HEIGHT = 26;

export const FIELD_TYPE_ICONS = new Map([
  ['TEXT', 'text'],
  ['DATE', 'date'],
  ['INTEGER', 'integer'],
  ['AMOUNT', 'decimal'],
  ['REFERENCE', 'reference'],
  ['DATETIME', 'datetime'],
  ['TIME', 'time'],
  ['BOOLEAN', 'boolean'],
  ['IMAGE', 'image'],
  ['VIDEO', 'video'],
  ['AUDIO', 'audio'],
  ['DOCUMENT', 'document'],
  ['ICON', 'image'],
  ['SLUG', 'slug'],
  ['LINK', 'text'],
  ['RICHTEXT', 'richtext'],
  ['COLOUR', 'colour'],
  ['GEOPOINT', 'geopoint'],
]);
