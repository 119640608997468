import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 16,
    display: 'flex',
    '&:last-child': {
      marginBottom: 24,
    },
  },
  comparable: {
    width: 'calc(100% - 12px)',
    paddingTop: 7,
    marginTop: 4,
  },
  heading: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 4,
  },
  title: {
    fontWeight: 600,
    color: '#161618',
    marginRight: 6,
    marginTop: 0,
    marginBottom: 0,
  },
  small: {
    fontSize: 12,
    fontWeight: 400,
    color: '#858585',
    marginTop: 0,
    marginBottom: 0,
  },
  children: {},
  footer: {
    marginTop: 6,
    fontSize: 11,
    color: '#545456',
  },
  displayNone: {
    display: 'none',
  },
  error: {
    borderLeft: `4px solid ${theme.palette.error.light}`,
  },
  stick: {
    width: 4,
    borderRadius: 2,
    height: 'auto',
    marginRight: 26,
    boxShadow: 'inset 2px 0px 0px #DCDCDC',
  },
  controller: {
    padding: '8px 0',
    width: 'calc(100% - 15px)',
  },
  fill: {
    width: '100%',
  },
  right: {
    marginLeft: 'auto',
  },
}));
