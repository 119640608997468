import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((_theme: Theme) => ({
  empty: {
    fontSize: 20,
    lineHeight: '24px',
    textAlign: 'center',
    color: '#6B778C',
    padding: '40px 25%',
    minHeight: '50vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  tableContainer: {
    height: '100%',
  },
  table: {
    width: '100%',
    overflowX: 'auto',
  },
  cell: ({ width }: { readonly width?: number }) => ({
    position: 'relative',
    minWidth: 40,
    padding: '4px 8px',
    width: width ? `${width}%` : undefined,
    border: '1px solid #EBECF0',
    '&:hover .edit-table-item': {
      display: 'inline-block',
    },
  }),
  edit: {
    background: '#FFFFFF',
    position: 'absolute',
    top: 'calc(50% - 15px)',
    right: 8,
    display: 'none',
  },
  headRow: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  headCell: {
    padding: '8px 4px',
    background: '#FFFFFF',
    icon: {
      color: 'red',
    },
    '& .MuiTableSortLabel-root ': {
      color: '#6B778C',
    },
    '& .MuiTableSortLabel-icon': {
      display: 'none',
    },
    '& .MuiTypography-caption': {
      fontSize: 12,
      fontWeight: 600,
      color: '#8F8F91',
      fontFamily: 'Inter',
    },
  },
  tableRow: {
    height: 48,
    '&:hover': {
      backgroundColor: '#EFF0F2 !important',
      cursor: 'pointer',
    },
    '& .tableAnchor': {
      color: '#0039D4',
      fontWeight: 500,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  tableRowNonStriped: {
    '&:nth-child(2n)': {
      background: '#fff',
    },
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  descSortIcon: {
    color: 'blue',
  },
  settings: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  notSorted: {
    display: 'inline-block',
    lineHeight: 0,
  },
  treeButton: {
    marginLeft: 'auto',
    paddingLeft: 6,
    paddingRight: 6,
    minWidth: 0,
    paddingTop: 6,
  },
  tableDynamic: {
    marginLeft: 4,
  },
  settingsTableIcon: {
    marginLeft: 6,
  },
  flex: {
    display: 'flex',
  },
  loading: {
    minHeight: 400,
    height: '70%',
    position: 'relative',
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  resizer: {
    maxWidth: 1,
    width: 1,
    padding: '0',
    transition: '0.2s',
    background: 'transparent',
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    cursor: 'col-resize !important',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  drag: {
    margin: 'auto 0',
    cursor: 'move',
    color: '#858585',
  },
  preview: {
    background: '#000000',
    opacity: 0.4,
  },
}));
