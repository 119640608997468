import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const BellOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.658 6.116l-.688.249c-1.253.452-2.093 1.757-2.244 4.591l-.002.039-.006.053c-.06.572-.132 1.268-.283 1.898-.146.609-.418 1.41-1.052 2-.395.373-.684.655-.884.925a2.015 2.015 0 00-.09.13H18.59a2.001 2.001 0 00-.089-.128c-.2-.27-.491-.553-.89-.924l-.006-.005c-.625-.589-.894-1.385-1.04-1.987-.147-.612-.223-1.289-.285-1.849l-.005-.045-.007-.063-.003-.044c-.15-2.838-.984-4.139-2.242-4.59l-.695-.251-.226-.704C12.91 4.806 12.468 4.5 12 4.5c-.473 0-.912.303-1.114.921l-.228.695zm7.107 4.78c.133 1.184.268 2.39.868 2.955.773.72 1.563 1.483 1.563 2.398 0 .746-.56 1.252-1.456 1.252H5.251c-.888 0-1.447-.506-1.447-1.252 0-.914.79-1.678 1.554-2.397.612-.569.746-1.785.87-2.975.16-3.002 1.092-5.15 3.232-5.923C9.824 3.844 10.757 3 12 3c1.234 0 2.176.844 2.53 1.954 2.15.772 3.073 2.921 3.233 5.923l.002.02zM14.028 20.182a2.466 2.466 0 00.698-1.5H9.265a2.464 2.464 0 00.702 1.5c.49.497 1.2.818 2.033.818s1.541-.321 2.028-.818z"
    />
  </SvgIcon>
);
export default BellOutline;
