import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const useStyles = makeStyles(() => ({
  root: {
    padding: '30px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: '#858585',
  },
}));

export const NoDiff: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Typography className={styles.title} variant={'h3'}>
        {t('No difference')}
      </Typography>
    </div>
  );
};
