export enum Permission {
  HEADER_GET = 'HEADER_GET',
  HEADER_UPDATE = 'HEADER_UPDATE',
  HEADER_DELETE = 'HEADER_DELETE',
  HEADER_CREATE = 'HEADER_CREATE',
  HEADER_LIST = 'HEADER_LIST',
  REPORT_GENERATE = 'REPORT_GENERATE',
  REPORT_HISTORY = 'REPORT_HISTORY',
  REPORT_GENERATE_X_L_S_XBY_ID = 'REPORT_GENERATE_X_L_S_XBY_ID',
  REPORT_GENERATE_X_L_S_XBY_CODE = 'REPORT_GENERATE_X_L_S_XBY_CODE',
  REPORT_HISTORY_LIST = 'REPORT_HISTORY_LIST',
  REPORT_GET = 'REPORT_GET',
  REPORT_UPDATE = 'REPORT_UPDATE',
  REPORT_DELETE = 'REPORT_DELETE',
  REPORT_CREATE = 'REPORT_CREATE',
  REPORT_LIST = 'REPORT_LIST',
  REPORT_PARAMETER_PARAMETER_TYPE_LIST = 'REPORT_PARAMETER_PARAMETER_TYPE_LIST',
  REPORT_PARAMETER_UPDATE = 'REPORT_PARAMETER_UPDATE',
  REPORT_PARAMETER_DELETE = 'REPORT_PARAMETER_DELETE',
  REPORT_PARAMETER_CREATE = 'REPORT_PARAMETER_CREATE',
  REPORT_PARAMETER_LIST = 'REPORT_PARAMETER_LIST',
  MAILING_GET = 'MAILING_GET',
  MAILING_CREATE = 'MAILING_CREATE',
  MAILING_LIST = 'MAILING_LIST',
  LANGUAGE_UPDATE = 'LANGUAGE_UPDATE',
  LANGUAGE_DELETE = 'LANGUAGE_DELETE',
  LANGUAGE_CREATE = 'LANGUAGE_CREATE',
  FORM_REST_FORM_BY_ROLE = 'FORM_REST_FORM_BY_ROLE',
  FORM_REST_FORM_GROUP = 'FORM_REST_FORM_GROUP',
  FORM_CREATE_REST_FORM_RIGHT = 'FORM_CREATE_REST_FORM_RIGHT',
  FORM_LIST_FORM_BY_GROUP = 'FORM_LIST_FORM_BY_GROUP',
  FORM_REST_FORM_BY_ROLE_AND_GROUP = 'FORM_REST_FORM_BY_ROLE_AND_GROUP',
  UTILS_START_CONVERT_BROKEN_LINK = 'UTILS_START_CONVERT_BROKEN_LINK',
  UTILS_START_BROKEN_LINKS = 'UTILS_START_BROKEN_LINKS',
  UTILS_PUBLISH_ALL = 'UTILS_PUBLISH_ALL',
  ROLE_GET = 'ROLE_GET',
  ROLE_UPDATE = 'ROLE_UPDATE',
  ROLE_DELETE = 'ROLE_DELETE',
  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_LIST = 'ROLE_LIST',
  PROJECT_GET = 'PROJECT_GET',
  PROJECT_UPDATE = 'PROJECT_UPDATE',
  PROJECT_DELETE = 'PROJECT_DELETE',
  PROJECT_CREATE = 'PROJECT_CREATE',
  PROJECT_LIST = 'PROJECT_LIST',
  ASSET_DELETE = 'ASSET_DELETE',
  ASSET_CREATE = 'ASSET_CREATE',
  ASSET_LIST = 'ASSET_LIST',
  BROKEN_LINK_LIST_DETAILS = 'BROKEN_LINK_LIST_DETAILS',
  BROKEN_LINK_LIST = 'BROKEN_LINK_LIST',
  WORKFLOW_GET_CONTENT_ACTIONS = 'WORKFLOW_GET_CONTENT_ACTIONS',
  WORKFLOW_LIST = 'WORKFLOW_LIST',
  CONTENT_TYPE_GET = 'CONTENT_TYPE_GET',
  CONTENT_TYPE_UPDATE = 'CONTENT_TYPE_UPDATE',
  CONTENT_TYPE_DELETE = 'CONTENT_TYPE_DELETE',
  CONTENT_TYPE_CREATE = 'CONTENT_TYPE_CREATE',
  CONTENT_TYPE_LIST = 'CONTENT_TYPE_LIST_ALL',
  CONTENT_TYPE_GROUP_GET = 'CONTENT_TYPE_GROUP_GET',
  CONTENT_TYPE_GROUP_UPDATE = 'CONTENT_TYPE_GROUP_UPDATE',
  CONTENT_TYPE_GROUP_CREATE = 'CONTENT_TYPE_GROUP_CREATE',
  CONTENT_TYPE_GROUP_LIST = 'CONTENT_TYPE_GROUP_LIST',
  CONTENT_CONVERSATION_FIND_ALL = 'CONTENT_CONVERSATION_FIND_ALL',
  CONTENT_CONVERSATION_FIND_ALL_BY_SOURCE = 'CONTENT_CONVERSATION_FIND_ALL_BY_SOURCE',
  CONTENT_VERSION_FIND_ALL = 'CONTENT_VERSION_FIND_ALL',
  CONTENT_VERSION_FIND_ALL_BY_SOURCE = 'CONTENT_VERSION_FIND_ALL_BY_SOURCE',
  CONTENT_MONITORING_LIST = 'CONTENT_MONITORING_LIST',
  CONTENT_TYPE_FIELD_FIELD_TYPES = 'CONTENT_TYPE_FIELD_FIELD_TYPES',
  CONTENT_TYPE_FIELD_LIST_REFERENCING_FIELDS = 'CONTENT_TYPE_FIELD_LIST_REFERENCING_FIELDS',
  CONTENT_TYPE_FIELD_GET = 'CONTENT_TYPE_FIELD_GET',
  CONTENT_TYPE_FIELD_UPDATE = 'CONTENT_TYPE_FIELD_UPDATE',
  CONTENT_TYPE_FIELD_DELETE = 'CONTENT_TYPE_FIELD_DELETE',
  CONTENT_TYPE_FIELD_CREATE = 'CONTENT_TYPE_FIELD_CREATE',
  CONTENT_TYPE_FIELD_LIST = 'CONTENT_TYPE_FIELD_LIST',
  SETTINGS_GET_ALL = 'SETTINGS_GET_ALL',
  SETTINGS_GET_BY_TYPE = 'SETTINGS_GET_BY_TYPE',
  SETTINGS_SET_UPDATE = 'SETTINGS_SET_UPDATE',
  USER_BIND_AND_UNBIND_USER_PROJECT = 'USER_BIND_AND_UNBIND_USER_PROJECT',
  USER_BIND_AND_UNBIND_USER_ROLE = 'USER_BIND_AND_UNBIND_USER_ROLE',
  USER_USER_ROLES = 'USER_USER_ROLES',
  USER_EVENT_LOG_LIST = 'USER_EVENT_LOG_LIST',
  USER_GET = 'USER_GET',
  USER_UPDATE = 'USER_UPDATE',
  USER_DELETE = 'USER_DELETE',
  USER_CREATE = 'USER_CREATE',
  USER_LIST = 'USER_LIST',
  TEST_CREATE = 'TEST_CREATE',
  TEST_UPDATE = 'TEST_UPDATE',
  TEST_DELETE = 'TEST_DELETE',
  TEST_FIND_ONE = 'TEST_FIND_ONE',
  TEST_FIND_ALL = 'TEST_FIND_ALL',
  TEST_PUBLISH = 'TEST_PUBLISH',
  TEST_REJECT = 'TEST_REJECT',
  TEST_APPROVE = 'TEST_APPROVE',
  TEST_ARCHIVE = 'TEST_ARCHIVE',
  INTERNAL_CREATE = 'INTERNAL_CREATE',
  INTERNAL_UPDATE = 'INTERNAL_UPDATE',
  INTERNAL_DELETE = 'INTERNAL_DELETE',
  INTERNAL_FIND_ONE = 'INTERNAL_FIND_ONE',
  INTERNAL_FIND_ALL = 'INTERNAL_FIND_ALL',
  INTERNAL_PUBLISH = 'INTERNAL_PUBLISH',
  INTERNAL_REJECT = 'INTERNAL_REJECT',
  INTERNAL_APPROVE = 'INTERNAL_APPROVE',
  INTERNAL_ARCHIVE = 'INTERNAL_ARCHIVE',
  CONTENT = 'CONTENT',
  SETTINGS = 'SETTINGS',
  USER = 'USER',
  LANGUAGE = 'LANGUAGE',
  MODEL_GROUP = 'MODEL_GROUP',
  ASSET = 'ASSET',
  INTEGRATION = 'INTEGRATION',
  ENVIRONMENT_AND_RELEASE = 'ENVIRONMENT_AND_RELEASE',
  ACCESS_TOKEN = 'ACCESS_TOKEN',
  WEBHOOK = 'WEBHOOK',
  CONTENT_TYPE = 'CONTENT_TYPE',
  PERMISSIONS_ROLES = 'PERMISSIONS_ROLES',
  PROJECT = 'PROJECT',
}
