import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
import 'bpmn-js-properties-panel/dist/assets/properties-panel.css';

import { Divider } from '@material-ui/core';
import {
  BpmnPropertiesPanelModule,
  BpmnPropertiesProviderModule,
  ZeebePropertiesProviderModule,
} from 'bpmn-js-properties-panel';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import request from 'common/utils/axios-request';
import {
  AddSquareOutline,
  ImageSquareOutline,
  SaveOutline,
  UploadOutline,
} from 'components/IconPelican/icons';
import React, { useCallback, useEffect } from 'react';
import ZeebeBpmnModdle from 'zeebe-bpmn-moddle/resources/zeebe.json';

import { newDiagramXml } from './new-diagram-xml';
import { useStyles } from './styles';

// eslint-disable-next-line functional/no-let, @typescript-eslint/no-explicit-any
let modeler: any;

function createNewDiagram(): void {
  openDiagram(newDiagramXml);
}

async function openDiagram(xml: ArrayBuffer | string | null): Promise<void> {
  try {
    await modeler.importXML(xml);

    console.log('success loaded');
  } catch (err) {
    console.log('error on loading: ', err);
    console.error(err);
  }
}

const CustomDivider: React.FC = () => (
  <Divider
    style={{ width: 2, margin: '0 20px', height: 20 }}
    color={'#DCDCDC'}
    orientation={'vertical'}
  />
);

export const TezAppModeler: React.FC = () => {
  const styles = useStyles();
  const canvasRef = React.useRef<HTMLDivElement>(null);
  const propertiesPanelRef = React.useRef<HTMLDivElement>(null);
  const uploadRef = React.useRef<HTMLInputElement>(null);

  const handleOpenFile = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      // eslint-disable-next-line prefer-destructuring
      const file = e.target.files[0];

      const reader = new FileReader();

      reader.onload = function (e) {
        const xml = e.target?.result;

        openDiagram(xml || null);
      };

      reader.readAsText(file);
    }
  }, []);

  const handleUpload = (): void => {
    uploadRef.current?.click();
  };

  const downloadFile = (fileName: string, encodedData: string): void => {
    const url = `data:application/bpmn20-xml;charset=UTF-8,${encodedData}`;
    const tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', fileName);
    tempLink.click();
    tempLink.remove();
  };

  const handleSave = async (): Promise<void> => {
    const file = uploadRef?.current?.files?.length ? uploadRef?.current?.files[0] : null;
    const fileName = file?.name || 'newDiagram.bpmn';
    const { xml } = await modeler.saveXML({ format: true });
    const encodedData = encodeURIComponent(xml);
    downloadFile(fileName, encodedData);
  };

  const handleSaveSvg = async (): Promise<void> => {
    const file = uploadRef?.current?.files?.length ? uploadRef?.current?.files[0] : null;
    const fileName = file?.name?.replace('.bpmn', '.svg') || 'newDiagram.svg';
    const { svg } = await modeler.saveSVG({ format: true });
    const encodedData = encodeURIComponent(svg);
    downloadFile(fileName, encodedData);
  };

  const handleSendFile = async (): Promise<void> => {
    const { xml } = await modeler.saveXML({ format: true });
    const blob = new Blob([xml], { type: 'text/xml' });
    const formData = new FormData();
    formData.append('file', blob);

    const res = await request({
      url: '/process/deploy',
      method: 'POST',
      data: formData,
      baseURL: 'bpmg',
    });

    console.log('res: ', res);
  };

  useEffect(() => {
    modeler = new BpmnModeler({
      container: canvasRef.current,
      propertiesPanel: {
        parent: propertiesPanelRef.current,
      },
      additionalModules: [
        BpmnPropertiesPanelModule,
        BpmnPropertiesProviderModule,
        ZeebePropertiesProviderModule,
      ],
      moddleExtensions: {
        zeebe: ZeebeBpmnModdle,
      },
    });
    createNewDiagram();
  }, [canvasRef, propertiesPanelRef]);

  return (
    <div className={styles.container}>
      <section className={styles.content}>
        <div ref={canvasRef} className={styles.canvas} id="js-canvas" />
        <div ref={propertiesPanelRef} className={styles.propertiesPanel} id="js-properties-panel" />
      </section>
      <footer className={styles.footer}>
        <div className={styles.actions}>
          <input
            type={'file'}
            ref={uploadRef}
            style={{ display: 'none' }}
            onChange={handleOpenFile}
          />
          <AddSquareOutline onClick={handleUpload} />
          <CustomDivider />
          <SaveOutline onClick={handleSave} />
          <CustomDivider />
          <ImageSquareOutline onClick={handleSaveSvg} />
          <CustomDivider />
          <UploadOutline onClick={handleSendFile} />
        </div>
      </footer>
    </div>
  );
};
