import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const StarFill: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      d="M12 3s.291 5.473 1.91 7.09C15.526 11.71 21 12 21 12s-5.473.291-7.09 1.91C12.29 15.526 12 21 12 21s-.291-5.473-1.91-7.09C8.474 12.29 3 12 3 12s5.473-.291 7.09-1.91C11.71 8.474 12 3 12 3z"
      fill="#000"
    />
  </SvgIcon>
);
export default StarFill;
