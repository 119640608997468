import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Divider, Modal, Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import {
  approveSchedule,
  archiveSchedule,
  cancelApproveSchedule,
  cancelArchiveSchedule,
  getContentItemComments,
  getContentItemSchedules,
  getContentItemVersions,
  getProjectLocales,
} from 'api/content';
import { CommentType } from 'api/types/Comment';
import { ContentItemChangesType, Schedule } from 'api/types/ContentItem';
import { ContentType } from 'api/types/ContentType';
import { History, HistoryDiffs } from 'api/types/History';
import { Language } from 'api/types/Language';
import { User } from 'api/types/User';
import { Version as VersionType } from 'api/types/Version';
import { userGet } from 'api/users';
import { DATE_FORMAT } from 'common/constants/date';
import { statusChipMapping, StatusChipType, statuses } from 'common/constants/statuses';
import { AnchorElType } from 'common/types/AnchorElType';
import { formatDateTime2, formatTime, getParsedDate, parseNativeDate } from 'common/utils/date';
import { ButtonPelican } from 'components/ButtonPelican';
import { Card } from 'components/Card';
import Chip from 'components/Chip/Chip';
import { ArrowRight, Db } from 'components/IconPelican/icons';
import JSONPreview from 'components/JSONPreview/component';
import { SelectItem, SelectPelican } from 'components/SelectPelican';
import Spin from 'components/Spin';
import { SwitchField } from 'components/SwitchField';
import TabsPelican from 'components/TabsPelican';
import TabPanelPelican from 'components/TabsPelican/TabPanelPelican/component';
import { VerticalTab } from 'components/TabsPelican/VerticalTabs/VerticalTab/VerticalTab';
import { VerticalTabs } from 'components/TabsPelican/VerticalTabs/VerticalTabs';
import Locale from 'containers/Locale';
import { add, addMinutes, format } from 'date-fns';
import { useSnackbar } from 'notistack';
import { Comment } from 'pages/ContentEditorPage/ContentEditor/ContentEditorRightBar/ContentItemDetails/Comment';
import { CommentCreateForm } from 'pages/ContentEditorPage/ContentEditor/ContentEditorRightBar/ContentItemDetails/CommentCreateForm';
import { GET_AUDIT_TRAILS } from 'pages/ContentEditorPage/ContentEditor/ContentEditorRightBar/ContentItemDetails/queries';
import { ScheduleModal } from 'pages/ContentEditorPage/ContentEditor/ContentEditorRightBar/ContentItemDetails/ScheduleModal/component';
import { Version } from 'pages/ContentEditorPage/ContentEditor/ContentEditorRightBar/ContentItemDetails/Version';
import { useContentEditorProvider } from 'pages/ContentEditorPage/ContentEditor/useContentEditorProvider';
import { ContentEnvironment } from 'pages/Contents/ContentEnvironment/component';
import { ENVIRONMENTS_GET } from 'pages/Environments/components/EnvironmentList/queries';
import { EnvironmentItemDto } from 'pages/Environments/types/EnvironmentItemDto';
import React, { useCallback, useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getTypes } from 'store/contentSlice';
import { setAllVersions } from 'store/versionSlice';
import { getProjects } from 'store/workspaceSlice';
import { ToggleMenu } from 'templates/MainTemplate/TopNav/ToggleMenu/ToggleMenu';

import { ContentEditorNavigator } from './ContentEditorNavigator';
import { ContentItemHistory } from './ContentItemHistory/component';
import { ContentProjectSelect } from './ContentProjectSelect';
import { ContentItemDetailsProps } from './index';
import { useStyles } from './styles';

export const ContentItemDetails: React.FC<ContentItemDetailsProps> = ({
  contentItem,
  type,
  contentItemId,
  handleSelectLocales,
  handleShowOnlyMultilingual,
  toggledPanel: rightBarOn,
  openPanel,
  contentChangedProps,
  embedLevel,
  projectId,
  handleOpenEmbeddedItem,
}) => {
  const { fields, groupFields, groups, setContentChanged } = useContentEditorProvider();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const types: readonly ContentType[] = useSelector(getTypes);
  const classes = useStyles({ toggled: rightBarOn });
  const projects = useSelector(getProjects);
  const { enqueueSnackbar } = useSnackbar();
  const workFlowId = types.find((val) => val.slug === type)?.workflow_id;
  const contentTypeId = types.find((val) => val.slug === type)?.id;
  const [activeTab, setActiveTab] = useState(0);
  const [locales, setLocales] = useState<ReadonlyArray<Language>>([]);
  const [tabLoading, setTabLoading] = useState<boolean>(true);
  const [, setComments] = useState<ReadonlyArray<CommentType>>([]);
  const [commentsDated, setCommentsDated] = useState<
    ReadonlyArray<{
      readonly date: Date;
      readonly list: ReadonlyArray<CommentType>;
    }>
  >([]);
  const [versions, setVersions] = useState<ReadonlyArray<VersionType>>([]);
  const [selectedHistory, setSelectedHistory] = useState<SelectItem>();
  const [history, setHistory] = useState<ReadonlyArray<History>>([]);
  const [historyLoading, setHistoryLoading] = useState<boolean>();
  const [contentAuditLoaded, setContentAuditLoaded] = useState(false);
  const [currentHistory, setCurrentHistory] = useState<ReadonlyArray<History>>([]);
  const [historyChanged, setHistoryChanged] = useState(false);
  const [currentUser, setCurrentUser] = useState<User>();
  const [anchorEl, setAnchorEl] = useState<AnchorElType>(null);
  const isOpenUserPopup = Boolean(anchorEl);
  const [popoverIndex, setPopoverIndex] = useState<number | undefined>();
  const [currentWorkspace, setCurrentWorkspace] = useState<ContentType>();
  const [showSchedule, setShowSchedule] = useState<boolean>(false);
  const [showJSONModal, setShowJSONModal] = useState<boolean>(false);
  const [approveDate, setApproveDate] = useState<Date | undefined>(undefined);
  const [archiveDate, setArchiveDate] = useState<Date | undefined>(undefined);
  const [commentFormOpen, setCommentFormOpen] = useState(false);
  const [, setParsedApproveDate] = useState<string>('');
  const [, setParsedArchiveDate] = useState<string>('');
  const [totalVersions, setTotalVersions] = useState<number>();
  const [versionsPage, setVersionsPage] = useState<number>(1);
  const [versionsLoading, setVersionsLoading] = useState<boolean>(false);
  const [contentItemChanged, setContentItemChanged] = useState<number>();
  const [openResetScheduleModal, setOpenResetScheduleModal] = useState<boolean>(false);
  const [userLoading, setUserLoading] = useState(false);

  const onGetUser = useCallback(
    async (event: React.MouseEvent<React.ReactNode>, userId: string, index: number) => {
      setUserLoading(true);
      setAnchorEl(event.currentTarget);
      setPopoverIndex(index);
      try {
        const user = await userGet(userId);
        setCurrentUser(user);
      } catch (e) {
        enqueueSnackbar(
          `${t('Error on loading')} ${t('user data')} ${e.response?.data?.message || e}`,
          {
            variant: 'error',
          }
        );
      } finally {
        setUserLoading(false);
      }
    },
    [enqueueSnackbar, t]
  );

  const getUserName = (
    index: number,
    user?: User,
    username?: string,
    userId?: string
  ): JSX.Element | string => {
    return (
      <span>
        {user && (
          <Typography
            variant="overline"
            className={classes.infoText}
            onClick={(e) => onOpenUserPopup(e, user, index)}
          >
            {user.username}
          </Typography>
        )}
        {!user && username && userId && (
          <Typography
            variant="overline"
            className={classes.infoText}
            onClick={(e) => onGetUser(e, userId, index)}
          >
            {username}
          </Typography>
        )}
        <Popover
          open={isOpenUserPopup && popoverIndex === index}
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          keepMounted
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box className={classes.popover}>
            {userLoading ? (
              <Spin variant="inline" size={20} />
            ) : currentUser?.name || currentUser?.surname ? (
              <>
                {currentUser?.name || ''} {currentUser?.surname || ''}
              </>
            ) : (
              <Typography variant="h4" className={classes.empty}>
                {t('Name is empty')}
              </Typography>
            )}
          </Box>
        </Popover>
      </span>
    );
  };

  const onOpenUserPopup = useCallback(
    (event: React.MouseEvent<React.ReactNode>, user: User, index: number): void => {
      setCurrentUser(user);
      setAnchorEl(event.currentTarget);
      setPopoverIndex(index);
    },
    []
  );
  const getLocales = useCallback(
    async (projectSlug: string): Promise<void> => {
      setTabLoading(true);
      try {
        const list = await getProjectLocales(projectSlug);
        if (list.length > 3) {
          const newList = list.map((l, i) =>
            i === 0 || i === 1 || i === 2 ? { ...l, checked: true } : l
          );
          handleSelectLocales(newList.filter((l) => l.checked));
          setLocales(newList);
        } else {
          const newList = list.map((l) => ({ ...l, checked: true }));
          handleSelectLocales(newList);
          setLocales(newList);
        }
      } catch (e) {
        enqueueSnackbar(
          `${t('Error on loading')} ${t('locales')} ${e.response?.data?.message || e}`,
          {
            variant: 'error',
          }
        );
      } finally {
        setTabLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar, t]
  );

  const getVersions = useCallback(
    async (sourceId: number | undefined, page: number) => {
      page === 1 ? setTabLoading(true) : setVersionsLoading(true);
      try {
        if (contentItem.id && sourceId) {
          const res = await getContentItemVersions(type, page, sourceId);
          const newVersions =
            page !== 1 ? [...versions, ...Array.from(res.list)] : Array.from(res.list);
          page === 1 &&
            embedLevel === -1 &&
            contentItem.source_id &&
            dispatch(
              setAllVersions({
                versions: newVersions,
                sourceId: contentItem.source_id,
              })
            );
          setVersions(newVersions);
          setTotalVersions(res.totalCount);
          setVersionsPage(page + 1);
        } else {
          setVersions([]);
          setTotalVersions(undefined);
          setVersionsPage(1);
        }
      } catch (e) {
        enqueueSnackbar(
          `${t('Error on loading')} ${t('versions')} ${e.response?.data?.message || e}`,
          {
            variant: 'error',
          }
        );
      } finally {
        page === 1 ? setTabLoading(false) : setVersionsLoading(false);
      }
    },
    [
      contentItem.id,
      contentItem.source_id,
      type,
      versions,
      embedLevel,
      dispatch,
      enqueueSnackbar,
      t,
    ]
  );

  const getAllVersions = useCallback(
    async (sourceId: number) => {
      if (historyLoading === undefined) {
        setHistoryLoading(true);
        try {
          const res = await getContentItemVersions(type, 1, sourceId, 1000);
          dispatch(
            setAllVersions({
              versions: res.list,
              sourceId: sourceId,
            })
          );
          setVersions(res.list);
          setTotalVersions(res.totalCount);
          if (selectedHistory) {
            const newSelectedHistory = res.list.find((h) => h.data_id === selectedHistory.value);
            newSelectedHistory &&
              setSelectedHistory({
                value: newSelectedHistory.data_id,
                label: `${newSelectedHistory.status} v${newSelectedHistory.version}`,
              });
          }
        } catch (e) {
          enqueueSnackbar(
            `${t('Error on loading')} ${t('versions')} ${e.response?.data?.message || e}`,
            {
              variant: 'error',
            }
          );
        } finally {
          setHistoryLoading(false);
        }
      }
    },
    [dispatch, selectedHistory, type, t, enqueueSnackbar, historyLoading]
  );

  const getMoreVersions = useCallback(() => {
    contentItem.source_id && getVersions(contentItem.source_id, versionsPage);
  }, [versionsPage, getVersions, contentItem]);

  const [getAuditTrail, { data, error, loading }] = useLazyQuery(GET_AUDIT_TRAILS, {
    fetchPolicy: 'no-cache',
  });
  const { data: environmentsData, error: envError } = useQuery<{
    readonly publicationEnvironments: readonly EnvironmentItemDto[];
  }>(ENVIRONMENTS_GET);

  const environments = environmentsData?.publicationEnvironments;

  const getEnvironmentName = useCallback(
    (envId: string) => {
      return environments?.find((env) => env.id === envId)?.name;
    },
    [environments]
  );

  const getComments = useCallback(
    async (type: string, id: number) => {
      try {
        const res = await getContentItemComments(type, id);
        const dates = res.map((r) => ({
          ...r,
          trail_date_parsed: getParsedDate(r.trail_date),
          trail_date_formatted: format(getParsedDate(r.trail_date), DATE_FORMAT),
        }));
        const datesSorted = dates.sort(
          (a, b) => b.trail_date_parsed.getTime() - a.trail_date_parsed.getTime()
        );
        const commentsDatedSorted = datesSorted.reduce(
          (
            prev: ReadonlyArray<{
              readonly date: Date;
              readonly list: ReadonlyArray<CommentType>;
            }>,
            comm
          ) => {
            if (
              prev.length > 0 &&
              prev[prev.length - 1].date.getFullYear() === comm.trail_date_parsed.getFullYear() &&
              prev[prev.length - 1].date.getMonth() === comm.trail_date_parsed.getMonth() &&
              prev[prev.length - 1].date.getDate() === comm.trail_date_parsed.getDate()
            ) {
              return prev.slice(0, prev.length - 1).concat({
                date: prev[prev.length - 1].date,
                list: prev[prev.length - 1].list.concat(comm),
              });
            } else {
              return prev.concat({
                date: comm.trail_date_parsed,
                list: [comm],
              });
            }
          },
          []
        );
        setCommentsDated(commentsDatedSorted);
        setComments(res);
      } catch (e) {
        enqueueSnackbar(
          `${t('Error on loading')} ${t('comments')} ${e.response?.data?.message || e}`,
          {
            variant: 'error',
          }
        );
      }
    },
    [enqueueSnackbar, t]
  );

  const getSchedules = useCallback(async () => {
    try {
      const schedules = await getContentItemSchedules(type, Number(contentItemId));
      const resApprove = schedules.approve && schedules.approve.split(' ');
      const resArchive = schedules.archive && schedules.archive.split(' ');
      if (resApprove) {
        const approvedDate = resApprove[0] + 'T' + resApprove[1];
        const date = getParsedDate(approvedDate);
        const parsedUTCDate = addMinutes(date, -date.getTimezoneOffset());
        setApproveDate(parsedUTCDate);
      } else {
        setApproveDate(undefined);
      }
      if (resArchive) {
        const archivedDate = resArchive[0] + 'T' + resArchive[1];
        const date = getParsedDate(archivedDate);
        const parsedUTCDate = addMinutes(date, -date.getTimezoneOffset());
        setArchiveDate(parsedUTCDate);
      } else {
        setArchiveDate(undefined);
      }
    } catch (e) {
      enqueueSnackbar(
        `${t('Error on loading')} ${t('schedule')} ${e.response?.data?.message || e}`,
        {
          variant: 'error',
        }
      );
    }
  }, [type, contentItemId, enqueueSnackbar, t]);

  const handleConfirmSchedule = useCallback(
    async (publishSchedule: Schedule | undefined, unpublishSchedule: Schedule | undefined) => {
      if (publishSchedule) {
        const publishDate = format(publishSchedule.date, 'yyyy-MM-dd');
        const publishTime = format(publishSchedule.time, 'HH:mm:ss');
        const scheduledTime = `${publishDate} ${publishTime} ${publishSchedule.gmt}`;
        try {
          await approveSchedule(scheduledTime, type, contentItemId);
          !unpublishSchedule && setShowSchedule(false);
        } catch (e) {
          enqueueSnackbar(`${t('Error saving')} ${t(e.response?.data?.message || e)}`, {
            variant: 'error',
          });
        }
      }
      if (unpublishSchedule) {
        const unpublishDate = format(unpublishSchedule.date, 'yyyy-MM-dd');
        const unpublishTime = format(unpublishSchedule.time, 'HH:mm:ss');
        const unscheduledTime = `${unpublishDate} ${unpublishTime} ${unpublishSchedule.gmt}`;
        try {
          await archiveSchedule(unscheduledTime, type, contentItemId);
        } catch (e) {
          enqueueSnackbar(`${t('Error saving')} ${e.response?.data?.message || e}`, {
            variant: 'error',
          });
        }
        setShowSchedule(false);
      }
      getSchedules();
    },
    [getSchedules, enqueueSnackbar, type, contentItemId, t]
  );

  const handleResetSchedule = useCallback(async () => {
    try {
      await cancelApproveSchedule(type, contentItemId);
      await cancelArchiveSchedule(type, contentItemId);
    } catch (e) {
      enqueueSnackbar(
        `${t('Error on loading')} ${t('schedule')} ${t(e.response?.data?.message || e)}`,
        {
          variant: 'error',
        }
      );
    }
    setOpenResetScheduleModal(false);
    getSchedules();
  }, [getSchedules, type, contentItemId, enqueueSnackbar, t]);

  const handleToggleLocale = useCallback(
    (id: number, checked?: boolean): void => {
      const newLocales = locales.map((l: Language) =>
        l.id === id ? { ...l, checked: !checked } : l
      );
      setLocales(newLocales);
      handleSelectLocales(newLocales);
    },
    [locales, handleSelectLocales]
  );

  const handleChangeTab = useCallback(
    (tabId: number): void => {
      setActiveTab(tabId);
      if (tabId === 4) {
        getComments(type, Number(contentItemId));
      }

      if (rightBarOn) {
        openPanel();
      }
    },
    [contentItemId, getComments, openPanel, type, rightBarOn]
  );
  // TODO: change function name; add functionality for auditEventTypes like: CREATED, DELETED, READ; create an interface;
  const getHistoryChanges = useCallback(
    (
      diffs: HistoryDiffs
    ): ReadonlyArray<{
      readonly field_name: string;
      readonly oldValue?: ContentItemChangesType;
      readonly newValue?: ContentItemChangesType;
    }> => {
      try {
        const { auditEventType } = diffs;
        if (auditEventType === 'EDITED') {
          const changes = Object.entries(diffs.changes || {}).map(([key, val]) => {
            return {
              field_name: key,
              ...val,
            };
          });

          return +diffs.dataId === (selectedHistory?.value || +contentItemId) && diffs.slug === type
            ? changes
            : [];
        }
      } catch (e) {
        console.error(e);
      }

      return [{ field_name: '' }];
    },
    [contentItemId, selectedHistory, type]
  );

  /* const handlePublishAt = useCallback(() => {
    const { requireValid, minMaxValid } = handleValidate();
    if (requireValid && minMaxValid) {
      setShowSchedule(true);
    } else {
      !requireValid && enqueueSnackbar(t('Fill requires first'), { variant: 'warning' });
      !minMaxValid && enqueueSnackbar(t('There is incorrect'), { variant: 'warning' });
    }
  }, [enqueueSnackbar, handleValidate, t]);*/

  const handleSelectedVersion = useCallback((val) => {
    setSelectedHistory(val);
    setHistoryChanged(true);
  }, []);

  const addCommentClickHandler = useCallback(() => {
    setCommentFormOpen(true);
  }, []);

  const handleCommentSubmitted = useCallback(() => {
    setCommentFormOpen(false);
    getComments(type, Number(contentItemId));
  }, [contentItemId, getComments, type]);

  const handleCommentFormClose = useCallback(() => {
    setCommentFormOpen(false);
  }, []);

  useEffect(() => {
    const auditHistory = ((data?.audit_pageable?.data as ReadonlyArray<History>) || [])
      .filter((h: History) => ['EDITED', 'CREATE', 'NEW_VERSION'].includes(h.auditEventType))
      .slice()
      .sort(
        (a: History, b: History) =>
          new Date(b.trailDate).getTime() - new Date(a.trailDate).getTime()
      );

    if (auditHistory) {
      setCurrentHistory((prev) =>
        prev.length === 0
          ? auditHistory.filter((h: History) => h.eventType !== 'RELEASE_CONTENT_TYPE')
          : prev
      );
      setHistory(auditHistory);
    }
  }, [contentItemId, data, selectedHistory, type]);

  useEffect(() => {
    const project = projects.find((w) => w.id === projectId);
    project && getLocales(project.slug);
  }, [getLocales, projectId, projects]);

  useEffect(() => {
    if (workFlowId === 3 && contentItemId !== '-1') {
      getSchedules();
    }
  }, [workFlowId, getSchedules, contentItemId]);

  useEffect(() => {
    if (contentChangedProps !== contentItemChanged && contentChangedProps !== 0) {
      getVersions(contentItem.source_id, 1);
      setContentItemChanged(contentChangedProps);
    }
  }, [
    getVersions,
    contentItem,
    contentItemId,
    contentChangedProps,
    setContentItemChanged,
    contentItemChanged,
  ]);

  useEffect(() => {
    setCurrentWorkspace(types.find((t) => t.slug === type));
  }, [type, types]);

  useEffect(() => {
    if (approveDate) {
      setParsedApproveDate(format(approveDate, 'dd-MM-yy / HH:mm'));
    } else {
      setParsedApproveDate('');
    }
    if (archiveDate) {
      setParsedArchiveDate(format(archiveDate, 'dd-MM-yy / HH:mm'));
    } else {
      setParsedArchiveDate('');
    }
  }, [archiveDate, approveDate]);

  useEffect(() => {
    if (
      contentItemId !== '-1' &&
      (([1, 2].indexOf(activeTab) > -1 && !rightBarOn && !contentAuditLoaded) ||
        (contentChangedProps !== contentItemChanged &&
          contentChangedProps !== 0 &&
          historyLoading === false) ||
        historyChanged)
    ) {
      getAuditTrail({
        variables: {
          page: 1,
          size: 1000,
          dataId: selectedHistory?.value || contentItemId,
          contentTypeId,
        },
      });

      setHistoryChanged(false);
      setContentItemChanged(contentChangedProps);
      setContentAuditLoaded(true);
    }
  }, [
    activeTab,
    contentChangedProps,
    contentItem.source_id,
    contentItemId,
    contentItemChanged,
    getAllVersions,
    historyLoading,
    getAuditTrail,
    selectedHistory,
    type,
    historyChanged,
    rightBarOn,
    contentAuditLoaded,
    contentTypeId,
  ]);

  useEffect(() => {
    error &&
      enqueueSnackbar(`${t('Error on loading')} ${t('audit trail')} ${error.message}`, {
        variant: 'error',
      });
  }, [enqueueSnackbar, error, t]);

  useEffect(() => {
    envError &&
      enqueueSnackbar(`${t('Error loading environments')} ${envError.message}`, {
        variant: 'error',
      });
  }, [enqueueSnackbar, envError, t]);

  useEffect(() => {
    activeTab === 1 && contentItem.source_id && getAllVersions(+contentItem.source_id);
  }, [activeTab, contentItem.source_id, getAllVersions]);

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <ToggleMenu
          variant="right"
          toggle={openPanel}
          value={rightBarOn}
          className={classes.rightBarToggler}
        />
        <div className={classes.verticalTabWrapper}>
          <VerticalTabs>
            <VerticalTab index={0} value={activeTab} handleTabClick={handleChangeTab}>
              {t('Table of contents')}
            </VerticalTab>
            <VerticalTab index={1} value={activeTab} handleTabClick={handleChangeTab}>
              {t('Information')}
            </VerticalTab>
            <VerticalTab index={2} value={activeTab} handleTabClick={handleChangeTab}>
              {t('History')}
            </VerticalTab>
            <VerticalTab index={3} value={activeTab} handleTabClick={handleChangeTab}>
              {t('Versions')}
            </VerticalTab>
            <VerticalTab index={4} value={activeTab} handleTabClick={handleChangeTab}>
              {t('Comments')}
            </VerticalTab>
          </VerticalTabs>
        </div>
      </div>
      <Scrollbars autoHide autoHideTimeout={1000} style={{ height: '100%' }}>
        <div className={classes.tabsWrapper}>
          <TabsPelican
            value={activeTab}
            onChange={(_, newValue: number) => handleChangeTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            className={classes.tabs}
          />
          <TabPanelPelican padding={'0px'} value={activeTab} index={0}>
            <ContentEditorNavigator fields={fields} groups={groups} groupFields={groupFields} />
          </TabPanelPelican>
          <TabPanelPelican value={activeTab} index={1} padding={'0'}>
            {tabLoading || loading ? (
              <div className={classes.spin}>
                <Spin size={60} variant={'inline'} />
              </div>
            ) : (
              <div style={{ width: '100%' }}>
                <Typography className={classes.headTitle} variant={'h5'}>
                  {t('Information')}
                </Typography>
                <ContentProjectSelect />
                <div id="content-item-environments" className={classes.environments}>
                  <ContentEnvironment
                    environments={environments}
                    contentItem={contentItem}
                    contentTypeId={contentTypeId || 0}
                    handleDeploy={() => setContentChanged((prev) => prev + 1)}
                  />
                </div>
                <Divider color={'#F7F8FA'} />

                {/* TODO: Temporary commented
                 {workFlowId === 3 && contentItemId !== '-1' && (
                  <div>
                    <div className={classes.flexBetween}>
                      <Typography variant="h4" className={classes.title}>
                        {t('Schedule')}
                      </Typography>
                      {(parsedApproveDate || parsedArchiveDate) && (
                        <Typography
                          onClick={() => setOpenResetScheduleModal(true)}
                          variant="body2"
                          className={classes.subTitle}
                        >
                          Сбросить
                        </Typography>
                      )}
                    </div>
                    <div className={classes.block}>
                      <Typography variant="body2">
                        {parsedApproveDate === '' ? t('Publish') : t('Publish at')}
                        <span className={classes.publishAt}>{parsedApproveDate}</span>
                      </Typography>
                      <div className={classes.flex}>
                        <IconButton
                          onClick={handlePublishAt}
                          className={classes.editScheduleButton}
                        >
                          <EditIcon className={classes.editScheduleIcon} />
                        </IconButton>
                      </div>
                    </div>
                    <div className={classes.block}>
                      <Typography variant="body2">
                        {parsedArchiveDate === '' ? t('Unpublish') : t('Unpublish at')}
                        <span className={classes.publishAt}>{parsedArchiveDate}</span>
                      </Typography>
                      <div className={classes.flex}>
                        <IconButton
                          onClick={() => setShowSchedule(true)}
                          className={classes.editScheduleButton}
                        >
                          <EditIcon className={classes.editScheduleIcon} />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                )}*/}
                {/* <div className={classes.flexBetween}>
                  <Typography variant="h4" className={classes.title}>
                    {t('Export')}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.subTitle}
                    onClick={() => setShowJSONModal(true)}
                  >
                    {t('JSON Preview')}
                  </Typography>
                </div>
                <Card
                  items={[
                    <FileDownload key={'pdf'} label={t('PDF')} />,
                    <FileDownload key={'excel'} label={t('Excel')} />,
                  ]}
                  delimiter={
                    <Divider
                      style={{ width: 'calc(100% - 30px)', alignSelf: 'flex-end' }}
                      color={'#D3D4D6'}
                    />
                  }
                />*/}
                <Typography variant="h4" className={classes.title}>
                  {t('Localizations')}
                </Typography>
                {locales && (
                  <div>
                    <div className={classes.multilingualSwitch}>
                      <SwitchField
                        id="only multilingual"
                        handleChange={(checked: boolean) => handleShowOnlyMultilingual(checked)}
                        label={t('Show only multilingual fields')}
                      />
                    </div>
                    <Card
                      items={locales.map((l: Language, i: number) => (
                        <Locale
                          id={l.id}
                          key={i}
                          code={l.code}
                          label={l.description}
                          checked={l.checked}
                          onToggle={handleToggleLocale}
                        />
                      ))}
                      delimiter={
                        <Divider
                          style={{ width: 'calc(100% - 50px)', alignSelf: 'flex-end' }}
                          color={'#D3D4D6'}
                        />
                      }
                    />
                  </div>
                )}
                <table className={classes.infoTable}>
                  <tbody>
                    <tr>
                      <td className={classes.infoLabel}>{t('Entry ID')}:</td>
                      <td className={classes.infoValue}>{contentItemId}</td>
                    </tr>
                    <tr>
                      <td className={classes.infoLabel}>{t('Source ID')}:</td>
                      <td className={classes.infoValue}>{contentItem.source_id}</td>
                    </tr>
                    <tr>
                      <td className={classes.infoLabel}>{t('Model code')}:</td>
                      <td className={classes.infoValue}>{currentWorkspace?.slug}</td>
                    </tr>
                    <tr>
                      <td className={classes.infoLabel}>{t('Model name')}:</td>
                      <td className={classes.infoValue}>{currentWorkspace?.description}</td>
                    </tr>
                    <tr>
                      <td className={classes.infoLabel}>{t('Created at')}:</td>
                      <td className={classes.infoValue}>
                        {currentHistory.length > 0 &&
                          formatDateTime2(
                            parseNativeDate(currentHistory[currentHistory.length - 1]?.trailDate),
                            DATE_FORMAT
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.infoLabel}>{t('Created by')}:</td>
                      <td className={classes.infoValue}>
                        {getUserName(
                          -1,
                          undefined,
                          currentHistory[currentHistory.length - 1]?.username || '',
                          currentHistory[currentHistory.length - 1]?.userId
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.infoLabel}>{t('Last updated at')}:</td>
                      <td className={classes.infoValue}>
                        {currentHistory.length > 0 &&
                          formatDateTime2(
                            parseNativeDate(currentHistory[0]?.trailDate),
                            DATE_FORMAT
                          )}
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.infoLabel}>{t('Updated by')}:</td>
                      <td className={classes.infoValue}>
                        {getUserName(
                          -2,
                          undefined,
                          currentHistory[0]?.username || '',
                          currentHistory[0]?.userId
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </TabPanelPelican>
          <TabPanelPelican value={activeTab} index={2} padding={'0'}>
            <Typography className={classes.headTitle} variant={'h5'}>
              {t('History')}
            </Typography>
            {contentItemId !== '-1' && !historyLoading && !loading && (
              <div className={classes.infoTable}>
                <div className={classes.historyFilters}>
                  <div className={classes.versionSelect}>
                    <SelectPelican
                      id="versions"
                      placeholder="Select versions"
                      options={versions.map((v) => ({
                        label: `${v.status} v${v.version}`,
                        value: v.data_id,
                      }))}
                      defaultValue={
                        versions[0] && {
                          label: `${versions[0].status} v${versions[0].version}`,
                          value: versions[0].data_id,
                        }
                      }
                      value={selectedHistory}
                      selected={handleSelectedVersion}
                      multiButtons={false}
                    />
                  </div>
                </div>
                {history.map((h: History, index) => (
                  <Card style={{ marginBottom: 15 }} key={h.dataId}>
                    <div style={{ padding: 12 }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar alt="User avatar" src="" className={classes.avatar} />
                        <div style={{ marginLeft: '8px' }}>
                          <div>
                            {getUserName(index * -1 - 3, undefined, h.username, h.userId)}
                            {h.auditEventType !== 'RELEASE_CONTENT_TYPE' && (
                              <span className={classes.eventType}>{h?.auditEventType}</span>
                            )}
                          </div>
                          <div className={classes.historyDetails}>
                            {formatDateTime2(parseNativeDate(h.trailDate), DATE_FORMAT)}
                          </div>
                        </div>
                      </div>
                      {h.eventType === 'RELEASE_CONTENT_TYPE' && (
                        <div style={{ marginTop: 8 }}>
                          <span style={{ fontWeight: 'bold' }}>{t('Environment')}</span>
                          <div className={classes.releaseContainer}>
                            <div className={classes.environment}>
                              <Db />
                              <span className={classes.environmentName}>
                                {getEnvironmentName(h.envFrom)}
                              </span>
                            </div>
                            <ArrowRight htmlColor="#8F8F91" />
                            <div className={classes.environment}>
                              <Db />
                              <span className={classes.environmentName}>
                                {getEnvironmentName(h.envTo)}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {h.dataDiffs &&
                        h.eventType !== 'RELEASE_CONTENT_TYPE' &&
                        h.dataDiffs.map((dataDiff) =>
                          getHistoryChanges(dataDiff).map(
                            (
                              k: Readonly<{
                                readonly field_name: string;
                                readonly oldValue?: ContentItemChangesType;
                                readonly newValue?: ContentItemChangesType;
                              }>
                            ) => {
                              const { field_name } = k;
                              const field = fields?.find(
                                (f) => !f.embedded && f.slug === field_name
                              );
                              return !field ? null : (
                                <ContentItemHistory
                                  handleOpenEmbeddedItem={handleOpenEmbeddedItem}
                                  field={field}
                                  oldValue={k?.oldValue}
                                  newValue={k?.newValue}
                                  contentItem={contentItem}
                                />
                              );
                            }
                          )
                        )}
                      {fields
                        ?.filter(
                          (f) =>
                            f.embedded &&
                            h.dataDiffs &&
                            h.dataDiffs.some((diff) => diff.changes[f.slug])
                        )
                        .map((f) => {
                          const oldValues = h.dataDiffs
                            .filter((diff) => diff.changes[f.slug]?.oldValue)
                            .map((diff) => diff.changes[f.slug].oldValue);
                          const newValues = h.dataDiffs
                            .filter((diff) => diff.changes[f.slug]?.newValue)
                            .map((diff) => diff.changes[f.slug].newValue);
                          return (
                            <ContentItemHistory
                              key={f.slug}
                              handleOpenEmbeddedItem={handleOpenEmbeddedItem}
                              field={f}
                              oldValue={oldValues}
                              newValue={newValues}
                              contentItem={contentItem}
                            />
                          );
                        })}
                      {h.dataDiffs &&
                        h.dataDiffs[0] &&
                        fields
                          ?.filter(
                            (f) =>
                              f.multilingual &&
                              Object.values(h.dataDiffs[0].multilingual || {}).some(
                                (v) => v[f.slug]
                              )
                          )
                          .map((f) =>
                            Object.entries(h.dataDiffs[0].multilingual || {})
                              .filter(([_key, value]) => value[f.slug])
                              .map(([key, value]) => {
                                const k = value[f.slug];
                                return (
                                  <ContentItemHistory
                                    key={key}
                                    locale={key}
                                    field={f}
                                    oldValue={k?.oldValue}
                                    newValue={k?.newValue}
                                    contentItem={contentItem}
                                  />
                                );
                              })
                          )}
                      {h.oldEventState !== h.newEventState && (
                        <ContentItemHistory
                          field={{ label: t('Status'), slug: 'status', type: 'TEXT', id: 0 }}
                          oldValue={t(
                            statuses.find((s) => s.code === h.oldEventState)?.name ||
                              h.oldEventState ||
                              ''
                          )}
                          newValue={t(
                            statuses.find((s) => s.code === h.newEventState)?.name ||
                              h.newEventState ||
                              ''
                          )}
                          contentItem={contentItem}
                        />
                      )}
                    </div>
                    {h.eventType !== 'RELEASE_CONTENT_TYPE' && <Divider color="#D3D4D6" />}
                    {h.eventType !== 'RELEASE_CONTENT_TYPE' && (
                      <footer
                        style={{
                          height: 32,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {h.newEventState || h.oldEventState ? (
                          <Chip
                            variant={
                              statusChipMapping[
                                ((h.newEventState || h.oldEventState) as keyof StatusChipType) ||
                                  'draft'
                              ] as 'success' | 'error' | 'primary' | 'disabled' | 'default'
                            }
                          >
                            {h.newEventState || h.oldEventState}
                          </Chip>
                        ) : (
                          <Typography style={{ color: '#545456' }}>{`${h.dataDiffs?.length} ${t(
                            'Changes'
                          )}`}</Typography>
                        )}
                      </footer>
                    )}
                  </Card>
                ))}
              </div>
            )}
            {(historyLoading || loading) && (
              <div className={classes.spin}>
                <Spin size={60} variant={'inline'} />
              </div>
            )}
          </TabPanelPelican>
          <TabPanelPelican value={activeTab} index={3} padding={'0'}>
            <Typography variant="h5" className={classes.headTitle}>
              {t('Versions')}
            </Typography>
            <div className={classes.versions}>
              <Card
                items={versions.map((v, i) => (
                  <Version
                    key={v.data_id}
                    currentId={Number(contentItemId)}
                    version={v}
                    embedLevel={embedLevel}
                    author={getUserName(i, v.user)}
                  />
                ))}
                delimiter={<Divider color="#D3D4D6" style={{ width: '100%' }} />}
              />
              {versions.length < (totalVersions || 0) && !versionsLoading && (
                <ButtonPelican
                  variant="text"
                  className={classes.showMore}
                  onClick={getMoreVersions}
                >
                  {t('More versions')} ({(totalVersions || 0) - versions.length})
                </ButtonPelican>
              )}
              {versionsLoading && (
                <div className={classes.spin}>
                  <Spin variant="inline" />
                </div>
              )}
            </div>
          </TabPanelPelican>
          <TabPanelPelican value={activeTab} index={4} padding={'0'}>
            <>
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <Typography variant={'h5'} className={classes.headTitle}>
                  {t('Comments')}
                </Typography>
                {!commentFormOpen && (
                  <ButtonPelican
                    className={classes.createCommentButton}
                    variant="contained"
                    color="primary"
                    onClick={addCommentClickHandler}
                  >
                    {t('Add')}
                  </ButtonPelican>
                )}
              </div>
              {commentFormOpen && (
                <CommentCreateForm
                  contentItemId={contentItemId}
                  type={type}
                  onCommentSubmitted={handleCommentSubmitted}
                  onClose={handleCommentFormClose}
                />
              )}
              {commentsDated.map((comm, i) => (
                <>
                  <div className={classes.flexBetween}>
                    <Typography variant="h4" className={classes.title}>
                      {format(comm.date, 'yyyy-MM-dd')}
                    </Typography>
                  </div>
                  <Card
                    items={comm.list.map((c: CommentType) => (
                      <Comment
                        key={c.id}
                        avatar={'/assets/ava.jpg'}
                        text={c.message}
                        author={getUserName(i, c.user)}
                        time={formatTime(add(getParsedDate(c.trail_date), { hours: 6 }))}
                      />
                    ))}
                    delimiter={<Divider color="#D3D4D6" />}
                  />
                </>
              ))}
            </>
          </TabPanelPelican>

          <Modal
            open={openResetScheduleModal}
            onClose={() => setOpenResetScheduleModal(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div className={classes.paper}>
              <div className={classes.modalHeader}>
                <Typography variant="h4">Сбросить публикацию</Typography>
              </div>
              <div className={classes.modalBody}>
                <Typography variant="body2">Вы уверены что хотите очистить публикацию?</Typography>
              </div>
              <div className={classes.modalFooter}>
                <div>
                  <ButtonPelican onClick={handleResetSchedule} variant="contained" color="danger">
                    {t('Reset')}
                  </ButtonPelican>
                  <ButtonPelican variant="text" color="primary">
                    {t('Cancel')}
                  </ButtonPelican>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            open={showSchedule}
            onClose={() => setShowSchedule(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <ScheduleModal
              approveDate={approveDate}
              archiveDate={archiveDate}
              onConfirm={handleConfirmSchedule}
              onClose={() => setShowSchedule(false)}
            />
          </Modal>
          <JSONPreview
            open={showJSONModal}
            json={JSON.parse(JSON.stringify(contentItem))}
            onClose={() => setShowJSONModal(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          />
        </div>
      </Scrollbars>
    </div>
  );
};
