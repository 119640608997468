import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const PenCrossedFill: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.993 15.067L8.94 12.12l2.934 2.934-2.945 2.946-4.107 1.842a.5.5 0 01-.661-.66l1.833-4.116zm8.002-2.133L11.06 10 6.103 5.042c-.444-.443-1.04-.566-1.334-.273-.293.293-.17.89.273 1.334l12.855 12.855c.444.443 1.04.566 1.334.273.292-.293.17-.89-.274-1.334l-4.963-4.963zm3.352-3.353l-2.292 2.292-2.934-2.934 2.292-2.292 2.934 2.934zm2.748-2.748a.944.944 0 000-1.336l-1.598-1.598a.944.944 0 00-1.336 0l-1.734 1.734 2.934 2.934 1.734-1.734z"
      fill="#000"
    />
  </SvgIcon>
);
export default PenCrossedFill;
