import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const PenFill: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      d="M5.993 15.067L4.16 19.183a.5.5 0 00.661.66L8.927 18l8.42-8.42-2.934-2.934-8.42 8.42zm14.102-8.234a.944.944 0 000-1.336l-1.598-1.598a.944.944 0 00-1.336 0l-1.734 1.734 2.934 2.934 1.734-1.734z"
      fill="currentColor"
    />
  </SvgIcon>
);
export default PenFill;
