import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const DuplicateOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.838 5.117C3.5 5.767 3.5 6.622 3.5 8.33v3.94c0 1.709 0 2.563.338 3.213a3 3 0 001.28 1.28c.437.226.966.3 1.787.325l.004.026c.01.198.02.443.043.659.036.343.109.743.29 1.131.188.4.483.77.931 1.055.44.279.991.453 1.662.524l.04.005h5.623l.364.002h.002c1.383.01 2.134.017 2.913-.293l.032-.013.032-.016c.29-.148.55-.3.781-.531.226-.225.382-.485.536-.777.227-.402.284-.84.309-1.318.014-.27.018-.61.023-1.019.004-.3.009-.64.018-1.013V10.142l-.002-.03c-.114-1.47-.675-2.351-1.516-2.798-.623-.33-1.35-.363-1.753-.381l-.148-.01c-.024-.83-.098-1.365-.327-1.806a3 3 0 00-1.28-1.28C14.833 3.5 13.98 3.5 12.27 3.5H8.33c-1.708 0-2.563 0-3.213.338a3 3 0 00-1.28 1.28zm1.2 1.334C5 6.883 5 7.45 5 8.33v3.94c0 .88.001 1.447.037 1.88.034.413.093.566.132.641a1.5 1.5 0 00.64.64c.075.04.228.098.642.132.432.036 1 .037 1.879.037h3.94c.88 0 1.447-.001 1.88-.037.413-.034.566-.093.641-.132a1.5 1.5 0 00.64-.64c.04-.075.098-.228.132-.642.036-.432.037-1 .037-1.879V8.33c0-.88-.001-1.447-.037-1.88-.034-.413-.093-.566-.132-.641a1.5 1.5 0 00-.64-.64c-.075-.04-.228-.098-.642-.132-.432-.036-1-.037-1.879-.037H8.33c-.88 0-1.447.001-1.88.037-.413.034-.566.093-.641.132a1.5 1.5 0 00-.64.64c-.04.075-.098.228-.132.642zM17.1 12.27c0 1.709 0 2.563-.338 3.213a3 3 0 01-1.28 1.28c-.65.337-1.503.337-3.212.337H8.41l.002.023c.007.165.013.316.032.493.027.262.075.477.157.651a.973.973 0 00.376.425c.191.122.494.24.98.296h5.616c1.678.01 2.133.013 2.619-.173.225-.117.311-.18.37-.239.063-.063.136-.162.274-.425l.007-.013.007-.012c.048-.083.096-.224.119-.661.013-.25.016-.54.02-.912.004-.297.009-.646.019-1.07V10.2c-.09-1.093-.46-1.423-.72-1.561-.3-.158-.618-.177-1.021-.2l-.167-.015v3.845z"
      fill="#000"
    />
  </SvgIcon>
);
export default DuplicateOutline;
