import { CloseButton } from 'containers/SnackbarProvider/CloseButton';
import { SnackbarKey, SnackbarProvider as BaseSnackbarProvider } from 'notistack';
import React from 'react';

export const SnackbarProvider: React.FC = ({ children }) => {
  return (
    <BaseSnackbarProvider
      maxSnack={5}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      autoHideDuration={3000}
      action={(key: SnackbarKey) => <CloseButton snackKey={key} />}
    >
      {children}
    </BaseSnackbarProvider>
  );
};

export default SnackbarProvider;
