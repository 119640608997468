import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback, useState } from 'react';
import InputMask, { InputState } from 'react-input-mask';

import { Eye, EyeClose } from '../IconPelican/icons';
import { Props } from './props';
import { useStyles } from './styles';
// Todo: value not works, instead we using default value. But, should fix value. Test in embed content item.
export const TextInput: React.FC<Props> = ({
  placeholder,
  type = 'text',
  defaultValue = '',
  textMask,
  readOnly,
  inputClassName,
  size,
  ...rest
}: Props) => {
  const [initialValue, setInitialValue] = useState<string>(defaultValue);
  const [showPassword, setShowPassword] = useState(false);
  const classes = useStyles();

  const beforeMaskedStateChange = useCallback((newState: InputState): InputState => {
    setInitialValue(newState.value);
    return newState;
  }, []);

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword: React.MouseEventHandler<HTMLButtonElement> = (event): void => {
    event.preventDefault();
  };

  return (
    <div className={inputClassName} id={rest.id}>
      {textMask ? (
        <InputMask
          mask={textMask}
          value={initialValue}
          disabled={false}
          beforeMaskedValueChange={beforeMaskedStateChange}
          maskChar={'_'}
        >
          {() => (
            <TextField
              variant="outlined"
              placeholder={placeholder}
              value={initialValue}
              type={type}
              className={clsx(classes.root, size === 'small' && classes.variantSizeSmall)}
              inputProps={{ readOnly }}
              {...rest}
            />
          )}
        </InputMask>
      ) : (
        <TextField
          variant="outlined"
          placeholder={placeholder}
          defaultValue={defaultValue}
          type={!showPassword ? type : 'text'}
          className={clsx(
            classes.root,
            size === 'small' && classes.variantSizeSmall,
            rest.className,
            rest.InputProps?.startAdornment && classes.paddingWithIcon
          )}
          inputProps={{ readOnly }}
          data-cy="input-field"
          InputProps={{
            endAdornment:
              type === 'password' ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    style={{ color: '#545456', marginRight: 8, padding: 0 }}
                  >
                    {!showPassword ? <Eye /> : <EyeClose />}
                  </IconButton>
                </InputAdornment>
              ) : undefined,
          }}
          {...rest}
        />
      )}
    </div>
  );
};
