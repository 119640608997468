import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  tabs: {
    marginRight: 12,
    marginLeft: 20,
  },
  stickyDiv: {
    backgroundColor: 'white',
    zIndex: 2,
    borderBottom: '1px solid #e8e8e8',
    marginTop: 4,
  },
}));
