import FileCopyIcon from '@material-ui/icons/FileCopy';
import { ButtonPelican } from 'components/ButtonPelican';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalPelican } from '../ModalPelican';
import { Props } from './props';
import { useStyles } from './styles';

const JSONPreview: React.FC<Props> = ({ onClose, json, open, ...rest }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(JSON.stringify(json, null, 3));
    enqueueSnackbar(t('Successfully copied'), { variant: 'success' });
  }, [t, enqueueSnackbar, json]);

  return (
    <ModalPelican
      open={open}
      onClose={onClose}
      title="Export"
      footer={
        <ButtonPelican variant="text" onClick={onClose}>
          {t('Cancel')}
        </ButtonPelican>
      }
      {...rest}
    >
      <div className={styles.body}>
        <pre className={styles.jsonContainer}>{JSON.stringify(json, null, 3)}</pre>
        <FileCopyIcon onClick={() => copyToClipboard()} className={styles.copyJsonFile} />
      </div>
    </ModalPelican>
  );
};

export default JSONPreview;
