import { Typography } from '@material-ui/core';
import { PlanetOutline } from 'components/IconPelican/icons';
import { VideoUpload } from 'components/VideoUpload';
import { fieldToRules } from 'pages/ContentEditorPage/ContentEditor/helpers';
import React, { useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useContentEditorProvider } from '../../useContentEditorProvider';
import { Props } from './props';
import { useStyles } from './styles';

export const ContentUploadInput: React.FC<Props> = ({ field }) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const {
    form,
    embedLevel,
    isReviewing,
    selectedCheckedLocales,
    multilingual,
    setMultilingualValue,
    handleFieldChange,
  } = useContentEditorProvider();

  const { control, setValue, getValues } = form;

  const inputValue = getValues(field.slug);

  const hasLocales = useMemo(() => selectedCheckedLocales.length > 0, [selectedCheckedLocales]);

  const isMultilingual = useMemo(() => field.multilingual, [field]);

  const handleSelectFile = useCallback(
    (slug: string, selectedLink?: string): void => {
      setValue(slug, selectedLink);
    },
    [setValue]
  );

  const RegularInput = (): JSX.Element => (
    <Controller
      name={field.slug}
      as={VideoUpload}
      control={control}
      slug={field.slug}
      slugId={`${field.slug}-${embedLevel}`}
      uploadType={
        field.type === 'ICON'
          ? 'image'
          : (field?.type?.toLowerCase() as 'audio' | 'video' | 'image' | 'document')
      }
      link={inputValue}
      onChangeValue={(selectedLink?: string) => {
        handleSelectFile(field.slug, selectedLink);
        handleFieldChange(field.slug);
      }}
      disabled={isReviewing}
      rules={fieldToRules(field)}
    />
  );

  const MultilingualInput = (): JSX.Element => (
    <>
      {hasLocales &&
        selectedCheckedLocales.map((l) => (
          <div key={l.id} className={styles.multilangual}>
            <div className={styles.locale}>
              <PlanetOutline fontSize="small" htmlColor="#0052CC" />
              <span>{l.code}</span>
            </div>
            <VideoUpload
              link={multilingual[l.code] && multilingual[l.code][field.slug]}
              uploadType={
                field.type === 'ICON'
                  ? 'image'
                  : ((field.type || 'image').toLowerCase() as
                      | 'audio'
                      | 'video'
                      | 'image'
                      | 'document')
              }
              onChangeValue={(selectedLink?: string) => {
                setMultilingualValue({
                  ...multilingual,
                  [l.code]: { ...(multilingual?.[l.code] || {}), [field.slug]: selectedLink },
                });
                handleFieldChange(l.code + '$' + field.slug);
              }}
              slug={`${field.slug}-${l.code}-${embedLevel}`}
              slugId={`${field.slug}-${l.code}-${embedLevel}`}
              disabled={isReviewing}
            />
          </div>
        ))}
      {!hasLocales && (
        <Typography variant="h4" className={styles.emptyLocale}>
          {t('Choose locales')}
        </Typography>
      )}
    </>
  );

  return (
    <>
      {!isMultilingual && RegularInput()}
      {isMultilingual && MultilingualInput()}
    </>
  );
};

export default ContentUploadInput;
