import { IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { CancelOutline } from 'components/IconPelican/icons';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useStatusStyle } from 'theme/status-styles';

import { Props } from './props';
import { useStyles } from './styles';

export const ContentTab: React.FC<Props> = ({ tab, handleRemove }) => {
  const styles = useStyles();
  const statusStyles = useStatusStyle();
  const history = useHistory();
  const splitted = useLocation().pathname.split('/');
  const active =
    splitted.length === 4 &&
    splitted.includes('content') &&
    splitted[2] === tab.contentTypeSlug &&
    splitted[3] === String(tab.contentItemId);

  const redirectToContent = useCallback(() => {
    history.push(`/content/${tab.contentTypeSlug}/${tab.contentItemId}`);
  }, [history, tab]);

  return (
    <div className={clsx(styles.tab, active && styles.active)}>
      <div
        className={clsx(
          styles.filledRound,
          tab.hasDraftVersion && (tab.status === 'published' || tab.status === 'draft')
            ? styles.multiFilled
            : statusStyles[tab.status]
        )}
      />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={redirectToContent} className={styles.cursorPointer}>
        <span className={styles.tabInfo}>
          <span className={styles.tabLabel}>{tab.contentTypeLabel}</span>
          {tab.contentItemId}
        </span>
      </div>
      <IconButton className={styles.close} size="small" onClick={handleRemove}>
        <CancelOutline style={{ width: 16, height: 16 }} />
      </IconButton>
    </div>
  );
};

export default ContentTab;
