import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  tab: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    whiteSpace: 'nowrap',
    marginRight: 20,
  },
  removeButton: {
    borderRadius: '50%',
    width: 14,
    height: 14,
    marginLeft: 4,
  },
  tabLabel: {
    textOverflow: 'ellipsis',
    maxWidth: 120,
    overflow: 'hidden',
    marginRight: 8,
  },
  tabInfo: {
    color: '#fff',
    fontSize: 12,
    display: 'flex',
  },
  tabStatus: {
    color: '#fff',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 11,
    lineHeight: '16px',
  },
  ml8: {
    marginLeft: 8,
  },
  rotate: {
    animation: 'rotate 2s infinite',
  },
  '@keyframes rotate': {
    '100%': {
      transform: 'rotate(360deg)',
      '-webkit-transform': 'rotate(360deg)',
    },
  },
  filledRound: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    margin: 6,
  },
  close: {
    color: '#fff',
    margin: 0,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  multiFilled: {
    background: 'linear-gradient(90deg, #6554C0 50%, #36B37E 50%)',
  },
  active: {
    paddingBottom: 0,
  },
});
