import { FormControl, RadioGroup } from '@material-ui/core';
import RadioPelican from 'components/RadioPelican';
import React, { useEffect, useState } from 'react';

import { Props } from './props';
import { useStyles } from './styles';

export const RadioGroupPelican: React.FC<Props> = ({
  items,
  value,
  name,
  handleRadioChange,
  disabled,
}) => {
  const classes = useStyles();

  const [checked, setChecked] = useState<string>(value || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.value);
    if (handleRadioChange) {
      handleRadioChange(event.target.value);
    }
  };

  useEffect(() => {
    setChecked(value || '');
  }, [value]);

  return (
    <FormControl component="fieldset">
      <RadioGroup name={name} value={checked} onChange={handleChange} className={classes.root}>
        {items.map((item, index) => (
          <RadioPelican key={index} value={item.value} label={item.label} disabled={disabled} />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioGroupPelican;
