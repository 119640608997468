import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    backgroundColor: '#FFFFFF',
    borderRadius: '3px',
    padding: '8px 12px',
    display: 'flex',
    flexDirection: 'column',
  },
  flex: {
    display: 'grid',
    gridTemplateColumns: '28px 1fr 50px',
    alignItems: 'center',
  },
  avatar: {
    width: '24px',
    height: '24px',
    marginRight: '12px',
  },
  author: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#161618',
  },
  time: {
    fontSize: '12px',
    color: '#5E6C84',
    fontWeight: 400,
  },
  text: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#161618',
    wordBreak: 'break-word',
    marginTop: 8,
  },
  action: {
    marginRight: 'auto',
  },
  showMoreButton: {
    paddingLeft: 0,
    alignSelf: 'flex-end',
  },
});
