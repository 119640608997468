import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const RefreshOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path d="M11.313 6h.75v-.75h-.75V6zM7.07 5.25a.75.75 0 100 1.5v-1.5zm3.493 4.992a.75.75 0 001.5 0h-1.5zm5.365-2.068a.75.75 0 10-1.06 1.06l1.06-1.06zm-4.615-2.925H7.07v1.5h4.243v-1.5zm.75 4.993V6h-1.5v4.243h1.5zm-1.28-4.773L7.75 8.5l1.06 1.061 3.032-3.031-1.06-1.06zm5.462 3.021l-.317-.316-1.06 1.06.316.317 1.06-1.061zm-.002 8.499a6.01 6.01 0 00.002-8.499l-1.061 1.06a4.51 4.51 0 01-.002 6.378l1.06 1.06zm-1.06-1.061a4.505 4.505 0 01-6.371.002l-1.06 1.06a6.005 6.005 0 008.49-.001l-1.06-1.061zM7.75 8.5a6.003 6.003 0 000 8.49l1.06-1.06a4.503 4.503 0 010-6.369l-1.06-1.06z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.87 8.175a.75.75 0 011.06 0l.317.316a6.01 6.01 0 01-.002 8.499 6.003 6.003 0 11-8.492-8.488l1.75-1.751H7.073a.75.75 0 110-1.5h4.993v4.992a.75.75 0 01-1.5 0V7.811L8.814 9.562a4.503 4.503 0 106.37 6.367 4.51 4.51 0 00.002-6.377l-.316-.316a.75.75 0 010-1.061z"
    />
  </SvgIcon>
);
export default RefreshOutline;
