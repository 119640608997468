import React from 'react';

import { IconPelicanProps } from './index';
import { useStyles } from './styles';

export const IconPelican: React.FC<IconPelicanProps> = ({ type, width, height }) => {
  const classes = useStyles({ type, width, height });
  return <div className={classes.root} />;
};

export const ArrowDownCircleIcon: React.FC = () => <IconPelican type="arrow-down-circle" />;
export const ArrowRightCircleIcon: React.FC = () => <IconPelican type="arrow-right-circle" />;
export const HomeWorkspaceIcon: React.FC = () => <IconPelican type="home-workspace-icon" />;
export const AddFileIcon: React.FC = () => <IconPelican type="add-file-icon" />;
export const AudioTableIcon: React.FC = () => <IconPelican type="audio-table" />;
export const VideoTableIcon: React.FC = () => <IconPelican type="video-table" />;
export const DocumentTableIcon: React.FC = () => <IconPelican type="document-table" />;
export const EmptyDataIcon: React.FC = () => (
  <IconPelican width={138} height={186} type="empty-data" />
);
export const CrossCircleIcon: React.FC = () => <IconPelican type="cross-circle" />;

export default IconPelican;
