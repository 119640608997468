import { Box, IconButton } from '@material-ui/core';
import { getContentItemVersions } from 'api/content';
import { ContentTabType } from 'api/types/ContentTab';
import { CancelOutline, RefreshOutline } from 'components/IconPelican/icons';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getContentTabs, setNewContentTabs } from 'store/contentTabsSlice';

import ContentTab from './ContentTab/component';
import { useStyles } from './styles';

export const ContentTabs: React.FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const tabs = useSelector(getContentTabs);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [tabsUpdating, setTabsUpdating] = useState(false);

  const handleRemoveTab = useCallback(
    (tab: ContentTabType) => {
      dispatch(
        setNewContentTabs(
          tabs.filter(
            (t: ContentTabType) =>
              t.contentTypeSlug !== tab.contentTypeSlug || t.contentItemId !== tab.contentItemId
          )
        )
      );
    },
    [tabs, dispatch]
  );

  const handleRemoveTabs = useCallback(() => {
    dispatch(setNewContentTabs([]));
  }, [dispatch]);

  const handleUpdateTabs = useCallback(async () => {
    setTabsUpdating(true);
    try {
      const promises = Array.from(tabs, (tab) => {
        return getContentItemVersions(tab.contentTypeSlug, 1, tab.contentSourceId + '', 3);
      });
      const versionsList = await Promise.all(promises);
      const res = versionsList.map((versions, i) => ({
        id: tabs[i].contentItemId,
        slug: tabs[i].contentTypeSlug,
        versions: versions.list,
      }));
      res &&
        dispatch(
          setNewContentTabs(
            tabs.map((t) => {
              const tab = res.find(
                (current) => current.id === t.contentItemId && current.slug === t.contentTypeSlug
              );
              if (tab) {
                const newStatus =
                  tab.versions.find((version) => version.data_id === t.contentItemId)?.status ||
                  'draft';
                return {
                  ...t,
                  status: newStatus,
                  hasDraftVersion:
                    newStatus === 'published' &&
                    tab.versions.some((version) => version.status === 'draft'),
                };
              } else {
                return t;
              }
            })
          )
        );
    } catch (e) {
      enqueueSnackbar(`${t('Update error')} ${e.response?.data?.message || e}`, {
        variant: 'error',
      });
    } finally {
      setTabsUpdating(false);
    }
  }, [dispatch, enqueueSnackbar, tabs, t]);

  useEffect(() => {
    dispatch(setNewContentTabs(JSON.parse(localStorage.getItem('content_tabs') || '[]')));
  }, [dispatch]);

  return tabs.length > 0 ? (
    <>
      <div className={styles.container} id="content-tabs">
        <div className={styles.tabs}>
          {tabs?.map((tab: ContentTabType) => (
            <div key={tab.contentTypeSlug + tab.contentItemId}>
              <ContentTab tab={tab} handleRemove={() => handleRemoveTab(tab)} />
            </div>
          ))}
        </div>
        <Box display="flex">
          <IconButton className={styles.button} onClick={handleUpdateTabs} disabled={tabsUpdating}>
            <RefreshOutline htmlColor={tabsUpdating ? '#bdbdbd' : '#fff'} />
          </IconButton>
          <IconButton className={styles.button} onClick={handleRemoveTabs}>
            <CancelOutline htmlColor="#fff" />
          </IconButton>
        </Box>
      </div>
    </>
  ) : null;
};

export default ContentTabs;
