import { ContentItem } from 'api/types/ContentItem';
import { ContentTypeField } from 'api/types/ContentTypeField';

import { ContentCheckedType } from './props';

export const notNullValue = (content: ContentItem, compare: ContentItem, k: string): boolean =>
  (!content[k]?.items ||
    content[k]?.items.length > 0 ||
    !compare[k]?.items ||
    compare[k]?.items.length > 0) &&
  ((content[k] !== '' && content[k] !== undefined) ||
    (compare[k] !== '' && compare[k] !== undefined)) &&
  k !== 'id' &&
  k !== 'source_id' &&
  k !== 'projects' &&
  k !== 'project_id' &&
  k !== 'version' &&
  k !== 'priority' &&
  k !== 'content_state' &&
  k !== '$.multilingual';

export const getMultilingualSlugs = (
  currentMulti: ContentItem,
  compareMulti: ContentItem,
  locales: ReadonlyArray<string>
): ReadonlySet<string> => {
  const arr = locales.map((l) => [
    ...Object.keys(currentMulti?.[l] || {}),
    ...Object.keys(compareMulti?.[l] || {}),
  ]);
  return new Set(arr.flat());
};
export const getCurrentAndCompareItems = (
  currents: ReadonlyArray<ContentItem>,
  compares: ReadonlyArray<ContentItem>,
  isCompare: boolean
): ReadonlyArray<ContentItem> => {
  if (isCompare) {
    return currents
      .map(
        (c: ContentItem) => compares.find((ci: ContentItem) => ci.source_id === c.source_id) || {}
      )
      .concat(
        compares.filter(
          (c: ContentItem) => !currents.some((ci: ContentItem) => ci.source_id === c.source_id)
        )
      );
  } else {
    return currents.concat(
      new Array(
        compares.filter(
          (ci: ContentItem) => !currents.some((c: ContentItem) => c.source_id === ci.source_id)
        ).length
      ).fill({})
    );
  }
};

export const checkAllFields = (
  currentsItems: ReadonlyArray<ContentItem>,
  comparesItems: ReadonlyArray<ContentItem>,
  isCurrent: boolean,
  locales: ReadonlyArray<string>,
  rawItems = true
): ReadonlyArray<ContentCheckedType> => {
  const currents = rawItems
    ? getCurrentAndCompareItems(currentsItems, comparesItems, false)
    : currentsItems;
  const compares = rawItems
    ? getCurrentAndCompareItems(currentsItems, comparesItems, true)
    : comparesItems;
  const notCurrentItems = isCurrent ? compares : currents;
  return (isCurrent ? currents : compares).map((content: ContentItem, i: number) => {
    const checked: ContentCheckedType = {};
    Object.keys({ ...content, ...(notCurrentItems[i] || {}) }).forEach((k) => {
      if (Array.isArray(content[k] || notCurrentItems[i]?.[k])) {
        checked[k] = {
          checkedCurrent: isCurrent,
          checkedCompare: !isCurrent,
          items: checkAllFields(
            content[k] || [],
            notCurrentItems[i]?.[k] || [],
            isCurrent,
            locales
          ),
        };
      } else if (notNullValue(content, notCurrentItems[i] || {}, k)) {
        checked[k] = isCurrent;
      }
      if (k === '$.multilingual') {
        const set = getMultilingualSlugs(
          content['$.multilingual'],
          notCurrentItems[i]?.['$.multilingual'],
          locales
        );
        set.forEach((key) => {
          if (checked[key] === undefined) {
            checked[key] = isCurrent;
          }
        });
      }
    });
    return checked;
  });
};

export const sortEmbeddedItems = (
  currentItem: ContentItem,
  compareItem: ContentItem,
  fields: ReadonlyArray<ContentTypeField>,
  isCompare: boolean
): ContentItem => {
  return fields.reduce(
    (prev, field) => {
      if (Array.isArray(currentItem[field.slug]) || Array.isArray(compareItem[field.slug])) {
        return {
          ...prev,
          [field.slug]: getCurrentAndCompareItems(
            currentItem[field.slug] || [],
            compareItem[field.slug] || [],
            isCompare
          ),
        };
      } else {
        return prev;
      }
    },
    isCompare ? compareItem : currentItem
  );
};
