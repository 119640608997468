import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  loginContainer: {
    display: 'flex',
    minWidth: 430,
    width: '27%',
    flexDirection: 'column',
    padding: '44px 28px',
    background: '#fff',
    boxShadow: '0px 0px 1px rgba(26, 32, 36, 0.32), 0px 1px 2px rgba(91, 104, 113, 0.32)',
    borderRadius: '8px',
    zIndex: 100,
    position: 'relative',
    marginTop: '-6%',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  appLogo: {
    color: '#0E4DEC',
    '& svg': {
      color: '#0E4DEC',
    },
  },
  h1: {
    fontWeight: 700,
    margin: '24px auto',
    padding: 0,
    fontSize: 26,
  },
  formItem: {
    marginBottom: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  forgotPwd: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  rememberMe: {
    display: 'flex',
  },
  button: { marginTop: '20px', width: '100%' },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginLeft: -10,
    marginRight: -10,
    '& button': {
      marginLeft: 10,
      marginRight: 10,
    },
  },
  footer: {
    marginTop: 30,
    color: '#545456',
  },
}));
