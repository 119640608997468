import { Link } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

import { Props } from './props';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.blue[500],
  },
}));

export const LinkButton: React.FC<Props> = ({ ...props }) => {
  const styles = useStyles();
  return <Link className={styles.root} {...props} />;
};

export default LinkButton;
