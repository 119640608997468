import DateRangeIcon from '@material-ui/icons/DateRange';
import clsx from 'clsx';
import { formatDateAndTime } from 'common/utils/date';
import { Datepicker } from 'components/Datepicker';
import { Timepicker } from 'components/Timepicker';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Props } from './props';
import { useStyles } from './styles';

export const DateTimepicker: React.FC<Props> = ({
  onChangeDateTime,
  datetime,
  className,
  disabled,
  dateFormat,
  ...rest
}) => {
  const [currentDate, setCurrentDate] = useState<Date | undefined>(datetime);
  const currentDateRef = useRef<Date>();
  currentDateRef.current = currentDate;
  const [currentTime, setCurrentTime] = useState<Date | undefined>(datetime);
  const currentTimeRef = useRef<Date>();
  currentTimeRef.current = currentTime;
  const changeDateTime = useCallback(
    (dateTime) => {
      onChangeDateTime && onChangeDateTime(dateTime);
    },
    [onChangeDateTime]
  );
  useEffect(() => {
    setCurrentDate(datetime);
    setCurrentTime(datetime);
  }, [datetime]);
  const classes = useStyles();
  const onDatePicked = useCallback(
    (date) => {
      setCurrentDate(date);
      if (date && currentTimeRef.current) {
        changeDateTime(formatDateAndTime(date, currentTimeRef.current));
      }
    },
    [changeDateTime]
  );
  const onTimePicked = useCallback(
    (time) => {
      setCurrentTime(time);
      if (time && currentDateRef.current) {
        changeDateTime(formatDateAndTime(currentDateRef.current, time));
      }
    },
    [changeDateTime]
  );
  return (
    <div id={rest.id} className={clsx(classes.root, className && className)}>
      <div className={classes.flex}>
        <Datepicker
          disabled={disabled}
          onChangeDate={onDatePicked}
          date={currentDate}
          placeholderText="DD/MM/YYYY"
          dateFormat={dateFormat}
        />
        <Timepicker
          disabled={disabled}
          onChangeTime={onTimePicked}
          time={currentTime}
          placeholderText="HH:MM"
        />
      </div>
      <DateRangeIcon className={classes.calendarIcon} />
    </div>
  );
};
