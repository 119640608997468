import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const PlanetOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 19.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zm9-7.5a9 9 0 11-18 0 9 9 0 0118 0z"
    />
    <path d="M3.396 12c0-4.802 3.897-8.699 8.699-8.699 1.237 0 2.414.259 3.48.725l-.465.877-1.347.719c0 .956.135 2.537-.822 2.537l-1.716-.508.4 1.4c0 .479-.836.635-1.315.635l-2.02.634.325 1.68h3.344a2 2 0 011.903 2.613l-.1.31 1.812.556c.783 0 1.84 1.036 2.066 1.74l.91.61a8.677 8.677 0 01-6.455 2.87l-.47-.895-.4-2.585c-.957 0-2.61-.954-2.61-1.911l-.483-2.239-3.456-3.383c-.113.504-.343 1.775-.343 2.314h-.937z" />
  </SvgIcon>
);
export default PlanetOutline;
