import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: () => ({
    display: 'flex',
    width: '100%',
    position: 'relative',
    height: '100%',
  }),
  paper: {
    position: 'absolute',
    width: 631,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  tabsWrapper: ({ toggled }: { readonly toggled: boolean }) => ({
    display: toggled ? 'none' : 'block',
    position: 'relative',
    overflowY: 'auto',
    background: '#F7F8FA',
    boxShadow: 'inset 1px 0px 0px rgba(0, 0, 0, 0.2)',
    padding: 20,
    width: '100%',
    height: '100%',
  }),
  rightBarToggler: ({ toggled }: { readonly toggled: boolean }) => ({
    left: toggled ? 2 : -24,
    color: '#CECECE',
    position: 'absolute',
    top: 4,
    right: 4,
  }),
  wrapper: {
    display: 'flex',
    position: 'relative',
  },
  verticalTabWrapper: ({ toggled }: { readonly toggled: boolean }) => ({
    transformOrigin: 'left top',
    transform: 'rotate(90deg)',
    position: 'absolute',
    left: toggled ? 30 : 1,
    zIndex: 30,
    top: 30,
  }),
  verticalTabs: {
    listStyle: 'none',
    display: 'flex',
    transform: 'rotate(90deg)',
    padding: 0,
    margin: 0,
    '&>li:first-child': {
      borderRadius: '0 10px 0 0',
    },
    '&>li:last-child': {
      borderRadius: '10px 0 0 0',
    },
  },
  tabItem: {
    transform: 'rotate(180deg)',
    paddingRight: 20,
    paddingLeft: 20,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.surface[50],
    border: '1px solid #CECECE',
    color: '#858585',
  },
  tabs: {
    transform: 'rotate(-90deg)',
    position: 'absolute',
    left: -230,
    zIndex: 9999999,
  },
  infoTable: {
    marginBottom: '24px',
    marginTop: '24px',
    width: '100%',
  },
  infoLabel: {
    color: '#7C7C7C',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    paddingBottom: '12px',
  },
  infoValue: {
    color: '#172B4D',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    paddingBottom: '12px',
  },
  infoText: {
    lineHeight: '16px',
    color: '#242424',
    cursor: 'pointer',
    '&:hover': {
      color: 'rgb(85, 26, 139)',
    },
  },
  headTitle: {
    marginBottom: 10,
    fontWeight: 600,
  },
  title: {
    color: '#545456',
    fontSize: '13px',
    fontWeight: 600,
    marginBottom: 12,
    textTransform: 'uppercase',
    paddingTop: 12,
  },
  subTitle: {
    paddingTop: '12px',
    marginBottom: '12px',
    lineHeight: '16px',
    color: '#0052CC',
    cursor: 'pointer',
  },
  historyFilters: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 18,
  },
  historyBlock: {
    padding: '15px 16px',
    background: '#ffffff',
    borderRadius: 3,
    marginBottom: 4,
    fontSize: 12,
    lineHeight: '16px',
    color: '#505F79',
    fontWeight: 600,
  },
  historyCategoryType: {
    color: '#000000',
  },
  primary: {
    color: '#0052CC',
  },
  historyDetails: {
    fontSize: 11,
    fontWeight: 500,
    color: '#5E6C84',
  },
  historyChanges: {
    fontSize: 13,
    lineHeight: '18px',
    marginTop: 8,
    color: '#242424',
    fontWeight: 'normal',
  },
  popover: {
    padding: '10px 8px',
  },
  spin: {
    display: 'flex',
    justifyContent: 'center',
  },
  multilingualSwitch: {
    marginBottom: 18,
  },
  showMore: {
    fontSize: 14,
    lineHeight: '20px',
    color: '#0052CC',
    marginBottom: 20,
    background: 'transparent',
  },
  block: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: '#fff',
    color: '#242424',
    height: 40,
    padding: '12px 16px',
  },
  scheduleAction: {
    cursor: 'pointer',
    paddingLeft: 12,
    paddingRight: 12,
  },
  publishAt: {
    color: '#0052CC',
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  createCommentButton: {},
  flex: {
    display: 'flex',
  },
  alignCenter: {
    alignItems: 'center',
  },
  icon: {
    marginRight: 5,
  },
  w100: {
    width: '100%',
  },
  versionSelect: {
    width: '50%',
  },
  editScheduleButton: {
    padding: 0,
  },
  editScheduleIcon: {
    width: 20,
    height: 20,
  },
  modalHeader: {
    fontSize: '20px',
    fontWeight: 500,
    padding: '24px',
    borderBottom: '2px solid #EBECF0',
  },
  modalBody: {
    padding: '45px 24px',
    borderBottom: '2px solid #EBECF0',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px',
  },
  multilingualIcon: {
    marginLeft: 4,
    marginRight: 4,
    marginBottom: -2,
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  eventType: {
    fontWeight: 500,
    fontSize: '11px',
    color: '#0039D4',
    background: '#E8EAFD',
    borderRadius: '4px',
    padding: '0 2px',
    marginLeft: 12,
  },
  lineClamp: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    wordBreak: 'break-all',
  },
  oldValue: {
    wordBreak: 'break-word',
    color: '#8F8F91',
  },
  newValue: {
    wordBreak: 'break-word',
    color: '#00801B',
  },
  removed: {
    color: '#BF2E0D',
  },
  added: {
    color: '#0039D4',
  },
  embeddedId: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  richtextDetails: {
    display: 'flex',
    justifyContent: 'end',
  },
  environments: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    margin: '18px 0',
  },
  dNone: {
    display: 'none',
  },
  changes: {
    display: 'flex',
    alignItems: 'center',
  },
  versions: {},
  empty: {
    color: '#97A0AF',
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: 600,
  },
  releaseContainer: {
    width: 'max-content',
    display: 'flex',
    marginTop: 4,
  },
  environment: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 24,
    margin: 'auto',
  },
  environmentName: {
    fontSize: 12,
    color: '#545456',
    lineHeight: '150%',
    textTransform: 'lowercase',
    overflow: 'unset',
  },
}));
