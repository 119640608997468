import { gql } from '@apollo/client';

export const GET_AUDIT_TRAILS = gql`
  query GetAuditTrails(
    $page: Int
    $size: Int
    $sourceType: AuditEventSourceType
    $auditEventType: AuditEventType
    $eventType: EventType
    $contentTypeId: ID
    $dataId: ID
  ) {
    audit_pageable(
      page: $page
      size: $size
      sourceType: $sourceType
      auditEventType: $auditEventType
      eventType: $eventType
      contentTypeId: $contentTypeId
      dataId: $dataId
    ) {
      data {
        dataId
        userId
        username
        eventType
        auditEventType
        oldEventState
        newEventState
        slug
        envFrom
        envTo
        dataDiffs {
          changes
          multilingual
          auditEventType
          dataId
          newData
          slug
        }
        trailDate
      }
      total_count
    }
  }
`;
