import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ImportOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.75 3.752a.75.75 0 00-1.5 0v8.635l-2.222-2.202a.75.75 0 00-1.056 1.066l3.5 3.468.528.523.528-.523 3.5-3.468a.75.75 0 10-1.056-1.066l-2.222 2.202V3.752zM8.554 20.985h-.032c-.813 0-1.469 0-2-.044-.546-.044-1.026-.139-1.47-.365a3.75 3.75 0 01-1.64-1.639c-.226-.444-.32-.924-.365-1.47-.043-.532-.043-1.187-.043-2v-4.232h1.5v4.2c0 .852 0 1.446.038 1.91.037.453.106.714.207.911.216.424.56.768.983.983.198.101.459.17.912.207.463.038 1.057.039 1.91.039h6.892c.853 0 1.447 0 1.91-.039.453-.037.714-.106.912-.207a2.25 2.25 0 00.983-.983c.1-.197.17-.458.207-.912.038-.463.038-1.057.038-1.91v-4.2h1.5V15.468c0 .813 0 1.468-.043 2-.045.546-.14 1.026-.366 1.47a3.75 3.75 0 01-1.638 1.639c-.445.226-.925.32-1.471.365-.531.044-1.187.044-2 .044H8.554z"
      fill="#000"
    />
  </SvgIcon>
);
export default ImportOutline;
