// eslint-disable-next-line @typescript-eslint/explicit-function-return-type

import { useContext } from 'react';

import { DataGridContext } from './DataGridContext';

export const useDataGrid = (): React.ContextType<typeof DataGridContext> => {
  const context = useContext(DataGridContext);

  return context;
};
