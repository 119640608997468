import React, { useState } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useCustomPagination = (initialPage = 1, perPageCount = 10) => {
  const [page, setPage] = React.useState(initialPage === 0 ? 0 : initialPage);
  const [perPage, setPerPage] = React.useState(perPageCount);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  return {
    page,
    setPage,
    perPage,
    setPerPage,
    total,
    setTotal,
    pageCount,
    setPageCount,
  };
};
