import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { TextInput } from 'components/TextInput';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextFieldProps } from './index';
import { useStyles } from './styles';

export const TextField: React.FC<TextFieldProps> = ({
  name,
  label,
  control,
  placeholder,
  errorMessage,
  errors,
  rules,
  displayNone,
  labelType = 'horizontal',
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        classes.formItem,
        displayNone && classes.displayNone,
        labelType === 'vertical' && classes.vertical
      )}
    >
      <label
        className={labelType === 'vertical' ? classes.verticalLabel : classes.label}
        htmlFor={name}
      >
        {t(label)}
        {rest.required && <span className={classes.required}>*</span>}
      </label>
      <div className={classes.input}>
        <Controller
          id={name}
          name={name}
          control={control}
          as={({ ...props }) => <TextInput {...props} {...rest} />}
          placeholder={placeholder || ''}
          rules={rules}
        />
        {errorMessage && (
          <div className={classes.error}>
            <ErrorMessage errors={errors} message={t(errorMessage)} name={name} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TextField;
