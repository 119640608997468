import { createStyles, ListItemIcon, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { NavItemWrapperProps } from './index';
import NavItem from './NavMenuItemText';

const useStyles = makeStyles((theme) =>
  createStyles({
    menuItemIcon: {
      color: '#161618',
      minWidth: 'auto',
      '& svg': {
        color: '#161618',
      },
    },
    toggleMenuItemIcon: {
      display: 'flex',
    },
    badge: {
      marginRight: theme.spacing(1),
      background: theme.palette.secondary.light,
    },
    listItemMargin: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 24,
      fontWeight: 500,
      maxWidth: 184,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  })
);

export const NavMenuItem: React.FC<NavItemWrapperProps> = ({
  name,
  path,
  icon,
  items = [],
  isToggle,
  expandSidebar,
}) => {
  const classes = useStyles();
  const isExpandable = items && items.length > 0;
  const { t } = useTranslation();

  const handleClick = (): void => {
    isExpandable && expandSidebar && expandSidebar();
  };

  const MenuItemRoot = (
    <NavItem onClick={handleClick} path={path} toggle={isToggle} title={t(name)}>
      {!!icon && (
        <ListItemIcon
          className={clsx(classes.menuItemIcon, isToggle && classes.toggleMenuItemIcon)}
        >
          {icon}
        </ListItemIcon>
      )}
      {!isToggle && <Typography className={classes.listItemMargin}>{t(name)}</Typography>}
    </NavItem>
  );

  return <>{MenuItemRoot}</>;
};

export default NavMenuItem;
