import { Typography } from '@material-ui/core';
import { CheckboxPelican } from 'components/CheckboxPelican';
import { Drag } from 'components/IconPelican/icons';
import { Space } from 'components/Space';
import { XYCoord } from 'dnd-core';
import { useRef } from 'react';
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

import { ItemTypes } from '../types/ItemTypes';
import { Props } from './props';
import { useStyles } from './styles';

export const DataGridSettingsItem: React.FC<Props> = ({ column, index, moveCard, id }) => {
  const styles = useStyles();
  const ref = useRef<HTMLLIElement>(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    hover(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [{ isDragging }, drag] = useDrag({
    item: { index, id, type: ItemTypes.CARD },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <li
      key={column.id}
      className={styles.listItem}
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <Space size={2}>
        <Drag viewBox="8 0 14 24" width={14} style={{ width: 18, cursor: 'move' }} />
        <Typography variant="body1" style={{ color: '#161618' }}>
          {column.Header || column.id}
        </Typography>
      </Space>
      <div style={{ width: 20 }} />
      <CheckboxPelican type="checkbox" {...column.getToggleHiddenProps()} />
    </li>
  );
};
