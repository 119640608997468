import { Dialog } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ModalFooter from './ModalFooter';
import { ModalTitle } from './ModalTitle';
import { Props } from './props';
import { useStyles } from './styles';

export const ModalPelican: React.FC<Props> = ({
  title,
  footer,
  children,
  overflowUnset,
  onClose,
  subtitle,
  className,
  ...rest
}) => {
  const styles = useStyles({ overflowUnset });
  const { t } = useTranslation();
  return (
    <Dialog
      classes={{
        paper: styles.paper,
      }}
      onClose={onClose}
      {...rest}
    >
      {title && (
        <ModalTitle>
          {t(title)}
          {subtitle && <Typography variant="subtitle1">{subtitle}</Typography>}
        </ModalTitle>
      )}
      {onClose && (
        <CancelIcon onClick={() => onClose({}, 'backdropClick')} className={styles.closeButton} />
      )}
      <div className={styles.hr} />
      <div
        id="modal-body"
        className={clsx(
          className,
          styles.overflowYScroll,
          styles.container,
          overflowUnset && styles.overflowUnset
        )}
      >
        {children}
      </div>
      {footer && <ModalFooter>{footer}</ModalFooter>}
    </Dialog>
  );
};
