import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingLeft: '10%',
    paddingRight: '10%',
    height: '100%',
  },
  center: {
    margin: '0 auto',
    padding: '24px 30px',
  },
  contentStateContainer: {
    marginLeft: 20,
  },
  contentState: {
    fontWeight: 'bold',
    fontSize: 11,
    color: '#fff',
    borderRadius: 3,
    marginRight: 8,
    textTransform: 'uppercase',
    padding: '1px 7px 0 7px',
    display: 'inline-block',
  },
  flex: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  flexAlignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  flexStart: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'space-between',
    marginLeft: 'auto',
  },
  autoMargin: {},
  flexStarting: {
    display: 'flex',
    justifyContent: 'start',
    backgroundColor: 'white',
    height: '100%',
    paddingBottom: 50,
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'end',
  },
  modal: {
    marginTop: 50,
    width: '650px',
    background: 'white',
  },
  modalHeader: {
    fontSize: '20px',
    fontWeight: 500,
    padding: '24px',
    borderBottom: '2px solid #EBECF0',
  },
  modalBody: {
    padding: '20px 65px',
    borderBottom: '2px solid #EBECF0',
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'end',
    padding: '25px 16px',
  },
  editorButton: {
    marginBottom: 8,
  },
  saveButton: {
    width: 80,
    height: 32,
    background: '#0052CC',
    color: 'white',
    fontSize: 14,
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
  },
  cancelButton: {
    width: 80,
    height: 32,
    background: 'white',
    color: '#42526E',
    fontSize: 14,
    border: 'none',
    cursor: 'pointer',
  },
  heading: {
    marginTop: 0,
    paddingBottom: 16,
  },
  moreButton: {
    display: 'flex',
    alignSelf: 'center',
    padding: 0,
  },
  contentActionContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 24px',
    background: 'white',
    borderTop: '1px solid #DFE1E6',
  },
  smallWidth: {},
  stickyFooter: {
    position: 'sticky',
    width: '100%',
  },
  contentActionButtons: {
    '&>*': {
      marginRight: 12,
    },
  },
  aside: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    overflowY: 'auto',
    right: 0,
    background: '#F4F5F7',
    boxShadow: 'inset 1px 0px 0px rgba(0, 0, 0, 0.2)',
  },
  collapse: {
    marginTop: 'auto',
    borderBottom: 0,
    borderLeft: '1px solid rgba(0, 0, 0, 0.2)',
    borderRight: 0,
    bottom: 0,
    left: 0,
    borderTop: '1px solid #DFE1E6',
    outline: 0,
    width: '100%',
    padding: '11px 16px 12px 16px',
    position: 'sticky',
    color: '#505F79',
    display: 'flex',
    textAlign: 'start',
    fontSize: 14,
    alignItems: 'center',
    background: '#F4F5F7',
    lineHeight: '20px',
    cursor: 'pointer',
  },
  rotate: {
    width: 25,
    height: 25,
    marginRight: 12,
    transform: 'rotate(180deg)',
    borderRadius: '50%',
    backgroundColor: '#505F79',
    color: 'white',
  },
  multilangual: {
    '&:first-child': {
      marginTop: 0,
    },
    marginTop: 6,
  },
  locale: {
    color: '#0052CC',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    '&>span': {
      margin: '0 4px',
    },
  },
  emptyLocale: {
    color: '#97A0AF',
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: 600,
  },
  colorInput: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    fontSize: '14px',
    minHeight: 32,
    border: '2px solid #dfe1e5',
    borderRadius: '3px',
    marginBottom: '12px',
  },
  colorChoose: {
    width: 20,
    height: 20,
    cursor: 'pointer',
    marginRight: 5,
    border: 'none',
  },
  stickyDiv: {
    position: 'sticky',
    backgroundColor: 'white',
    zIndex: 2,
  },
  tabs: {
    marginTop: 6,
  },
  displayNone: {
    display: 'none',
  },
  codeSpan: {
    marginRight: 12,
  },
  error: {
    color: theme.palette.error.main,
  },
  fieldNavigation: {
    position: 'relative',
  },
  fixed: {
    position: 'fixed',
    paddingLeft: 16,
    paddingRight: 16,
    scrollbarWidth: 'none',
    paddingTop: 20,
    maxWidth: 220,
    overflowY: 'auto',
    marginTop: 36,
    backgroundColor: '#F4F5F7',
    borderRadius: 3,
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  fieldsHeader: {
    fontWeight: 600,
    marginBottom: 16,
    fontSize: 16,
    lineHeight: '24px',
    textTransform: 'uppercase',
    color: '#0052CC',
  },
  fieldsTypography: {
    marginBottom: 10,
    fontSize: 14,
    lineHeight: '20px',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.5,
    },
  },
  spinContainer: {
    position: 'relative',
    height: '200px',
    width: '200px',
  },
  fieldSpinContainer: {
    position: 'relative',
    width: '100%',
    marginTop: 100,
  },
  headingProjects: {
    color: '#172B4D',
    marginBottom: 12,
  },
  spanProjects: {
    fontSize: 12,
    color: '#97A0AF',
  },
  toggledAside: {
    width: 68,
  },
  toggledCollapse: {
    padding: '11px 0 12px 0',
    justifyContent: 'center',
    '& > svg': {
      width: 25,
      height: 25,
      borderRadius: '50%',
      backgroundColor: '#505F79',
      color: 'white',
    },
  },
  marginTop: {
    marginTop: 16,
  },
  fieldsScroll: {
    overflowY: 'auto',
    height: 'calc(100% - 117px)',
    width: '100%',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  required: {
    color: '#F44336',
  },
  title: {
    padding: '20px 16px 16px 16px',
    fontSize: 14,
    color: '#253858',
    borderBottom: '1px solid #C4C4C4',
  },
}));
