import 'react-datepicker/dist/react-datepicker.css';

import DateRangeIcon from '@material-ui/icons/DateRange';
import clsx from 'clsx';
import ru from 'date-fns/locale/ru';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { ArrowLeftOutline, ArrowRightOutline } from '../IconPelican/icons';
import { Props } from './props';
import { useStyles } from './styles';

export const Datepicker: React.FC<Props> = ({
  onChangeDate,
  className,
  date,
  disabled,
  placeholderText,
  dateFormat,
  minDate,
  withIcon,
  isClearable,
  ...rest
}) => {
  const { i18n } = useTranslation();
  const [startDate, setStartDate] = useState<Date | undefined>(date);
  useEffect(() => {
    setStartDate(date);
  }, [date]);
  const classes = useStyles();
  const compareDates = (dateFirst?: Date, dateSecond?: Date): boolean => {
    if (dateFirst && dateSecond) {
      return (
        dateFirst.getDate() === dateSecond.getDate() &&
        dateFirst.getMonth() === dateSecond.getMonth() &&
        dateFirst.getFullYear() === dateSecond.getFullYear()
      );
    } else {
      return false;
    }
  };
  const onChange = (date?: Date): void => {
    setStartDate(date);
    onChangeDate && onChangeDate(date);
  };
  const getDayClassName = (date: Date): string => {
    return (compareDates(date, startDate) && classes.dayClassNameCurrent) || classes.dayClassName;
  };
  const getWeekDayClassName = (): string => classes.weekDayClassName;
  const getMonthClassName = (): string => classes.monthClassName;
  const getTimeClassName = (): string => classes.timeClassName;
  return (
    <div id={rest.id} className={clsx(withIcon && classes.root, className && className)}>
      <DatePicker
        locale={['kk', 'ru'].includes(i18n.language) ? ru : undefined}
        selected={startDate}
        className={classes.className}
        calendarClassName={classes.calendarClassName}
        dayClassName={getDayClassName}
        weekDayClassName={getWeekDayClassName}
        monthClassName={getMonthClassName}
        previousMonthButtonLabel={<ArrowLeftOutline />}
        nextMonthButtonLabel={<ArrowRightOutline />}
        timeClassName={getTimeClassName}
        disabled={disabled}
        formatWeekDay={(nameOfWeekDay) => nameOfWeekDay.substring(0, 3)}
        popperClassName={classes.popperClassName}
        wrapperClassName={clsx(className, classes.wrapperClassName)}
        onChange={(date) =>
          date ? !Array.isArray(date) && onChange(date) : isClearable && onChange(undefined)
        }
        placeholderText={placeholderText}
        dateFormat={dateFormat}
        minDate={minDate}
        id={rest.id}
        isClearable={isClearable}
      />
      {withIcon && <DateRangeIcon className={classes.calendarIcon} />}
    </div>
  );
};
