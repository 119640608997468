import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const QuestionCircleOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 19.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zm0 1.5a9 9 0 100-18 9 9 0 000 18zm.83-4.956a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm-.576-9.03c-1.19 0-2.037.341-2.589.883-.54.531-.72 1.18-.72 1.656a.75.75 0 101.5 0c0-.128.055-.373.271-.586.205-.2.637-.453 1.538-.453.863 0 1.196.31 1.306.505a.652.652 0 01-.078.729c-.239.301-.548.564-.894.857l-.267.228a4.8 4.8 0 00-.609.606c-.17.214-.373.538-.373.948V13.294a.75.75 0 001.5 0V12.437a.65.65 0 01.046-.064c.083-.104.218-.233.418-.407l.207-.176c.351-.296.806-.681 1.148-1.112.531-.671.651-1.611.209-2.396-.453-.804-1.385-1.269-2.613-1.269z"
    />
  </SvgIcon>
);
export default QuestionCircleOutline;
