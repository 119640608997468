import {
  DATE_COMMON_FORMAT,
  ONLY_DATE_COMMON_FORMAT,
  TIME_COMMON_FORMAT,
} from 'common/constants/date';
import { format, isDate, parse, parseISO } from 'date-fns';
import moment from 'moment';

export function formatDate(date?: Date): string | undefined {
  return date ? format(date, ONLY_DATE_COMMON_FORMAT) : undefined;
}

export function formatDateTime2(date?: Date, dateFormat?: string): string | undefined {
  return date ? format(date, dateFormat || DATE_COMMON_FORMAT) : undefined;
}

export function formatOnlyDate(date: Date): string | undefined {
  return date ? format(date, ONLY_DATE_COMMON_FORMAT) : undefined;
}

export function formatTime(date?: Date): string | undefined {
  const res = date ? format(date, TIME_COMMON_FORMAT) : undefined;

  return res;
}

export function formatDateTime(value: string): string | undefined {
  return value;
}

export function formatDateAndTime(date: Date, time: Date): string {
  return `${format(date, ONLY_DATE_COMMON_FORMAT)}T${formatTime(time)}`;
}

export function parseTime(value: string | Date): Date | undefined {
  if (value !== undefined) {
    if (!isDate(value)) {
      const valueStr = value.toString();
      return value ? parse(valueStr, TIME_COMMON_FORMAT, new Date()) : undefined;
    } else {
      return value as Date;
    }
  } else {
    return undefined;
  }
}

export function parseDateTime(value: string): Date | undefined {
  const dotIndex = value?.indexOf('.');
  const valueWithoutTz = dotIndex > -1 ? value.slice(0, dotIndex) : value;
  const res = value ? parseISO(valueWithoutTz) : undefined;

  return res;
}

export function parseDateTimeNotUndef(value: string | Date): Date {
  if (!isDate(value)) {
    const valueStr = value.toString();
    const dotIndex = valueStr.indexOf('.');
    const valueWithoutTz = dotIndex > -1 ? valueStr.slice(0, dotIndex) : valueStr;
    return parseISO(valueWithoutTz);
  } else {
    return value as Date;
  }
}

export function getParsedDate(value: string): Date {
  const momentFormat = moment.utc(value, 'YYYY-MM-DD hh:mm:ss').local();
  return momentFormat.isValid() ? momentFormat.toDate() : moment.utc(value).local().toDate();
}

export function parseNativeDate(value: string | undefined): Date | undefined {
  return value ? getParsedDate(value) : undefined;
}

export function parseDate(value: string): Date | undefined {
  return value ? parseISO(value) : undefined;
}
