import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {},
  multilangual: {
    '&:first-child': {
      marginTop: 0,
    },
    marginTop: 6,
  },
  locale: {
    color: '#0052CC',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    '&>span': {
      marginLeft: 4,
    },
  },
  emptyLocale: {
    color: '#97A0AF',
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: 600,
  },
  editorButton: {
    padding: '8px 16px',
    fontSize: '14px',
    cursor: 'pointer',
    border: 'none',
    background: '#EBECF0',
    borderRadius: '3px',
  },
}));
