import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: 0,
  },
  icon: {
    borderRadius: 4,
    width: 16,
    height: 16,
    border: '1px solid #D3D4D6',
    backgroundColor: theme.palette.surface[100],
    'input:hover ~ &': {
      backgroundColor: theme.palette.surface[200],
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: theme.palette.surface[300],
    },
  },
  medium: {
    width: 18,
    height: 18,
  },
  mediumBefore: {
    '&:before': {
      width: '18px !important',
      height: '18px !important',
    },
  },
  checkedIcon: {
    backgroundColor: '#0052CC',
    borderColor: '#0052CC',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      marginLeft: -1,
      marginTop: -1,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}));

export const CheckboxPelican: React.FC<CheckboxProps & { readonly size?: 'small' | 'medium' }> =
  // eslint-disable-next-line react/display-name
  React.forwardRef(({ size, ...rest }, ref) => {
    const classes = useStyles();
    return (
      <Checkbox
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={
          <span
            className={clsx(
              classes.icon,
              size === 'medium' && classes.medium,
              classes.checkedIcon,
              size === 'medium' && classes.mediumBefore
            )}
          />
        }
        ref={ref}
        icon={<span className={clsx(classes.icon, size === 'medium' && classes.medium)} />}
        inputProps={{ 'aria-label': 'decorative checkbox' }}
        {...rest}
      />
    );
  });
