import { AxiosProgressEvent, GenericAbortSignal } from 'axios';
import request from 'common/utils/axios-request';
import { RequestResponse } from 'pages/Contents/types/Response';

import { ContentDataOfAsset, GetAssets } from './types/Assets';
import { FileItem } from './types/FileItem';
import { User } from './types/User';

export function assetsGet(params: string): Promise<GetAssets> {
  return request('/api/v1/assets?' + params).then(({ data, headers }) => {
    return {
      list: data,
      totalCount: parseInt(headers['x-total-count']),
    };
  });
}

export function assetDelete(id: string | number, me: User): Promise<RequestResponse> {
  return request('/api/v1/assets/' + id, { method: 'DELETE', data: me })
    .then((res) => ({
      status: res.status,
      url: res.config.url,
      message: 'Deleted successfully',
      variant: 'success',
      statusCode: 0,
    }))
    .catch((e) => ({ ...e.response.data, variant: 'error' }));
}

export function assetPut(file: FileItem, projects: string): Promise<RequestResponse> {
  return request('/api/v1/assets/' + file.id, { method: 'PUT', params: { projects }, data: file })
    .then((res) => ({
      status: res.status,
      url: res.config.url,
      message: 'Saved successfully',
      variant: 'success',
      statusCode: 0,
    }))
    .catch((e) => ({ ...e.response.data, variant: 'error' }));
}

export function assetDestroy(hash: string): Promise<unknown> {
  return request('/api/v1/assets/' + hash, { method: 'DELETE' }).then((res) => res);
}

export function assetInfoGet(id: string): Promise<FileItem> {
  return request('/api/v1/assets/' + id).then(({ data }) => data);
}

export function assetInfoGetByHash(hash: string): Promise<FileItem> {
  return request('/api/v1/assets/hash/' + hash).then(({ data }) => data);
}

export function getMe(): Promise<User> {
  return request('/api/v1/me').then((res) => res.data as User);
}
export function assetsCreate(
  assets: FormData,
  projects: string,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
  signal?: GenericAbortSignal
): Promise<{ readonly data: ReadonlyArray<FileItem>; readonly status: number }> {
  return request(`/api/v1/assets/bulk`, {
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: assets,
    params: { projects },
    onUploadProgress,
    signal,
  }).then((res) => res);
}

export function assetsRename(
  data: readonly {
    readonly file_name?: string;
    readonly hash?: string;
  }[],
  projects?: string
): Promise<RequestResponse> {
  return request('/api/v1/assets', { method: 'PUT', data, params: { projects } })
    .then((res) => ({
      status: res.status,
      url: res.config.url,
      message: 'Saved successfully',
      variant: 'success',
      statusCode: 0,
    }))
    .catch((e) => ({ ...e.response.data, variant: 'error' }));
}

export function contentsDataOfAsset(
  id: string | number
): Promise<ReadonlyArray<ContentDataOfAsset>> {
  return request('/api/v1/asset-mappings/' + id).then(({ data }) => data);
}
