import { makeStyles } from '@material-ui/core';
import { RIGHT_BAR_WIDTH } from 'common/constants/styles';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

const useStyles = makeStyles({
  root: {
    padding: '10px 30px',
  },
  flex: {
    display: 'flex',
  },
  block1: {
    flex: 1,
    margin: '20px 3%',
  },
  breadcrumbs: {
    width: 100,
  },
  projects: {
    height: 100,
    marginBottom: 48,
  },
  field: {
    marginBottom: 20,
  },
  navigator: {
    marginTop: 20,
    height: 600,
    width: RIGHT_BAR_WIDTH - 50,
  },
});

export const ContentEditorSkeleton: React.FC = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Skeleton height={40} />
      <div className={styles.flex}>
        <div className={styles.block1}>
          <Skeleton className={styles.projects} />
          <Skeleton className={styles.field} height={90} />
          <Skeleton className={styles.field} height={150} />
          <Skeleton className={styles.field} height={60} />
          <Skeleton className={styles.field} height={90} />
          <Skeleton className={styles.field} height={150} />
        </div>
        <div>
          <Skeleton className={styles.navigator} />
        </div>
      </div>
    </div>
  );
};
