import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import * as QueryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

import { useContentEditorProvider } from '../../../useContentEditorProvider';
import { Props } from './props';
import { useStyles } from './styles';

export const ContentEditorNavigator: React.FC<Props> = ({ groups, groupFields }) => {
  const styles = useStyles();
  const { t } = useTranslation();

  const {
    form,
    embedLevel,
    setActiveTab,
    showOnlyMultilingual,
    restRequires,
    lengthValidates,
  } = useContentEditorProvider();
  const { errors } = form;
  const location = useLocation();
  const queryString: { readonly searchFor?: string } = QueryString.parse(location.search);

  const [fieldFounded, setFieldFounded] = useState(false);

  const scrollToAnchor = useCallback((id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ block: 'center', behavior: 'smooth', inline: 'start' });
    }
  }, []);

  const handleChangeTab = useCallback(
    (tabId: number): void => {
      setActiveTab(tabId);
    },
    [setActiveTab]
  );

  const hasErrorMessage = (fieldSlug: string): boolean =>
    Object.keys(errors).includes(fieldSlug) ||
    restRequires.includes(fieldSlug) ||
    lengthValidates.includes(fieldSlug);

  useEffect(() => {
    groupFields.forEach((g, i) => {
      const first = g.find(
        (f) => f.slug === [...restRequires, ...lengthValidates, ...Object.keys(errors)][0]
      );
      if (first) {
        setActiveTab(i);
        setTimeout(() => {
          scrollToAnchor(`${first.slug}--${embedLevel}`);
        }, 100);
      }
    });
  }, [
    errors,
    embedLevel,
    scrollToAnchor,
    groupFields,
    setActiveTab,
    restRequires,
    lengthValidates,
  ]);

  const onChangeTab = useCallback(
    (index: number, slug: string) => {
      handleChangeTab(index);
      setTimeout(() => {
        scrollToAnchor(`${slug}--${embedLevel}`);
      }, 100);
    },
    [embedLevel, handleChangeTab, scrollToAnchor]
  );

  useEffect(() => {
    if (!fieldFounded && embedLevel === -1) {
      groupFields.forEach((fields, i) => {
        const field = fields.find((f) => Number(queryString.searchFor) === f.id);
        if (field) {
          onChangeTab(i, field.slug);
          setFieldFounded(true);
        }
      });
    }
  }, [embedLevel, fieldFounded, groupFields, onChangeTab, queryString.searchFor]);

  return (
    <div className={styles.fieldNavigation}>
      <div
        className={styles.fixed}
        style={{ maxHeight: `calc(100% - ${embedLevel > -1 ? 125 : 149}px)` }}
      >
        <Typography className={styles.title} variant={'h5'}>
          {t('Table of contents')}
        </Typography>
        {groups.map((g, i) => (
          <div key={i}>
            <Typography
              className={clsx(styles.fieldsHeader, i !== 0 && styles.marginTop)}
              variant="body2"
              color="textSecondary"
              key={g.id}
            >
              {g.label}
            </Typography>
            {(showOnlyMultilingual
              ? groupFields[i] && groupFields[i].filter((f) => f.multilingual)
              : groupFields[i]
            ).map((field, index) => {
              return (
                <Typography
                  className={clsx(
                    styles.fieldsTypography,
                    hasErrorMessage(field.slug) && styles.required
                  )}
                  variant="body2"
                  onClick={() => onChangeTab(i, field.slug)}
                  key={index}
                >
                  {index + 1}. {field.label}
                  {field.required && <span className={styles.required}>*</span>}
                </Typography>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
