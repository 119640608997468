import Typography from '@material-ui/core/Typography';
import { ContentItem, SelectContentItem } from 'api/types/ContentItem';
import clsx from 'clsx';
import { formatOnlyDate, formatTime, parseDateTimeNotUndef } from 'common/utils/date';
import { BooleanField } from 'components/BooleanField';
import { SwitchField } from 'components/SwitchField';
import { isDate, parse } from 'date-fns';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePageStyles as usersPageStyles } from 'theme/page-styles';

import { exclusion_keys } from '../../../../helpers';
import { EmbeddedFieldDisplayedValues } from './EmbeddedFieldDisplayedValues';
import { Props } from './props';
import { useStyles } from './styles';

export const DisplayedValues: React.FC<Props> = ({ contentItem, fields, projectId }) => {
  const { t } = useTranslation();

  const classes = useStyles();
  const usersPageClasses = usersPageStyles();
  const [expandIndex, setExpandIndex] = useState<number>();

  return (
    <div className={classes.text}>
      {fields.some((f) => f.displayedValue) || contentItem.id ? (
        <table className={classes.table}>
          {contentItem.id && (
            <tr>
              <td className={clsx(classes.displayFieldLabel, classes.displayFieldItem)}>id</td>
              <td className={classes.displayFieldDivider}></td>
              <td
                title={contentItem.id as string}
                className={clsx(classes.displayFieldItem, classes.displayFieldContent)}
              >
                {contentItem.id as string}
              </td>
            </tr>
          )}
          {fields
            .filter(
              (t) =>
                t.displayedValue &&
                contentItem[t.slug] !== undefined &&
                (t.type !== 'REFERENCE' ||
                  (!t.embedded
                    ? !!(contentItem[t.slug] as { readonly items: readonly SelectContentItem[] })
                        ?.items?.length
                    : (contentItem[t.slug] as ReadonlyArray<ContentItem>).length > 0))
            )
            .map((field, i) => {
              switch (field.type) {
                case 'TEXT':
                case 'INTEGER':
                case 'AMOUNT':
                case 'LINK':
                  return (
                    <tr>
                      <td className={clsx(classes.displayFieldLabel, classes.displayFieldItem)}>
                        {field.label}
                      </td>
                      <td className={classes.displayFieldDivider}></td>
                      <td
                        title={contentItem[field.slug] as string}
                        className={clsx(classes.displayFieldItem, classes.displayFieldContent)}
                      >
                        {contentItem[field.slug] as string}
                      </td>
                    </tr>
                  );
                case 'BOOLEAN':
                  if (field.defaultValue) {
                    return (
                      <tr>
                        <td className={clsx(classes.displayFieldLabel, classes.displayFieldItem)}>
                          {field.label}
                        </td>
                        <td className={classes.displayFieldDivider}></td>
                        <td className={clsx(classes.displayFieldItem, classes.displayFieldContent)}>
                          <SwitchField
                            id={`${field.slug}_boolean_${i}`}
                            initChecked={
                              contentItem[field.slug] === true || contentItem[field.slug] === false
                                ? String(contentItem[field.slug] as string)
                                : field.defaultValue
                            }
                            disabled
                          />
                        </td>
                      </tr>
                    );
                  } else {
                    return (
                      <tr>
                        <td className={clsx(classes.displayFieldLabel, classes.displayFieldItem)}>
                          {field.label}
                        </td>
                        <td className={classes.displayFieldDivider}></td>
                        <td className={clsx(classes.displayFieldItem, classes.displayFieldContent)}>
                          <div key={i} className={classes.displayField}>
                            <BooleanField
                              disabled
                              initValue={
                                contentItem[field.slug] !== undefined
                                  ? (contentItem[field.slug] as boolean).toString()
                                  : ''
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  }
                case 'DATE':
                  return (
                    <tr>
                      <td className={clsx(classes.displayFieldLabel, classes.displayFieldItem)}>
                        {field.label}
                      </td>
                      <td className={classes.displayFieldDivider}></td>
                      <td className={clsx(classes.displayFieldItem, classes.displayFieldContent)}>
                        {isDate(contentItem[field.slug] as Date)
                          ? formatOnlyDate(contentItem[field.slug] as Date)
                          : (contentItem[field.slug] as string)}
                      </td>
                    </tr>
                  );
                case 'DATETIME':
                  return (
                    contentItem[field.slug] && (
                      <tr>
                        <td className={clsx(classes.displayFieldLabel, classes.displayFieldItem)}>
                          {field.label}
                        </td>
                        <td className={classes.displayFieldDivider}></td>
                        <td className={clsx(classes.displayFieldItem, classes.displayFieldContent)}>
                          {formatOnlyDate(parseDateTimeNotUndef(contentItem[field.slug] as string))}
                          {formatTime(parseDateTimeNotUndef(contentItem[field.slug] as string))}
                        </td>
                      </tr>
                    )
                  );
                case 'TIME':
                  return (
                    contentItem[field.slug] && (
                      <tr>
                        <td className={clsx(classes.displayFieldLabel, classes.displayFieldItem)}>
                          {field.label}
                        </td>
                        <td className={classes.displayFieldDivider}></td>
                        <td className={clsx(classes.displayFieldItem, classes.displayFieldContent)}>
                          {formatTime(
                            isDate(contentItem[field.slug] as Date)
                              ? (contentItem[field.slug] as Date)
                              : parse(
                                  contentItem[field.slug] as string,
                                  'HH:mm:ss',
                                  new Date(2000, 0, 1)
                                )
                          )}
                        </td>
                      </tr>
                    )
                  );
                case 'IMAGE':
                case 'ICON':
                  return (
                    contentItem[field.slug] && (
                      <tr>
                        <td className={clsx(classes.displayFieldLabel, classes.displayFieldItem)}>
                          {field.label}
                        </td>
                        <td className={classes.displayFieldDivider}></td>
                        <td className={clsx(classes.displayFieldItem, classes.displayFieldContent)}>
                          <img
                            src={contentItem[field.slug] as string}
                            alt=""
                            className={clsx('image', `image-${field.type}`)}
                          />
                        </td>
                      </tr>
                    )
                  );
                case 'REFERENCE':
                  // (contentItem[field.slug] as ContentItem).items.slice(0, 2).map(item => <div>
                  //   {item.id}
                  // </div>)
                  return (
                    <tr>
                      <td
                        onMouseEnter={() => field.list && setExpandIndex(i)}
                        onMouseLeave={() => setExpandIndex(undefined)}
                        className={clsx(
                          classes.displayFieldLabel,
                          classes.displayFieldItem,
                          classes.refFieldLabel
                        )}
                      >
                        {field.label}
                        {field.list &&
                          ((!field.embedded && (
                            <span>
                              (
                              {(contentItem[field.slug] as ContentItem).items &&
                                (contentItem[field.slug] as ContentItem).items.length}
                              )
                            </span>
                          )) ||
                            (field.embedded &&
                              (contentItem[field.slug] as ContentItem) &&
                              (contentItem[field.slug] as ContentItem).length))}
                        {expandIndex === i &&
                          (field.embedded
                            ? (contentItem[field.slug] as ReadonlyArray<ContentItem>)?.length > 3
                            : (contentItem[field.slug] as ContentItem)?.items?.length > 3) && (
                            <div className={classes.absolute}>
                              <div
                                className={clsx(
                                  classes.displayFieldItem,
                                  classes.displayFieldContent,
                                  classes.maxWidth
                                )}
                              >
                                {(!field.embedded &&
                                  (contentItem[field.slug] as ContentItem).items.map(
                                    (refItem: ContentItem, i: number) => (
                                      <div
                                        key={i}
                                        className={clsx(
                                          usersPageClasses.reference,
                                          (field.list && usersPageClasses.referenceMultiple) ||
                                            usersPageClasses.referenceSingle
                                        )}
                                      >
                                        {Object.entries(refItem)
                                          .filter(
                                            (item) =>
                                              !exclusion_keys.includes(item[0]) &&
                                              typeof refItem[item[0]] !== 'object'
                                          )
                                          .reduce(
                                            (prev, next) =>
                                              (prev.length > 0 && `${prev} ${next[1]}`) ||
                                              `${next[1]}`,
                                            ''
                                          )}
                                      </div>
                                    )
                                  )) || (
                                  <EmbeddedFieldDisplayedValues
                                    contentItems={
                                      contentItem[field.slug] as ReadonlyArray<ContentItem>
                                    }
                                    contentTypeSlug={field.referencedContentType || ''}
                                    contentField={field}
                                    projectId={projectId}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                      </td>
                      <td className={classes.displayFieldDivider}></td>
                      <td className={clsx(classes.displayFieldItem, classes.displayFieldContent)}>
                        {(!field.embedded &&
                          (contentItem[field.slug] as ContentItem).items
                            .slice(0, 2)
                            .map((refItem: ContentItem, i: number) => (
                              <div
                                title={Object.entries(refItem)
                                  .filter(
                                    (item) =>
                                      !exclusion_keys.includes(item[0]) &&
                                      typeof refItem[item[0]] !== 'object'
                                  )
                                  .reduce(
                                    (prev, next) =>
                                      (prev.length > 0 && `${prev} ${next[1]}`) || `${next[1]}`,
                                    ''
                                  )}
                                key={i}
                                className={clsx(
                                  usersPageClasses.reference,
                                  (field.list && usersPageClasses.referenceMultiple) ||
                                    usersPageClasses.referenceSingle
                                )}
                              >
                                {Object.entries(refItem)
                                  .filter(
                                    (item) =>
                                      !exclusion_keys.includes(item[0]) &&
                                      typeof refItem[item[0]] !== 'object'
                                  )
                                  .reduce(
                                    (prev, next) =>
                                      (prev.length > 0 && `${prev} ${next[1]}`) || `${next[1]}`,
                                    ''
                                  )}
                              </div>
                            ))) || (
                          // (contentItem[field.slug] as ContentItem)
                          //   .slice(0, 2)
                          //   .map((refItem: ContentItem, i: number) => (
                          //     <div key={i}>{refItem.id}</div>
                          //   ))
                          <EmbeddedFieldDisplayedValues
                            contentItems={contentItem[field.slug] as ReadonlyArray<ContentItem>}
                            contentTypeSlug={field.referencedContentType || ''}
                            contentField={field}
                          />
                        )}
                      </td>
                    </tr>
                  );
                default:
                  return (
                    <tr>
                      <td>{field.type}</td>
                      <td>{field.type}</td>
                    </tr>
                  );
              }
            })
            .slice(0, 12)}
        </table>
      ) : (
        <Typography variant="body2">{t('The displayed value is not specified')}</Typography>
      )}
    </div>
  );
};
