import { Drawer } from '@material-ui/core';
import { fieldsGet, referableObjectsGet } from 'api/content';
import { ContentItem, SelectContentItem } from 'api/types/ContentItem';
import { ContentTypeField } from 'api/types/ContentTypeField';
import { SelectItem, SelectPelican } from 'components/SelectPelican';
import { useSnackbar } from 'notistack';
import { ContentReferenceLabel } from 'pages/ContentEditorPage/ContentEditor/ContentFormFields/ContentReferenceInput/ContentReferenceLabel';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ValueType } from 'react-select';
import { InputActionMeta, OptionTypeBase as OptionType } from 'react-select/src/types';

import { ContentEditor } from '../../../ContentEditor';
import { Props } from './props';

export const ReferenceField: React.FC<Props> = ({
  contentItem,
  field,
  onChange,
  value,
  embedLevel,
  isReviewing,
  rootProjectId,
  selectedProjectOwner,
  contentTypeSlug,
  loadedReferable,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { i18n, t } = useTranslation();

  const projectId =
    embedLevel === -1
      ? contentItem.project_id || (selectedProjectOwner?.value as number)
      : rootProjectId;

  const [referableObjects, setReferableObjects] = useState<{
    readonly list: ReadonlyArray<SelectContentItem>;
    readonly page: number;
    readonly loading: boolean;
    readonly totalCount: number | undefined;
    readonly search: string | undefined;
  }>({
    list: [],
    page: 1,
    loading: false,
    totalCount: undefined,
    search: undefined,
  });
  const referableObjectsRef = useRef(referableObjects);
  referableObjectsRef.current = referableObjects;
  const [fields, setFields] = useState<ReadonlyArray<ContentTypeField>>();
  const [drawerOpenType, setDrawerOpenType] = useState<'create' | 'edit'>();

  const setReferableLoading = useCallback((loading: boolean) => {
    setReferableObjects((prev) => ({ ...prev, loading: loading }));
  }, []);

  const getReferableObjects = useCallback(
    async (search?: string) => {
      const sameSearch = search === referableObjectsRef.current.search;
      if (
        (referableObjectsRef.current.totalCount !== referableObjectsRef.current.list.length ||
          !sameSearch) &&
        !referableObjectsRef.current.loading
      ) {
        setReferableLoading(true);
        try {
          const res = await referableObjectsGet(contentTypeSlug, field.id, {
            project_id: projectId,
            page: sameSearch ? referableObjectsRef.current.page : 1,
            size: 15,
            search_text: search,
          });
          setReferableObjects((prev) => ({
            ...prev,
            list: [
              ...(referableObjectsRef.current.page > 1 && sameSearch ? prev.list : []),
              ...res.list,
            ],
            totalCount: res.totalCount,
            page: sameSearch ? referableObjectsRef.current.page + 1 : 2,
            search,
          }));
        } catch (e) {
          enqueueSnackbar(
            `${t('Error on loading')} ${t('referable')} ${e.response?.data?.message || e}`,
            {
              variant: 'error',
            }
          );
        } finally {
          setReferableLoading(false);
        }
      }
    },
    [setReferableLoading, contentTypeSlug, field, projectId, enqueueSnackbar, t]
  );

  const onMenuOpen = useCallback(() => {
    if (referableObjectsRef.current.totalCount === undefined) {
      getReferableObjects(referableObjectsRef.current.search);
    }
  }, [getReferableObjects]);

  const onMenuScrollToBottom = useCallback(() => {
    if (referableObjectsRef.current.page > 1) {
      getReferableObjects(referableObjectsRef.current.search);
    }
  }, [getReferableObjects]);

  const onInputChange = useCallback(
    (val: string, action: InputActionMeta) => {
      if (action.action === 'input-change') {
        getReferableObjects(val);
      } else if (action.action === 'menu-close' && referableObjectsRef.current.search) {
        setReferableObjects((prev) => ({
          ...prev,
          search: undefined,
          totalCount: undefined,
          page: 1,
          list: [],
        }));
      }
    },
    [getReferableObjects]
  );

  const referenceSelected = useCallback(
    (
      val: ValueType<OptionType, boolean> | OptionType,
      field: ContentTypeField,
      referable: ReadonlyArray<SelectContentItem>
    ) => {
      if (!field.list) {
        const selectVal = val as SelectItem;
        const selectedValue = selectVal
          ? referable.filter((obj) => selectVal.value === obj.id)
          : [];

        onChange(selectedValue);
      } else {
        const selectVal = (Array.isArray(val) ? val : [val]) as ReadonlyArray<SelectItem>;
        const ids = selectVal.map((item) => item.value);
        const selectedValues = referable.filter((obj) => ids.indexOf(obj.id) > -1);
        onChange(selectedValues);
      }
    },
    [onChange]
  );

  const onCloseEditItemDrawer = useCallback(
    async (update?: boolean, newId?: string, newContentItem?: ContentItem) => {
      setDrawerOpenType(undefined);
      if (update) {
        setReferableLoading(true);
        const referable = await referableObjectsGet(contentTypeSlug, field.id, {
          project_id: projectId,
          page: 1,
          size: 10000,
        });
        if (newId && referable) {
          setReferableObjects((prev) => ({
            ...prev,
            list: referable.list,
            totalCount: referable.totalCount,
          }));
          const filtered = referable.list.filter(
            (r) =>
              +newId === r.id || (field.list && value.some((o: SelectContentItem) => o.id === r.id))
          );
          if (drawerOpenType === 'create' || filtered.length > 0) {
            onChange(filtered, referable.list);
          } else if (fields && newContentItem) {
            const newItem = fields.reduce(
              (prev, f) => {
                if (f.displayedValue) {
                  return {
                    ...prev,
                    [f.slug]: f.multilingual
                      ? newContentItem['$.multilingual']?.[i18n.language]?.[f.slug]
                      : newContentItem[f.slug],
                  };
                }
                return prev;
              },
              { id: +newId, source_id: value[0]?.source_id } as SelectContentItem
            );
            onChange([newItem], referable.list);
          }
        }
        setReferableLoading(false);
      }
    },
    [
      i18n,
      setReferableLoading,
      contentTypeSlug,
      field.id,
      field.list,
      projectId,
      drawerOpenType,
      fields,
      value,
      onChange,
    ]
  );

  const parseItems = useCallback(
    (items?: ReadonlyArray<SelectContentItem>): ReadonlyArray<OptionType> | undefined => {
      if (fields && items) {
        return items.map((item) => {
          return {
            value: item.id,
            label: <ContentReferenceLabel option={item} fields={fields} />,
          };
        });
      }
    },
    [fields]
  );

  const onSelectAll = useCallback(async () => {
    const refObjects = referableObjectsRef.current;

    if (refObjects.list.length === refObjects.totalCount) {
      onChange(refObjects.list);
    } else {
      setReferableLoading(true);
      try {
        const res = await referableObjectsGet(contentTypeSlug, field.id, {
          project_id: projectId,
          page: 1,
          size: 1000,
        });

        onChange(res.list);
        setReferableObjects((prev) => ({
          ...prev,
          list: res.list,
          totalCount: res.totalCount,
          page: Math.ceil(res.list.length / 15),
        }));
      } catch (e) {
        enqueueSnackbar(
          `${t('Error on loading')} ${t('referable')} ${e.response?.data?.message || e}`,
          {
            variant: 'error',
          }
        );
      } finally {
        setReferableLoading(false);
      }
    }
  }, [onChange, setReferableLoading, contentTypeSlug, field.id, projectId, enqueueSnackbar, t]);

  const getFields = useCallback(async () => {
    try {
      const fieldsList = await fieldsGet(field.referencedContentType || '', {
        'fetch-references': false,
        project_id: projectId,
      });
      setFields(fieldsList);
    } catch (e) {
      enqueueSnackbar(`${t('Error on loading')} ${t('fields')} ${e.response?.data?.message || e}`, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, field.referencedContentType, projectId, t]);

  const handleCreateNewRefItem = (): void => {
    setDrawerOpenType('create');
  };
  const handleEditRefItem = (): void => {
    setDrawerOpenType('edit');
  };

  useEffect(() => {
    getFields();
  }, [getFields]);

  useEffect(() => {
    if (selectedProjectOwner?.value) {
      setReferableObjects((prev) => ({ ...prev, totalCount: undefined, page: 1 }));
    }
  }, [selectedProjectOwner]);

  useEffect(() => {
    if (loadedReferable) {
      const referable = loadedReferable[field.referencedContentType || ''];
      referable &&
        setReferableObjects((prev) => ({
          ...prev,
          list: referable,
          page: Math.ceil(referable.length / 15),
          totalCount: referable.length,
        }));
    }
  }, [loadedReferable, field.referencedContentType]);

  return (
    <>
      <SelectPelican
        name="reference"
        id={field.slug}
        isClearable
        placeholder=""
        isMulti={field.list}
        value={parseItems(value)}
        options={parseItems(referableObjects.list) || []}
        selected={(val: ValueType<OptionType, boolean> | OptionType) => {
          referenceSelected(val, field, [
            ...referableObjects.list,
            ...value.filter(
              (v: SelectContentItem) => !referableObjects.list.some((r) => r.id === v.id)
            ),
          ]);
        }}
        handleCreateNew={handleCreateNewRefItem}
        handleEditItem={handleEditRefItem}
        isLoading={referableObjects.loading || !fields}
        onMenuOpen={onMenuOpen}
        onMenuScrollToBottom={onMenuScrollToBottom}
        onInputChange={onInputChange}
        filterOption={null}
        onSelectAll={onSelectAll}
      />
      <Drawer
        anchor={'right'}
        disableBackdropClick
        open={!!drawerOpenType}
        data-cy={`drawer-${embedLevel}`}
        onClose={() => setDrawerOpenType(undefined)}
      >
        <div
          style={{
            width: `${90 - embedLevel - 1}vw`,
            minHeight: '100vh',
            paddingLeft: 20,
            paddingTop: 10,
          }}
        >
          {!!drawerOpenType && (
            <ContentEditor
              contentTypeSlug={field?.referencedContentType || ''}
              sourceId={drawerOpenType === 'edit' ? String(value[0]?.id) : '-1'}
              embedLevel={embedLevel + 1}
              isReviewing={isReviewing}
              isEmbeddedItem={false}
              onCloseButtonClick={onCloseEditItemDrawer}
              rootProjectId={projectId}
            />
          )}
        </div>
      </Drawer>
    </>
  );
};
