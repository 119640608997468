import { Menu, MenuItem, Typography } from '@material-ui/core';
import { DATE_FORMAT } from 'common/constants/date';
import { statusChipMapping } from 'common/constants/statuses';
import { AnchorElType } from 'common/types/AnchorElType';
import { getParsedDate } from 'common/utils/date';
import Chip from 'components/Chip/Chip';
import { CircleTripleOutline } from 'components/IconPelican/icons';
import { format } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { Props } from './props';
import { useStyles } from './styles';

export const Version: React.FC<Props> = ({ version, currentId, embedLevel, author }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const styles = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<AnchorElType>(null);
  const isCurrent = version.data_id === currentId;

  const handleMenuClick = useCallback((event: React.MouseEvent<React.ReactNode>) => {
    setAnchorEl(event.currentTarget);
  }, []);
  const handleClose = (): void => {
    setAnchorEl(null);
  };
  const toCompare = (withCurrent: boolean): void => {
    history.push(
      `${currentId}/${version.data_id}?controlType=${withCurrent ? 'compare' : 'restore'}`
    );
  };
  const toVersion = (): void => {
    const path = location.pathname.split('/');
    path[path.length - 1] = `${version.data_id}`;
    const newLocation = path.join('/');
    window.location.href = newLocation;
  };

  const isMain = useMemo(() => embedLevel === undefined || embedLevel === -1, [embedLevel]);

  return (
    <div className={styles.root}>
      <div>
        {isCurrent && <Chip>{t('Current')}</Chip>}
        <Chip
          variant={
            statusChipMapping[version.status || 'draft'] as
              | 'success'
              | 'error'
              | 'primary'
              | 'disabled'
              | 'default'
          }
        >
          {version.status} v{version.version}
        </Chip>
        <Typography variant="h4" className={styles.title}>
          {author}
        </Typography>
        <Typography variant="h5" className={styles.comment}>
          {format(getParsedDate(version.created_at), DATE_FORMAT)}
        </Typography>
      </div>
      {!isCurrent && isMain && (
        <div className={styles.action}>
          <CircleTripleOutline cursor="pointer" onClick={handleMenuClick} />
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            keepMounted
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem disabled={isCurrent} onClick={() => toCompare(true)}>
              {t('Compare with current')}
            </MenuItem>

            <MenuItem disabled={isCurrent} onClick={() => toCompare(false)}>
              {t('Restore')}
            </MenuItem>
            <MenuItem disabled={isCurrent} onClick={() => toVersion()}>
              {t('Go to')}
            </MenuItem>
          </Menu>
        </div>
      )}
    </div>
  );
};
