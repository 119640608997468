import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ImageSquareOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2 19.5H7.8c-.865 0-1.423-.001-1.848-.036-.408-.033-.559-.09-.633-.127a1.5 1.5 0 01-.656-.656c-.037-.074-.094-.225-.127-.633-.035-.425-.036-.983-.036-1.848V7.8c0-.865.001-1.423.036-1.848.033-.408.09-.559.127-.633a1.5 1.5 0 01.656-.656c.074-.037.225-.094.633-.127C6.377 4.5 6.935 4.5 7.8 4.5h8.4c.865 0 1.423.001 1.848.036.408.033.559.09.633.127a1.5 1.5 0 01.655.656c.038.074.095.225.128.633.035.425.036.983.036 1.848v8.4c0 .865-.001 1.423-.036 1.848-.033.408-.09.559-.127.633a1.5 1.5 0 01-.656.655c-.074.038-.225.095-.633.128-.425.035-.983.036-1.848.036zm-12.873-.138C3 18.72 3 17.88 3 16.2V7.8c0-1.68 0-2.52.327-3.162a3 3 0 011.311-1.311C5.28 3 6.12 3 7.8 3h8.4c1.68 0 2.52 0 3.162.327a3 3 0 011.311 1.311C21 5.28 21 6.12 21 7.8v8.4c0 1.68 0 2.52-.327 3.162a3 3 0 01-1.311 1.311C18.72 21 17.88 21 16.2 21H7.8c-1.68 0-2.52 0-3.162-.327a3 3 0 01-1.311-1.311zM6.56 16.84l2.45-3.342a.2.2 0 01.316-.008l1.785 2.192a.2.2 0 00.314-.004l2.673-3.457a.2.2 0 01.325.012l3.046 4.615a.2.2 0 01-.167.31H6.721a.2.2 0 01-.161-.318zM10.453 12a1.547 1.547 0 100-3.095 1.547 1.547 0 000 3.095z"
      fill="#000"
    />
  </SvgIcon>
);
export default ImageSquareOutline;
