import { Typography } from '@material-ui/core';
import { userLogout } from 'api/login';
import { removeCookie } from 'common/utils/cookies';
import { useSnackbar } from 'notistack';
import AssetsCreate from 'pages/AssetsPage/AssetsCreate/AssetsCreate';
import React, { useCallback, useEffect, useState } from 'react';
import Hotkeys from 'react-hot-keys';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { routeIcons } from 'router/helpers';
import { RouteIcon } from 'router/types/route-icon';
import { getMenuItems } from 'store/menuSlice';
import MatNav from 'templates/MainTemplate/Sidebar/NavMenu';

import { ToggleMenu } from '../TopNav/ToggleMenu/ToggleMenu';
import { Props } from './props';
import { useStyles } from './styles';

export const Sidebar: React.FC<Props> = ({
  isToggle,
  showContentNavigation,
  showSearch,
  expandToggle,
}) => {
  const classes = useStyles();
  const menuItems = useSelector(getMenuItems);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const appVersion = process.env.REACT_APP_COMMIT_TAG || process.env.REACT_APP_VERSION;

  const [iconList, setIconList] = useState<readonly RouteIcon[]>([]);
  const [isCreate, setCreate] = React.useState(false);

  useEffect(() => {
    setIconList(routeIcons());
  }, []);

  const onKeyDown = (hotkey: string, e: KeyboardEvent): void => {
    if (hotkey === 'option+q' || hotkey === 'alt+q') {
      handleLogout();
      e.preventDefault();
    }
    if (hotkey === 'command+l' || hotkey === 'ctrl+l') {
      showContentNavigation();
      e.preventDefault();
    }
    if (hotkey === 'command+k' || hotkey === 'ctrl+k') {
      showSearch();
      e.preventDefault();
    }
    if (hotkey === 'command+m' || hotkey === 'ctrl+m') {
      history.push('/content-types');
      e.preventDefault();
    }
    if (hotkey === 'command+shift+m' || hotkey === 'ctrl+shift+m') {
      history.push('/content-types/create');
      e.preventDefault();
    }
    if (hotkey === 'option+shift+n' || hotkey === 'alt+shift+n') {
      const location = window.location.pathname.split('/').filter((v) => v !== '');
      if (location.length === 2 && location[0] === 'content') {
        history.push(window.location.pathname + '/-1');
      }
      e.preventDefault();
    }
    if (hotkey === 'command+shift+a' || hotkey === 'ctrl+shift+a') {
      const location = window.location.pathname.split('/').filter((v) => v !== '');
      if (location[0] !== 'assets') {
        setCreate(true);
      }
      e.preventDefault();
    }
  };

  const handleLogout = useCallback(async () => {
    try {
      await userLogout();
      removeCookie('SESSION');
      history.push('/login');
    } catch (e) {
      enqueueSnackbar(`${t('Logout error')} ${e.response?.data?.message || e}`, {
        variant: 'error',
      });
    }
  }, [history, t, enqueueSnackbar]);

  const handleClose = useCallback(() => {
    setCreate(false);
  }, []);

  return (
    <Hotkeys
      keyName="command+l,ctrl+l,command+m,ctrl+m,ctrl+shift+m,command+shift+m,command+shift+a,ctrl+shift+a,option+shift+n,alt+shift+n,option+q,alt+q,command+k,ctrl+k"
      onKeyDown={onKeyDown}
    >
      <div className={classes.nav}>
        <MatNav
          expandSidebar={expandToggle}
          isToggle={isToggle}
          items={menuItems}
          icons={iconList}
        />
        <div className={classes.footer}>
          {!isToggle && (
            <div className={classes.appName}>
              <img
                src="/logo-gray.svg"
                className={classes.appLogo}
                width={18}
                height={24}
                alt="tezapp"
              />
              <Typography className={classes.appVersion}>tezapp: v{appVersion}</Typography>
            </div>
          )}
          <ToggleMenu value={isToggle} toggle={expandToggle} className={classes.toggleSidebar} />
        </div>
      </div>
      <AssetsCreate
        open={isCreate}
        onCloseModal={() => handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      />
    </Hotkeys>
  );
};
