import Popover from '@material-ui/core/Popover/Popover';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';

import { Props } from './props';
import { useStyles } from './styles';

export const ColourInput: React.FC<Props> = ({ color, handleColorChange, disabled, slug }) => {
  const styles = useStyles();
  const [paletteColor, setPaletteColor] = useState<string>(color);
  const [openEditColor, setEditColor] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const mainButtonRef = useRef<HTMLDivElement>(null);

  const handleChangePaletteColor = useCallback(
    (color: string) => {
      setPaletteColor(color);
      handleColorChange && handleColorChange(color);
    },
    [handleColorChange, setPaletteColor]
  );

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (color) {
      setPaletteColor(color);
    }
  }, [color]);

  return (
    <div id={slug}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={styles.colorInput}
        ref={mainButtonRef}
        onClick={() => setAnchorEl(mainButtonRef.current)}
      >
        <button
          className={styles.colorChoose}
          disabled={disabled}
          style={{
            backgroundColor: paletteColor,
          }}
          type="button"
          onClick={() => setEditColor(!openEditColor)}
        />
        <span>{paletteColor}</span>
      </div>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={6}
        disableScrollLock={false}
        classes={{ root: styles.popoverRoot, paper: styles.popover }}
      >
        <SketchPicker
          color={paletteColor}
          onChange={(color) => {
            handleChangePaletteColor(color.hex);
          }}
        />
      </Popover>
    </div>
  );
};
