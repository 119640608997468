import { Box, IconButton, Link } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';
import { ArrowDownCircleIcon, ArrowRightCircleIcon } from 'components/IconPelican';
import { useStyles as useTableStyles } from 'components/TableDynamic/styles';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setSelected } from 'store/contentSlice';

import { ContentNavItemProps } from './index';
import { useStyles } from './styles';

export const ContentNavItem: React.FC<ContentNavItemProps> = ({
  name,
  items,
  id,
  slug,
  stats,
  onClose,
  openModel,
}) => {
  const classesOwn = useStyles();
  const classes = useTableStyles({});
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = (name: string, slug?: string): void => {
    dispatch(setSelected({ name, slug }));
    history.push(`/content/${slug}`);
    onClose && onClose();
  };

  const handleCreateClick = useCallback(
    (name: string, slug?: string) => {
      history.push(`/content/${slug}/-1`);
      dispatch(setSelected({ name, slug }));
      onClose && onClose();
    },
    [dispatch, history, onClose]
  );

  useEffect(() => {
    setOpen(openModel);
  }, [openModel]);

  return (
    <>
      <TableRow className={classes.tableRow}>
        <TableCell className={clsx(classes.cell, classesOwn.cell, classesOwn.centerAlign)}>
          {!(items && items.length) && (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <IconButton
              className={classesOwn.addIcon}
              onClick={() => handleCreateClick(name, slug)}
            >
              <AddIcon />
            </IconButton>
          )}
        </TableCell>
        <TableCell className={clsx(classes.cell, classesOwn.cell)}>
          {items && items.length ? (
            <>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                {open ? <ArrowDownCircleIcon /> : <ArrowRightCircleIcon />}
              </IconButton>
              <span className="tableAnchor">{name}</span>
            </>
          ) : (
            <Box display="flex" paddingLeft={1}>
              <Link
                component="button"
                variant="body2"
                className="tableAnchor"
                onClick={() => handleClick(name, slug)}
              >
                {name}
              </Link>
            </Box>
          )}
        </TableCell>
        <TableCell className={clsx(classes.cell, classesOwn.cell)}>{stats?.archived}</TableCell>
        <TableCell className={clsx(classes.cell, classesOwn.cell)}>{stats?.draft}</TableCell>
        <TableCell className={clsx(classes.cell, classesOwn.cell)}>{stats?.published}</TableCell>
        <TableCell className={clsx(classes.cell, classesOwn.cell)}>{stats?.reviewing}</TableCell>
        <TableCell className={clsx(classes.cell, classesOwn.cell)}>{stats?.scheduled}</TableCell>
        <TableCell className={clsx(classes.cell, classesOwn.cell)}>{stats?.total}</TableCell>
      </TableRow>
      {items?.length &&
        open &&
        items.map((item, index) => (
          <TableRow className={classes.tableRow} key={`${id}_${item.id}_${index}`}>
            <TableCell className={clsx(classes.cell, classesOwn.cell, classesOwn.centerAlign)}>
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <IconButton
                className={classesOwn.addIcon}
                onClick={() => handleCreateClick(item.name, item.slug)}
              >
                <AddIcon />
              </IconButton>
            </TableCell>
            <TableCell className={clsx(classes.cell, classesOwn.cell)}>
              <Box display="flex">
                <span className={classesOwn.mark}>
                  <FiberManualRecordIcon
                    fontSize="small"
                    classes={{
                      fontSizeSmall: classesOwn.dot,
                    }}
                  />
                </span>
                <Link
                  component="button"
                  variant="body2"
                  className="tableAnchor"
                  onClick={() => handleClick(item.name, item.slug)}
                >
                  {item.name}
                </Link>
              </Box>
            </TableCell>
            <TableCell className={clsx(classes.cell, classesOwn.cell)}>
              {item.stats?.archived}
            </TableCell>
            <TableCell className={clsx(classes.cell, classesOwn.cell)}>
              {item.stats?.draft}
            </TableCell>
            <TableCell className={clsx(classes.cell, classesOwn.cell)}>
              {item.stats?.published}
            </TableCell>
            <TableCell className={clsx(classes.cell, classesOwn.cell)}>
              {item.stats?.reviewing}
            </TableCell>
            <TableCell className={clsx(classes.cell, classesOwn.cell)}>
              {item.stats?.scheduled}
            </TableCell>
            <TableCell className={clsx(classes.cell, classesOwn.cell)}>
              {item.stats?.total}
            </TableCell>
          </TableRow>
        ))}
    </>
  );
};
