import { Box, Typography } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import { useCustomPagination } from 'common/hooks/useCustomPagination';
import { CheckboxPelican } from 'components/CheckboxPelican';
import { ArrowRight } from 'components/IconPelican/icons';
import { Spin } from 'components/Spin/component';
import { TextInput } from 'components/TextInput';
import NoData from 'containers/NoData/NoData';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NoDiff } from '../../NoDiff/NoDiff';
import { useReleasesContext } from '../ReleasesProvider';
import { DiffEnvResult } from '../types/DiffModels';
import { PublishItemDto } from '../types/PublishItemDto';
import { Props } from './props';
import { useStyles } from './styles';

export const ModelList: React.FC<Props> = ({ list, loading }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { page, perPage, pageCount, setPageCount, setPage } = useCustomPagination();
  const [listItems, setListItems] = useState<readonly DiffEnvResult[]>([]);
  const [pageItems, setPageItems] = useState<readonly DiffEnvResult[]>([]);
  const [filteredItems, setFilteredItems] = useState<readonly DiffEnvResult[]>(list || []);
  const [searchVal, setSearchVal] = useState('');
  const [noDiff, setNoDiff] = useState(false);
  const {
    selectedSlugId,
    setSelectedSlug,
    setSelectedContentName,
    publishItems,
    setPublishItems,
    setLeftSlug,
    setRightSlug,
  } = useReleasesContext();

  const publishItemKeys = publishItems.map((p) => p.contentTypeId);

  const handleChange = (checked: boolean, item: DiffEnvResult): void => {
    if (checked) {
      setPublishItems([
        ...publishItems,
        {
          contentTypeId: item.contentTypeId,
          sourceIds: [],
          publishAll: false,
        },
      ]);
    } else {
      setPublishItems(publishItems.filter((p) => p.contentTypeId !== item.contentTypeId));
    }
  };

  const onItemClick = (id: number, name: string, leftSlug?: string, rightSlug?: string): void => {
    setSelectedSlug(id);
    setSelectedContentName(name);
    setLeftSlug(leftSlug);
    setRightSlug(rightSlug);
  };

  const includeFilteredItems = useCallback(
    (items: ReadonlyArray<PublishItemDto>): ReadonlyArray<PublishItemDto> => {
      return items.filter(
        (item) => !filteredItems.some((l) => item.contentTypeId === l.contentTypeId)
      );
    },
    [filteredItems]
  );

  const handleCheckAll = (checked: boolean): void => {
    if (checked) {
      if (filteredItems?.length > 0) {
        setPublishItems((prev) =>
          includeFilteredItems(prev).concat(
            filteredItems.map(
              (l) =>
                ({
                  contentTypeId: l.contentTypeId,
                  publishAll: true,
                  sourceIds: [],
                } as PublishItemDto)
            )
          )
        );
      } else {
        setPublishItems(
          listItems.map(
            (l) =>
              ({
                contentTypeId: l.contentTypeId,
                publishAll: true,
                sourceIds: [],
              } as PublishItemDto)
          )
        );
      }
    } else {
      if (filteredItems?.length > 0) {
        setPublishItems((prev) => includeFilteredItems(prev));
      } else {
        setPublishItems([]);
      }
    }
  };

  const onChangePage = (_e: ChangeEvent<unknown>, page: number): void => {
    setPage(page);
    const start = (page - 1) * perPage;
    filteredItems && setPageItems(filteredItems.slice(start, page * perPage));
  };

  const handleSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const val = e.target.value;
    setSearchVal(val);

    if (val) {
      const page = 1;
      setPage(page);
      const start = (page - 1) * perPage;
      const lowerVal = val.toLowerCase();
      const filtered = list?.filter((l) => l.description.toLowerCase().includes(lowerVal));
      filtered && setFilteredItems(filtered);
      filtered && setPageItems(filtered.slice(start, page * perPage));
      const count = Math.ceil((filtered?.length || 0) / perPage);
      setPageCount(count);
    } else {
      setFilteredItems(list || []);
      const count = Math.ceil((list?.length || 0) / perPage);
      setPageCount(count);
      const page = 1;
      setPage(page);
      const start = (page - 1) * perPage;
      const end = count === page ? listItems?.length : start + perPage;
      setPageItems(listItems.slice(start, end));
    }
  };

  useEffect(() => {
    const count = Math.ceil((list?.length || 0) / perPage);
    setPageCount(count);
    const start = perPage * (page - 1);
    const end = count === page ? listItems?.length : start + perPage;

    if (listItems?.length >= end) {
      setPageItems(listItems.slice(start, end));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, setPageCount, page, listItems]);

  useEffect(() => {
    list && setListItems(list);
    if (list?.length === 0) {
      setNoDiff(true);
    } else {
      setNoDiff(false);
    }
  }, [list]);

  return (
    <Box className={styles.container}>
      <Box display={'flex'} justifyContent={'space-between'} paddingRight={'20px'}>
        <Typography style={{ fontWeight: 'bold' }} variant={'h3'}>
          {t('Models')}
        </Typography>
        <MoreHorizIcon cursor="pointer" className={styles.moreButton} />
      </Box>
      <Box display={'flex'} mt={2} alignItems={'center'} marginBottom={1}>
        <div className={styles.checkbox}>
          <CheckboxPelican
            checked={
              filteredItems.length > 0
                ? publishItems.filter((item) =>
                    filteredItems.some((l) => item.contentTypeId === l.contentTypeId)
                  ).length === filteredItems.length
                : listItems.length > 0 && publishItems.length === listItems.length
            }
            onChange={(_e, checked) => handleCheckAll(checked)}
          />
        </div>
        <Box flex={1} paddingRight={'20px'}>
          <TextInput placeholder={t('Search')} value={searchVal} onChange={handleSearch} />
        </Box>
      </Box>
      <Box position={'relative'} height={'60%'}>
        {loading && <Spin />}
        {!list?.length && !loading && !noDiff && <NoData />}
        {noDiff && <NoDiff />}
        {list?.length && !loading && (
          <>
            {pageItems?.map((l) => (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <div
                key={l.contentTypeId}
                className={clsx(styles.item, l.contentTypeId === selectedSlugId && styles.active)}
                onClick={() =>
                  onItemClick(l.contentTypeId, l.description, l.slugs?.left, l.slugs?.right)
                }
              >
                <Box display={'flex'} style={{ width: '70%', wordBreak: 'break-all' }}>
                  <div className={styles.checkbox}>
                    <CheckboxPelican
                      value={l.contentTypeId}
                      checked={publishItemKeys?.includes(l.contentTypeId)}
                      onChange={(_e, checked) => handleChange(checked, l)}
                    />
                  </div>
                  <div>{l.description}</div>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography style={{ color: '#00801B' }} variant={'body2'}>
                    {l.state === 'EDITED' && t('Active')}
                    {l.state === 'NEW' && t('New')}
                    {l.state === 'DELETED' && t('Active')}
                  </Typography>
                  {l.state !== 'NEW' && (
                    <>
                      <Box
                        width={'50px'}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <ArrowRight />
                      </Box>
                      <Typography
                        style={{ color: l.state === 'DELETED' ? '#8F8F91' : '#0039D4' }}
                        variant={'body2'}
                      >
                        {l.state === 'EDITED' && t('Edited')}
                        {l.state === 'NEW' && '-'}
                        {l.state === 'DELETED' && t('Deleted')}
                      </Typography>
                    </>
                  )}
                </Box>
              </div>
            ))}
            <Pagination
              classes={{ root: styles.pagination }}
              page={page}
              onChange={onChangePage}
              count={pageCount}
              variant="outlined"
              color={'primary'}
              shape="rounded"
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default ModelList;
