import request from 'common/utils/axios-request';

export type AccessRightDto = {
  readonly id: number;
  readonly code: string;
  readonly description: string;
  readonly rest_form_group_id: number;
};

export function fetchAccessRights(): Promise<readonly AccessRightDto[]> {
  return request('/api/v1/me/rest-forms').then(({ data }) => data);
}

export function fetchMenuRights(): Promise<readonly AccessRightDto[]> {
  return request('/api/v1/me/menu').then(({ data }) => data);
}

export function applyModelsRights(
  group_ids: ReadonlyArray<number>,
  rest_form_codes: ReadonlyArray<string>,
  roleId: number
): Promise<unknown> {
  return request('/api/v1/forms/right/batch/' + roleId, {
    method: 'POST',
    data: { group_ids, rest_form_codes },
  }).then(({ data }) => data);
}
