import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import clsx from 'clsx';
import { ButtonPelican } from 'components/ButtonPelican';
import { TextInput } from 'components/TextInput';
import React, { ChangeEvent, useCallback } from 'react';
import AvatarEditor, { Position } from 'react-avatar-editor';
import { useTranslation } from 'react-i18next';

import { Props } from './Props';
import { useStyles } from './styles';

const ImageEditor: React.FC<Props> = ({ preview, handleSave, imageId }) => {
  const img = imageId
    ? (document.getElementById(imageId || 'editor-image') as HTMLImageElement)
    : undefined;
  const defaultEditValues = {
    width: img?.clientWidth,
    height: img?.clientHeight,
    scale: 1,
    rotate: 0,
    allowScale: false,
    borderRadius: 0,
    positionX: 0.5,
    positionY: 0.5,
  };
  const styles = useStyles();
  const { t } = useTranslation();
  const [editValues, setEditValues] = React.useState(defaultEditValues);
  const [canvas, setCanvas] = React.useState<AvatarEditor>();
  const [image, setImage] = React.useState(preview);

  const handleSettingsChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>): void => {
      setEditValues({
        ...editValues,
        [e.target.name]:
          e.target.type === 'number' || e.target.type === 'range'
            ? e.target.name.includes('position')
              ? parseInt(e.target.value) / 100
              : e.target.name.includes('scale')
              ? parseInt(e.target.value) / (editValues.allowScale ? 100 : 10)
              : parseInt(e.target.value)
            : e.target.type === 'text'
            ? e.target.value
            : e.target.checked,
      });
    },
    [editValues]
  );

  const onPositionChange = useCallback(
    (position: Position): void => {
      setEditValues({ ...editValues, positionX: position.x, positionY: position.y });
    },
    [editValues]
  );

  const onRotate = useCallback(
    (deg: number): void => {
      setEditValues({ ...editValues, rotate: editValues.rotate + deg });
    },
    [editValues]
  );

  const setRef = useCallback((editor: AvatarEditor): void => {
    setCanvas(editor);
  }, []);

  React.useEffect(() => {
    setImage(preview);
  }, [preview]);

  return (
    <div className={styles.container}>
      <div className={styles.dFlex}>
        <div className={clsx(styles.option, styles.dFlex)}>
          <label htmlFor="zoom">{t('Zoom')}</label>
          <input
            id="zoom"
            type="range"
            name="scale"
            min="10"
            max="200"
            value={editValues.scale * (editValues.allowScale ? 100 : 10)}
            onChange={handleSettingsChange}
          />
        </div>
        <div className={clsx(styles.dFlex, styles.option)}>
          <label htmlFor="positionX">{t('X Position')}</label>
          <input
            id="positionX"
            type="range"
            name="positionX"
            min="0"
            max="100"
            value={editValues.positionX * 100}
            onChange={handleSettingsChange}
          />
        </div>
        <div className={clsx(styles.dFlex, styles.option)}>
          <label htmlFor="positionY">{t('Y Position')}</label>
          <input
            id="positionY"
            type="range"
            name="positionY"
            min="0"
            max="100"
            value={editValues.positionY * 100}
            onChange={handleSettingsChange}
          />
        </div>
      </div>
      <div className={styles.dFlex}>
        <ButtonPelican
          className={styles.rotateButton}
          variant="text"
          color="default"
          onClick={() => onRotate(-90)}
        >
          <span className={styles.dFlex}>
            <RotateLeftIcon fontSize="default" color="primary" />
            <span className={styles.rotateLabel}>{t('Rotate left')}</span>
          </span>
        </ButtonPelican>
        <ButtonPelican
          className={styles.rotateButton}
          variant="text"
          color="default"
          onClick={() => onRotate(90)}
        >
          <span className={styles.dFlex}>
            <RotateRightIcon fontSize="default" color="primary" />
            <span className={styles.rotateLabel}>{t('Rotate right')}</span>
          </span>
        </ButtonPelican>
        <div className={clsx(styles.dFlex, styles.option)}>
          <label htmlFor="width">{t('Width')}:</label>
          <TextInput
            id="width"
            name="width"
            type="number"
            value={editValues.width}
            onChange={handleSettingsChange}
          />
        </div>
        <div className={clsx(styles.dFlex, styles.option)}>
          <label htmlFor="height">{t('Height')}:</label>
          <TextInput
            id="height"
            name="height"
            type="number"
            value={editValues.height}
            onChange={handleSettingsChange}
          />
        </div>
      </div>
      <div className={styles.dFlex}>
        <div className={clsx(styles.dFlex, styles.option)}>
          <label htmlFor="borderRadius">{t('Border radius')}</label>
          <input
            id="borderRadius"
            type="range"
            name="borderRadius"
            min="0"
            max={editValues.width}
            value={editValues.borderRadius}
            onChange={handleSettingsChange}
          />
        </div>
        <div className={clsx(styles.option, styles.dFlex)}>
          <label htmlFor="allowScale">{t('Allow scale')}</label>
          <input
            id="allowScale"
            type="checkbox"
            name="allowScale"
            checked={editValues.allowScale}
            onChange={handleSettingsChange}
          />
        </div>
        <ButtonPelican
          className={styles.saveButton}
          variant="contained"
          color="primary"
          onClick={() => canvas && handleSave(canvas)}
        >
          {t('Save')}
        </ButtonPelican>
      </div>
      <div className={styles.editor}>
        <AvatarEditor
          ref={setRef}
          image={image || ''}
          width={editValues.width ? (editValues.width < 500 ? editValues.width : 500) : undefined}
          height={
            editValues.height ? (editValues.height < 450 ? editValues.height : 450) : undefined
          }
          scale={editValues.scale}
          rotate={editValues.rotate}
          borderRadius={editValues.borderRadius}
          position={{ x: editValues.positionX, y: editValues.positionY }}
          border={10}
          onPositionChange={onPositionChange}
        />
      </div>
    </div>
  );
};

export default ImageEditor;
