import { contentItemGet, fieldsGet } from 'api/content';
import { projectGetById } from 'api/projects';
import { ContentItem as ContentItemType } from 'api/types/ContentItem';
import { ContentTypeField } from 'api/types/ContentTypeField';
import { useSnackbar } from 'notistack';
import CompareVersions from 'pages/CompareVersion/CompareVersions/component';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentVersion } from 'store/versionSlice';
import { getProjects } from 'store/workspaceSlice';

import { Props } from './props';

const CompareEnvVersion: React.FC<Props> = ({ url, type, versionId, contentId, controlType }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [currentFields, setCurrentFields] = useState<ReadonlyArray<ContentTypeField>>([]);
  const [contentItem, setContentItem] = useState<ContentItemType>({});
  const [compareItem, setCompareItem] = useState<ContentItemType>({});
  const [locales, setLocales] = React.useState<readonly string[]>([]);
  const dispatch = useDispatch();
  const projects = useSelector(getProjects);
  const { t } = useTranslation();

  const getFieldsList = useCallback(
    async (projectId: number) => {
      const fieldsResult = await fieldsGet(type, {
        'fetch-references': true,
        project_id: projectId,
      });
      setCurrentFields(fieldsResult);
    },
    [type]
  );

  const getLocales = useCallback(
    async (projectId: number) => {
      try {
        const model = projects.find((m) => m.id === projectId);
        if (model) {
          const res = await projectGetById(model.slug);
          res.languages && setLocales(res.languages.map((l) => l.code));
        }
      } catch (e) {
        enqueueSnackbar(
          `${t('Error on loading')} ${t('locales')} ${t(e.response?.data?.message || e)}`,
          {
            variant: 'error',
          }
        );
      }
    },
    [projects, enqueueSnackbar, t]
  );

  const getContentItem = useCallback(
    async (isCurrent: boolean) => {
      try {
        const contentItemResult = await contentItemGet(
          type,
          isCurrent ? contentId : versionId,
          -1,
          true
        );
        if (isCurrent) {
          setContentItem(contentItemResult);
          dispatch(
            setCurrentVersion({
              id: Number(contentId),
              sourceId: Number(contentItemResult.source_id?.toString()),
              version: Number(contentItemResult.version),
            })
          );
          if (contentItemResult.project_id) {
            getLocales(contentItemResult.project_id);
            getFieldsList(contentItemResult.project_id);
          }
        } else {
          setCompareItem(contentItemResult);
        }
      } catch (e) {
        enqueueSnackbar(
          `${t('Error while fetching content')} ${t(e.response?.data?.message || e)}`,
          {
            variant: 'error',
          }
        );
      }
    },
    [type, contentId, versionId, dispatch, getLocales, getFieldsList, enqueueSnackbar, t]
  );

  useEffect(() => {
    getContentItem(true);
    getContentItem(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CompareVersions
      url={url}
      fields={currentFields}
      compareItems={[compareItem]}
      currentItems={[contentItem]}
      locales={locales}
      compareType={controlType || ''}
      environmentCompare={true}
    />
  );
};

export default CompareEnvVersion;
