import 'video-react/dist/video-react.css';

import { Modal } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { assetInfoGetByHash } from 'api/assets';
import { fileGet, filePost } from 'api/files';
import { AssetType } from 'api/types/Assets';
import { FileItem } from 'api/types/FileItem';
import axios, { AxiosProgressEvent } from 'axios';
import clsx from 'clsx';
import { bytesToSize, getFileHash } from 'common/utils/helpers';
import { ButtonPelican } from 'components/ButtonPelican';
import { TextInput } from 'components/TextInput';
import ImageEditor from 'containers/ImageEditor/component';
import { useSnackbar } from 'notistack';
import AssetsPage from 'pages/AssetsPage/AssetsPage';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useModalStyles } from 'theme/modal-styles';
import { Player } from 'video-react';

import { AddFileIcon } from '../IconPelican';
import { Props } from './props';
import { useStyles } from './styles';

export const VideoUpload: React.FC<Props> = ({
  onChangeValue,
  link,
  disabled,
  slug,
  uploadType,
  slugId,
}) => {
  const styles = useStyles();
  const modalStyles = useModalStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedFile, setSelectedFile] = useState<FileItem>();
  const [preview, setPreview] = useState<string>();
  const [defaultValue, setDefaultValue] = useState<string | undefined>(link);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isAssetModal, setAssetModal] = useState(false);
  const [searchAsset, setSearchAsset] = useState('');
  const [selectedAsset, setSelectedAsset] = useState<FileItem>();
  const [dragOver, setDragOver] = useState(false);
  const [currentAbortController, setCurrentAbortController] = useState<AbortController>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploading, setUploading] = useState(false);

  const uploadFile = useCallback(
    async (file: File): Promise<void> => {
      setUploading(true);
      try {
        const uploadProgress = (progressEvent: AxiosProgressEvent): void => {
          const { loaded, total } = progressEvent;
          setUploadProgress(Math.floor((loaded * 100) / (total || 1)));
        };

        const formData = new FormData();
        formData.append('asset', file);
        const controller = new AbortController();
        setCurrentAbortController(controller);
        const fileResult = await filePost(formData, uploadProgress, controller.signal);
        setPreview(fileResult.link);
        setSelectedFile(fileResult);

        onChangeValue && onChangeValue(fileResult.link);
        setModalOpen(false);
      } catch (e) {
        if (!axios.isCancel(e)) {
          enqueueSnackbar(`${t('Error saving')} ${t(e.response?.data?.message || e)}`, {
            variant: 'error',
          });
        }
      } finally {
        setUploading(false);
      }
    },
    [enqueueSnackbar, onChangeValue, t]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      setDragOver(false);
      uploadFile(Array.from(acceptedFiles as FileList)[0]);
    },
    [uploadFile]
  );

  const { getRootProps } = useDropzone({ onDrop });

  const handleOnDragOver = useCallback(() => {
    setDragOver(true);
  }, []);

  const handleOnDragLeave = useCallback(() => {
    setDragOver(false);
  }, []);

  const openModal = useCallback((isOpen: boolean): void => {
    setModalOpen(isOpen);
  }, []);

  const handleSelectAsset = useCallback((asset: FileItem) => {
    setSelectedAsset(asset);
  }, []);

  const handleSaveImage = useCallback(
    (canvas: AvatarEditor): void => {
      const newCanvas = canvas.getImageScaledToCanvas().toDataURL();
      fetch(newCanvas || '')
        .then((res) => res.blob())
        .then(async (blob) => {
          selectedFile &&
            selectedFile.file_name &&
            uploadFile(new File([blob], selectedFile.file_name));
        });
    },
    [selectedFile, uploadFile]
  );

  const onDelete = useCallback((): void => {
    setSelectedFile(undefined);
    setPreview(undefined);
    setDefaultValue('');
    const el = document.getElementById('upload-files-' + slugId) as HTMLInputElement;
    if (el) el.value = '';
    onChangeValue && onChangeValue('');
  }, [onChangeValue, slugId]);

  const onConfirm = useCallback(() => {
    if (selectedAsset) {
      setAssetModal(false);
      setSelectedFile(selectedAsset);
      setPreview(selectedAsset.link);
      onChangeValue && onChangeValue(selectedAsset.link);
    }
  }, [onChangeValue, selectedAsset]);

  const getFileItem = useCallback(
    async (hash: string) => {
      try {
        const res = await assetInfoGetByHash(hash);
        setSelectedFile(res);
      } catch (e) {
        enqueueSnackbar(
          `${t('Error on loading')} ${t('file info')} ${e.response?.data?.message || e}`,
          {
            variant: 'error',
          }
        );
      }
    },
    [enqueueSnackbar, t]
  );

  const handleDownload = useCallback(
    async (link?: string) => {
      const result = (link && (await fileGet(link))) as Blob | MediaSource;
      const csvURL = window.URL.createObjectURL(result);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', selectedFile?.file_name || 'file');
      tempLink.click();
    },
    [selectedFile]
  );

  const onSelectFiles = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files as FileList;

      if (files?.length > 0) {
        uploadFile(files[0]);
      }
    },
    [uploadFile]
  );

  const onCancelUploading = useCallback(() => {
    if (currentAbortController) {
      currentAbortController.abort();
      setCurrentAbortController(undefined);
      setUploadProgress(0);
    }
  }, [currentAbortController]);

  useEffect(() => {
    if (link) {
      setPreview(link);
      setDefaultValue(link);
      getFileItem(getFileHash(link));
    }
  }, [link, getFileItem]);

  return (
    <div id={slugId}>
      {preview && !uploading && (
        <div className={styles.fileContainer}>
          <img id={'selectedImage_' + slug} className={styles.input} src={preview} alt="" />
          <div className={styles.preview}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
            <img
              onClick={() => openModal(uploadType !== 'document')}
              className={styles.image}
              src={uploadType === 'image' ? preview : `/assets/uploaded-${uploadType}-icon.svg`}
              alt="preview"
            />
          </div>
          <div className={styles.fileInfo}>
            <h3 className={styles.fileName}>{selectedFile?.file_name}</h3>
            <p className={styles.fileDetails}>
              <span className={styles.extension}>{selectedFile?.extension}, </span>
              {bytesToSize(selectedFile?.size || 0)}
            </p>
            <div className={styles.dFlex}>
              {uploadType !== 'document' && (
                <>
                  <div>
                    <ButtonPelican
                      variant="text"
                      color="primary"
                      disableRipple
                      className={styles.previewButton}
                      onClick={() => openModal(true)}
                    >
                      {t('Open preview')}
                    </ButtonPelican>
                  </div>
                  <div className={styles.divider}></div>
                </>
              )}
              <ButtonPelican
                variant="text"
                color="primary"
                disableRipple
                className={styles.previewButton}
                onClick={() => handleDownload(selectedFile?.link || defaultValue)}
              >
                {t('Download')}
              </ButtonPelican>

              {!disabled && (
                <>
                  <div className={styles.divider}></div>
                  <DeleteIcon
                    color="secondary"
                    cursor="pointer"
                    fontSize="small"
                    onClick={onDelete}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {!preview && !uploading && (
        <div
          className={styles.emptyContainer}
          {...getRootProps({
            onDragOver: handleOnDragOver,
            onDragLeave: handleOnDragLeave,
          })}
        >
          <AddFileIcon />
          {!dragOver && <p className={styles.dragAndDrop}>{t('Drag and Drop file to upload')}</p>}
          {dragOver && (
            <p className={styles.dragAndDrop}>{t('Now just drop the file to upload')}</p>
          )}
        </div>
      )}
      {uploading && (
        <div className={styles.uploadContainer}>
          <div className={styles.progressBar} style={{ width: `${uploadProgress || 0}%` }} />
          {t('Uploading')}... {uploadProgress || 0}%
        </div>
      )}
      {!disabled && !uploading && (
        <div className={styles.dFlex}>
          <ButtonPelican onClick={() => setAssetModal(true)}>
            <span className={styles.dFlex}>
              {t('Choose from asset')}
              <KeyboardArrowDownIcon />
            </span>
          </ButtonPelican>
          <span className={styles.or}>{t('or')}</span>
          <label htmlFor={`upload-files-${slugId}`} className={styles.label}>
            {t('Upload new file')}
            <input
              type="file"
              id={`upload-files-${slugId}`}
              className={styles.input}
              onChange={onSelectFiles}
              value=""
              accept={
                uploadType === 'document'
                  ? '.xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, .geojson'
                  : uploadType + '/*'
              }
              multiple={!uploadType}
            />
          </label>
        </div>
      )}
      {uploading && <ButtonPelican onClick={onCancelUploading}>{t('Cancel')}</ButtonPelican>}
      <Modal
        open={isModalOpen}
        onClose={() => openModal(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={styles.modalContainer}>
          {uploadType === 'image' ? (
            disabled ? (
              <img src={preview} alt="" />
            ) : (
              <ImageEditor
                imageId={'selectedImage_' + slug}
                preview={preview}
                handleSave={handleSaveImage}
              />
            )
          ) : uploadType === 'video' ? (
            <div className={styles.video}>
              <Player playsInline src={preview} />
            </div>
          ) : (
            <ReactAudioPlayer controls src={preview} />
          )}
        </div>
      </Modal>
      {isAssetModal && (
        <Modal
          open={isAssetModal}
          onClose={() => setAssetModal(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={clsx(modalStyles.paper, styles.assetsContainer)}>
            <div className={modalStyles.container}>
              <div className={styles.dFlex}>
                <TextInput
                  inputClassName={styles.fullWidth}
                  value={searchAsset}
                  onChange={(e) => setSearchAsset(e.target.value)}
                  placeholder={t('Search by name')}
                />
                <ClearIcon
                  cursor="pointer"
                  onClick={() => {
                    setAssetModal(false);
                  }}
                  className={styles.marginLeft}
                />
              </div>
            </div>
            <div className={modalStyles.hr} />
            <AssetsPage
              assetType={uploadType.toUpperCase() as AssetType['type']}
              handleSelectAsset={handleSelectAsset}
              inModal
              searchName={searchAsset}
            />
            <div className={modalStyles.hr} />
            <div className={modalStyles.formFooter}>
              <ButtonPelican
                onClick={onConfirm}
                disabled={!selectedAsset}
                className={styles.marginRight}
                variant="contained"
                color="primary"
              >
                {t('Confirm')}
              </ButtonPelican>

              <ButtonPelican onClick={() => setAssetModal(false)} variant="text">
                {t('Cancel')}
              </ButtonPelican>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default VideoUpload;
