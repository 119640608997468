import request from 'common/utils/axios-request';
import { ModelDuplicateType } from 'pages/ContentTypes/ModelDuplicateModal/component';

import { AccessRightDto } from './access-right';
import { ContentType, ContentTypeInput, ContentTypeResult } from './types/ContentType';

export function contentTypesGet(
  isMy?: boolean,
  params?: ContentTypeInput
): Promise<readonly ContentType[]> {
  return request(`/api/v1/${isMy ? 'me/' : ''}content-types`, {
    params,
  }).then(({ data }) => data as readonly ContentType[]);
}

export function contentTypesGetPageable(params?: ContentTypeInput): Promise<ContentTypeResult> {
  return request('api/v1/me/content-types/pageable', { params }).then(({ data, headers }) => ({
    list: data,
    totalCount: headers['x-total-count'],
  }));
}

export function contentTypeGetBySlug(slug: string): Promise<ContentType> {
  return request(`/api/v1/content-types/${slug}`).then(({ data }) => data as ContentType);
}

export function contentTypesPut(data: ContentType): Promise<ContentType> {
  return request('/api/v1/content-types', {
    data,
    method: 'PUT',
  }).then(({ data }) => data as ContentType);
}

export function contentTypesPost(data: ContentType): Promise<ContentType> {
  return request('/api/v1/content-types', {
    data,
    method: 'POST',
  }).then(({ data }) => data as ContentType);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function contentTypeDelete(id: number | undefined): Promise<any> {
  return request(`/api/v1/content-types/${id}`, {
    method: 'DELETE',
    params: {
      archive: true,
    },
  });
}

export function contentTypeDuplicate(slug: string, data: ModelDuplicateType): Promise<ContentType> {
  return request(`/api/v1/content-types/${slug}/duplicate`, {
    data,
    method: 'POST',
  }).then(({ data }) => data);
}

// /api/v1/content-types/{contentTypeSlug}/{id}/unarchive
export function contentTypeUnrachive(slug: string): Promise<unknown> {
  return request(`/api/v1/content-types/${slug}/unarchive`, {
    method: 'POST',
  }).then(({ data }) => data as unknown);
}

export function contentTypeRightsGet(slug: string): Promise<ReadonlyArray<AccessRightDto>> {
  return request(`/api/v1/me/rest-forms/${slug}`).then(({ data }) => data);
}
