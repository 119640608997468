import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  tabItem: {
    transform: 'rotate(180deg)',
    paddingRight: 12,
    paddingLeft: 12,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    background: '#fff',
    borderBottom: '1px solid #CECECE',
    color: '#858585',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.surface[700],
    },
  },
  active: {
    background: '#F7F8FA',
    borderLeft: '1px solid #CECECE',
    borderRight: '1px solid #CECECE',
    borderBottom: '0',
    borderRadius: '10px 10px 0 0 !important',
    borderTop: '0',
  },
}));
