import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  container: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    padding: '0 10px',
    zIndex: 300,
    height: 28,
    border: '1px solid #545456',
    color: '#fff',
    borderRadius: 4,
    margin: '0 8px',
    overflow: 'auto',
    width: '100%',
  },
  tabs: {
    display: 'flex',
    overflowX: 'auto',
  },
  button: {
    margin: 4,
    padding: 0,
    width: 14,
    height: 14,
  },
  rotating: {
    '-webkit-transform': 'rotate(-360deg)',
    '-webkit-transition-duration': '1s',
    '-webkit-transition-delay': 'now',
    '-webkit-animation-timing-function': 'linear',
    '-webkit-animation-iteration-count': 'infinite',
  },
});
