import { Box, TableHead } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { contentTypesGet } from 'api/content-types';
import { ContentInfoModel } from 'api/types/ContentInfoModel';
import { ContentType } from 'api/types/ContentType';
import clsx from 'clsx';
import { useDebounce } from 'common/hooks/useDebounce';
import { ButtonPelican } from 'components/ButtonPelican';
import Spin from 'components/Spin';
import { useStyles as useTableStyles } from 'components/TableDynamic/styles';
import { TextInput } from 'components/TextInput';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGroups,
  getRecentlyContents,
  getSearch,
  getTypes,
  setRecentlyContents,
  setSearch,
} from 'store/contentSlice';
import { ContentNavItem } from 'templates/MainTemplate/TopNav/ContentNavigation/ContentNavItem';

import { ContentInfoProps } from './index';
import { useStyles } from './styles';

export const ContentInfo: React.FC<ContentInfoProps> = ({ onClose }) => {
  const classesOwn = useStyles();
  const classes = useTableStyles({});
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const searchHistory = useSelector(getSearch);
  const recentlyContentsOpen = useSelector(getRecentlyContents);
  const [openLastContent, setOpenLastContents] = useState(recentlyContentsOpen || false);
  const [contentsList, setContentsList] = useState<readonly ContentInfoModel[]>([]);
  const [text, setText] = useState(searchHistory || '');
  const groups = useSelector(getGroups);
  const models = useSelector(getTypes);
  const [isLoadingContent, setLoadingContent] = useState(false);
  const [openTree, setOpenTree] = useState(false);
  const [list, setList] = useState<readonly ContentInfoModel[]>([]);
  const debouncedSearchTerm = useDebounce(text, 500);

  const handleToggleLastContents = useCallback(() => {
    dispatch(setRecentlyContents(!openLastContent));
    setOpenLastContents(!openLastContent);
  }, [openLastContent, dispatch]);

  const onMapTypes = useCallback(
    (types: ReadonlyArray<ContentType>) => {
      const typeGroups: { readonly [key: string]: readonly ContentType[] } = types.reduce(
        (r: { readonly [key: string]: readonly ContentType[] }, a) => {
          return {
            ...r,
            ...{
              [groups.find((g) => g.id === a.group_id)?.description || -1]: [
                ...(r[groups.find((g) => g.id === a.group_id)?.description || -1] || []),
                a,
              ],
            },
          };
        },
        {}
      );

      const entries = Object.entries(typeGroups);

      const res = entries
        .filter(([key]) => key !== '-1')
        .map((tg, index) => {
          const [key, items] = tg;
          return {
            id: index,
            name: key,
            items: items?.map((i) => ({
              id: i.id,
              name: i.description,
              slug: i.slug,
              stats: i.stats,
            })),
          } as ContentInfoModel;
        });

      const res2: readonly ContentInfoModel[] = entries
        .filter(([key]) => key === '-1')
        .flatMap((f) => {
          const [, items] = f;
          return items.map((i) => ({
            id: i.id as number,
            name: i.description,
            slug: i.slug,
            stats: i.stats,
          }));
        });

      setList([...res, ...res2]);
    },
    [groups]
  );

  const handleSearchName = useCallback(async () => {
    try {
      setLoadingContent(true);
      dispatch(setSearch(debouncedSearchTerm));
      const contentTypes = await contentTypesGet(true, {
        searchText: debouncedSearchTerm || undefined,
      });
      onMapTypes(contentTypes);
      setOpenTree(debouncedSearchTerm !== '');
    } catch (e) {
      enqueueSnackbar(`${t('Error loading')} ${t(e.response?.data?.message || e)}`, {
        variant: 'error',
      });
    } finally {
      setLoadingContent(false);
    }
  }, [debouncedSearchTerm, dispatch, enqueueSnackbar, t, onMapTypes]);

  useEffect(() => {
    if (debouncedSearchTerm || debouncedSearchTerm === '') {
      handleSearchName();
    }
  }, [debouncedSearchTerm, handleSearchName]);

  useEffect(() => {
    if (openLastContent) {
      const lastContents = localStorage.getItem('recently_contents');
      const recentlyContents = lastContents ? (JSON.parse(lastContents) as readonly number[]) : [];
      setContentsList(
        models
          .filter((c) => recentlyContents.some((s) => s === c.id))
          .map((r) => {
            return {
              id: r.id,
              slug: r.slug,
              stats: r.stats,
              name: r.description,
            } as ContentInfoModel;
          })
      );
    } else {
      setContentsList(list);
    }
  }, [openLastContent, models, list]);

  return (
    <div className={classesOwn.root}>
      <Box display="flex" justifyContent="space-between" paddingTop={2} paddingBottom={2}>
        <Box flexBasis="30%">
          <TextInput
            onChange={(e) => setText(e.target.value)}
            placeholder={t('Code')}
            value={text}
            autoFocus
          />
        </Box>
        <ButtonPelican onClick={handleToggleLastContents}>
          {openLastContent ? t('All') : t('Recently edited')}
        </ButtonPelican>
      </Box>
      {isLoadingContent ? (
        <div className={classesOwn.spinContainer}>
          <Spin />
        </div>
      ) : (
        <TableContainer className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={clsx(classes.cell, classesOwn.cell)} />
                <TableCell className={clsx(classes.headCell, classes.cell)}>
                  {t('Model name')}
                </TableCell>
                <TableCell className={clsx(classes.headCell, classes.cell)}>
                  {t('Archived')}
                </TableCell>
                <TableCell className={clsx(classes.headCell, classes.cell)}>{t('Draft')}</TableCell>
                <TableCell className={clsx(classes.headCell, classes.cell)}>
                  {t('Published')}
                </TableCell>
                <TableCell className={clsx(classes.headCell, classes.cell)}>
                  {t('Review')}
                </TableCell>
                <TableCell className={clsx(classes.headCell, classes.cell)}>
                  {t('Scheduled')}
                </TableCell>
                <TableCell className={clsx(classes.headCell, classes.cell)}>{t('All')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contentsList.map((row, i) => (
                <ContentNavItem key={i} {...row} onClose={onClose} openModel={openTree} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default ContentInfo;
