import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const CancelOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.773 7.227a.75.75 0 010 1.06L13.061 12l3.712 3.712a.75.75 0 11-1.06 1.06L12 13.062l-3.712 3.712a.75.75 0 01-1.06-1.06L10.938 12 7.227 8.288a.75.75 0 111.06-1.061L12 10.939l3.712-3.712a.75.75 0 011.061 0z"
    />
  </SvgIcon>
);
export default CancelOutline;
