import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nav: {
      display: 'flex',
      height: '100%',
      background: theme.palette.surface[50],
      flexDirection: 'column',
      justifyContent: 'start',
      zIndex: 8,
      borderRight: '1px solid #DCDCDC',
    },
    toggleSidebar: {
      marginLeft: 5,
      transition: '0.3s',
    },
    collapseSidebar: {
      border: 0,
      outline: 0,
      fontFamily: 'Roboto',
      display: 'flex',
      height: 48,
      color: '#505F79',
      alignItems: 'center',
      padding: '0 0 0 24px',
      fontSize: '1rem',
      cursor: 'pointer',
      background: 'transparent',
      transition: '0.3s',
    },
    toggleIcon: {
      transform: 'rotate(180deg)',
      color: 'white',
      backgroundColor: '#505F79',
      width: 25,
      height: 25,
      borderRadius: 15,
    },
    rotate: {
      transform: 'rotate(0)',
      color: 'white',
      backgroundColor: '#505F79',
      width: 25,
      height: 25,
      borderRadius: 15,
    },
    footer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 24,
      left: 0,
      right: 0,
      paddingLeft: 20,
      paddingRight: 20,
    },
    appName: {
      display: 'flex',
      overflow: 'hidden',
      transition: '0.3s',
      wordBreak: 'keep-all',
    },
    appLogo: {
      marginRight: 5,
      transition: '0.3s',
    },
    appVersion: {
      color: '#545456',
      fontSize: '1rem',
    },
    collapseText: {
      maxWidth: 148,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      marginLeft: 24,
    },
  })
);
