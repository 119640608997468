import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const BagOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2.146a2.75 2.75 0 00-2.75 2.75v1c-1.322.004-2.044.038-2.612.327a3 3 0 00-1.311 1.31C2 8.177 2 9.017 2 10.697v6.4c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.31c.642.328 1.482.328 3.162.328h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311c.327-.642.327-1.482.327-3.162v-6.4c0-1.68 0-2.52-.327-3.162a3 3 0 00-1.311-1.311c-.568-.29-1.29-.323-2.612-.327v-1A2.75 2.75 0 0015 2.146H9zm7.25 3.75v-1c0-.69-.56-1.25-1.25-1.25H9c-.69 0-1.25.56-1.25 1.25v1h8.5zm-9.45 14.5h10.4c.865 0 1.423-.001 1.848-.036.408-.033.559-.09.633-.128a1.5 1.5 0 00.655-.655c.038-.074.095-.225.128-.633.035-.425.036-.983.036-1.848v-4.988a32.426 32.426 0 01-3.5 1.153v1.135a.75.75 0 01-1.5 0V13.6c-1.159.22-2.285.33-3.42.328a18.702 18.702 0 01-3.58-.365v.833a.75.75 0 01-1.5 0v-1.177a36.218 36.218 0 01-3.5-1.13v5.007c0 .865.001 1.423.036 1.848.033.408.09.559.127.633a1.5 1.5 0 00.656.655c.074.038.225.095.633.128.425.035.983.036 1.848.036zm13.7-9.9c-1.256.504-2.41.907-3.5 1.211v-.311a.75.75 0 00-1.5 0v.676c-1.178.24-2.298.358-3.417.356a17.426 17.426 0 01-3.583-.395v-.637a.75.75 0 00-1.5 0v.27a37.995 37.995 0 01-3.5-1.182c0-.746.004-1.247.036-1.636.033-.408.09-.559.127-.633a1.5 1.5 0 01.656-.656c.074-.037.225-.094.633-.127.425-.035.983-.036 1.848-.036h10.4c.865 0 1.423.001 1.848.036.408.033.559.09.633.127a1.5 1.5 0 01.655.656c.038.074.095.225.128.633.032.39.036.895.036 1.648z"
    />
  </SvgIcon>
);
export default BagOutline;
