import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const HomeOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path d="M10.5 8.921a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z" fill="#000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.476 1.92a.75.75 0 00-.952 0L4.165 7.968l-.009.008-1.632 1.34a.75.75 0 10.952 1.16l.41-.337V20.25c0 .414.336.75.75.75h14.728a.75.75 0 00.75-.75V10.138l.41.337a.75.75 0 10.952-1.16l-1.632-1.34-.009-.008-7.359-6.046zm-7.09 6.985V19.5h3.73v-6.446a.8.8 0 01.8-.8h4.179a.8.8 0 01.8.8V19.5h3.719V8.905L12 3.471 5.386 8.905zM12 10.421a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"
    />
  </SvgIcon>
);
export default HomeOutline;
