import { CheckboxPelican } from 'components/CheckboxPelican';
import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any,react/display-name
export const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }: any, ref) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultRef = React.useRef<any>();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <CheckboxPelican ref={resolvedRef} {...rest} />;
});
