import { ButtonPelican } from 'components/ButtonPelican';
import { ModalPelican } from 'components/ModalPelican';
import { SelectItem, SelectPelican } from 'components/SelectPelican';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getProjects, getWorkspaces } from 'store/workspaceSlice';

import { Props } from './props';

const ContentDuplicate: React.FC<Props> = ({ onClose, open, ownerId, onConfirm }) => {
  const { t } = useTranslation();
  const workspaces = useSelector(getWorkspaces);
  const projects = useSelector(getProjects);
  const ownerProject = projects.find((p) => p.id === ownerId);

  const [owner, setOwner] = useState<SelectItem | undefined>();

  useEffect(() => {
    setOwner(ownerProject && { value: ownerProject.id, label: ownerProject.title });
  }, [ownerProject]);

  return (
    <ModalPelican
      overflowUnset
      open={open}
      title={t('Duplicating')}
      onClose={onClose}
      footer={
        <>
          <ButtonPelican onClick={onClose} variant="text">
            {t('Cancel')}
          </ButtonPelican>
          <ButtonPelican
            disabled={!owner}
            variant="contained"
            color="primary"
            onClick={() => owner && onConfirm(owner.value as number)}
          >
            {t('Confirm')}
          </ButtonPelican>
        </>
      }
    >
      <div>
        <p>{t('Select content project owner')}</p>
        <SelectPelican
          value={owner}
          id="owner-select"
          options={projects
            .filter((p) => workspaces.some((w) => w.workspaceId === p.id))
            .map((p) => ({ value: p.id, label: p.title }))}
          placeholder={t('Select content project owner')}
          selected={(val) => setOwner(val as SelectItem)}
        />
      </div>
    </ModalPelican>
  );
};

export default ContentDuplicate;
