import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  colorInput: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    fontSize: '14px',
    minHeight: 32,
    border: '2px solid #dfe1e5',
    borderRadius: '3px',
    marginBottom: '12px',
  },
  colorChoose: {
    width: 20,
    height: 20,
    cursor: 'pointer',
    marginRight: 5,
    border: 'none',
  },
  popover: {
    borderRadius: 3,
    marginTop: 50,
  },
  popoverRoot: {
    top: 50,
  },
});
