import {
  ContentItem,
  ContentItem as ContentItemType,
  PatchDataType,
  UpdateInfoForPatch,
} from 'api/types/ContentItem';
import { ContentTypeField } from 'api/types/ContentTypeField';

// В этом месте данные которые приходят с бэкенда, либо через embedded поля,
// трансформируется в нужный формат. Это единственное место, прошу в других местах не делать
export function transformFromApi(
  fields: readonly ContentTypeField[],
  contentItem: ContentItemType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return fields.reduce((prev, next) => {
    if (contentItem[next.slug] === undefined) {
      if (next.type === 'BOOLEAN' && !contentItem.id) {
        const defaultValue = next.defaultValue?.toString();
        if (defaultValue === 'true' || defaultValue === 'false') {
          return {
            ...prev,
            ...{
              [next.slug]: next.defaultValue,
            },
          };
        }
      }
    } else if (contentItem[next.slug] === true || contentItem[next.slug] === false) {
      return { ...prev, ...{ [next.slug]: contentItem[next.slug].toString() } };
    }

    return { ...prev, ...{ [next.slug]: contentItem[next.slug] } };
  }, {});
}

export function transformToApi(
  fields: readonly ContentTypeField[],
  contentItem: ContentItemType,
  isNewItem?: boolean
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return fields.reduce((prev, next) => {
    if (contentItem[next.slug]) {
      if (next.type === 'REFERENCE' && !next.embedded) {
        return {
          ...prev,
          ...{
            [next.slug]: Array.isArray(contentItem[next.slug]?.items)
              ? contentItem[next.slug].items.map((it: { readonly id: number }) => it.id).join(',')
              : contentItem[next.slug] || '',
          },
        };
      }

      if (next.type === 'REFERENCE' && next.embedded) {
        return {
          ...prev,
          ...{
            [next.slug]: isNewItem
              ? initEmbeddedContentItem2(contentItem[next.slug])
              : (contentItem[next.slug] as ReadonlyArray<ContentItemType>)
                  .map((c) => c.id)
                  .join(','),
          },
        };
      }
    }

    return { ...prev, ...{ [next.slug]: contentItem[next.slug] } };
  }, {});
}

export const initEmbeddedContentItem2 = (item: ReadonlyArray<ContentItem>): ContentItem => {
  return (
    item.map((embeddedItem: ContentItem) => {
      const itemKeys = Object.keys(embeddedItem);
      const result = itemKeys.reduce((prev, key) => {
        if (embeddedItem[key] && embeddedItem[key].items && embeddedItem[key].items.length >= 0) {
          return {
            ...prev,
            [key]:
              embeddedItem[key].items.length > 0
                ? embeddedItem[key].items.reduce(
                    (prev: string, next: ContentItem) =>
                      (prev.length > 0 && `${prev},${next.id}`) || `${next.id}`,
                    ''
                  )
                : '',
          };
        }
        if (Array.isArray(embeddedItem[key])) {
          return {
            ...prev,
            [key]: initEmbeddedContentItem2(embeddedItem[key]),
          };
        }

        return {
          ...prev,
          [key]: embeddedItem[key],
        };
      }, {});
      return {
        ...result,
        id: undefined,
      };
    }) || []
  );
};

export const getPatchDataArray = (obj: PatchDataType): ReadonlyArray<UpdateInfoForPatch> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const res: any = [];
  Object.keys(obj).forEach((k) => {
    if (k === '$.updateInfo') {
      res.push(obj[k]);
    } else if (!k.includes('$.')) {
      res.push(
        ...(obj[k] as ReadonlyArray<PatchDataType>).flatMap((el: PatchDataType) =>
          getPatchDataArray(el)
        )
      );
    }
  });

  return res
    .filter((item: UpdateInfoForPatch) => item && Object.keys(item).length !== 0)
    .sort((a: UpdateInfoForPatch, b: UpdateInfoForPatch) => (b.dataId || 0) - (a.dataId || 0));
};
