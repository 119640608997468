import { SwitchMaterial } from 'components/SwitchMaterial';
import React, { useEffect, useState } from 'react';

import { Props } from './props';
import { useStyles } from './styles';

export const SwitchField: React.FC<Props> = ({ label, initChecked, handleChange, ...rest }) => {
  const classes = useStyles();
  const [initialChecked, setInitialChecked] = useState<boolean>();
  useEffect(() => {
    const parse = (str: string): boolean => {
      try {
        return JSON.parse(str);
      } catch (e) {
        return false;
      }
    };
    initChecked && setInitialChecked(parse(initChecked));
  }, [initChecked]);
  return (
    <div id={`${rest.id}-wrapper`} className={classes.root}>
      <span className={classes.label}>{label}</span>
      <SwitchMaterial checked={initialChecked} handleChange={handleChange} {...rest} />
    </div>
  );
};
