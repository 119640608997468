import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const RhombusFill: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      d="M11.41 5.518a.833.833 0 011.18 0l5.892 5.893a.833.833 0 010 1.178l-5.893 5.893a.833.833 0 01-1.178 0l-5.893-5.893a.833.833 0 010-1.178l5.893-5.893z"
      fill="#000"
    />
  </SvgIcon>
);
export default RhombusFill;
