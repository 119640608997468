import { combineReducers } from 'redux';

import contentReducer from './contentSlice';
import contentTabsSlice from './contentTabsSlice';
import integrationReducer from './integrationSlice';
import menuReducer from './menuSlice';
import sessionSlice from './sessionSlice';
import userInfoReducer from './userInfoSlice';
import versionSlice from './versionSlice';
import workspaceReducer from './workspaceSlice';

const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  menu: menuReducer,
  workspace: workspaceReducer,
  content: contentReducer,
  version: versionSlice,
  integration: integrationReducer,
  session: sessionSlice,
  contentTabs: contentTabsSlice,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
