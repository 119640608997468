import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const MailOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.8 18.5h10.4c.865 0 1.423-.001 1.848-.036.163-.013.284-.03.377-.048l-5.165-5.84c-.712.66-1.443 1.104-2.27 1.098-.804-.007-1.514-.437-2.203-1.061l-5.205 5.804c.091.018.211.034.37.047.425.035.983.036 1.848.036zm-3.236-1.195l5.145-5.737-5.132-4.952c-.016.087-.03.196-.041.336C3.5 7.377 3.5 7.935 3.5 8.8v6.4c0 .865.001 1.423.036 1.848.008.1.018.185.028.257zM4.654 5.57c.08-.012.177-.024.298-.034C5.377 5.5 5.935 5.5 6.8 5.5h10.4c.865 0 1.423.001 1.848.036.12.01.219.022.299.034l-5.587 5.39-.072.07-.012.011-.011.012c-.86.907-1.337 1.123-1.664 1.12-.335-.002-.812-.233-1.67-1.124l-.01-.01-.009-.009-.028-.027v-.001L4.653 5.57zm15.77 1.046l-5.09 4.91 5.103 5.77c.01-.07.02-.151.027-.248.035-.425.036-.983.036-1.848V8.8c0-.865-.001-1.423-.036-1.848a3.71 3.71 0 00-.04-.336zM2 15.2c0 1.68 0 2.52.327 3.162a3 3 0 001.311 1.311C4.28 20 5.12 20 6.8 20h10.4c1.68 0 2.52 0 3.162-.327a3 3 0 001.311-1.311C22 17.72 22 16.88 22 15.2V8.8c0-1.68 0-2.52-.327-3.162a3 3 0 00-1.311-1.311C19.72 4 18.88 4 17.2 4H6.8c-1.68 0-2.52 0-3.162.327a3 3 0 00-1.311 1.311C2 6.28 2 7.12 2 8.8v6.4z"
      fill="#000"
    />
  </SvgIcon>
);
export default MailOutline;
