export const assetInfoLabels = {
  id: 'ID',
  content_type: 'Content type',
  extension: 'Extension',
  file_name: 'File name',
  hash: 'Hash',
  link: 'Link',
  size: 'Size',
  type: 'Type',
  uploaded: 'Uploaded',
  user_id_uploaded: 'User id uploaded',
};

export type AssetFields =
  | 'id'
  | 'content_type'
  | 'extension'
  | 'file_name'
  | 'hash'
  | 'link'
  | 'size'
  | 'type'
  | 'uploaded'
  | 'user_id_uploaded';
export const getSizeColor = (
  bytes: number
): 'smallFile' | 'mediumFile' | 'bigFile' | 'largeFile' => {
  const mb = bytes / (1024 * 1024);
  return mb <= 1
    ? 'smallFile'
    : mb > 1 && mb <= 10
    ? 'mediumFile'
    : mb > 10 && mb <= 20
    ? 'bigFile'
    : 'largeFile';
};
