import axios, { AxiosInstance } from 'axios';
import { Store } from 'redux';
import { browserHistory } from 'router/Routes';
import { setSessionExpired, setSessionReturnUrl } from 'store/sessionSlice';

import { getCookie, removeCookie } from './cookies';

const baseApiUrl = process.env.BASE_API_URL;
const service: AxiosInstance = axios.create({ baseURL: baseApiUrl });

export const AxiosInterceptor = (store: Store): void => {
  service.interceptors.request.use(
    (config) => {
      if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
      }
      const token = getCookie('TOKEN');
      if (!config.headers['Authorization'] && token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      // const language = localStorage.i18nextLng;
      // config.headers['Accept-Language'] = language ? language.slice(0, 2) : 'ru';
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  service.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const status = error?.response?.status;
      if (status && status === 401) {
        localStorage.removeItem('userInfo');
        removeCookie('TOKEN');
        const { pathname } = window.location;
        if (pathname === '/' || pathname === '/home') {
          browserHistory.push('/login');
        } else {
          console.dir(browserHistory.location);
          const { pathname, search, hash } = browserHistory.location;
          const path = `${pathname}${search}${hash}`;

          store.dispatch(setSessionReturnUrl(path));
          store.dispatch(setSessionExpired(true));
        }
      }

      return Promise.reject(error);
    }
  );
};

export default service;
