import Chip from 'components/Chip/Chip';
import React, { useState } from 'react';

import { useAssetStyles } from '../styles';
import { Props } from './props';

export const AssetProjects: React.FC<Props> = ({ projects }) => {
  const styles = useAssetStyles();
  const [showMore, setShowMore] = useState(false);

  return (
    <div>
      {projects.slice(0, 2).map((p) => (
        <Chip key={p.project_slug} variant="default">
          {p.project_title}
        </Chip>
      ))}
      {projects.length > 2 && (
        <Chip
          onMouseLeave={() => setShowMore(false)}
          onMouseEnter={() => setShowMore(true)}
          variant="default"
        >
          ...
        </Chip>
      )}
      {showMore && (
        <div className={styles.projects}>
          {projects.slice(2).map((p) => (
            <Chip key={p.project_slug} variant="default">
              {p.project_title}
            </Chip>
          ))}
        </div>
      )}
    </div>
  );
};
