import { makeStyles } from '@material-ui/core';

import { Props } from './props';

export const useStyles = makeStyles(() => ({
  root: ({ align, size, direction }: Props) => ({
    display: 'flex',
    justifyContent:
      (align === 'start' && 'flex-start') ||
      (align === 'end' && 'flex-end') ||
      (align === 'center' && 'center') ||
      (align === 'baseline' && 'baseline') ||
      'flex-start',
    alignItems: 'center',
    gap: !Number.isInteger(size)
      ? (size === 'large' && '24px') ||
        (size === 'middle' && '16px') ||
        (size === 'small' && '8px') ||
        '8px'
      : `${size}px`,
    flexDirection:
      (direction === 'horizontal' && 'row') || (direction === 'vertical' && 'column') || 'row',
  }),
}));
