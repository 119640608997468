import { assetInfoGetByHash } from 'api/assets';
import { ContentItem } from 'api/types/ContentItem';
import { DATE_FORMAT } from 'common/constants/date';
import { parseDateTime } from 'common/utils/date';
import { getFileHash } from 'common/utils/helpers';
import { ArrowRight } from 'components/IconPelican/icons';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isUploadType } from '../../../ContentFormFields';
import { useStyles } from '../styles';
import { Props } from './props';

export const ContentItemHistory: React.FC<Props> = ({
  contentItem,
  handleOpenEmbeddedItem,
  field,
  oldValue,
  newValue,
  locale,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles({ toggled: false });

  const [fileName, setFileName] = useState<string>();

  const renderEmbeddedId = useCallback(
    (id: number, last: boolean): JSX.Element | string => {
      const itemIsExist = (contentItem[field.slug] as ReadonlyArray<ContentItem>).some(
        (c) => c.id === id
      );
      return itemIsExist && handleOpenEmbeddedItem ? (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        <span className={classes.embeddedId} onClick={() => handleOpenEmbeddedItem(id, field.slug)}>
          {id}
          {!last && ', '}
        </span>
      ) : (
        `${id}${!last ? ', ' : ''}`
      );
    },
    [classes, contentItem, field, handleOpenEmbeddedItem]
  );

  const getFileName = useCallback(
    async (hash: string) => {
      try {
        const res = await assetInfoGetByHash(hash);
        setFileName(res.file_name);
      } catch (e) {
        enqueueSnackbar(
          `${t('Error on loading')} ${t('file info')} ${t(e.response?.data?.message || e)}`,
          {
            variant: 'error',
          }
        );
      }
    },
    [enqueueSnackbar, t]
  );

  const renderReferenceHistory = useCallback(
    (newValue: readonly number[], oldValue: readonly number[]): JSX.Element => {
      const addedItems = newValue.filter((id) => !oldValue.some((newId) => newId === id));
      const removedItems = oldValue.filter((id) => !newValue.some((newId) => newId === id));
      const editedItems = oldValue.filter((id) => newValue.some((newId) => newId === id));
      return (
        <>
          {removedItems.length > 0 && (
            <div className={classes.changes}>
              <span className={classes.removed}>REMOVED</span> <ArrowRight htmlColor="#8F8F91" />{' '}
              <span className={classes.newValue}>ID: {removedItems.join(', ')}</span>
            </div>
          )}
          {addedItems.length > 0 && (
            <div className={classes.changes}>
              <span className={classes.added}>ADDED</span> <ArrowRight htmlColor="#8F8F91" />{' '}
              <span className={classes.newValue}>
                ID:
                {field.embedded
                  ? addedItems.map((id, i) => renderEmbeddedId(id, i === addedItems.length - 1))
                  : addedItems.join(', ')}
              </span>
            </div>
          )}
          {editedItems.length > 0 && field.embedded && (
            <div className={classes.changes}>
              <span className={classes.oldValue}>EDITED</span> <ArrowRight htmlColor="#8F8F91" />{' '}
              <span className={classes.newValue}>
                ID: {editedItems.map((id, i) => renderEmbeddedId(id, i === editedItems.length - 1))}
              </span>
            </div>
          )}
        </>
      );
    },
    [classes, renderEmbeddedId, field]
  );

  const getFieldValue = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (val: any): any => {
      if (isUploadType(field)) {
        if (fileName) {
          return fileName;
        } else {
          getFileName(getFileHash(val));
          return val;
        }
      } else if (field.type === 'DATETIME') {
        return format(parseDateTime(val) || 0, DATE_FORMAT);
      } else if (field.type === 'GEOPOINT') {
        return val && `${val.x}, ${val.y}`;
      } else {
        return val;
      }
    },
    [fileName, getFileName, field]
  );

  return (
    <div className={classes.historyChanges}>
      <div style={{ marginBottom: 4 }}>
        <span style={{ fontWeight: 'bold' }}>{field.label}</span>
        {locale && (
          <>
            <img className={classes.multilingualIcon} src="/assets/language.svg" alt="lang" />
            {locale}
          </>
        )}
      </div>
      {(oldValue !== null || newValue !== null) &&
        field.type !== 'RICHTEXT' &&
        field.type !== 'REFERENCE' &&
        (field.type !== 'BOOLEAN' ? (
          <div className={classes.changes}>
            {oldValue && newValue && (
              <span className={classes.oldValue}>{getFieldValue(oldValue)}</span>
            )}
            {oldValue && !newValue && <span className={classes.removed}>REMOVED</span>}
            {!oldValue && newValue && <span className={classes.added}>CREATED</span>}{' '}
            <ArrowRight htmlColor="#8F8F91" />{' '}
            <span className={classes.newValue}>
              {!newValue && oldValue ? getFieldValue(oldValue) : getFieldValue(newValue)}
            </span>
          </div>
        ) : (
          <div className={classes.changes}>
            <span className={classes.oldValue}>
              {oldValue === false ? t('No') : oldValue === true ? t('Yes') : t('Not given')}
            </span>{' '}
            <ArrowRight htmlColor="#8F8F91" />{' '}
            <span className={classes.newValue}>
              {newValue === false ? t('No') : newValue === true ? t('Yes') : t('Not given')}
            </span>
          </div>
        ))}
      {(oldValue || newValue) &&
        field.type === 'REFERENCE' &&
        renderReferenceHistory(
          (newValue
            ? Array.isArray(newValue)
              ? newValue
              : [newValue]
            : []) as ReadonlyArray<number>,
          (oldValue
            ? Array.isArray(oldValue)
              ? oldValue
              : [oldValue]
            : []) as ReadonlyArray<number>
        )}
    </div>
  );
};
