import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { Props } from './props';

export const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    background: '#fff',
    border: '1px solid #CECECE',
  },
}));

export const Card: React.FC<Props> = ({ items, delimiter, children, ...rest }) => {
  const styles = useStyles();

  return (
    <div className={styles.root} {...rest}>
      {children
        ? children
        : items?.map((item, index) => {
            return delimiter && index !== items.length - 1 ? [item, delimiter] : [item];
          })}
    </div>
  );
};
