import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ElasticSearch: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12c0 .865.121 1.7.328 2.5H15.5a2.5 2.5 0 000-5H3.328A9.99 9.99 0 003 12z"
      fill="#343741"
    />
    <mask
      id="prefix__a"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={4}
      y={2}
      width={17}
      height={6}
    >
      <path d="M4.027 2H20.8v5.625H4.027V2z" fill="#fff" />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.828 6.788c.349-.322.674-.667.972-1.038A9.977 9.977 0 0013 2C9.05 2 5.65 4.298 4.027 7.625h13.667c.792 0 1.552-.3 2.134-.837z"
        fill="#FEC514"
      />
    </g>
    <mask
      id="prefix__b"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={4}
      y={16}
      width={17}
      height={6}
    >
      <path d="M4.027 16.375H20.8V22H4.027v-5.625z" fill="#fff" />
    </mask>
    <g mask="url(#prefix__b)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.694 16.375H4.027C5.65 19.701 9.05 22 13 22a9.979 9.979 0 007.8-3.75 9.492 9.492 0 00-.973-1.038 3.144 3.144 0 00-2.133-.837z"
        fill="#00BFB3"
      />
    </g>
  </SvgIcon>
);
export default ElasticSearch;
