import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const Db: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.273 4.549c-1.203.646-1.71 1.414-1.71 2.072 0 .658.507 1.425 1.71 2.072 1.171.63 2.841 1.042 4.727 1.042 1.886 0 3.556-.412 4.727-1.042 1.203-.647 1.71-1.414 1.71-2.072 0-.658-.507-1.426-1.71-2.072-1.171-.63-2.841-1.042-4.727-1.042-1.886 0-3.556.412-4.727 1.042zm12.665 2.072c0-1.476-1.101-2.641-2.5-3.393-1.43-.769-3.354-1.221-5.438-1.221-2.084 0-4.008.452-5.438 1.22-1.399.753-2.5 1.918-2.5 3.394v10.758c0 1.476 1.101 2.641 2.5 3.393 1.43.769 3.354 1.221 5.438 1.221 2.084 0 4.008-.452 5.438-1.22 1.399-.753 2.5-1.918 2.5-3.394V6.621zm-1.5 2.738a6.605 6.605 0 01-1 .655c-1.43.769-3.354 1.221-5.438 1.221-2.084 0-4.008-.452-5.438-1.221a6.608 6.608 0 01-1-.655v2.666c.002.658.51 1.424 1.71 2.07 1.172.63 2.842 1.042 4.728 1.042 1.886 0 3.556-.413 4.727-1.043 1.203-.646 1.71-1.413 1.71-2.071V9.359zm0 5.401a6.61 6.61 0 01-1 .656c-1.43.768-3.354 1.22-5.438 1.22-2.084 0-4.008-.452-5.438-1.22a6.612 6.612 0 01-1-.656v2.62c0 .658.508 1.425 1.71 2.071 1.172.63 2.842 1.042 4.728 1.042 1.886 0 3.556-.412 4.727-1.042 1.203-.646 1.71-1.413 1.71-2.072V14.76z"
    />
  </SvgIcon>
);
export default Db;
