import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 631,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      top: 50,
      left: `50%`,
      transform: `translate(-50%, 0)`,
      fontFamily: 'SF Pro Text, sans-serif',
      minHeight: '50vh',
      maxHeight: 'calc(100vh - 50px)',
      overflowY: 'auto',
    },
    container: {
      padding: '18px 24px 0',
      display: 'flex',
      alignItems: 'center',
    },
    clearIcon: {
      marginLeft: 16,
      cursor: 'pointer',
    },
    recentContainer: {
      borderBottom: '7px solid #EBECF0',
    },
    recentTitle: {
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      margin: '8px 24px',
    },
    searchResult: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 24px',
      minHeight: 56,
    },
    marginLeftAuto: {
      marginLeft: 'auto',
    },
    marginLeftFixed: {
      marginLeft: '10px',
    },
    fileIcon: {
      width: 24,
      height: 24,
      background: 'url(/assets/file-icon.svg)',
    },
    folderIcon: {
      width: 24,
      height: 24,
      background: 'url(/assets/folder-icon.svg)',
    },
    contentInfo: {
      marginLeft: 12,
    },
    contentName: {
      cursor: 'pointer',
      color: '#0E4DEC !important',
      fontWeight: 500,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    contentResult: {
      fontSize: 11,
      lineHeight: '127%',
      color: '#555555',
    },
    results: {
      padding: 0,
      maxHeight: '60vh',
      overflowY: 'auto',
    },
    spin: {
      display: 'flex',
      justifyContent: 'center',
      padding: '80px 0',
    },
    showMore: {
      padding: '8px 28px',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '20px',
      margin: 0,
      color: '#354662',
    },
    width100: {
      width: '100%',
    },
    hr: {
      borderBottom: '2px solid #EBECF0',
    },
    filterWidth: {
      width: '33%',
    },
    justifyBetween: {
      justifyContent: 'space-between',
    },
    pagination: {
      padding: '14px 24px',
      height: 56,
    },
    empty: {
      fontSize: 20,
      lineHeight: '24px',
      textAlign: 'center',
      color: '#6B778C',
      width: '100%',
      margin: '80px 0',
    },
    mb18: {
      marginBottom: 18,
    },
  })
);
