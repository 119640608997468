import { createSlice } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { routeList } from 'router/route-list';

import { buildMenu } from './helpers';
import { AppMenuItem } from './types/app-menu-item';
import { MenuState } from './types/menu-state';

const initialState: MenuState = { items: Array.from(buildMenu(routeList)) };

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuItems(
      state,
      action: Action & {
        readonly payload: { readonly name: string; readonly items: readonly AppMenuItem[] };
      }
    ) {
      const { name, items } = action.payload;
      const existingItems = state.items.concat();
      const foundIndex = existingItems.findIndex((i) => i.name === name);
      const updated: AppMenuItem = existingItems[foundIndex];

      return {
        ...state,
        items: [
          ...existingItems.splice(0, foundIndex),
          { ...updated, items: items },
          ...existingItems.splice(foundIndex + 1),
        ],
      };
    },
  },
});

export const { setMenuItems } = menuSlice.actions;

export const { getMenuItems } = {
  getMenuItems(state: { readonly menu: MenuState }) {
    return state.menu.items;
  },
};

export default menuSlice.reducer;
