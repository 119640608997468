import { FormControl } from '@material-ui/core';
import RadioGroupPelican from 'components/RadioGroupPelican';
import { RadioItem } from 'components/RadioGroupPelican/types/RadioItem';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Props } from './props';

export const BooleanField: React.FC<Props> = ({
  initValue,
  name,
  handleRadioChange,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation();

  const [boolValue, setBoolValue] = useState<string>();
  const items: readonly RadioItem[] = [
    {
      label: t('Not given'),
      value: '',
    },
    {
      label: 'No',
      value: 'false',
    },
    {
      label: 'Yes',
      value: 'true',
    },
  ];

  useEffect(() => {
    setBoolValue(initValue && initValue.toString());
  }, [initValue, name]);

  return (
    <FormControl id={rest.id} component="fieldset">
      <RadioGroupPelican
        items={items}
        name={name}
        value={boolValue}
        handleRadioChange={handleRadioChange}
        disabled={disabled}
      />
    </FormControl>
  );
};
