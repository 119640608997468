import { gql } from '@apollo/client';

export const ENVIRONMENT_DIFF = gql`
  query contentDifference($contentTypeIds: [Int], $leftEnvId: String!, $rightEnvId: String!) {
    contentTypeDiff(
      leftEnvId: $leftEnvId
      rightEnvId: $rightEnvId
      contentTypeIds: $contentTypeIds
    ) {
      contentTypeId
      description
      state
      isRenamed
      slugs {
        left
        right
      }
    }
  }
`;
