import { Typography } from '@material-ui/core';
import React from 'react';

import { useStyles } from './styles';

export const ModalTitle: React.FC = ({ children }) => {
  const styles = useStyles({});

  return (
    <div className={styles.container}>
      <Typography className={styles.modalTitle} variant="h3">
        {children}
      </Typography>
    </div>
  );
};
