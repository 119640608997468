import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ArrowsOpen: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon width={18} height={18} {...props}>
    <path
      d="M16.42 16.42v.75h.75v-.75h-.75zm-5.299-6.36a.75.75 0 10-1.06 1.061l1.06-1.06zm6.05 2.118a.75.75 0 00-1.5 0h1.5zm-4.993 3.493a.75.75 0 000 1.5v-1.5zM1.58 1.579V.83H.83v.75h.75zm5.3 6.36a.75.75 0 001.06-1.06l-1.06 1.06zM5.822 2.33a.75.75 0 000-1.5v1.5zM.829 5.822a.75.75 0 001.5 0H.83zM16.951 15.89l-5.83-5.83-1.06 1.06 5.83 5.83 1.06-1.06zm.22.53v-4.243h-1.5v4.243h1.5zm-4.993.75h4.243v-1.5h-4.243v1.5zM1.048 2.11l5.83 5.83L7.94 6.878l-5.83-5.83-1.06 1.06zm.531.22h4.243V.83H1.579v1.5zm.75 3.492V1.579H.83v4.243h1.5z"
      fill="#000"
    />
  </SvgIcon>
);
export default ArrowsOpen;
