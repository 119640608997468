import React from 'react';

import { Space } from '../Space';
import { useStyles } from './styles';

export const ModalFooter: React.FC = ({ children }) => {
  const styles = useStyles({});

  return (
    <>
      <div className={styles.hr} />
      <div className={styles.formFooter}>
        <Space size="small" align="end">
          {children}
        </Space>
      </div>
    </>
  );
};

export default ModalFooter;
