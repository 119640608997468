import { contentItemGet, contentTypeFieldGroupsGet, fieldsGet } from 'api/content';
import { ContentItem, ContentItem as ContentItemType } from 'api/types/ContentItem';
import { ContentTypeField } from 'api/types/ContentTypeField';
import { ContentTypeFieldGroup } from 'api/types/ContentTypeFieldGroup';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getContentTabs, setNewContentTabs } from 'store/contentTabsSlice';
import {
  getNewVersion,
  setAllVersions,
  setCurrentVersion,
  setNewVersion,
} from 'store/versionSlice';
import { getWorkspaces } from 'store/workspaceSlice';

import { ContentEditorForm } from './ContentEditorForm';
import { ContentEditorProvider } from './ContentEditorProvider';
import { ContentEditorSkeleton } from './ContentEditorSkeleton';
import { sortEmbeddedValues, sortFieldsByPosition } from './helpers';
import { Props } from './props';

type Params = {
  readonly sourceId: string;
  readonly type: string;
};

export const ContentEditor: React.FC<Props> = ({
  contentTypeSlug,
  embedLevel,
  sourceId,
  contentItemInitValue,
  isEmbeddedItem,
  isNewItem,
  onCloseButtonClick,
  onEmbeddedFieldSave,
  parentSlug,
  parentId,
  fieldSlugInParent,
  mainSourceId,
  rootUpdateInfo,
  inTreeView,
  rootProjectId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [fetchLoading, setFetchLoading] = useState<boolean>();
  const comparedVersion = useSelector(getNewVersion);
  const contentTabs = useSelector(getContentTabs);
  const contentTabsRef = useRef(contentTabs);
  contentTabsRef.current = contentTabs;
  const dispatch = useDispatch();
  const params = useParams<Params>();

  const [notFoundContent, setNotFoundContent] = useState<{
    readonly id: string;
    readonly contentType: string;
  }>();
  const [contentItem, setContentItem] = useState<ContentItemType>();
  const [fields, setFields] = useState<ReadonlyArray<ContentTypeField>>([]);
  const [groups, setGroups] = useState<ReadonlyArray<ContentTypeFieldGroup>>([]);
  const [groupFields, setGroupFields] = useState<ReadonlyArray<readonly ContentTypeField[]>>([]);
  const [multilingual, setMultilingual] = useState<{ readonly [key: string]: ContentItemType }>({});
  const [contentLoaded, setContentLoaded] = useState(false);
  const workspaces = useSelector(getWorkspaces);

  const getContentTypeFieldGroupsList = useCallback(
    async (projectId?: number) => {
      const fieldGroupsResult = await contentTypeFieldGroupsGet(contentTypeSlug);
      const promiseFields = Array.from(fieldGroupsResult, (f) => {
        return fieldsGet(contentTypeSlug, {
          project_id: projectId,
          'content-type-field-group-id': f.id,
        });
      });
      const groupFieldList = await Promise.all(promiseFields);
      setFields(groupFieldList.flat(1));

      setGroupFields(groupFieldList.map((f) => sortFieldsByPosition(f)));
      setGroups(fieldGroupsResult);
    },
    [contentTypeSlug]
  );

  const assignContentItem = useCallback(
    async (item: ContentItem): Promise<void> => {
      item['$.multilingual'] && setMultilingual(item['$.multilingual']);
      setContentItem(sortEmbeddedValues(item));
      const tab = contentTabsRef.current.find(
        (t) => t.contentItemId === item.id && t.contentTypeSlug === contentTypeSlug
      );
      if (tab && tab.status !== item.content_state?.code && embedLevel === -1) {
        dispatch(
          setNewContentTabs(
            contentTabsRef.current.map((t) =>
              t.contentItemId === +sourceId && t.contentTypeSlug === contentTypeSlug
                ? { ...t, status: item.content_state?.code || 'draft' }
                : t
            )
          )
        );
      }
      try {
        await getContentTypeFieldGroupsList(
          item.project_id || rootProjectId || workspaces[0]?.workspaceId
        );
        setNotFoundContent(undefined);
        setContentLoaded(true);
      } catch (e) {
        enqueueSnackbar(
          `${t('Error on loading')} ${t('fields')} ${t(e.response?.data?.message || e)}`,
          {
            variant: 'error',
          }
        );
      }
    },
    [
      embedLevel,
      getContentTypeFieldGroupsList,
      rootProjectId,
      workspaces,
      contentTypeSlug,
      dispatch,
      sourceId,
      enqueueSnackbar,
      t,
    ]
  );

  const loadData = useCallback(
    async (contentId?: string) => {
      const id = contentId || sourceId;
      try {
        setFetchLoading(true);
        if (
          id !== '-1' &&
          !contentItemInitValue &&
          (!(comparedVersion && Object.keys(comparedVersion).length > 0 && embedLevel === -1) ||
            contentLoaded)
        ) {
          const contentItemResult = await contentItemGet(contentTypeSlug, id, 1);
          await assignContentItem(contentItemResult);
        }

        if (
          id === '-1' &&
          embedLevel === -1 &&
          comparedVersion &&
          Object.keys(comparedVersion).length > 0
        ) {
          await assignContentItem({
            ...comparedVersion,
            source_id: undefined,
            id: undefined,
          });
        } else if (id === '-1' && embedLevel === -1) {
          await assignContentItem({});
        } else if (embedLevel > -1 && (id === '-1' || contentItemInitValue)) {
          await assignContentItem(contentItemInitValue || {});
        }
      } catch (e) {
        setContentItem(undefined);
        setNotFoundContent({ id, contentType: contentTypeSlug });
        if (e.response.data.status === 400 && embedLevel === -1) {
          dispatch(
            setNewContentTabs(
              contentTabsRef.current.filter(
                (t) => !(t.contentItemId === +sourceId && t.contentTypeSlug === contentTypeSlug)
              )
            )
          );
        }
        enqueueSnackbar(
          `${t('Error while fetching content')} ${t(e.response?.data?.message || e)}`,
          {
            variant: 'error',
          }
        );
      } finally {
        setFetchLoading(false);
      }
    },
    [
      sourceId,
      contentItemInitValue,
      comparedVersion,
      embedLevel,
      contentLoaded,
      contentTypeSlug,
      assignContentItem,
      enqueueSnackbar,
      t,
      dispatch,
    ]
  );

  useEffect(() => {
    if (fetchLoading === undefined) {
      loadData();
    }
  }, [fetchLoading, loadData]);

  useEffect(() => {
    if (embedLevel === -1) {
      return () => {
        dispatch(setNewVersion({}));
        dispatch(setCurrentVersion({}));
        dispatch(setAllVersions({ versions: [], sourceId: 0 }));
      };
    }
  }, [dispatch, embedLevel]);

  useEffect(() => {
    if (
      notFoundContent &&
      (notFoundContent.id !== sourceId || notFoundContent.contentType !== contentTypeSlug)
    ) {
      loadData();
    }
  }, [loadData, contentTypeSlug, notFoundContent, sourceId]);

  return (
    <div style={{ height: '100%', width: '100%', position: 'relative' }}>
      {fetchLoading && <ContentEditorSkeleton />}
      {fetchLoading === false && contentItem && (
        <ContentEditorProvider
          contentItem={contentItem}
          multilingualInitialValue={multilingual}
          embedLevel={embedLevel}
          contentTypeSlug={contentTypeSlug}
          contentItemId={sourceId || '-1'}
          isEmbeddedItem={isEmbeddedItem}
          isNewItem={isNewItem}
          onCloseButtonClick={onCloseButtonClick}
          fields={fields}
          groups={groups}
          groupFields={groupFields}
          onEmbeddedFieldSave={onEmbeddedFieldSave}
          reloadContentItem={loadData}
          parentId={parentId}
          parentSLug={parentSlug}
          params={params}
          fieldSlugInParent={fieldSlugInParent}
          mainSourceId={mainSourceId}
          rootUpdateInfo={rootUpdateInfo}
          inTreeView={inTreeView}
          rootProjectId={rootProjectId}
        >
          <ContentEditorForm />
        </ContentEditorProvider>
      )}
    </div>
  );
};

export default ContentEditor;
