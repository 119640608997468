import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  itemsSelected: {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
  },
  buttons: {
    textAlign: 'right',
    '&>*': {
      marginLeft: 12,
    },
  },
  numItems: {
    color: '#0052CC',
    fontWeight: 500,
    fontSize: 16,
  },
  itemsList: {
    color: '#6B778C',
    fontWeight: 600,
    fontSize: 12,
  },
  itemButton: {
    color: '#354662',
    backgroundColor: '#ECEDF0',
    '&:not(:last-child)': {
      marginRight: 8,
    },
    '&>span': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  clearIcon: {
    padding: 0,
  },
  icon: {
    width: 20,
    height: 20,
  },
});
