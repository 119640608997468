import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  moreButton: {
    display: 'flex',
    alignSelf: 'center',
    padding: 0,
  },
  container: {
    width: '30%',
    padding: '20px',
  },
  checkbox: {
    flexBasis: '20px',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '50px',
    alignItems: 'center',
  },
  fields: {
    borderRadius: '5px',
    border: '1px solid #ccc',
    overflow: 'hidden',
    marginTop: '15px',
    position: 'relative',
    minHeight: '90%',
  },
  newField: {
    background: '#E5F6E7',
  },
  newFieldState: {
    color: '#00801B',
  },
  editedField: {
    background: '#E8EAFD',
  },
  editedFieldState: {
    color: '#0039D4',
  },
  deletedField: {
    background: '#FBE9E7',
  },
  deletedFieldState: {
    color: '#BF2E0D',
  },
}));
