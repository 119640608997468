import { SelectItem, SelectPelican } from 'components/SelectPelican';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OptionTypeBase as OptionType, ValueType } from 'react-select';
import { getProjects, getWorkspaces } from 'store/workspaceSlice';

import { useContentEditorProvider } from '../../../useContentEditorProvider';
import { useStyles } from './styles';

export const ContentProjectSelect: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();

  const {
    isReviewing,
    contentItemId,
    embedLevel,
    setSelectedProjects,
    selectedProjects,
    isTreeView,
    setProjectsChanged,
    setSelectedProjectOwner,
    selectedProjectOwner,
  } = useContentEditorProvider();

  const rawProjects = useSelector(getProjects);
  const workspaces = useSelector(getWorkspaces);
  const projects = rawProjects.map((p): SelectItem => ({ label: p.title, value: p.slug }));

  const filteredSelectedProjects = projects.filter((p) =>
    selectedProjects?.split(';').some((w) => w === p.value)
  );

  const handleSelectProjects = (val: ValueType<OptionType, boolean> | OptionType): void => {
    setSelectedProjects(
      val ? (val as ReadonlyArray<SelectItem>).map((s) => s.value).join(';') : ''
    );
    setProjectsChanged(true);
  };

  const handleSelectOwnerProject = useCallback(
    async (val: SelectItem) => {
      setSelectedProjectOwner(val);
    },
    [setSelectedProjectOwner]
  );

  return (
    <div className={styles.wrapper}>
      {embedLevel === -1 && (
        <div>
          {!isTreeView && (
            <div className={styles.projects}>
              <div className={styles.ownerProject}>
                <SelectPelican
                  id="project-owner"
                  name="project-owner"
                  multiButtons={false}
                  options={rawProjects
                    .filter((p) => workspaces.some((w) => w.workspaceId === p.id))
                    .map((p) => ({ label: p.title, value: p.id }))}
                  label={t('Owner')}
                  value={selectedProjectOwner}
                  selected={(val) => handleSelectOwnerProject(val as SelectItem)}
                  isDisabled={contentItemId !== '-1'}
                />
              </div>
              <div style={{ width: '100%', marginTop: 16 }}>
                <SelectPelican
                  id="projects"
                  name="projects"
                  placeholder="Select projects"
                  isDisabled={isReviewing}
                  isMulti
                  multiButtons={false}
                  value={filteredSelectedProjects}
                  options={projects}
                  selected={handleSelectProjects}
                  label={t('Availability')}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
