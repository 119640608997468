import 'react-datepicker/dist/react-datepicker.css';

import ScheduleIcon from '@material-ui/icons/Schedule';
import clsx from 'clsx';
import { useStylesDatepicker } from 'components/Datepicker';
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';

import { Props } from './props';

export const Timepicker: React.FC<Props> = ({
  className,
  onChangeTime,
  time,
  disabled,
  placeholderText,
  minTime,
  minDate,
  withIcon,
  ...rest
}) => {
  const [startDate, setStartDate] = useState<Date | undefined>(time);
  useEffect(() => {
    setStartDate(time);
  }, [time]);
  const classes = useStylesDatepicker();
  const compareDates = (dateFirst?: Date, dateSecond?: Date): boolean => {
    if (dateFirst && dateSecond) {
      return (
        dateFirst.getDate() === dateSecond.getDate() &&
        dateFirst.getMonth() === dateSecond.getMonth() &&
        dateFirst.getFullYear() === dateSecond.getFullYear()
      );
    } else {
      return false;
    }
  };
  const getDayClassName = (date: Date): string => {
    return (compareDates(date, startDate) && classes.dayClassNameCurrent) || classes.dayClassName;
  };
  const getWeekDayClassName = (): string => classes.weekDayClassName;
  const getMonthClassName = (): string => classes.monthClassName;
  const getTimeClassName = (): string => classes.timeClassName;
  const handleOnChange = useCallback(
    (date) => {
      date && !Array.isArray(date) && setStartDate(date);
      onChangeTime && onChangeTime(date);
    },
    [onChangeTime]
  );
  return (
    <div id={rest.id} className={clsx(withIcon && classes.root, className && className)}>
      <DatePicker
        className={classes.className}
        calendarClassName={classes.calendarClassName}
        dayClassName={getDayClassName}
        weekDayClassName={getWeekDayClassName}
        monthClassName={getMonthClassName}
        timeClassName={getTimeClassName}
        popperClassName={classes.popperClassName}
        wrapperClassName={clsx(className, classes.wrapperClassName)}
        disabled={disabled}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="HH:mm"
        timeFormat="HH:mm"
        selected={startDate}
        onChange={handleOnChange}
        placeholderText={placeholderText}
        minTime={minTime}
        minDate={minDate}
      />
      {withIcon && <ScheduleIcon className={classes.calendarIcon} />}
    </div>
  );
};
