import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const TreeOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 4.5V7H10V4.5H5.5zM4 7.5a1 1 0 001 1h2.017v7.806a2.75 2.75 0 002.75 2.75h2.983V20a1 1 0 001 1h5.5a1 1 0 001-1v-3.5a1 1 0 00-1-1h-5.5a1 1 0 00-1 1v1.056H9.767c-.69 0-1.25-.56-1.25-1.25V12.25h4.233v.741a1 1 0 001 1h5.5a1 1 0 001-1v-3.5a1 1 0 00-1-1h-5.5a1 1 0 00-1 1v1.259H8.517V8.5H10.5a1 1 0 001-1V4a1 1 0 00-1-1H5a1 1 0 00-1 1v3.5zm10.25 12V17h4.5v2.5h-4.5zm0-9.509v2.5h4.5v-2.5h-4.5z"
    />
  </SvgIcon>
);
export default TreeOutline;
