import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const MultiOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.75 7.5a1 1 0 000 2h3a1 1 0 100-2h-3zm10.5 3.5a1 1 0 100 2h3a1 1 0 100-2h-3zm-11.5 4.5a1 1 0 011-1h1.5a1 1 0 110 2h-1.5a1 1 0 01-1-1zm7.5-8a1 1 0 100 2h4.5a1 1 0 100-2h-4.5zM5.75 12a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1zm5 2.5a1 1 0 100 2h7.5a1 1 0 100-2h-7.5z"
      fill="#000"
    />
  </SvgIcon>
);
export default MultiOutline;
