import { makeStyles, Theme } from '@material-ui/core/styles';
import { getMe } from 'api/assets';
import { TOP_NAV_HEIGHT } from 'common/constants/styles';
import { getCookie, setCookie } from 'common/utils/cookies';
import * as QueryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { loadContentData } from 'store/contentSlice';
import { loadInitialData, setLoading, setUserInfo } from 'store/userInfoSlice';
import { UserInfoState } from 'store/userInfoSlice/types/user-info-state';
import LanguageSelect from 'templates/MainTemplate/TopNav/LanguageSelect';
import { Logo } from 'templates/MainTemplate/TopNav/Logo';

import LoginForm from './LoginForm';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: `calc(100% - ${TOP_NAV_HEIGHT}px)`,
    width: '100%',
    background: '#E5E5E5',
  },
  header: {
    height: '44px',
    background: theme.palette.surface[900],
    width: `calc(100%)`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 30px',
    color: '#fff',
  },
  nav: {
    display: 'flex',
    listStyle: 'none',
    '&>li': {
      marginRight: '20px',
      color: '#545456',
    },
  },
  signUp: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const Login: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const query: { readonly session?: string } = QueryString.parse(location.search);

  const load = useCallback(async () => {
    dispatch(setLoading(false));
    try {
      const me = await getMe();
      const [authority] = me.authorities;
      const allUserRoles = me.authorities.map((role) => role.description);
      dispatch(
        setUserInfo(({
          userName: me.username,
          roleCode: authority.code,
          role: authority?.description || authority?.code,
          need_to_change_password: me.need_to_change_password,
          userRoles: allUserRoles,
          userId: me.id,
        } as unknown) as UserInfoState)
      );
      localStorage.setItem('sessionExpired', 'false');
      dispatch(loadInitialData());
      dispatch(loadContentData());
      history.push('/');
    } catch (e) {
      console.error(e);
      history.push('/login');
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch, history]);

  useEffect(() => {
    const session = getCookie('TOKEN');
    if (query.session && !session) {
      setCookie('TOKEN', query.session);
      load();
    }
  }, [history, query, load]);

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <Logo />
        <nav>
          <ul className={classes.nav}>
            <li>{t('Login via SSO')}</li>
            <li>{t('Reset password')}</li>
            <li>{t('Unlock information')}</li>
          </ul>
        </nav>
        <div className={classes.signUp}>
          <div style={{ marginRight: '20px' }}>
            <LanguageSelect />
          </div>
          <div style={{ color: '#545456' }}>Sign up</div>
        </div>
      </header>
      <div className={classes.container}>
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
