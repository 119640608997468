import { Modal } from '@material-ui/core';
import { ButtonPelican } from 'components/ButtonPelican';
import { Location } from 'history';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';

import { useContentEditorProvider } from '../useContentEditorProvider';
import { useStyles } from './styles';

export const ContentLeaveModal: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const { embedLevel, changedFields, contentItemId, contentTypeSlug } = useContentEditorProvider();
  const contentPath = `/content/${contentTypeSlug}/${contentItemId}`;

  const history = useHistory();

  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (
      embedLevel === -1 &&
      !confirmedNavigation &&
      changedFields.length > 0 &&
      nextLocation.pathname !== contentPath
    ) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = (): void => {
    setModalVisible(false);
    setConfirmedNavigation(true);
    return;
  };

  const closeModal = (): void => {
    setModalVisible(false);
    location.pathname !== contentPath && history.push(contentPath);
  };

  const keyPressFunction = useCallback((event) => {
    if (event.keyCode === 13) {
      handleConfirmNavigationClick();
    }
  }, []);

  useEffect(() => {
    if (changedFields.length > 0) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => undefined;
    }
  }, [changedFields]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      history.push(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, history]);

  useEffect(() => {
    if (changedFields.length > 0 && modalVisible) {
      document.addEventListener('keydown', keyPressFunction, false);

      return () => {
        document.removeEventListener('keydown', keyPressFunction, false);
      };
    }
  }, [changedFields, modalVisible, keyPressFunction]);

  return (
    <>
      <Prompt when={changedFields.length > 0} message={handleBlockedNavigation} />
      <Modal
        open={modalVisible}
        onClose={() => closeModal()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={styles.paper}>
          <div className={styles.modalHeader}>{t('Unsaved data')}</div>
          <div className={styles.changeModalBody}>{t('You have unsaved changes')}</div>
          <div className={styles.modalFooter}>
            <div className={styles.flexEnd}>
              <ButtonPelican
                onClick={() => handleConfirmNavigationClick()}
                variant="contained"
                color="danger"
              >
                {t('Leave')}
              </ButtonPelican>
              <ButtonPelican
                className={styles.cancelButton}
                onClick={() => closeModal()}
                variant="text"
              >
                {t('Cancel')}
              </ButtonPelican>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
