import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const LanguageOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.795 3.005a.75.75 0 01.75.75v.974h5.284a.75.75 0 010 1.5h-1.607c-.697 2.005-1.958 3.915-3.522 5.765a31.457 31.457 0 002.156 1.928.75.75 0 11-.957 1.156 32.833 32.833 0 01-2.193-1.958c-1.272 1.387-2.687 2.743-4.133 4.086a.75.75 0 11-1.02-1.1c1.474-1.369 2.864-2.709 4.085-4.042-.944-.983-1.425-1.819-2.105-3.24a.75.75 0 111.353-.648c.61 1.276 1.006 1.967 1.746 2.757 1.307-1.557 2.35-3.113 2.99-4.704h-8.86a.75.75 0 010-1.5h5.283v-.974a.75.75 0 01.75-.75zM17.125 9.25a.75.75 0 01.701.484l2.585 6.811 1.291 3.452a.75.75 0 01-1.404.526l-1.11-2.965h-4.134l-1.098 2.963a.75.75 0 11-1.406-.522l1.282-3.458 2.592-6.808a.75.75 0 01.701-.483zm-1.504 6.808h3l-1.497-3.947-1.503 3.947z"
    />
  </SvgIcon>
);
export default LanguageOutline;
