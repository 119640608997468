import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const MongoDB: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      d="M17.27 9.61c-1.292-5.613-3.987-7.108-4.67-8.16-.286-.472-.535-.952-.748-1.45-.037.498-.101.812-.535 1.19-.868.758-4.532 3.702-4.846 10.08-.286 5.945 4.44 9.61 5.067 9.989.48.23 1.062 0 1.348-.213 2.28-1.541 5.39-5.64 4.385-11.437z"
      fill="#10AA50"
    />
    <path
      d="M11.954 18c-.12 1.459-.212 2.308-.526 3.139 0 0 .212 1.393.35 2.861h.518c.12-1.034.304-2.068.554-3.083-.656-.305-.868-1.634-.896-2.917z"
      fill="#B8C4C2"
    />
    <path
      d="M12.95 21c-.636-.295-.82-1.67-.849-2.954.157-2.123.204-4.246.139-6.378-.037-1.108.018-10.32-.277-11.668.203.452.434.895.683 1.33.683 1.06 3.35 2.556 4.625 8.196.997 5.806-2.068 9.914-4.32 11.474z"
      fill="#12924F"
    />
  </SvgIcon>
);
export default MongoDB;
