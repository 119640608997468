import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { ButtonPelican } from 'components/ButtonPelican';
import { TextInput } from 'components/TextInput';
import React, {
  ChangeEvent,
  createContext,
  PropsWithChildren,
  useCallback,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { useStyles } from './styles';
import { ConfirmOptions } from './types/ConfirmOptions';

export const ConfirmContext = createContext((_: ConfirmOptions) => Promise.resolve(''));

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ConfirmProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [warnings, setWarnings] = useState<ReadonlyArray<string>>([]);
  const [open, setOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [message, setMessage] = useState('');
  const [withMessage, setWithMessage] = useState(false);
  const [rejectText, setRejectText] = useState('');
  const [resolveText, setResolveText] = useState('');
  const classes = useStyles();
  const { t } = useTranslation();

  const resolveRef = useRef((message?: string | PromiseLike<string>) => {
    console.log('resolve', message);
  });
  const rejectRef = useRef(() => {
    console.log('reject');
  });
  const confirm = (options: ConfirmOptions): Promise<string> => {
    setTitle(options.title || t('Are you sure?'));
    setDescription(options.description || '');
    setDialogProps(options.dialogProps || {});
    setWarnings(options.warnings || []);
    setOpen(true);
    setWithMessage(options.withMessage || false);
    setRejectText(options.rejectText || t('No'));
    setResolveText(options.resolveText || t('Yes'));
    return new Promise<string>((resolve, reject) => {
      resolveRef.current = resolve as (val: string | PromiseLike<string> | undefined) => void;
      rejectRef.current = reject;
    });
  };

  const handleOk = useCallback(() => {
    resolveRef.current(message);
    setOpen(false);
    setMessage('');
  }, [message]);

  const handleClose = useCallback(() => {
    rejectRef.current();
    setOpen(false);
    setMessage('');
  }, []);

  return (
    <ConfirmContext.Provider value={confirm}>
      <Dialog open={open} fullWidth={true} {...dialogProps} onClose={handleClose}>
        <DialogTitle className={classes.modalHeader}>
          <Typography className={classes.messageTitle} variant="h3" color="textPrimary">
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.modalBody}>
          {warnings.map((w, i) => (
            <Typography key={i} className={classes.warning} variant="body1">
              {w}
            </Typography>
          ))}
          <Typography className={classes.messageDescription} variant="body1">
            {description}
          </Typography>
          {withMessage && (
            <div className={classes.textareaContainer}>
              <TextInput
                className={classes.messageInput}
                placeholder={t('Type message')}
                value={message}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
                multiline
              />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <div className={classes.modalFooter}>
            <ButtonPelican
              className={classes.cancelButton}
              variant="text"
              color={'danger'}
              onClick={handleClose}
            >
              {rejectText}
            </ButtonPelican>
            <ButtonPelican onClick={handleOk} variant="contained" color="primary">
              {resolveText}
            </ButtonPelican>
          </div>
        </DialogActions>
      </Dialog>
      {children}
    </ConfirmContext.Provider>
  );
};

export default ConfirmProvider;
