import { gql } from '@apollo/client';

export const DIFF_FIELDS = gql`
  query fieldsDiff($contentTypeId: Int!, $leftEnvId: String!, $rightEnvId: String!) {
    contentTypeFieldsDiff(
      leftEnvId: $leftEnvId
      rightEnvId: $rightEnvId
      contentTypeId: $contentTypeId
    ) {
      id
      label
      state
    }
  }
`;
