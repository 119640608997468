import { ContentTypeField } from 'api/types/ContentTypeField';
import TabPanelPelican from 'components/TabsPelican/TabPanelPelican/component';
import { FormField } from 'containers/FormField';
import React, { useEffect } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

import { useContentEditorProvider } from '../useContentEditorProvider';
import { ContentBooleanInput } from './ContentBooleanInput';
import { ContentColourInput } from './ContentColourInput';
import { ContentDateInput } from './ContentDateInput';
import { ContentDateTimeInput } from './ContentDateTimeInput';
import { ContentEmbeddedInput } from './ContentEmbeddedInput';
import { ContentGeoPoint } from './ContentGeoPoint';
import { ContentReferenceInput } from './ContentReferenceInput';
import { ContentTextInput } from './ContentTextInput';
import { ContentTimeInput } from './ContentTimeInput';
import { ContentUploadInput } from './ContentUploadInput';
import { useStyles } from './styles';

export const isTextType = (field: ContentTypeField): boolean =>
  !!field.type &&
  ['TEXT', 'LINK', 'RICHTEXT', 'AMOUNT', 'INTEGER', 'SLUG'].indexOf(field.type) > -1;

export const isUploadType = (field: ContentTypeField): boolean =>
  !!field.type && ['IMAGE', 'AUDIO', 'VIDEO', 'DOCUMENT', 'ICON'].indexOf(field.type) > -1;

export const ContentFormFields: React.FC = () => {
  const {
    form,
    activeTab,
    showOnlyMultilingual,
    restRequires,
    lengthValidates,
    fields,
    groups,
    groupFields,
    updateInfo,
    toggledBar,
    handleFieldChange,
    embedLevel,
  } = useContentEditorProvider();

  const styles = useStyles({ sidebarOn: toggledBar });
  const { register, errors } = form;
  const { t } = useTranslation();

  useEffect(() => {
    fields
      .filter((f) => !f.multilingual)
      .forEach((f) => {
        register(f.slug);
      });
    const restMultilingual = updateInfo['$.updateInfo']?.newData?.['$.multilingual'];
    if (restMultilingual) {
      Object.keys(restMultilingual).forEach((locale) => {
        restMultilingual[locale] &&
          Object.keys(restMultilingual[locale]).forEach((slug) => {
            handleFieldChange(locale + '$' + slug);
          });
      });
    }
  }, [fields, handleFieldChange, register, updateInfo]);

  return (
    <div className={styles.container}>
      <div className={styles.fieldsScroll} data-cy="content-form">
        <Scrollbars autoHide autoHideTimeout={1000} style={{ height: '100%' }}>
          {groups.map((group, index) => (
            <TabPanelPelican padding="24px 64px" key={group.id} index={index} value={activeTab}>
              {groupFields[index].map((field, fieldIndex) => (
                <FormField
                  key={fieldIndex}
                  title={field.label}
                  description={`${field.slug}${(field.required && ` (${t('required')})`) || ``}`}
                  help={field.description}
                  error={
                    !!errors[field.slug] ||
                    restRequires.includes(field.slug) ||
                    lengthValidates.includes(field.slug)
                  }
                  id={`${field.slug}--${embedLevel}`}
                  hidden={showOnlyMultilingual && !field.multilingual}
                  type={field.type}
                  maxLength={field.maxLength || field.maxValue}
                  minLength={field.minLength || field.minValue}
                >
                  {isTextType(field) && <ContentTextInput field={field} />}
                  {field.type === 'DATE' && <ContentDateInput field={field} />}
                  {field.type === 'DATETIME' && <ContentDateTimeInput field={field} />}
                  {field.type === 'TIME' && <ContentTimeInput field={field} />}
                  {field.type === 'COLOUR' && <ContentColourInput field={field} />}
                  {field.type === 'BOOLEAN' && <ContentBooleanInput field={field} />}
                  {field.type === 'REFERENCE' && !field.embedded && (
                    <ContentReferenceInput field={field} />
                  )}
                  {field.type === 'REFERENCE' && field.embedded && (
                    <ContentEmbeddedInput field={field} />
                  )}
                  {field.type === 'GEOPOINT' && <ContentGeoPoint field={field} />}
                  {isUploadType(field) && <ContentUploadInput field={field} />}
                </FormField>
              ))}
            </TabPanelPelican>
          ))}
        </Scrollbars>
      </div>
    </div>
  );
};
