import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const LogoMini: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      d="m14.332 3.059-8.63 8.136a.5.5 0 0 0 .342.863H9.54a.5.5 0 0 1 .49.604l-1.683 7.929a.5.5 0 0 0 .832.467l8.631-8.136a.5.5 0 0 0-.343-.864h-3.495a.5.5 0 0 1-.489-.603l1.683-7.929a.5.5 0 0 0-.833-.467Z"
      fill="#161618"
    />
  </SvgIcon>
);
export default LogoMini;
