import { createStyles, Tab, Theme, withStyles } from '@material-ui/core';

import { PelicanTabProps } from './index';

export const TabPelican = withStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'visible',
      minWidth: 64,
      minHeight: 36,
      marginRight: theme.spacing(2),
      padding: 0,
      color: theme.palette.surface[500],
      '&:hover': {
        color: theme.palette.surface[700],
      },
      '&$selected': {
        color: theme.palette.blue[700],
        '&:hover': {
          color: `${theme.palette.surface[700]} !important`,
        },
      },
      // '&:focus': {
      //   border: '1px solid #0835C6',
      //   boxSizing: 'border-box',
      //   boxShadow: '0px 0px 0px 4px rgba(0, 57, 212, 0.33)',
      //   borderRadius: 4,
      // },
    },
  })
)((props: PelicanTabProps) => <Tab disableRipple {...props} />);

export default TabPelican;
