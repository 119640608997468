import { Props } from './props';
import { useStyles } from './styles';

export const Chip: React.FC<Props> = ({
  children,
  variant = 'default',
  size = 'large',
  ...props
}) => {
  const classes = useStyles({ variant, size, ...props });
  return (
    <span className={classes.root} {...props}>
      {children}
    </span>
  );
};

export default Chip;
