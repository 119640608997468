import { useQuery } from '@apollo/client';
import { Menu, MenuItem } from '@material-ui/core';
import clsx from 'clsx';
import { usePostApi } from 'common/hooks/useApiQuery';
import { AnchorElType } from 'common/types/AnchorElType';
import { addUserHeaders } from 'common/utils/axios-utils';
import { removeDuplicates } from 'common/utils/helpers';
import { EnvironmentItemDto } from 'pages/Environments/types/EnvironmentItemDto';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserInfo } from 'store/userInfoSlice';
import { CustomTooltip } from 'templates/MainTemplate/Sidebar/NavMenu/NavMenuItem/NavMenuItemText';

import { PublishRootSnackDto } from '../../Environments/components/Realises/types/publishRootDto';
import { Props } from './props';
import { EnvironmentVersionInfo, PUBLICATION_STATE, PublicationStateType } from './queries';
import { useStyles } from './styles';

export const ContentEnvironment: React.FC<Props> = ({
  environments,
  contentItems,
  contentItem,
  contentTypeId,
  handleDeploy,
}) => {
  const styles = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<AnchorElType>(null);
  const [enableReleases, setEnableReleases] = useState<ReadonlyArray<EnvironmentVersionInfo>>([]);
  const [openEnv, setOpenEnv] = useState<string>();

  const { post, loading: postLoading } = usePostApi<PublishRootSnackDto>('/content');

  const {
    data: publishedVersions,
    loading: publicationLoading,
    refetch,
  } = useQuery<PublicationStateType>(PUBLICATION_STATE, {
    variables: {
      sourceIds: contentItems
        ? contentItems.map((c) => c.source_id || 0)
        : contentItem.source_id || 0,
      contentTypeId,
    },
  });

  const user = useSelector(getUserInfo);
  const headers = useMemo(() => addUserHeaders(user.userName, user.userId), [user]);

  const sortedEnvs = environments?.slice()?.sort((a, b) => a.priority - b.priority);

  const isPublished = contentItem.content_state?.code === 'published';
  const isArchived = contentItem.content_state?.code === 'archived';

  const handleClose = (e: React.MouseEvent<React.ReactNode>): void => {
    setOpenEnv(undefined);
    setAnchorEl(null);
    setEnableReleases([]);
    e.stopPropagation();
  };

  const onDeploy = useCallback(
    async (
      event: React.MouseEvent<React.ReactNode>,
      envFrom?: EnvironmentItemDto,
      envTo?: EnvironmentItemDto
    ) => {
      handleClose(event);
      if (envFrom && envTo) {
        await post(
          {
            env_from: envFrom.id,
            env_to: envTo.id,
            contents: [
              {
                content_type_id: contentTypeId,
                publish_all: false,
                source_ids: [contentItem.source_id || 0],
              },
            ],
            priority_from: envFrom.priority,
            priority_to: envTo.priority,
          },
          { baseURL: '/publication', headers }
        );
        refetch();
        handleDeploy && handleDeploy();
      }
    },
    [contentItem.source_id, contentTypeId, post, refetch, headers, handleDeploy]
  );

  const onClickEnvironment = useCallback(
    (
      event: React.MouseEvent<React.ReactNode>,
      id: string,
      env: EnvironmentItemDto,
      index: number,
      versions: ReadonlyArray<EnvironmentVersionInfo>,
      newestVersion: number,
      currentVersion?: EnvironmentVersionInfo
    ) => {
      if (
        newestVersion > -1 &&
        index !== 0 &&
        !postLoading &&
        !publicationLoading &&
        sortedEnvs &&
        (isPublished || isArchived) &&
        (!currentVersion || isArchived || newestVersion !== currentVersion.version)
      ) {
        setOpenEnv(id);
        setAnchorEl(event.currentTarget);
        setEnableReleases(
          sortedEnvs
            .slice()
            .reverse()
            .filter(
              (en) =>
                en.priority < env.priority &&
                !!versions.find(
                  (vi) => vi.envId === en.id && vi.version !== currentVersion?.version
                )
            )
            .map((en) => {
              const vi = versions.find((v) => v.envId === en.id);
              return {
                envId: en.id,
                version: vi?.version || 0,
                envName: en.name,
              };
            })
        );
        event.stopPropagation();
      }
    },
    [sortedEnvs, isArchived, isPublished, postLoading, publicationLoading]
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div
      className={clsx(styles.environments, (postLoading || publicationLoading) && styles.loading)}
    >
      {environments &&
        environments.map((env, index) => {
          const diff = publishedVersions?.contentPublicationState.find(
            (ps) => ps.sourceId === contentItem.source_id
          );
          const newestVersion =
            diff?.envVersionInfo.slice().sort((a, b) => b.version - a.version)[0]?.version || -1;
          const currentVersionInfo = diff?.envVersionInfo.find((vi) => vi.envId === env.id);
          return (
            <React.Fragment key={env.id}>
              <div className={styles.environment}>
                <CustomTooltip
                  arrow
                  disableHoverListener={!currentVersionInfo}
                  placement="bottom"
                  title={`Has ${
                    newestVersion === currentVersionInfo?.version ? 'Published' : 'old Published'
                  } version v${currentVersionInfo?.version}`}
                >
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                  <img
                    id={`${contentItem.id}_${env.id}_env`}
                    onClick={(e) =>
                      onClickEnvironment(
                        e,
                        `${contentItem.id}_${env.id}_env`,
                        env,
                        index,
                        diff?.envVersionInfo || [],
                        newestVersion,
                        currentVersionInfo
                      )
                    }
                    width="24"
                    height="24"
                    src={`/assets/${
                      currentVersionInfo
                        ? newestVersion === currentVersionInfo.version &&
                          (isPublished || isArchived)
                          ? 'newest'
                          : 'old'
                        : 'no'
                    }-version.svg`}
                    alt="no version"
                  />
                </CustomTooltip>
                <Menu
                  id="content-env-menu-2"
                  anchorEl={anchorEl}
                  open={
                    openEnv === `${contentItem.id}_${env.id}_env` &&
                    (enableReleases.length > 0 || isArchived)
                  }
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  onClose={handleClose}
                >
                  <MenuItem className={styles.menuLabel} disabled>
                    {env.name}
                  </MenuItem>
                  {isArchived && (
                    <MenuItem
                      onClick={(e) => onDeploy(e, environments[0], env)}
                      className={styles.menuItem}
                    >
                      <span className={styles.primary}>Deploy from {environments[0].name}</span>
                      <span className={styles.archived}>ARCHIVED</span>
                    </MenuItem>
                  )}
                  {removeDuplicates(enableReleases, (b) => b.version)
                    .slice()
                    .reverse()
                    .map((vi) => (
                      <MenuItem
                        onClick={(e) =>
                          onDeploy(
                            e,
                            environments?.find((en) => en.id === vi.envId),
                            env
                          )
                        }
                        key={vi.envId}
                        className={styles.menuItem}
                      >
                        <div>
                          <span className={styles.primary}>Deploy from {vi.envName}</span> v
                          {vi.version}{' '}
                          {newestVersion === vi.version && (
                            <span className={styles.newest}>NEWEST</span>
                          )}
                        </div>
                      </MenuItem>
                    ))}
                </Menu>
                <span className={styles.environmentName}>{env.name}</span>
              </div>
              {index !== environments.length - 1 && (
                <img src="/assets/arrow-right-sm.svg" alt="arrow right" />
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
};
