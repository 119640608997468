import { makeStyles, Theme } from '@material-ui/core/styles';

export const useFormStyles = makeStyles((theme: Theme) => ({
  formItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  label: {
    color: '#545456',
    flex: 1,
    paddingTop: 5,
    marginBottom: '2px',
    fontWeight: 500,
    fontSize: '.8rem',
  },
  input: {
    display: 'flex',
    flexDirection: 'column',
    flex: 4,
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: 500,
    fontSize: '0.8rem',
    marginTop: 2,
  },
  required: {
    color: '#F44336',
  },
}));
