import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  label: {
    color: '#242424',
    margin: '9px 4px 0 0',
  },
});
