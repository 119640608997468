import { EnvironmentItemDto } from '../../../types/EnvironmentItemDto';

export type EnvironmentCreateFormDto = {
  readonly id?: string;
  readonly name: string;
  readonly priority: number;
  readonly source_env_id: string;
};

export const convertModelToForm = (item?: EnvironmentItemDto): EnvironmentCreateFormDto =>
  ({
    id: item?.id,
    name: item?.name,
    priority: item?.priority,
    source_env_id: item?.sourceEnvId,
  } as EnvironmentCreateFormDto);
