import CancelIcon from '@material-ui/icons/Cancel';
import { searchGlobal } from 'api/search-global';
import { GlobalFilter, SearchGlobalField, SearchGlobalType } from 'api/types/SearchGlobal';
import clsx from 'clsx';
import { useDebounce } from 'common/hooks/useDebounce';
import { ButtonPelican } from 'components/ButtonPelican';
import { TablePaginationActions } from 'components/DataGrid/DataGridPagination/DataGridPaginationActions/DataGridPaginationActions';
import { FolderLineOutline, Search } from 'components/IconPelican/icons';
import { SelectItem, SelectPelican } from 'components/SelectPelican';
import { SelectVariant } from 'components/SelectPelican/types/SelectVariant';
import Spin from 'components/Spin';
import { TextInput } from 'components/TextInput';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { getTypes } from 'store/contentSlice';
import { getProjects } from 'store/workspaceSlice';

import { Props } from './props';
import { useStyles } from './styles';

const GlobalSearch: React.FC<Props> = ({ onClose }) => {
  const styles = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const projects = useSelector(getProjects).map(
    (p): SelectItem => ({ label: p.title, value: p.slug })
  );
  const contentTypes = useSelector(getTypes).map(
    (c): SelectItem => ({ label: c.description, value: c.slug })
  );
  const statuses = [
    {
      label: t('Published'),
      value: 'published',
    },
    {
      label: t('Draft'),
      value: 'draft',
    },
    {
      label: t('Reviewing'),
      value: 'reviewing',
    },
  ];
  const [filterQuery, setFilterQuery] = useState<GlobalFilter>({ searchText: '', page: 1 });
  const [searchValue, setSearchValue] = useState<string>('');
  const [isLoadingContent, setLoadingContent] = useState(false);
  const [content, setContent] = useState<readonly SearchGlobalType[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const debouncedSearchTerm = useDebounce(searchValue, 500);

  const onOpenList = useCallback(
    (r: SearchGlobalType) => {
      history.push(`/content/${r.content_type_slug}`);
      onClose();
    },
    [history, onClose]
  );

  const handleSearchGlobal = useCallback(async () => {
    try {
      setLoadingContent(true);
      await searchGlobal(filterQuery).then((value) => {
        setContent(value.list.slice(0, 10));
        setTotalCount(value.totalCount);
        setLoadingContent(false);
      });
    } catch (e) {
      enqueueSnackbar(`${t('Error loading')} ${e.response?.data?.message || e}`, {
        variant: 'error',
      });
      setContent([]);
      setLoadingContent(false);
    }
  }, [enqueueSnackbar, filterQuery, t]);

  const handlePageChange = useCallback((page: number) => {
    setFilterQuery((prev) => ({ ...prev, page }));
  }, []);

  useEffect(() => {
    handleSearchGlobal();
  }, [handleSearchGlobal]);

  useEffect(() => {
    debouncedSearchTerm !== filterQuery.searchText &&
      setFilterQuery((prev) => ({ ...prev, searchText: debouncedSearchTerm, page: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <div className={styles.paper}>
      <div className={styles.container}>
        <TextInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          inputClassName={styles.width100}
          placeholder={t('Global search placeholder')}
          autoFocus
        />
        <CancelIcon htmlColor="#858585" className={styles.clearIcon} onClick={onClose} />
      </div>
      <div className={clsx(styles.container, styles.justifyBetween, styles.mb18)}>
        <div className={styles.filterWidth}>
          <SelectPelican
            id="selectProjects"
            variant={SelectVariant.Text}
            placeholder={t('Projects')}
            isMulti
            isClearable
            options={projects}
            multiButtons={false}
            selected={(val) => {
              setFilterQuery({
                ...filterQuery,
                projects: val ? (val as readonly SelectItem[]).map((v) => v.value).join(',') : '',
              });
            }}
          />
        </div>
        <div className={styles.filterWidth}>
          <SelectPelican
            id="selectContentTypes"
            variant={SelectVariant.Text}
            placeholder={t('Models')}
            isMulti
            isClearable
            options={contentTypes}
            multiButtons={false}
            selected={(val) => {
              setFilterQuery({
                ...filterQuery,
                contenttypes: val
                  ? (val as readonly SelectItem[]).map((v) => v.value).join(',')
                  : '',
              });
            }}
          />
        </div>
        <div className={styles.filterWidth}>
          <SelectPelican
            id="selectStatus"
            variant={SelectVariant.Text}
            placeholder={t('Statuses')}
            isClearable
            options={statuses}
            selected={(val) => {
              setFilterQuery({
                ...filterQuery,
                status: val ? String((val as SelectItem).value) : '',
              });
            }}
          />
        </div>
      </div>
      <div className={styles.hr} />
      {isLoadingContent ? (
        <div className={styles.spin}>
          <Spin variant="inline" size={50} />
        </div>
      ) : (
        <div className={styles.results}>
          {content.length > 0 &&
            content.map((r: SearchGlobalType) =>
              r.fields.length ? (
                r.fields.map((l: SearchGlobalField) => (
                  // eslint-disable-next-line react/jsx-key
                  <div className={styles.searchResult}>
                    <Search />
                    <div className={styles.contentInfo}>
                      <Link
                        onClick={onClose}
                        to={`/content/${r.content_type_slug}/${r.content_id}`}
                        className={styles.contentName}
                      >
                        {r.content_type_name}
                      </Link>
                      <br />
                      <span className={styles.contentResult}>
                        {l.name}: <span dangerouslySetInnerHTML={{ __html: l.highlight }} />
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div key={r.source_id} className={styles.searchResult}>
                  <FolderLineOutline />
                  <div className={styles.contentInfo}>
                    <Link
                      onClick={onClose}
                      to={`/content-types/${r.content_type_slug}`}
                      className={styles.contentName}
                    >
                      {r.content_type_name}
                    </Link>
                  </div>
                  <ButtonPelican
                    onClick={() => onOpenList(r)}
                    className={styles.marginLeftAuto}
                    variant="contained"
                    color="default"
                  >
                    {t('Open content')}
                  </ButtonPelican>
                </div>
              )
            )}
          {content.length === 0 && (
            <div className={styles.container}>
              <p className={styles.empty}>{t('No results')}</p>
            </div>
          )}
        </div>
      )}
      <div className={styles.hr} />
      <div className={styles.pagination}>
        <TablePaginationActions
          inModal
          page={filterQuery.page}
          pageCount={Math.ceil((totalCount || 0) / 10)}
          handleChangePageTablePagination={(_e, page) => handlePageChange(page)}
        />
      </div>
    </div>
  );
};

export default GlobalSearch;
