import request from 'common/utils/axios-request';

import { IntegrationRequestAttribute } from './types/IntegrationRequestAttribute';

export function integrationAttrsGetAll(
  integrationId: number
): Promise<readonly IntegrationRequestAttribute[]> {
  return request('/api/v1/integration-request-attributes', {
    params: { integrationId },
  }).then(({ data }) => data as readonly IntegrationRequestAttribute[]);
}

export function integrationAttrPost(
  data: IntegrationRequestAttribute
): Promise<IntegrationRequestAttribute> {
  return request('/api/v1/integration-request-attributes', {
    method: 'POST',
    data,
  }).then(({ data }) => data as IntegrationRequestAttribute);
}

export function integrationAttrPut(
  data: IntegrationRequestAttribute
): Promise<IntegrationRequestAttribute> {
  return request('/api/v1/integration-request-attributes', {
    method: 'PUT',
    data,
  }).then(({ data }) => data as IntegrationRequestAttribute);
}

export function integrationAttrDelete(id: number): Promise<unknown> {
  return request(`/api/v1/integration-request-attributes/${id}`, {
    method: 'DELETE',
  });
}
