import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const ArrowLeftOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.692 5.46a.75.75 0 0 1 0 1.062l-5.482 5.477 5.482 5.48a.75.75 0 0 1-1.06 1.06L8.088 12 14.63 5.46a.75.75 0 0 1 1.06 0Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ArrowLeftOutline;
