import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const SortUpOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.172 3.25a.75.75 0 000 1.5h9.5a.75.75 0 000-1.5h-9.5zM3.422 8a.75.75 0 01.75-.75h8a.75.75 0 010 1.5h-8a.75.75 0 01-.75-.75zm.75 3.25a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5zm12.126 1.026l.53-.53.53.53 3 3a.75.75 0 01-1.06 1.061l-1.72-1.72V20a.75.75 0 01-1.5 0v-5.383l-1.72 1.72a.75.75 0 11-1.06-1.06l3-3zM4.172 19.25a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5zM3.422 16a.75.75 0 01.75-.75h5a.75.75 0 010 1.5h-5a.75.75 0 01-.75-.75z"
      fill="#000"
    />
  </SvgIcon>
);
export default SortUpOutline;
