import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  form: {
    marginBottom: 24,
  },
  formFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 8,
    '& button': {
      marginLeft: 12,
    },
  },
});
