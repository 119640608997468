import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const AddSquareOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.83 4.5h8.34c.88 0 1.447.001 1.88.037.413.034.566.093.642.132a1.5 1.5 0 01.64.64c.038.075.096.228.13.642.037.432.038 1 .038 1.879v8.34c0 .88-.001 1.447-.037 1.88-.035.413-.093.566-.132.642a1.5 1.5 0 01-.64.64c-.075.038-.228.096-.642.13-.432.037-1 .038-1.879.038H7.83c-.88 0-1.447-.001-1.88-.037-.413-.035-.566-.093-.641-.132a1.5 1.5 0 01-.64-.64c-.04-.075-.098-.228-.132-.642-.036-.432-.037-1-.037-1.879V7.83c0-.88.001-1.447.037-1.88.034-.413.093-.566.132-.641a1.5 1.5 0 01.64-.64c.075-.04.228-.098.642-.132.432-.036 1-.037 1.879-.037zM3 7.83c0-1.708 0-2.563.338-3.213a3 3 0 011.28-1.28C5.267 3 6.121 3 7.83 3h8.34c1.708 0 2.563 0 3.213.338a3 3 0 011.28 1.28C21 5.267 21 6.121 21 7.83v8.34c0 1.708 0 2.563-.338 3.213a2.999 2.999 0 01-1.28 1.28c-.65.337-1.504.337-3.212.337H7.83c-1.708 0-2.563 0-3.213-.338a3 3 0 01-1.28-1.28C3 18.733 3 17.879 3 16.17V7.83zm9.75.17a.75.75 0 00-1.5 0v3.25H8a.75.75 0 000 1.5h3.25V16a.75.75 0 001.5 0v-3.25H16a.75.75 0 000-1.5h-3.25V8z"
      fill="#000"
    />
  </SvgIcon>
);
export default AddSquareOutline;
