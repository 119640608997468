import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const DoorOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 5.578l8-2.105V20.25h-8V5.578zm-1.5-.193V20.25H2.5a.75.75 0 000 1.5h11c.69 0 1.25-.56 1.25-1.25V5.75h3.5V20.5c0 .69.56 1.25 1.25 1.25H21a.75.75 0 000-1.5h-1.25V5.5c0-.69-.56-1.25-1.25-1.25h-3.75V3.149a1.25 1.25 0 00-1.568-1.21l-8.5 2.238a1.25 1.25 0 00-.932 1.208zm6.96 7.977a1 1 0 100-2 1 1 0 000 2z"
    />
  </SvgIcon>
);
export default DoorOutline;
