import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  overflow: {
    maxHeight: '100vh',
    overflowY: 'auto',
  },
  table: {
    marginTop: 24,
    '& td': {
      padding: 12,
    },
    '& td:first-child': {
      width: 44,
      padding: '2px 0',
    },
  },
  tableCell: {
    color: '#161618',
    fontSize: 14,
    lineHeight: '20px',
    textTransform: 'capitalize',
  },
  fileName: {
    width: 330,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  previewContainer: {
    display: 'flex',
    width: 44,
    height: 44,
    justifyContent: 'center',
  },
  preview: {
    maxWidth: 44,
    maxHeight: 44,
  },
  editIcon: {
    marginLeft: 16,
  },
  hidden: {
    position: 'absolute',
    zIndex: -1,
    opacity: 0,
  },
  center: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  disabled: {
    opacity: 0.5,
    cursor: 'default',
  },
  actionButtonsCell: {
    width: 100,
  },
  spin: {
    position: 'relative',
    width: 50,
    height: 50,
  },
  label: {
    marginBottom: 8,
  },
  uploadContainer: {
    padding: '40px 0',
  },
  progressBar: {
    height: 10,
    background: theme.palette.blue[500],
    borderRadius: 10,
    marginBottom: 4,
  },
}));
