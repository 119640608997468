import { SvgIcon, SvgIconProps } from '@material-ui/core';
import * as React from 'react';

export const ArrowRight: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m19.528 12.519-3.53 3.53a.75.75 0 1 1-1.061-1.06l1.72-1.72H5.221a.75.75 0 0 1 0-1.5h11.434l-1.72-1.72a.75.75 0 0 1 1.061-1.06l3.53 3.53Z"
      fill="currentColor"
    />
  </SvgIcon>
);

export default ArrowRight;
