import { makeStyles } from '@material-ui/styles';
import { ArrowLeftOutline } from 'components/IconPelican/icons';
import LinkButton from 'components/LinkButton/LinkButton';
import { useHistory } from 'react-router';

import { Props } from './props';

const useStyles = makeStyles(() => ({
  root: {
    color: '#000',
  },
  wrapper: {
    padding: 0,
    margin: 0,
    marginLeft: -8,
    width: 24,
    height: 24,
  },
}));
export const BackButton: React.FC<Props> = ({ handleClick }) => {
  const styles = useStyles();
  const router = useHistory();
  const handleBack = (): void => {
    router.goBack();
  };
  return (
    <LinkButton className={styles.wrapper}>
      <ArrowLeftOutline
        cursor="pointer"
        className={styles.root}
        onClick={handleClick || handleBack}
      />
    </LinkButton>
  );
};

export default BackButton;
