import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  draggable: {
    userSelect: 'auto',
  },
  root: {
    display: 'flex',
    height: '44px',
    alignItems: 'center',
  },
  icon: {
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    border: '2px solid #D3D4D6',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fill: '#a6a6a6',
    background: '#F7F8FA',
  },
  description: {
    flex: 1,
    marginLeft: '10px',
  },
  arrowDown: {
    width: '44px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sortButton: {
    width: 60,
  },
  rotate: {
    transform: 'rotate(90deg)',
  },
});
