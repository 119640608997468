import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  canvas: {
    width: '100%',
  },
  propertiesPanel: {
    width: 400,
  },
  footer: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    bottom: 20,
    left: 20,
    right: 400,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 20px',
    background: '#F7F8FA',
    boxShadow: '0px 0px 1px rgba(26, 32, 36, 0.32), 0px 4px 8px rgba(91, 104, 113, 0.24)',
    borderRadius: 8,
  },
}));
