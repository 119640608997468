import { makeStyles, Theme } from '@material-ui/core/styles';
import { StylesConfig } from 'react-select';

import { Props } from './props';
import { SelectVariant } from './types/SelectVariant';

export const useStyles = makeStyles<Theme, { readonly row: number }>({
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  root: {},
  showHideBtn: {
    color: '#0052CC',
  },
  label: {
    color: '#545456',
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '16px',
    textTransform: 'uppercase',
    marginTop: 7,
  },
});

export const getReactSelectStyles = ({
  isMulti,
  row,
  variant,
  size,
  theme,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
Props): StylesConfig<any, false> => {
  return {
    container: (base) => ({
      ...base,
      // height: '28px',
    }),
    multiValueLabel: (base) => ({
      ...base,
    }),
    multiValueRemove: (base) => ({
      ...base,
      background: '#8F8F91',
      borderRadius: '50%',
      color: '#fff',
      cursor: 'pointer',
      width: 20,
      height: 20,
      '&:hover': {
        color: '#ffe',
        background: '#545456',
      },
      '&>svg': {
        transform: 'scale(1.4)',
      },
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      color: '#000',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999,
      color: '#000',
    }),
    control: (base) => ({
      ...base,
      display: 'flex',
      alignItems: 'center',
      height: size === 'medium' ? (isMulti ? 'auto' : 32) : 28,
      minHeight: size === 'medium' ? 32 : 28,
      ...(variant === undefined || variant === SelectVariant.Default
        ? {
            background: '#F7F8FA',
            borderRadius: '4px',
            border: '1px solid #D3D4D6',
          }
        : null),
      ...(variant === SelectVariant.Text
        ? {
            background: 'transparent',
            border: 'none',
          }
        : null),
      ...(isMulti
        ? {
            maxHeight: row * 33 * 3,
            overflowY: 'auto',
            transition: 'max-height linear 0.2s',
          }
        : null),
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: '100%',
      minHeight: size === 'medium' ? 30 : 26,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: theme === 'dark' ? '#fff' : '#000',
      padding: size === 'medium' ? '0 4px' : '0 2px',
      '&:hover': {
        color: theme === 'dark' ? '#fff' : '#000',
      },
    }),
    clearIndicator: (base) => ({
      ...base,
      background: '#8F8F91',
      borderRadius: '50%',
      color: '#fff',
      cursor: 'pointer',
      width: 20,
      height: 20,
      padding: 0,
      fontWeight: 'normal',
      '&:hover': {
        color: '#ffe',
        background: '#545456',
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none',
    }),
    singleValue: (base) => ({
      ...base,
      color: theme === 'dark' ? '#fff' : '#000',
    }),
    multiValue: (base) => ({
      ...base,
      border: '1px solid #D3D4D6',
      paddingRight: '2px',
      borderRadius: 4,
      alignItems: 'center',
      margin: '1px 2px',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: '1px 8px',
    }),
    option: (base) => ({
      ...base,
    }),
    placeholder: (base) => ({
      ...base,
      color: '#8F8F91',
    }),
  };
};
