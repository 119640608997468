import { Modal, Typography } from '@material-ui/core';
import { ContentItem } from 'api/types/ContentItem';
import { ContentTypeField } from 'api/types/ContentTypeField';
import clsx from 'clsx';
import { statusChipMapping, StatusChipType } from 'common/constants/statuses';
import { getParsedDate } from 'common/utils/date';
import { BooleanField } from 'components/BooleanField';
import { ButtonPelican } from 'components/ButtonPelican';
import Chip from 'components/Chip/Chip';
import { ColourInput } from 'components/ColourInput/component';
import { Datepicker } from 'components/Datepicker';
import { DateTimepicker } from 'components/DateTimepicker';
import { PlanetOutline } from 'components/IconPelican/icons';
import RadioPelican from 'components/RadioPelican';
import { SelectPelican } from 'components/SelectPelican';
import { SwitchField } from 'components/SwitchField';
import { TextInput } from 'components/TextInput';
import { Timepicker } from 'components/Timepicker';
import { VideoUpload } from 'components/VideoUpload';
import { FormField } from 'containers/FormField';
import { RichTextContent } from 'containers/RichTextContent';
import YandexMap from 'containers/YandexMap/component';
import { EmbeddedCheckedType } from 'pages/CompareVersion/CompareVersions/props';
import { useStyles } from 'pages/CompareVersion/CompareVersions/styles';
import { ContentEmbeddedItem } from 'pages/ContentEditorPage/ContentEditor/ContentFormFields/ContentEmbeddedInput/ContentEmbeddedItem';
import { parseItemsToOptions } from 'pages/ContentEditorPage/ContentEditor/helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useStyles as useFieldStyles } from 'theme/content-styles';

import { getCurrentAndCompareItems, getMultilingualSlugs, notNullValue } from '../helpers';
import { Props } from './props';

const VersionFields: React.FC<Props> = ({
  contentItem,
  compareItem,
  fields,
  onlyDiffs,
  onSelectField,
  onDeselectField,
  isCompare = false,
  locales,
  handleEmbedCompare,
  checkedContent,
  itemIndex,
  embedLevel,
  onlyReview,
  embedFields,
  handleOnlyDiffs,
  handleSelectAll,
  selectAllCurrent,
  environmentCompare,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const styles = useFieldStyles();
  const [fieldHeights, setFieldHeights] = useState<Record<string, number>>({});
  const [richtextInfo, setRichtextInfo] = useState<string>();
  const showContentItem = isCompare ? compareItem : contentItem;
  const { params } = useRouteMatch<{
    readonly type: string;
    readonly contentId: string;
    readonly versionId: string;
    readonly controlType: string;
  }>();

  const showField = (f: ContentTypeField): boolean => {
    return !f.multilingual
      ? f.type === 'REFERENCE' && !f.embedded
        ? contentItem[f.slug]?.items?.length > 0 || compareItem[f.slug]?.items?.length > 0
        : f.type === 'REFERENCE' && f.embedded
        ? contentItem[f.slug]?.length > 0 || compareItem[f.slug]?.length > 0
        : (contentItem[f.slug] !== undefined && contentItem[f.slug] !== '') ||
          (compareItem[f.slug] !== undefined && compareItem[f.slug] !== '')
      : locales.some(
          (l) =>
            !!contentItem['$.multilingual']?.[l]?.[f.slug] ||
            !!compareItem['$.multilingual']?.[l]?.[f.slug]
        );
  };

  const showOnlyDiffs = (f: ContentTypeField): boolean => {
    if (f.multilingual) {
      return locales.some(
        (l) =>
          contentItem['$.multilingual']?.[l]?.[f.slug] !==
          compareItem['$.multilingual']?.[l]?.[f.slug]
      );
    } else if (f.type === 'REFERENCE') {
      if (f.embedded) {
        return (
          !onlyReview ||
          !!(
            contentItem.changedFields?.includes(f.slug) ||
            contentItem.childChangedFields?.includes(f.slug)
          )
        );
      } else {
        const currents = contentItem[f.slug]?.items || [];
        const compares = compareItem[f.slug]?.items || [];
        const currentsSet = new Set(currents.map(({ id }: ContentItem) => id));
        return (
          currents.length !== compares.length ||
          !compares.every(({ id }: ContentItem) => currentsSet.has(id))
        );
      }
    } else if (f.type === 'BOOLEAN' && (f.defaultValue === 'true' || f.defaultValue === 'false')) {
      if (contentItem[f.slug] !== undefined && compareItem[f.slug] !== undefined) {
        return false;
      } else {
        return (
          JSON.parse(f.defaultValue) !==
          (contentItem[f.slug] !== undefined ? contentItem[f.slug] : compareItem[f.slug])
        );
      }
    }
    return contentItem[f.slug] !== compareItem[f.slug];
  };

  const isChecked = useCallback(
    (field: ContentTypeField): boolean | undefined => {
      if (field.embedded) {
        return !isCompare
          ? (checkedContent?.[field.slug] as EmbeddedCheckedType)?.checkedCurrent
          : (checkedContent?.[field.slug] as EmbeddedCheckedType)?.checkedCompare;
      } else {
        return (
          (!isCompare && (checkedContent?.[field.slug] as boolean)) ||
          (isCompare && checkedContent?.[field.slug] === false)
        );
      }
    },
    [checkedContent, isCompare]
  );

  const handleClickRadio = useCallback(
    (field: ContentTypeField): void => {
      const currentKey = isCompare ? 'checkedCompare' : 'checkedCurrent';
      const compareKey = isCompare ? 'checkedCurrent' : 'checkedCompare';
      if (field.embedded) {
        if (
          (checkedContent[field.slug] as EmbeddedCheckedType)?.[currentKey] &&
          !(checkedContent[field.slug] as EmbeddedCheckedType)?.[compareKey]
        ) {
          onDeselectField(field);
        } else {
          onSelectField(field, !isCompare, contentItem.source_id || compareItem.source_id || 0);
        }
      } else {
        if (
          (checkedContent[field.slug] && !isCompare) ||
          (checkedContent[field.slug] === false && isCompare)
        ) {
          onDeselectField(field);
        } else {
          onSelectField(field, !isCompare, contentItem.source_id || compareItem.source_id || 0);
        }
      }
    },
    [
      checkedContent,
      compareItem.source_id,
      contentItem.source_id,
      isCompare,
      onDeselectField,
      onSelectField,
    ]
  );

  const showContentValue = (field: ContentTypeField, content: ContentItem): boolean => {
    return !field.multilingual
      ? field.type === 'REFERENCE' && !field.embedded
        ? content[field.slug]?.items?.length > 0
        : field.type === 'REFERENCE' && field.embedded
        ? content[field.slug]?.length > 0
        : content[field.slug] !== undefined
      : locales.some((l) => !!content['$.multilingual']?.[l]?.[field.slug]);
  };

  const embeddedAmount = useCallback(
    (field: ContentTypeField): number => {
      return (checkedContent[field.slug] as EmbeddedCheckedType).items.reduce(
        (a, b) =>
          a +
          Object.values(b).filter((el) =>
            el === true || el === false
              ? el === !isCompare
              : isCompare
              ? (el as EmbeddedCheckedType).checkedCompare
              : (el as EmbeddedCheckedType).checkedCurrent
          ).length,
        0
      );
    },
    [checkedContent, isCompare]
  );

  const embedHasChanges = (field: ContentTypeField, item: ContentItem, i: number): boolean => {
    const comparingItem = ((isCompare ? contentItem : compareItem)[field.slug]?.[i] ||
      {}) as ContentItem;
    return !!(
      !item.id ||
      item.hasChanged ||
      item.hasChangedChild ||
      !comparingItem.id ||
      comparingItem.hasChanged ||
      comparingItem.hasChangedChild
    );
  };

  const allEmbedIsChecked = (field: ContentTypeField): boolean => {
    if (!checkedContent[field.slug]) {
      return true;
    } else {
      const currentItems = contentItem[field.slug]?.items || [];
      const compareItems = compareItem[field.slug]?.items || [];
      const currents = getCurrentAndCompareItems(currentItems, compareItems, !isCompare);
      const compares = getCurrentAndCompareItems(currentItems, compareItems, isCompare);
      return ![
        ...currents,
        ...new Array(
          compares.length > currents.length ? compares.length - currents.length : 0
        ).fill({}),
      ].find((c: ContentItem, i: number) => {
        const vals1 = Object.values(
          (checkedContent[field.slug] as EmbeddedCheckedType)?.items?.[i] || {}
        );
        const objSlugs = Object.keys({ ...compares[i], ...c }).filter((k) =>
          notNullValue(c, compares[i] || {}, k)
        );
        const multiSlugs = getMultilingualSlugs(
          c['$.multilingual'],
          compares[i]?.['$.multilingual'],
          locales
        );
        const vals2 = new Set([...objSlugs, ...Array.from(multiSlugs)]);
        return (
          vals1.length !== vals2.size ||
          (vals1.includes(true) && vals1.includes(false)) ||
          vals1
            .filter((val) => val !== true && val !== false)
            .find(
              (emb) =>
                (emb as EmbeddedCheckedType).checkedCurrent !==
                  (checkedContent[field.slug] as EmbeddedCheckedType).checkedCurrent ||
                (emb as EmbeddedCheckedType).checkedCompare !==
                  (checkedContent[field.slug] as EmbeddedCheckedType).checkedCompare
            )
        );
      });
    }
  };

  useEffect(() => {
    if (Object.keys(fieldHeights).length === 0) {
      setTimeout(() => {
        const newHeights: Record<string, number> = {};
        fields.forEach((f) => {
          const current = document.getElementById(f.slug + '_current' + itemIndex + embedLevel);
          const compare = document.getElementById(f.slug + '_compare' + itemIndex + embedLevel);
          if (current && compare) {
            newHeights[f.slug] =
              current.offsetHeight >= compare.offsetHeight
                ? current.offsetHeight
                : compare.offsetHeight;
          } else {
            newHeights[f.slug] = 0;
          }
        });
        setFieldHeights(newHeights);
      }, 100);
    }
  }, [fields, contentItem, itemIndex, embedLevel, fieldHeights]);

  return (
    <div>
      {(!onlyDiffs ||
        itemIndex === 0 ||
        fields.filter((f) => showField(f) && showOnlyDiffs(f)).length > 0) && (
        <div className={clsx(classes.container, classes.versionsHeader)}>
          <div>
            {showContentItem?.content_state?.code && (
              <Chip
                variant={
                  statusChipMapping[
                    (showContentItem?.content_state?.code as keyof StatusChipType) || 'draft'
                  ] as 'success' | 'error' | 'primary' | 'disabled' | 'default'
                }
              >
                {showContentItem?.content_state?.code}
              </Chip>
            )}{' '}
            ID: {showContentItem?.id || '-'}
          </div>
          {itemIndex === 0 && !isCompare && (
            <SwitchField
              id="only difference"
              label={t('Show only differences')}
              handleChange={(checked: boolean) => handleOnlyDiffs && handleOnlyDiffs(checked)}
            />
          )}
        </div>
      )}
      {!environmentCompare && !onlyReview && itemIndex === 0 && (
        <div
          className={clsx(classes.container, classes.flex, classes.alignCenter, classes.selectAll)}
        >
          <RadioPelican
            checked={selectAllCurrent === !isCompare}
            background="transparent"
            color="primary"
            name="all-checked"
            disabled={params.controlType === 'restore'}
            onClick={() => handleSelectAll(!isCompare)}
            label={t('Select all')}
          />
        </div>
      )}
      {/* {embedLevel > -1 && <div className={classes.contentId}>ID: {showContentItem.id || '-'}</div>}*/}
      {fields
        .filter((f) => (!onlyDiffs || showOnlyDiffs(f)) && showField(f))
        .map((field, index) =>
          showContentValue(field, isCompare ? compareItem : contentItem) ? (
            <div
              key={index}
              id={field.slug + (!isCompare ? '_current' : '_compare') + itemIndex + embedLevel}
              style={{ height: fieldHeights[field.slug] }}
              className={clsx(
                classes.flex,
                classes.container,
                classes.field,
                showOnlyDiffs(field) && !field.embedded && onlyReview && classes.different,
                isChecked(field) && classes.checkedField
              )}
            >
              {!onlyReview && (
                <div className={classes.radioContainer}>
                  <RadioPelican
                    name={field.slug + '_field'}
                    color="primary"
                    disabled={params.controlType === 'restore' && !isCompare}
                    checked={isChecked(field) || false}
                    onClick={() => handleClickRadio(field)}
                    background="transparent"
                    label={''}
                  />
                </div>
              )}
              <FormField comparable title={field.label} help={field.description}>
                {(field.type === 'TEXT' || field.type === 'RICHTEXT' || field.type === 'LINK') &&
                  (field.type === 'RICHTEXT' ? (
                    <div>
                      {!field.multilingual ? (
                        <div>
                          <ButtonPelican
                            onClick={() => setRichtextInfo(field.slug)}
                            className={styles.editorButton}
                            variant="contained"
                          >
                            {t('Open in editor')}
                          </ButtonPelican>
                          <TextInput readOnly defaultValue={showContentItem[field.slug]} />
                        </div>
                      ) : locales.length > 0 ? (
                        locales.map(
                          (l) =>
                            showContentItem['$.multilingual']?.[l]?.[field.slug] && (
                              <div key={l} className={styles.multilangual}>
                                <div className={styles.locale}>
                                  <PlanetOutline fontSize="small" htmlColor="#0052CC" />
                                  <span>{l}</span>
                                  <ButtonPelican
                                    className={styles.editorButton}
                                    variant="contained"
                                    onClick={() => setRichtextInfo(`${l}$${field.slug}`)}
                                  >
                                    {t('Open in editor')}
                                  </ButtonPelican>
                                </div>
                                <TextInput
                                  readOnly
                                  defaultValue={showContentItem['$.multilingual'][l][field.slug]}
                                />
                              </div>
                            )
                        )
                      ) : (
                        <Typography variant="h4" className={styles.emptyLocale}>
                          {t('Choose locales')}
                        </Typography>
                      )}
                    </div>
                  ) : !field.multilingual ? (
                    <TextInput multiline defaultValue={showContentItem[field.slug]} readOnly />
                  ) : locales.length > 0 ? (
                    locales.map(
                      (l) =>
                        showContentItem['$.multilingual']?.[l]?.[field.slug] && (
                          <div key={l} className={styles.multilangual}>
                            <div className={styles.locale}>
                              <PlanetOutline fontSize="small" htmlColor="#0052CC" />
                              <span>{l}</span>
                            </div>
                            <TextInput
                              multiline
                              defaultValue={showContentItem['$.multilingual'][l][field.slug]}
                              readOnly
                            />
                          </div>
                        )
                    )
                  ) : (
                    <Typography variant="h4" className={styles.emptyLocale}>
                      {t('Choose locales')}
                    </Typography>
                  ))}
                {field.type === 'SLUG' && (
                  <TextInput value={showContentItem[field.slug]} readOnly />
                )}
                {field.type === 'INTEGER' && (
                  <TextInput type="number" defaultValue={showContentItem[field.slug]} readOnly />
                )}
                {field.type === 'AMOUNT' && (
                  <TextInput type="number" defaultValue={showContentItem[field.slug]} readOnly />
                )}
                {field.type === 'BOOLEAN' && (
                  <>
                    {field.defaultValue && (
                      <SwitchField
                        id={'boolean value' + index + isCompare.toString()}
                        label={field.label}
                        initChecked={
                          showContentItem[field.slug]
                            ? showContentItem[field.slug].toString()
                            : 'false'
                        }
                        disabled
                      />
                    )}
                    {!field.defaultValue && (
                      <BooleanField
                        disabled
                        initValue={(showContentItem[field.slug] || '').toString()}
                      />
                    )}
                  </>
                )}
                {field.type === 'DATE' && (
                  <Datepicker readOnly date={getParsedDate(showContentItem[field.slug])} />
                )}
                {field.type === 'DATETIME' && (
                  <DateTimepicker datetime={getParsedDate(showContentItem[field.slug])} readOnly />
                )}
                {field.type === 'GEOPOINT' && (
                  <YandexMap disabled defaultValue={showContentItem[field.slug]} />
                )}
                {field.type === 'TIME' && (
                  <Timepicker
                    readOnly
                    time={new Date(`1970-01-01T${showContentItem[field.slug]}.000+0000`)}
                  />
                )}
                {field.type === 'COLOUR' && (
                  <ColourInput disabled color={showContentItem[field.slug]} />
                )}
                {(field.type === 'IMAGE' ||
                  field.type === 'VIDEO' ||
                  field.type === 'AUDIO' ||
                  field.type === 'DOCUMENT' ||
                  field.type === 'ICON') &&
                  (!field.multilingual ? (
                    <VideoUpload
                      uploadType={
                        field.type === 'ICON'
                          ? 'image'
                          : (field.type?.toLowerCase() as 'video' | 'document' | 'image' | 'audio')
                      }
                      slug={field.slug}
                      link={showContentItem[field.slug]}
                      disabled
                    />
                  ) : locales.length > 0 ? (
                    locales.map(
                      (l) =>
                        showContentItem['$.multilingual']?.[l]?.[field.slug] && (
                          <div key={l} className={styles.multilangual}>
                            <div className={styles.locale}>
                              <PlanetOutline fontSize="small" htmlColor="#0052CC" />
                              <span>{l}</span>
                            </div>
                            <VideoUpload
                              uploadType={
                                field.type === 'ICON'
                                  ? 'image'
                                  : (field.type?.toLowerCase() as
                                      | 'video'
                                      | 'document'
                                      | 'image'
                                      | 'audio')
                              }
                              link={showContentItem['$.multilingual'][l][field.slug]}
                              disabled
                            />
                          </div>
                        )
                    )
                  ) : (
                    <Typography variant="h4" className={styles.emptyLocale}>
                      {t('Choose locales')}
                    </Typography>
                  ))}
                {/* TODO: implement reference field*/}
                {field.type === 'REFERENCE' &&
                  Object.keys(showContentItem).length !== 0 &&
                  contentItem.constructor === Object &&
                  !field.embedded && (
                    <div style={{ maxWidth: '90%' }}>
                      <SelectPelican
                        inputProps={{ readOnly: true }}
                        id="referenceField"
                        name="reference"
                        placeholder=""
                        isMulti={field.list}
                        value={parseItemsToOptions(
                          showContentItem[field.slug] ? showContentItem[field.slug].items : []
                        )}
                        isDisabled
                        options={parseItemsToOptions(field.referableObjects || [])}
                        isClearable={false}
                      />
                    </div>
                  )}
                {field.type === 'REFERENCE' &&
                  field.embedded &&
                  (onlyReview ? (
                    <div>
                      {showContentItem[field.slug]?.map((item: ContentItem, i: number) => (
                        <ContentEmbeddedItem
                          disabled
                          onlyDiff={onlyDiffs}
                          isCompare={isCompare}
                          index={i}
                          item={item}
                          isList={!!field.list}
                          parentSlug={params.type}
                          embedLevel={embedLevel + 1}
                          fieldSlugInParent={field.slug}
                          fields={embedFields[field.slug] || []}
                          contentTypeSlug={field.referencedContentType || ''}
                          key={(item.id as number) || (item.__uniqueKey as string)}
                          handleShowChanges={() =>
                            handleEmbedCompare &&
                            handleEmbedCompare(
                              field,
                              itemIndex,
                              contentItem.source_id || compareItem.source_id || 0,
                              item.source_id ||
                                (isCompare ? contentItem : compareItem)[field.slug]?.[i]
                                  ?.source_id ||
                                0
                            )
                          }
                          hasChanges={embedHasChanges(field, item, i)}
                        />
                      ))}
                    </div>
                  ) : (
                    <ButtonPelican
                      onClick={() =>
                        handleEmbedCompare &&
                        handleEmbedCompare(
                          field,
                          itemIndex || 0,
                          contentItem.source_id || compareItem.sourceId || 0
                        )
                      }
                    >
                      {t('Show changes')}
                      {!allEmbedIsChecked(field) && embeddedAmount(field) > 0 && (
                        <span style={{ color: '#0052CC' }}>({embeddedAmount(field)})</span>
                      )}
                    </ButtonPelican>
                  ))}
                {field.type === 'GEOPOINT' && <span>{showContentItem[field.slug]}</span>}
              </FormField>
            </div>
          ) : (
            <div
              id={field.slug + (isCompare ? '_compare' : '_current') + itemIndex + embedLevel}
              style={{ height: fieldHeights[field.slug] }}
              className={clsx(
                classes.flex,
                classes.container,
                classes.field,
                classes.different,
                isChecked(field) && classes.checkedField
              )}
            >
              {!onlyReview && (
                <div className={classes.radioContainer}>
                  <RadioPelican
                    label={''}
                    background="transparent"
                    name={field.slug + '_field'}
                    color="primary"
                    checked={isChecked(field) || false}
                    onClick={() => handleClickRadio(field)}
                  />
                </div>
              )}
              <FormField comparable title={field.label}>
                {field.type === 'REFERENCE' && field.embedded && (
                  <ButtonPelican
                    onClick={() =>
                      handleEmbedCompare &&
                      handleEmbedCompare(
                        field,
                        itemIndex,
                        contentItem.source_id || compareItem.source_id || 0
                      )
                    }
                  >
                    {t('Show changes (no values)')}
                  </ButtonPelican>
                )}
                {(field.type !== 'REFERENCE' || !field.embedded) && (
                  <span className={classes.emptyValue}>-</span>
                )}
              </FormField>
            </div>
          )
        )}
      {richtextInfo && (
        <Modal
          open
          onClose={() => setRichtextInfo(undefined)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <RichTextContent
            id={`${embedLevel}`}
            selector={'textarea3'}
            onCancel={() => setRichtextInfo(undefined)}
            initialValue={
              richtextInfo.includes('$')
                ? showContentItem['$.multilingual'][richtextInfo.split('$')[0]][
                    richtextInfo.split('$')[1]
                  ]
                : showContentItem[richtextInfo]
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default VersionFields;
