import {
  ContentItem as ContentItemType,
  PatchDataType,
  SelectContentItem,
  TreePatchInfo,
} from 'api/types/ContentItem';
import { Language } from 'api/types/Language';
import useToggle from 'common/hooks/useToggle';
import { SelectItem } from 'components/SelectPelican';
import Spin from 'components/Spin';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UseFormMethods } from 'react-hook-form/dist/types/form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { setCurrentVersion, setNewVersion } from 'store/versionSlice';
import { getProjects, getWorkspaces } from 'store/workspaceSlice';

import { transformFromApi } from './helpers';
import { Props } from './props';
import { ContentEditorState, FieldsValidType } from './types/ContentEditorState';

export const ContentEditorContext = React.createContext<ContentEditorState>(
  {} as ContentEditorState
);

ContentEditorContext.displayName = 'ContentEditorProvider';

export const ContentEditorProvider: React.FC<Props> = ({
  contentItem,
  multilingualInitialValue,
  embedLevel,
  contentTypeSlug,
  children,
  isEmbeddedItem,
  isNewItem,
  onCloseButtonClick,
  fields,
  groupFields,
  groups,
  onEmbeddedFieldSave,
  contentItemId,
  reloadContentItem,
  parentId,
  parentSLug,
  params,
  fieldSlugInParent,
  mainSourceId,
  rootUpdateInfo,
  inTreeView,
  rootProjectId,
}) => {
  const [localContentItem, setLocalContentItem] = useState<ContentItemType>(contentItem);
  const [localContentTypeSlug] = useState(contentTypeSlug);

  const form: UseFormMethods<ContentItemType> = useForm<ContentItemType>({
    defaultValues: transformFromApi(fields, contentItem),
    reValidateMode: 'onBlur',
  });

  const ownerProject = useSelector(getProjects).find((p) => contentItem.project_id === p.id);
  const workspaces = useSelector(getWorkspaces);
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);
  const [selectedLocales, setSelectedLocales] = useState<ReadonlyArray<Language>>([]);
  const [selectedCheckedLocales, setSelectedCheckedLocales] = useState<ReadonlyArray<Language>>([]);
  const [showOnlyMultilingual, setShowOnlyMultilingual] = useState<boolean>(false);
  const [multilingual, setMultilingual] = useState<{ readonly [key: string]: ContentItemType }>(
    multilingualInitialValue
  );
  const [selectedProjects, setSelectedProjects] = useState<string | undefined>(
    contentItem.projects || workspaces.map((w) => w.workspaceSlug).join(';')
  );
  const [selectedProjectOwner, setSelectedProjectOwner] = useState<SelectItem>({
    label: ownerProject?.title || workspaces[0].workspaceName || '',
    value: ownerProject?.id || workspaces[0].workspaceId || 1,
  });
  const [toggledBar, setToggledBar] = useToggle(false, 'rightBarOn');
  const [restRequires, setRestRequires] = useState<readonly string[]>([]);
  const [lengthValidates, setLengthValidates] = useState<readonly string[]>([]);
  const [contentChanged, setContentChanged] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [isTreeView, setTreeView] = useState(false);
  const [treeUpdateInfo, setTreeUpdateInfo] = useState<ReadonlyArray<TreePatchInfo>>([]);
  const [changedFields, setChangedFields] = useState<ReadonlyArray<string>>([]);
  const [projectsChanged, setProjectsChanged] = useState(false);
  const [updateInfo, setUpdateInfo] = useState<PatchDataType>(rootUpdateInfo || {});
  const [loadedReferable, setLoadedReferable] = useState<
    Record<string, ReadonlyArray<SelectContentItem>>
  >({});
  const [openEmbeddedInfo, setOpenEmbeddedInfo] = useState<{
    readonly slug: string;
    readonly id: number;
  }>();

  const validateFormOutsideValues = useCallback((): FieldsValidType => {
    const emptyVal: ContentItemType = {};
    const lengthVal: ContentItemType = {};
    const formValues = form.getValues();

    if (fields) {
      fields
        .filter((f) => f.required && (f.multilingual || f.type === 'REFERENCE'))
        .forEach((f) => {
          if (
            f.type === 'REFERENCE' &&
            (!formValues[f.slug] ||
              (f.embedded
                ? formValues[f.slug].length === 0
                : formValues[f.slug].items.length === 0))
          ) {
            emptyVal[f.slug] = true;
          }

          if (
            f.multilingual &&
            !Object.values(multilingual)
              .map((el) => !!el[f.slug])
              .includes(true)
          ) {
            emptyVal[f.slug] = true;
          }
        });
      setRestRequires(Object.keys(emptyVal));

      fields
        .filter((f) => f.multilingual && (f.minLength || f.maxLength))
        .forEach((f) => {
          if (
            f.minLength &&
            Object.values(multilingual)
              .map((el) => f.minLength && el[f.slug] && (el[f.slug] as string).length > f.minLength)
              .includes(false)
          ) {
            lengthVal[f.slug] = true;
          }
          if (
            f.maxLength &&
            Object.values(multilingual)
              .map((el) => f.maxLength && el[f.slug] && (el[f.slug] as string).length < f.maxLength)
              .includes(false)
          ) {
            lengthVal[f.slug] = true;
          }
        });
      setLengthValidates(Object.keys(lengthVal));
    }

    return {
      requireValid: Object.keys(emptyVal).length === 0,
      minMaxValid: Object.keys(lengthVal).length === 0,
    };
  }, [fields, form, multilingual, setLengthValidates, setRestRequires]);

  const handleFieldChange = useCallback(
    (field: string | undefined) => {
      if (!field) {
        setChangedFields([]);
      } else if (!changedFields.includes(field)) {
        setChangedFields((prev) => [...prev, field]);
      }
    },
    [changedFields]
  );

  const setContentItemValue = useCallback(
    (newContentItem: ContentItemType) => {
      setLocalContentItem(newContentItem);
      embedLevel === -1 && newContentItem.id && history.replace(String(newContentItem.id));
    },
    [history, embedLevel]
  );

  useEffect(() => {
    if (
      embedLevel === -1 &&
      ((contentItemId === '-1'
        ? localContentItem.id
        : !localContentItem.id || localContentItem.id !== +contentItemId) ||
        localContentTypeSlug !== contentTypeSlug)
    ) {
      reloadContentItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentItemId, contentTypeSlug, embedLevel, localContentTypeSlug, reloadContentItem]);

  useEffect(() => {
    if (embedLevel === -1) {
      return () => {
        dispatch(setNewVersion({}));
        dispatch(setCurrentVersion({}));
      };
    }
  }, [dispatch, embedLevel]);

  return (
    <>
      {loading && <Spin />}
      {!loading && (
        <ContentEditorContext.Provider
          value={{
            form,
            selectedLocales,
            setSelectedLocales,
            selectedCheckedLocales,
            setSelectedCheckedLocales,
            embedLevel,
            isReviewing: localContentItem.content_state?.code === 'reviewing',
            contentTypeSlug,
            showOnlyMultilingual,
            setShowOnlyMultilingual,
            multilingual,
            setMultilingualValue: setMultilingual,
            activeTab,
            setActiveTab,
            contentItem: localContentItem,
            selectedProjects,
            setSelectedProjects,
            toggledBar,
            setToggledBar,
            isEmbeddedItem,
            isNewItem,
            onCloseButtonClick,
            fields,
            groupFields,
            groups,
            restRequires,
            setRestRequires,
            lengthValidates,
            setLengthValidates,
            contentItemId: embedLevel === -1 ? contentItemId : String(localContentItem.id || -1),
            onEmbeddedFieldSave,
            reloadContentItem,
            setContentItemValue,
            contentChanged,
            setContentChanged,
            setContentLoading: setLoading,
            isTreeView,
            setTreeView,
            handleFieldChange,
            changedFields,
            params,
            parentId,
            parentSLug,
            fieldSlugInParent,
            mainSourceId: embedLevel === -1 ? mainSourceId : localContentItem.source_id,
            updateInfo,
            setUpdateInfo,
            treeUpdateInfo,
            setTreeUpdateInfo,
            inTreeView,
            projectsChanged,
            setProjectsChanged,
            handleValidate: () => validateFormOutsideValues(),
            setSelectedProjectOwner,
            selectedProjectOwner,
            rootProjectId,
            setLoadedReferable,
            loadedReferable,
            setOpenEmbeddedInfo,
            openEmbeddedInfo,
          }}
        >
          {children}
        </ContentEditorContext.Provider>
      )}
    </>
  );
};

export default ContentEditorProvider;
