import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    user: {
      display: 'flex',
      alignItems: 'center',
    },
    hide: {
      justifyContent: 'center',
    },
    avatar: {
      cursor: 'pointer',
      height: '24px',
      width: '24px',
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      color: '#fff',
      marginLeft: 10,
    },
    name: {
      padding: '0 16px 0 0',
      margin: 0,
      color: '#fff',
    },
    role: {
      padding: 0,
      position: 'relative',
      fontSize: '13px',
    },
    roleHint: {
      position: 'absolute',
      background: '#253858',
      borderRadius: 3,
      top: '30px',
      right: '5px',
      color: 'white',
      whiteSpace: 'nowrap',
      padding: 8,
      fontSize: '13px',
    },
    dot: {
      marginLeft: '4px',
      marginRight: '4px',
    },
  })
);
