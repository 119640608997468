import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, {
  ActionMeta,
  components,
  OptionTypeBase as OptionType,
  ValueType,
} from 'react-select';

import { ButtonPelican } from '../ButtonPelican';
import { ArrowSmallOutline } from '../IconPelican/icons';
import { Props } from './props';
import { getReactSelectStyles, useStyles } from './styles';

export const SelectPelican: React.FC<Props> = ({
  placeholder,
  options,
  selected,
  isMulti = false,
  multiButtons = true,
  name,
  value,
  isDisabled,
  handleCreateNew,
  handleEditItem,
  label,
  isLoading,
  onSelectAll,
  small = true,
  theme,
  size = 'medium',
  ...rest
}) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ref = useRef<any | null>();
  const SELECT_ALL_LABEL = 'Select all';
  const SELECT_ALL_VALUE = '<SELECT_ALL>';
  const [valueRows, setValueRows] = useState(1);
  const [shownRows, setShownRows] = useState(1);
  const classes = useStyles({ row: shownRows });
  const selectAllOption: OptionType = {
    label: t(SELECT_ALL_LABEL),
    value: SELECT_ALL_VALUE,
  };
  const [selectValue, setSelectValue] = useState<readonly ValueType<OptionType, boolean>[]>(value);
  const handleOnChange = (
    val: ValueType<OptionType, boolean> | OptionType,
    action: ActionMeta<OptionType>
  ): void => {
    if (!isMulti) {
      const selectVal = val as OptionType;
      setSelectValue([selectVal]);
      selected &&
        selected(
          selectVal && {
            label: t(selectVal.label),
            value: selectVal.value,
          },
          action
        );
    }
    if (
      isMulti &&
      action.action === 'select-option' &&
      action.option &&
      action.option.value !== SELECT_ALL_VALUE
    ) {
      setSelectValue(
        (val &&
          val.map((v: OptionType) => ({
            label: v.label,
            value: v.value,
          }))) ||
          []
      );
      selected &&
        selected(
          val &&
            val.map((v: OptionType) => ({
              label: v.label,
              value: v.value,
            })),
          action
        );
    }
    if (
      isMulti &&
      action.action === 'select-option' &&
      action.option &&
      action.option.value === SELECT_ALL_VALUE
    ) {
      if (onSelectAll) {
        onSelectAll();
      } else {
        const allOptions = options.map((v: OptionType) => ({
          label: v.label,
          value: v.value,
        }));
        (val &&
          setSelectValue([
            ...val.map((v: OptionType) => ({
              label: v.label,
              value: v.value,
            })),
            ...allOptions,
          ])) ||
          setSelectValue(allOptions);
        selected && selected(allOptions, action);
      }
    }
    if (isMulti && (action.action === 'remove-value' || action.action === 'clear')) {
      setSelectValue(
        (val &&
          val.map((v: OptionType) => ({
            label: v.label,
            value: v.value,
          }))) ||
          []
      );
      selected &&
        selected(
          (val &&
            val.map((v: OptionType) => ({
              label: v.label,
              value: v.value,
            }))) ||
            [],
          action
        );
    }
    if (isMulti && action.action === 'pop-value') {
      setSelectValue(selectValue.slice(0, -1));
    }
  };

  useEffect(() => {
    if (isMulti && name && selectValue) {
      if (name) {
        const cellElement = document.getElementById(rest.id || name) as HTMLInputElement;
        const [valuesContainer] = Array.from(
          cellElement.getElementsByClassName('react-select__value-container--is-multi')
        );
        if (valuesContainer && selectValue.length > 0) {
          const valueElements = Array.from(valuesContainer.children);
          const rows = Math.ceil(
            valuesContainer.scrollHeight / ((valueElements[0].clientHeight + 4) * 3)
          );
          rows !== valueRows && setValueRows(rows);
        } else if (selectValue.length === 0) {
          setValueRows(1);
          setShownRows(1);
        }
      }
    }
  }, [isMulti, name, selectValue, valueRows, rest.id]);

  const handleToggleSelectedList = (): void => {
    setShownRows(shownRows + 1);
  };
  const handleHideList = (): void => {
    setShownRows(1);
  };
  const handleShowAll = (): void => {
    setShownRows(valueRows);
  };

  const handleSelectAll = (): void => {
    const allOptions = options.map((v: OptionType) => ({
      label: v.label,
      value: v.value,
    }));
    setSelectValue(allOptions);
    selected &&
      selected(allOptions, {
        action: 'select-option',
        name,
        option: {
          label: t(SELECT_ALL_LABEL),
          value: SELECT_ALL_VALUE,
        },
      });
  };
  const handleRemoveAll = (): void => {
    setShownRows(1);
    setSelectValue([]);
    selected &&
      selected([], {
        action: 'clear',
        name,
      });
  };

  React.useEffect(() => {
    setSelectValue(value);
  }, [value]);

  return (
    <>
      {label && <span className={classes.label}>{label}</span>}
      <ReactSelect
        {...rest}
        ref={ref}
        components={{
          // eslint-disable-next-line react/display-name
          DropdownIndicator: (props) => (
            <components.DropdownIndicator {...props}>
              <ArrowSmallOutline />
            </components.DropdownIndicator>
          ),
        }}
        className={clsx('single-select', classes.root)}
        classNamePrefix="react-select"
        id={rest.id || name}
        name={name}
        options={(isMulti && !isLoading && [selectAllOption, ...options]) || options}
        placeholder={placeholder}
        onChange={handleOnChange}
        isMulti={isMulti}
        tabSelectsValue={false}
        value={selectValue}
        closeMenuOnSelect={!isMulti}
        isDisabled={isDisabled}
        onKeyDown={(e) => {
          if (e.keyCode === 27) {
            ref.current.select.blur();
            e.stopPropagation();
          }
        }}
        isLoading={isLoading}
        styles={getReactSelectStyles({
          placeholder,
          options,
          selected,
          isMulti,
          multiButtons,
          name,
          value,
          isDisabled,
          handleCreateNew,
          handleEditItem,
          label,
          isLoading,
          onSelectAll,
          small,
          size,
          theme,
          ...rest,
        })}
      />
      {isMulti && (
        <div className={classes.flex}>
          <div>
            {shownRows < valueRows && valueRows > 1 && (
              <ButtonPelican
                onClick={handleToggleSelectedList}
                variant="text"
                className={classes.showHideBtn}
              >
                {t('Show more')}
              </ButtonPelican>
            )}
            {shownRows > 1 && (
              <ButtonPelican
                className={classes.showHideBtn}
                variant="text"
                onClick={handleHideList}
              >
                {t('Hide')}
              </ButtonPelican>
            )}
          </div>
          {shownRows < valueRows && valueRows > 1 && (
            <ButtonPelican onClick={handleShowAll} variant="text" className={classes.showHideBtn}>
              {t('Show all', { itemCount: selectValue.length })}
            </ButtonPelican>
          )}
        </div>
      )}
      {!handleCreateNew && isMulti && multiButtons && !isDisabled && (
        <>
          <ButtonPelican
            onClick={() => (onSelectAll ? onSelectAll() : handleSelectAll())}
            variant="text"
          >
            {t('Select all')}
          </ButtonPelican>
          <ButtonPelican onClick={handleRemoveAll} variant="text">
            {t('Remove all')}
          </ButtonPelican>
        </>
      )}
      {handleCreateNew && !isDisabled && (
        <ButtonPelican onClick={handleCreateNew} variant="text" data-cy="create-new">
          {t('Create new')}
        </ButtonPelican>
      )}
      {handleEditItem && selectValue && selectValue.length === 1 && !isDisabled && (
        <ButtonPelican onClick={handleEditItem} variant="text" data-cy="edit">
          {t('Edit')}
        </ButtonPelican>
      )}
    </>
  );
};
