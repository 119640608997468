import YandexMap from 'containers/YandexMap/component';
import React, { useCallback } from 'react';

import { useContentEditorProvider } from '../../useContentEditorProvider';
import { Props } from './props';

export const ContentGeoPoint: React.FC<Props> = ({ field }) => {
  const { form, handleFieldChange, isReviewing } = useContentEditorProvider();

  const { getValues, setValue } = form;

  const value = getValues(field.slug);

  const onChangePoint = useCallback(
    (val?: string) => {
      setValue(field.slug, val || '');
      handleFieldChange(field.slug);
    },
    [field.slug, handleFieldChange, setValue]
  );

  return <YandexMap disabled={isReviewing} defaultValue={value} onChangePoint={onChangePoint} />;
};
