import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    messageInput: {
      width: '100%',
      '& .MuiOutlinedInput-inputMultiline': {
        minHeight: 150,
      },
    },
    messageTitle: {
      fontWeight: 500,
      color: '#172B4D',
    },
    messageDescription: {
      marginBottom: 12,
    },
    warning: {
      color: '#BF2E0D',
      marginBottom: 8,
    },
    modalHeader: {
      padding: 24,
      borderBottom: '2px solid #EBECF0',
    },
    modalBody: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '24px 54px',
      borderBottom: '2px solid #EBECF0',
      flexDirection: 'column',
    },
    textareaContainer: {
      width: '75%',
    },
    modalFooter: {
      display: 'flex',
      justifyContent: 'end',
      padding: '10px 24px',
    },
    cancelButton: {
      color: '#172B4D',
      marginLeft: 6,
    },
  })
);
