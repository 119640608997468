import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  multilangual: {
    '&:first-child': {
      marginTop: 0,
    },
    marginTop: 6,
  },
  locale: {
    color: '#545456',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    '&>span': {
      margin: '0 4px',
    },
  },
  emptyLocale: {
    color: '#97A0AF',
    fontSize: '11px',
    lineHeight: '16px',
    fontWeight: 600,
  },
  editorButton: {
    cursor: 'pointer',
    border: 'none',
    color: theme.palette.blue[500],
    background: 'transparent',
    fontWeight: 'bold',
    height: '20px',
    marginTop: -20,
    padding: 0,
  },
  wysiwyg: {
    background: theme.palette.surface[50],
    border: '1px solid #D3D4D6',
    borderRadius: 4,
    padding: '4px 12px',
    minHeight: 32,
  },
  wysiwygWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));
