import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    boxShadow: '0px 0px 1px rgba(9, 30, 66, 0.31), 0px 1px 1px rgba(9, 30, 66, 0.25)',
    marginBottom: 8,
    borderRadius: 3,
    padding: '16px 16px 16px 0',
  },
  firstRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sortButton: {
    width: 60,
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 'normal',
    color: '#253858',
    flexGrow: 1,
  },
  editButton: {
    fontSize: 14,
    color: '#0052CC',
    background: 'none',
    fontWeight: 'normal',
    paddingLeft: 4,
    paddingRight: 4,
  },
  secondRow: {
    paddingLeft: 16,
    textAlign: 'center',
    color: 'red',
  },
  displayField: {
    display: 'flex',
  },
  displayFieldLabel: {
    alignSelf: 'center',
    width: '39%',
  },
  displayFieldDivider: {
    width: '2%',
  },
  displayFieldContent: {
    maxWidth: 50,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  displayFieldItem: {
    flex: '0 50%',
    '& .image-IMAGE': {
      width: 150,
    },
    '& .image-ICON': {
      width: 60,
    },
  },
  embeddedModal: {
    paddingTop: 40,
  },
  table: {
    width: '100%',
    '& tr': {
      borderBottom: '1px solid #D6D7DC',
    },
    '& td': {
      height: 30,
    },
  },
  absolute: {
    zIndex: 111,
    borderRadius: 3,
    padding: 5,
    position: 'absolute',
    background: '#ffffff',
  },
  refFieldLabel: {
    position: 'relative',
  },
  maxWidth: {
    maxWidth: '100%',
  },
  draggable: {
    userSelect: 'auto',
  },
});
