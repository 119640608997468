/* eslint-disable functional/prefer-readonly-type */
import { useQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useCustomPagination } from 'common/hooks/useCustomPagination';
import { CheckboxPelican } from 'components/CheckboxPelican';
import { Spin } from 'components/Spin';
import { TextInput } from 'components/TextInput';
import NoData from 'containers/NoData/NoData';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NoDiff } from '../../NoDiff/NoDiff';
import { useReleasesContext } from '../ReleasesProvider';
import { Content, ContentDiff } from '../types/DiffModels';
import { DiffContentParam } from '../types/DiffParam';
import { PublishItemDto } from '../types/PublishItemDto';
import ModelDiffLink from './ModelDiffLink';
import { CONTENT_DIFF } from './queries';
import { useStyles } from './styles';

export const ModelDiff: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  const { page, perPage, pageCount, setPageCount, setPage } = useCustomPagination();
  const { enqueueSnackbar } = useSnackbar();
  const [noDiff, setNoDiff] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  const {
    selectedContentName,
    selectedSlugId,
    envFrom,
    envTo,
    publishItems,
    setPublishItems,
    leftSlug,
  } = useReleasesContext();
  const { data, loading } = useQuery<{ readonly contentDiff: ContentDiff }, DiffContentParam>(
    CONTENT_DIFF,
    {
      variables: {
        leftEnvId: envFrom || '',
        rightEnvId: envTo || '',
        contentTypeId: selectedSlugId,
        onlyChanged: true,
        _page: page - 1,
        _size: perPage,
        sourceIds: searchVal ? [searchVal] : [],
      },
      skip: !(envFrom && envTo && selectedSlugId),
      fetchPolicy: 'network-only',
    }
  );

  const contents = data?.contentDiff?.data || [];
  const currentPublishItem = useMemo(
    () => publishItems.find((i) => i.contentTypeId === selectedSlugId),
    [publishItems, selectedSlugId]
  );

  const sources = useMemo(() => currentPublishItem?.sourceIds || [], [currentPublishItem]);

  const handleCheckAll = (checked: boolean): void => {
    const index = publishItems.findIndex((i) => i.contentTypeId === selectedSlugId);
    if (index !== -1) {
      const item = publishItems[index];
      const newPublishItems: PublishItemDto[] = [
        ...publishItems.slice(0, index),
        {
          ...item,
          sourceIds: checked
            ? item.sourceIds.concat(
                contents.map((d) => d.sourceId).filter((d) => item.sourceIds.indexOf(d) === -1)
              )
            : item.sourceIds.filter((id) => !contents.some((d) => d.sourceId === id)),
          publishAll: checked,
        },
        ...publishItems.slice(index + 1),
      ];

      setPublishItems(newPublishItems);
    } else {
      enqueueSnackbar(t('Choose please content type'), {
        variant: 'warning',
      });
    }
  };

  const onChangePage = (_e: ChangeEvent<unknown>, page: number): void => {
    setPage(page);
  };

  const handleSearch = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const val = e.target.value;
    setSearchVal(val);
  };

  const handleChange = (checked: boolean, val: Content): void => {
    const index = publishItems.findIndex((i) => i.contentTypeId === selectedSlugId);
    if (index !== -1) {
      const item = publishItems[index];
      // eslint-disable-next-line functional/no-let
      let newSourceIds: number[];
      if (checked && item) {
        newSourceIds = [...item.sourceIds, val.sourceId];
      } else {
        newSourceIds = item?.sourceIds?.filter((s) => s !== val.sourceId);
      }

      const newPublishItems: PublishItemDto[] = [
        ...publishItems.slice(0, index),
        { ...item, sourceIds: newSourceIds },
        ...publishItems.slice(index + 1),
      ];

      setPublishItems(newPublishItems);
    } else {
      enqueueSnackbar(t('Choose please content type'), {
        variant: 'warning',
      });
    }
  };

  useEffect(() => {
    setPageCount(data?.contentDiff?.totalPages || 0);
  }, [data, setPageCount]);

  useEffect(() => {
    if (selectedContentName) {
      setPage(1);
    }
  }, [setPage, selectedContentName]);

  useEffect(() => {
    if (data?.contentDiff?.data?.length === 0) {
      setNoDiff(true);
    } else {
      setNoDiff(false);
    }
  }, [data]);

  return (
    <Box className={styles.container}>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Typography style={{ fontWeight: 'bold' }} variant={'h3'}>
          {selectedContentName || t('Content')}
        </Typography>
      </Box>
      {selectedSlugId && (
        <Box display={'flex'} mt={2} alignItems={'center'} marginBottom={1}>
          <div className={styles.checkbox}>
            <CheckboxPelican
              checked={
                contents.length > 0 &&
                currentPublishItem?.sourceIds.filter((id) =>
                  contents.some((c) => c.sourceId === id)
                ).length === contents.length
              }
              onChange={(_e, checked) => handleCheckAll(checked)}
            />
          </div>
          <Box flex={1}>
            <TextInput value={searchVal} placeholder={t('Search')} onChange={handleSearch} />
          </Box>
        </Box>
      )}

      <Box position={'relative'} height={'60%'}>
        {loading && <Spin bg={'transparent'} variant={'absolute'} />}
        {!data?.contentDiff?.data?.length && !loading && !noDiff && <NoData />}
        {noDiff && <NoDiff />}
        {data?.contentDiff?.data?.map((l) => (
          <div key={l.sourceId} className={styles.item}>
            <Box display={'flex'}>
              <div className={styles.checkbox}>
                <CheckboxPelican
                  checked={sources.includes(l.sourceId)}
                  onChange={(_e, checked) => handleChange(checked, l)}
                />
              </div>
              <Typography variant={'body2'} style={{ color: '#0039D4', fontWeight: 500 }}>
                {l.sourceId}
              </Typography>
            </Box>
            <ModelDiffLink
              left={l?.versions?.left}
              right={l?.versions?.right}
              leftId={l?.ids?.left}
              rightId={l?.ids?.right}
              slug={leftSlug || ''}
              state={l?.state}
            />
          </div>
        ))}
        <Pagination
          classes={{ root: styles.pagination }}
          page={page}
          onChange={onChangePage}
          count={pageCount}
          variant="outlined"
          color={'primary'}
          shape="rounded"
        />
      </Box>
    </Box>
  );
};

export default ModelDiff;
