import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const FlagOutline: React.FC<SvgIconProps> = ({ ...props }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.266 5.492c.004-.015.025-.06.106-.127.129-.107.38-.238.796-.315.83-.155 2.186-.068 4.064.65l.01.004c1.913.7 3.321.876 4.374.854a8.002 8.002 0 001.285-.133c.16-.03.277-.053.371-.072.13-.027.215-.044.299-.052l.028-.002.027-.005a3.38 3.38 0 01.129-.02c0 .037-.001.079-.004.127l-.001.02v6.442a.786.786 0 01-.02.221.383.383 0 01-.09.064 2.247 2.247 0 01-.287.129c-1.096.215-1.885.267-2.616.192-.748-.078-1.481-.293-2.47-.67h-.001c-2.34-.886-4.07-.988-6-.671V5.491zm0 8.158v6.1a.75.75 0 01-1.5 0V5.503c-.006-.529.268-.977.648-1.292.378-.314.889-.526 1.48-.636 1.185-.22 2.828-.057 4.869.722 1.757.643 2.978.78 3.82.76.424-.008.766-.057 1.05-.108l.233-.045c.182-.037.378-.076.532-.093a2.95 2.95 0 01.585-.048c.212.007.482.054.73.229.513.364.56.99.537 1.469v6.378c.008.42-.064.853-.38 1.22-.283.331-.685.508-1.047.644l-.056.02-.06.012c-1.215.243-2.18.323-3.124.226-.94-.098-1.816-.366-2.85-.76-2.159-.818-3.664-.88-5.467-.551z"
      fill="#000"
    />
  </SvgIcon>
);
export default FlagOutline;
