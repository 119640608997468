import { createSlice } from '@reduxjs/toolkit';
import {
  integrationMappingDelete,
  integrationMappingPost,
  integrationMappingPut,
} from 'api/integration-mappings';
import {
  integrationAttrDelete,
  integrationAttrPost,
  integrationAttrPut,
} from 'api/integration-request-attributes';
import { IntegrationMappingItemModel } from 'api/types/IntegrationMappingItemModel';
import { IntegrationRequestAttribute } from 'api/types/IntegrationRequestAttribute';
import { changeRequestAttribute } from 'pages/Integrations/IntegrationEdit/helpers';
import { RequestAttributeType } from 'pages/Integrations/IntegrationEdit/types/RequestAttributeType';
import { Action } from 'redux';
import { AppThunk } from 'store/store';

import { IntegrationState } from './types/integrationState';

const initialState: IntegrationState = {
  headers: [],
  body: [],
  pathVariables: [],
  params: [],
  mappings: [],
  authType: 'None',
};

const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    setIntegrationAttribute(
      state,
      act: Action & {
        readonly payload: {
          readonly type: RequestAttributeType;
          readonly action: 'ADD' | 'EDIT' | 'DELETE';
          readonly item: IntegrationRequestAttribute;
          readonly index?: number;
        };
      }
    ) {
      const { action, item, index, type } = act.payload;
      switch (type) {
        case RequestAttributeType.BODY_ENTRY_FORM_URLENCODED:
          return { ...state, body: changeRequestAttribute(state.body, action, item, index) };
        case RequestAttributeType.HEADER:
          return { ...state, headers: changeRequestAttribute(state.headers, action, item, index) };
        case RequestAttributeType.PARAM:
          return { ...state, params: changeRequestAttribute(state.params, action, item, index) };
        case RequestAttributeType.PATH_VARIABLE:
          return {
            ...state,
            pathVariables: changeRequestAttribute(state.pathVariables, action, item, index),
          };
      }
    },
    setIntegrationMapping(
      state,
      act: Action & {
        readonly payload: {
          readonly action: 'ADD' | 'EDIT' | 'DELETE';
          readonly item: IntegrationMappingItemModel;
          readonly index?: number;
        };
      }
    ) {
      const { action, item, index } = act.payload;

      return {
        ...state,
        mappings: changeRequestAttribute(state.mappings, action, item, index),
      };
    },
    setIntegrationExisting(
      state,
      action: Action & {
        readonly payload: {
          readonly attributes: readonly IntegrationRequestAttribute[];
          readonly mappings: readonly IntegrationMappingItemModel[];
        };
      }
    ) {
      const { attributes, mappings } = action.payload;
      const params = attributes.filter((a) => a.attribute_type === RequestAttributeType.PARAM);
      const headers = attributes.filter((a) => a.attribute_type === RequestAttributeType.HEADER);
      const body = attributes.filter(
        (a) => a.attribute_type === RequestAttributeType.BODY_ENTRY_FORM_URLENCODED
      );
      const pathVars = attributes.filter(
        (a) => a.attribute_type === RequestAttributeType.PATH_VARIABLE
      );

      return {
        ...state,
        mappings: mappings,
        params: params,
        headers: headers,
        body: body,
        pathVariables: pathVars,
      };
    },
    setAuthType(state, action: Action & { readonly payload: string }) {
      return { ...state, authType: action.payload };
    },
    setAuthLogin(state, action: Action & { readonly payload: string }) {
      return { ...state, login: action.payload };
    },
    setAuthPassword(state, action: Action & { readonly payload: string }) {
      return { ...state, password: action.payload };
    },
    setAuthToken(state, action: Action & { readonly payload: string }) {
      return { ...state, token: action.payload };
    },
  },
});

export const {
  setIntegrationAttribute,
  setAuthLogin,
  setAuthPassword,
  setAuthToken,
  setAuthType,
  setIntegrationMapping,
  setIntegrationExisting,
} = integrationSlice.actions;

export const saveIntegrationAttribute = (data: {
  readonly type: RequestAttributeType;
  readonly action: 'ADD' | 'EDIT' | 'DELETE';
  readonly item: IntegrationRequestAttribute;
  readonly index?: number;
}): AppThunk => async (dispatch) => {
  try {
    const { item, action } = data;

    if (item.id) {
      dispatch(setIntegrationAttribute({ ...data }));

      if (action === 'DELETE') {
        await integrationAttrDelete(item.id);
      } else {
        if (item.key && item.value) {
          await integrationAttrPut(item);
        }
      }
    } else {
      if (item.key && item.value) {
        const newItem = await integrationAttrPost(item);
        dispatch(setIntegrationAttribute({ ...data, item: newItem }));
      } else {
        dispatch(setIntegrationAttribute({ ...data }));
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const saveIntegrationMapping = (data: {
  readonly action: 'ADD' | 'EDIT' | 'DELETE';
  readonly item: IntegrationMappingItemModel;
  readonly index?: number;
}): AppThunk => async (dispatch) => {
  try {
    const { item, action } = data;

    if (item.id) {
      dispatch(setIntegrationMapping(data));

      if (action === 'DELETE') {
        await integrationMappingDelete(item.id);
      } else {
        if (item.label && item.mapping_path && item.mapping_path.length > 2) {
          await integrationMappingPut(item);
        }
      }
    } else {
      if (item.label && item.mapping_path && item.mapping_path.length > 2) {
        const newItem = await integrationMappingPost(item);
        dispatch(setIntegrationMapping({ ...data, item: newItem }));
      } else {
        dispatch(setIntegrationMapping(data));
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const {
  getIntegrationAttributes,
  getIntegrationHeaders,
  getIntegrationBody,
  getIntegrationAuthLogin,
  getIntegrationAuthPassword,
  getIntegrationAuthToken,
  getIntegrationAuthType,
  getIntegrationPathVars,
  getIntegrationParams,
  getIntegrationMappings,
} = {
  getIntegrationAttributes(state: { readonly integration: IntegrationState }) {
    return state.integration;
  },
  getIntegrationHeaders(state: { readonly integration: IntegrationState }) {
    return state.integration.headers;
  },
  getIntegrationBody(state: { readonly integration: IntegrationState }) {
    return state.integration.body;
  },
  getIntegrationParams(state: { readonly integration: IntegrationState }) {
    return state.integration.params;
  },
  getIntegrationPathVars(state: { readonly integration: IntegrationState }) {
    return state.integration.pathVariables;
  },
  getIntegrationMappings(state: { readonly integration: IntegrationState }) {
    return state.integration.mappings;
  },
  getIntegrationAuthType(state: { readonly integration: IntegrationState }) {
    return state.integration.authType;
  },
  getIntegrationAuthLogin(state: { readonly integration: IntegrationState }) {
    return state.integration.login;
  },
  getIntegrationAuthPassword(state: { readonly integration: IntegrationState }) {
    return state.integration.password;
  },
  getIntegrationAuthToken(state: { readonly integration: IntegrationState }) {
    return state.integration.token;
  },
};

export default integrationSlice.reducer;
